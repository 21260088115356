import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { skillToSolve } from "../utils";
import { useParams } from "react-router-dom";

type Props = {
  trackEvent(
    trackingEvent:
      | "status_ping"
      | "unfocus_browser"
      | "start_problem"
      | "focus_browser"
      | "close_problem",
    eventTimestamp: Date,
    sc?: string,
    uniqueString?: string,
    saId?: string,
    oldProblemIds?: {
      skillCode: string;
      uniqueString: string;
      studentAssignmentId: string;
    }
  ): void;
  children: ReactElement;
};

const ProblemEventTracker = (props: Props) => {
  // Loading in problem data
  const { studentNextProblems, activeSection, dmAssignmentData } = useContext(
    StudentSectionsContext
  );
  const solveSkill: any =
    dmAssignmentData && activeSection ? skillToSolve() : undefined;
  const currentProblemData =
    solveSkill?.sa?._id && solveSkill?.ta?.skillName
      ? studentNextProblems[solveSkill.sa._id]?.[solveSkill.ta.skillName]
      : undefined;
  const uniqueString = currentProblemData
    ? currentProblemData.unique_string ?? currentProblemData._id
    : undefined;

  const { skillCode } = useParams();

  const [currentProblemIds, setCurrentProblemIds] = useState<{
    skillCode: string;
    uniqueString: string;
    studentAssignmentId: string;
  }>();
  const problemIdRef = useRef(currentProblemIds);
  problemIdRef.current = currentProblemIds;

  // ********************* //
  // PROBLEM CODES CHANGED //
  // ********************* //

  const codeChange = (
    skillCode?: string,
    uniqueString?: string,
    saId?: string
  ) => {
    if (skillCode && uniqueString && saId) {
      props.trackEvent(
        "start_problem",
        new Date(),
        skillCode,
        uniqueString,
        saId,
        currentProblemIds
      );

      setCurrentProblemIds({
        skillCode,
        uniqueString,
        studentAssignmentId: saId,
      });
    } else {
      if (currentProblemIds) {
        navigateAway(
          currentProblemIds.uniqueString,
          currentProblemIds.skillCode,
          currentProblemIds.studentAssignmentId
        );
      }
    }
  };

  useEffect(() => {
    // If params are undefined but we have them in state we navigated away so we can call that we left
    if (
      !skillCode &&
      !uniqueString &&
      !solveSkill?.sa?._id &&
      currentProblemIds
    ) {
      navigateAway(
        currentProblemIds.uniqueString,
        currentProblemIds.skillCode,
        currentProblemIds.studentAssignmentId
      );
      setCurrentProblemIds(undefined);
    }
    // Call code change no matter if it's undefined or not this will make sure previous calls are overwritten
    codeChange(skillCode, uniqueString, solveSkill?.sa?._id);
  }, [skillCode, uniqueString, solveSkill?.sa?._id]);

  // ************* //
  // WINDOW EVENTS //
  // ************* //

  const windowClosing = () => {
    props.trackEvent(
      "close_problem",
      new Date(),
      problemIdRef.current?.skillCode,
      problemIdRef.current?.uniqueString,
      problemIdRef.current?.studentAssignmentId
    );
  };

  function handleActivity(forcedFlag: boolean | object) {
    if (typeof forcedFlag === "boolean") {
      if (forcedFlag) {
        props.trackEvent(
          "focus_browser",
          new Date(),
          problemIdRef.current?.skillCode,
          problemIdRef.current?.uniqueString,
          problemIdRef.current?.studentAssignmentId
        );
      } else {
        props.trackEvent(
          "unfocus_browser",
          new Date(),
          problemIdRef.current?.skillCode,
          problemIdRef.current?.uniqueString,
          problemIdRef.current?.studentAssignmentId
        );
      }
      return;
    }
    if (document.hidden) {
      props.trackEvent(
        "unfocus_browser",
        new Date(),
        problemIdRef.current?.skillCode,
        problemIdRef.current?.uniqueString,
        problemIdRef.current?.studentAssignmentId
      );
    } else {
      props.trackEvent(
        "focus_browser",
        new Date(),
        problemIdRef.current?.skillCode,
        problemIdRef.current?.uniqueString,
        problemIdRef.current?.studentAssignmentId
      );
    }
  }

  const handleActivityFalse = () => handleActivity(false);
  const handleActivityTrue = () => handleActivity(true);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleActivity);
    document.addEventListener("blur", handleActivityFalse);
    window.addEventListener("blur", handleActivityFalse);
    window.addEventListener("focus", handleActivityTrue);
    document.addEventListener("focus", handleActivityTrue);
    window.addEventListener("beforeunload", windowClosing);

    return () => {
      window.removeEventListener("blur", handleActivity);
      document.removeEventListener("blur", handleActivityFalse);
      window.removeEventListener("focus", handleActivityFalse);
      document.removeEventListener("focus", handleActivityTrue);
      document.removeEventListener("visibilitychange", handleActivityTrue);
      window.removeEventListener("beforeunload", windowClosing);
    };
  }, []);

  const navigateAway = (
    uniqueString: string,
    skillCode: string,
    studentAssignmentId: string
  ) => {
    if (uniqueString && skillCode && studentAssignmentId) {
      props.trackEvent(
        "close_problem",
        new Date(),
        skillCode,
        uniqueString,
        studentAssignmentId
      );
      setCurrentProblemIds(undefined);
    }
  };

  // ************** //
  // 10 SECOND PING //
  // ************** //

  const timedPing = () => {
    if (problemIdRef.current !== undefined) {
      props.trackEvent(
        "status_ping",
        new Date(),
        problemIdRef.current.skillCode,
        problemIdRef.current.uniqueString,
        problemIdRef.current.studentAssignmentId
      );
    }
    setTimeout(timedPing, 10000);
  };

  useEffect(() => {
    timedPing();
  }, []);

  return <>{props.children}</>;
};
export default ProblemEventTracker;
