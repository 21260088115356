/* eslint-disable */
// Originally taken from https://github.com/isaacphysics/isaac-react-app/commit/ce3ba2c19969a293101daaa85c5381d4114e53a4
// And based on https://github.com/KaTeX/KaTeX/blob/v0.11.1/contrib/render-a11y-string/render-a11y-string.js

// Latest Khan version: https://github.com/KaTeX/KaTeX/blob/main/contrib/render-a11y-string/render-a11y-string.js
// Latest isaac version: https://github.com/isaacphysics/isaac-react-app/blob/master/src/app/services/katex-a11y.js
/**
 * renderA11yString returns a readable string.
 *
 * In some cases the string will have the proper semantic math
 * meaning,:
 *   renderA11yString("\\frac{1}{2}"")
 *   -> "start fraction, 1, divided by, 2, end fraction"
 *
 * However, other cases do not:
 *   renderA11yString("f(x) = x^2")
 *   -> "f, left parenthesis, x, right parenthesis, equals, x, squared"
 *
 * The commas in the string aim to increase ease of understanding
 * when read by a screenreader.
 */

import { filter } from "lodash";
import {
  stringMap,
  powerMap,
  openMap,
  closeMap,
  binMap,
  relMap,
  accentUnderMap,
  denominatorMap,
  arrowMap,
} from "./string-mappings";

declare global {
  interface Window {
    MQ: any;
    $: any;
    katex: any;
    renderA11yString: any;
  }
}

const dropZoneRegex =
  /\[drop-zone(?<params>\|(?<index>i-\d+?)?(?<width>w-\d+?)?(?<height>h-\d+?)?)?]/g;

const buildString = (str: string, type: string, a11yStrings: string[]) => {
  if (!str) {
    return;
  }

  let ret;

  if (type === "open") {
    ret = str in openMap ? openMap[str] : stringMap[str] || str;
  } else if (type === "close") {
    ret = str in closeMap ? closeMap[str] : stringMap[str] || str;
  } else if (type === "bin") {
    ret = binMap[str] || str;
  } else if (type === "rel") {
    ret = relMap[str] || str;
  } else if (type === "arrow") {
    ret = arrowMap[str] || "arrow";
  } else {
    ret = stringMap[str] || str;
  }

  // If the text to add is a number and the last string is a number, then
  // combine them into a single number. Do similar if this text is inside a
  // 'start text' region.
  let numRegex = /^\d+$/;
  let startTextRegex = /^start ((bold|italic) )?text$/;
  if (
    (a11yStrings.length > 0 &&
      numRegex.test(ret) &&
      numRegex.test(a11yStrings[a11yStrings.length - 1])) ||
    (a11yStrings.length > 1 &&
      type === "normal" &&
      startTextRegex.test(a11yStrings[a11yStrings.length - 2]))
  ) {
    a11yStrings[a11yStrings.length - 1] += ret;
  } else if (ret) {
    a11yStrings.push(ret);
  }
};

const buildRegion = (a11yStrings: string[][], callback: any) => {
  const regionStrings: string[] = [];
  a11yStrings.push(regionStrings);
  callback(regionStrings);
};

const handleObject = (tree: any, a11yStrings: any, atomType: any) => {
  // Everything else is assumed to be an object...
  switch (tree.type) {
    case "accent": {
      buildRegion(a11yStrings, (a11yStrings: any) => {
        buildA11yStrings(tree.base, a11yStrings, atomType);
        a11yStrings.push("with");
        buildString(tree.label, "normal", a11yStrings);
        a11yStrings.push("on top");
      });
      break;
    }

    case "accentUnder": {
      buildRegion(a11yStrings, (a11yStrings: any) => {
        buildA11yStrings(tree.base, a11yStrings, atomType);
        a11yStrings.push("with");
        buildString(accentUnderMap[tree.label], "normal", a11yStrings);
        a11yStrings.push("underneath");
      });
      break;
    }

    case "accent-token": {
      // Used internally by accent symbols.
      break;
    }

    case "atom": {
      const { text } = tree;
      switch (tree.family) {
        case "bin": {
          buildString(text, "bin", a11yStrings);
          break;
        }
        case "close": {
          buildString(text, "close", a11yStrings);
          break;
        }
        // TODO(kevinb): figure out what should be done for inner
        case "inner": {
          buildString(tree.text, "inner", a11yStrings);
          break;
        }
        case "open": {
          buildString(text, "open", a11yStrings);
          break;
        }
        case "punct": {
          buildString(text, "punct", a11yStrings);
          break;
        }
        case "rel": {
          buildString(text, "rel", a11yStrings);
          break;
        }
        default: {
          console.log(`"${tree.family}" is not a valid atom type`);
          break;
        }
      }
      break;
    }

    case "color": {
      const color = tree.color.replace(/katex-/, "");

      buildRegion(a11yStrings, (regionStrings: any) => {
        // regionStrings.push("start color " + color);
        buildA11yStrings(tree.body, regionStrings, atomType);
        // regionStrings.push("end color " + color);
      });
      break;
    }

    case "color-token": {
      // Used by \color, \colorbox, and \fcolorbox but not directly rendered.
      // It's a leaf node and has no children so just break.
      break;
    }

    case "delimsizing": {
      if (tree.delim && tree.delim !== ".") {
        buildString(tree.delim, "normal", a11yStrings);
      }
      break;
    }

    case "genfrac": {
      buildRegion(a11yStrings, (regionStrings: any) => {
        // genfrac can have unbalanced delimiters
        const { leftDelim, rightDelim } = tree;

        // NOTE: Not sure if this is a safe assumption
        // hasBarLine true -> fraction, false -> binomial
        if (tree.hasBarLine) {
          const numeratorString = flatten(
            buildA11yStrings(tree.numer, [], atomType)
          ).join(",");
          const denominatorString = flatten(
            buildA11yStrings(tree.denom, [], atomType)
          ).join(",");
          if ("1" === numeratorString && denominatorString in denominatorMap) {
            regionStrings.push(`one ${denominatorMap[denominatorString]}`);
          } else {
            regionStrings.push("start fraction");
            leftDelim && buildString(leftDelim, "open", regionStrings);
            buildA11yStrings(tree.numer, regionStrings, atomType);
            regionStrings.push("divided by");
            buildA11yStrings(tree.denom, regionStrings, atomType);
            rightDelim && buildString(rightDelim, "close", regionStrings);
            regionStrings.push("end fraction");
          }
        } else {
          regionStrings.push("start binomial");
          leftDelim && buildString(leftDelim, "open", regionStrings);
          buildA11yStrings(tree.numer, regionStrings, atomType);
          regionStrings.push("over");
          buildA11yStrings(tree.denom, regionStrings, atomType);
          rightDelim && buildString(rightDelim, "close", regionStrings);
          regionStrings.push("end binomial");
        }
      });
      break;
    }
    case "hbox": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "kern": {
      // No op: we don't attempt to present kerning information
      // to the screen reader.
      break;
    }

    case "leftright": {
      buildRegion(a11yStrings, (regionStrings: any) => {
        buildString(tree.left, "open", regionStrings);
        buildA11yStrings(tree.body, regionStrings, atomType);
        buildString(tree.right, "close", regionStrings);
      });
      break;
    }

    case "leftright-right": {
      // TODO: double check that this is a no-op
      break;
    }

    case "lap": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "mathord": {
      buildString(tree.text, "normal", a11yStrings);
      break;
    }

    case "op": {
      const { body, name } = tree;
      if (body) {
        buildA11yStrings(body, a11yStrings, atomType);
      } else if (name) {
        buildString(name, "normal", a11yStrings);
      }
      break;
    }

    case "op-token": {
      // Used internally by operator symbols.
      buildString(tree.text, atomType, a11yStrings);
      break;
    }

    case "ordgroup": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "overline": {
      buildRegion(a11yStrings, function (a11yStrings: any) {
        a11yStrings.push("start overline");
        buildA11yStrings(tree.body, a11yStrings, atomType);
        a11yStrings.push("end overline");
      });
      break;
    }

    case "pmb": {
      a11yStrings.push("bold");
      break;
    }

    case "phantom": {
      a11yStrings.push("empty space");
      break;
    }

    case "raisebox": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "rule": {
      a11yStrings.push("rectangle");
      break;
    }

    case "sizing": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "spacing": {
      a11yStrings.push("space");
      break;
    }

    case "styling": {
      // We ignore the styling and just pass through the contents
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "sqrt": {
      buildRegion(a11yStrings, (regionStrings: any) => {
        const { body, index } = tree;
        if (index) {
          const indexString = flatten(
            buildA11yStrings(index, [], atomType)
          ).join(",");
          if (indexString === "3") {
            regionStrings.push("cube root of");
            buildA11yStrings(body, regionStrings, atomType);
            regionStrings.push("end cube root");
            return;
          }

          regionStrings.push("root");
          regionStrings.push("start index");
          buildA11yStrings(index, regionStrings, atomType);
          regionStrings.push("end index");
          return;
        }

        regionStrings.push("square root of");
        buildA11yStrings(body, regionStrings, atomType);
        regionStrings.push("end square root");
      });
      break;
    }

    case "supsub": {
      const { base, sub, sup } = tree;
      const opType = base && base.type === "op" ? base.name : null;
      let isSimpleSubscriptVariable = false;

      // case e.g. "q_{1}q_{2}" to be read as "q one q two":
      if (base && base.type === "mathord" && sub) {
        const baseString = flatten(buildA11yStrings(base, [], atomType)).join(
          ","
        );
        const subscriptString = flatten(
          buildA11yStrings(sub, [], atomType)
        ).join(",");
        if (
          /^[a-zA-Z]$/.test(baseString) &&
          /^([0-9]+|\\textrm{(min|max)})$/.test(subscriptString)
        ) {
          buildRegion(a11yStrings, function (regionStrings: any) {
            regionStrings.push(`${baseString} ${subscriptString}`);
          });
          isSimpleSubscriptVariable = true;
        }
      }
      // otherwise ordinary base, subscript and superscript:

      if (base && !isSimpleSubscriptVariable) {
        buildA11yStrings(base, a11yStrings, atomType);
      }

      if (sub && !isSimpleSubscriptVariable) {
        switch (opType) {
          case "\\log":
            buildRegion(a11yStrings, function (regionStrings: any) {
              regionStrings.push(`base`);
              buildA11yStrings(sub, regionStrings, atomType);
            });
            break;
          case "\\int":
          case "\\sum":
            buildRegion(a11yStrings, function (regionStrings: any) {
              regionStrings.push(`from`);
              buildA11yStrings(sub, regionStrings, atomType);
            });
            break;
          default:
            buildRegion(a11yStrings, function (regionStrings: any) {
              regionStrings.push(`start subscript`);
              buildA11yStrings(sub, regionStrings, atomType);
              regionStrings.push(`end subscript`);
            });
        }
      }

      if (sup) {
        buildRegion(a11yStrings, function (regionStrings: any) {
          const supString = flatten(buildA11yStrings(sup, [], atomType)).join(
            " "
          );

          switch (opType) {
            case "\\int":
            case "\\sum":
              buildRegion(a11yStrings, function (regionStrings: any) {
                regionStrings.push(`to`);
                buildA11yStrings(sup, regionStrings, atomType);
                regionStrings.push(`of`);
              });
              break;
            default:
              if (supString in powerMap) {
                regionStrings.push(powerMap[supString]);
                return;
              }
              if (/^((minus )?[0-9]+|[A-Za-z])$/.test(supString)) {
                regionStrings.push(`to the power ${supString} `);
                return;
              }
              buildRegion(a11yStrings, function (regionStrings: any) {
                regionStrings.push(`start superscript`);
                buildA11yStrings(sup, regionStrings, atomType);
                regionStrings.push(`end superscript`);
              });
          }
        });
      } else if (sub && (opType === "\\int" || opType === "\\sum")) {
        buildRegion(a11yStrings, function (regionStrings: any) {
          regionStrings.push(`of`);
        });
      }
      break;
    }

    case "text": {
      // TODO: handle other fonts
      let modifier: any;
      switch (tree.font) {
        case "\\textbf":
          modifier = "bold";
          break;
        case "\\textit":
          modifier = "italic";
          break;
        default:
          modifier = "";
      }
      buildRegion(a11yStrings, function (regionStrings: any) {
        if (
          tree.body
            .map((a: any) => (a.hasOwnProperty("text") ? a.text : ""))
            .join("")
            .search(dropZoneRegex) !== -1
        ) {
          regionStrings.push("clickable drop zone");
        } else {
          /* Original treatment of \text elements: */
          //regionStrings.push(`start ${modifier} text`.replace(/\s+/, " "));
          //buildA11yStrings(tree.body, regionStrings, atomType);
          //regionStrings.push(`end ${modifier} text`.replace(/\s+/, " "));

          /* Updated treatment of \text elements (builds string based on all textord nodes and spacing nodes within the \text block) */
          const textordArr = tree.body.map((node: any) => node.text);
          const textStr = textordArr.join("");
          regionStrings.push(textStr);
        }
      });
      break;
    }

    case "textord": {
      buildString(tree.text, atomType, a11yStrings);
      break;
    }

    case "smash": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "enclose": {
      // TODO: create a map for these.
      // TODO: differentiate between a body with a single atom, e.g.
      // "cancel a" instead of "start cancel, a, end cancel"
      if (/cancel/.test(tree.label)) {
        buildRegion(a11yStrings, function (regionStrings: any) {
          regionStrings.push("start cancel");
          buildA11yStrings(tree.body, regionStrings, atomType);
          regionStrings.push("end cancel");
        });
        break;
      } else if (/box/.test(tree.label)) {
        buildRegion(a11yStrings, function (regionStrings: any) {
          regionStrings.push("start box");
          buildA11yStrings(tree.body, regionStrings, atomType);
          regionStrings.push("end box");
        });
        break;
      } else if (/sout/.test(tree.label)) {
        buildRegion(a11yStrings, function (regionStrings: any) {
          regionStrings.push("start strikeout");
          buildA11yStrings(tree.body, regionStrings, atomType);
          regionStrings.push("end strikeout");
        });
        break;
      }
      console.log(
        `KaTeX-a11y: enclose node with ${tree.label} not supported yet`
      );
      break;
    }

    case "vcenter": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "vphantom": {
      console.log("KaTeX-a11y: vphantom not implemented yet");
      break;
    }

    case "hphantom": {
      console.log("KaTeX-a11y: hphantom not implemented yet");
      break;
    }

    case "operatorname": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "array": {
      console.log("KaTeX-a11y: array not implemented yet");
      break;
    }

    case "raw": {
      console.log("KaTeX-a11y: raw not implemented yet");
      break;
    }

    case "size": {
      // Although there are nodes of type "size" in the parse tree, they have
      // no semantic meaning and should be ignored.
      break;
    }

    case "url": {
      console.log("KaTeX-a11y: url not implemented yet");
      break;
    }

    case "tag": {
      console.log("KaTeX-a11y: tag not implemented yet");
      break;
    }

    case "verb": {
      buildString(`start verbatim`, "normal", a11yStrings);
      buildString(tree.body, "normal", a11yStrings);
      buildString(`end verbatim`, "normal", a11yStrings);
      break;
    }

    case "environment": {
      console.log("KaTeX-a11y: environment not implemented yet");
      break;
    }

    case "horizBrace": {
      buildString(`start ${tree.label.slice(1)}`, "normal", a11yStrings);
      buildA11yStrings(tree.base, a11yStrings, atomType);
      buildString(`end ${tree.label.slice(1)}`, "normal", a11yStrings);
      break;
    }

    case "infix": {
      // All infix nodes are replace with other nodes.
      break;
    }

    case "includegraphics": {
      console.log("KaTeX-a11y: includegraphics not implemented yet");
      break;
    }

    case "font": {
      // TODO: callout the start/end of specific fonts
      // TODO: map \BBb{N} to "the naturals" or something like that
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "href": {
      console.log("KaTeX-a11y: href not implemented yet");
      break;
    }

    case "cr": {
      // This is used by environments and newlines.
      buildRegion(a11yStrings, function (a11yStrings: any) {
        if (tree.newLine || tree.newRow) {
          a11yStrings.push(". ");
        }
      });
      break;
    }

    case "underline": {
      buildRegion(a11yStrings, function (a11yStrings: any) {
        a11yStrings.push("start underline");
        buildA11yStrings(tree.body, a11yStrings, atomType);
        a11yStrings.push("end underline");
      });
      break;
    }

    case "xArrow": {
      buildString(tree.label, "arrow", a11yStrings);
      break;
    }

    case "cdlabel": {
      console.log("KaTeX-a11y: cdlabel not implemented yet");
      break;
    }

    case "cdlabelparent": {
      console.log("KaTeX-a11y: cdlabelparent not implemented yet");
      break;
    }

    case "mclass": {
      // \neq and \ne are macros so we let "htmlmathml" render the mathmal
      // side of things and extract the text from that.
      const atomType = tree.mclass.slice(1);
      // $FlowFixMe: drop the leading "m" from the values in mclass
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    case "mathchoice": {
      // TODO: track which which style we're using, e.g. dispaly, text, etc.
      // default to text style if even that may not be the correct style
      buildA11yStrings(tree.text, a11yStrings, atomType);
      break;
    }

    case "htmlmathml": {
      buildA11yStrings(tree.mathml, a11yStrings, atomType);
      break;
    }

    case "middle": {
      buildString(tree.delim, atomType, a11yStrings);
      break;
    }

    case "internal": {
      // internal nodes are never included in the parse tree
      break;
    }

    case "html": {
      buildA11yStrings(tree.body, a11yStrings, atomType);
      break;
    }

    default:
      console.log("KaTeX a11y un-recognized type: " + tree.type);
      break;
  }
};

const buildA11yStrings = (tree: any, a11yStrings: any, atomType: any) => {
  if (tree instanceof Array) {
    for (let i = 0; i < tree.length; i++) {
      buildA11yStrings(tree[i], a11yStrings, atomType);
    }
  } else {
    handleObject(tree, a11yStrings, atomType);
  }

  return a11yStrings;
};

const flatten = function (array: any) {
  let result: any = [];

  array.forEach(function (item: any) {
    if (item instanceof Array) {
      result = result.concat(flatten(item));
    } else {
      result.push(item);
    }
  });

  return result;
};

export const renderA11yString = function (text: any, settings?: any) {
  const tree = window.katex.__parse(text, settings);
  let a11yStrings = buildA11yStrings(tree, [], "normal");
  a11yStrings = filter(a11yStrings, (str) => {
    return str !== "start underbrace" && str !== "end underbrace";
  });
  return flatten(a11yStrings).join(", ");
};

// export default renderA11yString;
