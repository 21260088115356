import React, { useEffect, useState } from "react";
import axios from "axios";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../manager/utils";
import { useDMQuery } from "../../utils";
import { getAdminParams } from "../utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import ChangePassword from "./profile/ChangePassword";
import { DemoMode } from "../../utils/demo";
import { useUserContext } from "../../shared/contexts/UserContext";

export default function Index({
  hasIntegralAtAll,
}: {
  hasIntegralAtAll: boolean;
}) {
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [theUser, setTheUser] = useState<{
    admin?: string;
    _id?: string;
    googleSSOTimestamp?: any;
    defaultView?: string;
  }>({});
  const [currentUser, setCurrentUser] = useState<{
    first?: string;
    last?: string;
    email?: string;
  }>({});
  const [userCanChangePassword, setUserCanChangePassword] = useState(true);
  const [userAdminID, setUserAdminID] = useState("");
  const [userDefaultView, setUserDefaultView] = useState("");
  const [showChangePassword, setShowChangePassword] = useState(false);

  const adminParams = getAdminParams();

  const { data: credentialData, refetch: refetchCredentialData } = useDMQuery({
    path: "/admin_new/data/ltiCredentials",
    queryOptions: {
      enabled: false,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const { data: districtData, refetch: districtDataRefetch } = useDMQuery({
    path: "/admin_new/data/district",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false, // We only want to make this request if we're a district admin, we check that in a useEffect below
    },
  });

  const { data: adminsData } = useDMQuery({
    path: "/admin_new/data/admins",
    params: adminParams,
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const { data: adminTeacherInfoData, refetch: adminTeacherInfoRefetch } =
    useDMQuery({
      path: "/admin_new/data/admin-teacher-info",
      queryOptions: {
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false,
        enabled: false,
      },
    });

  useEffect(() => {
    const theUser = JSON.parse(localStorage.getItem("user") || "{}");
    setTheUser(theUser);

    if (theUser.googleSSOTimestamp) {
      setUserCanChangePassword(false);
    }
    setUserAdminID(theUser?.admin || "");
    setUserDefaultView(theUser?.defaultView || "admin");
  }, []);

  useEffect(() => {
    if (adminTeacherInfoData) {
      localStorage.setItem("user", JSON.stringify(adminTeacherInfoData));
      setUserDefaultView(adminTeacherInfoData.defaultView);
      setTheUser(adminTeacherInfoData);
    }
  }, [adminTeacherInfoData]);

  const updateDefaultView = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/admin/update_default_view", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess(data) {
        adminTeacherInfoRefetch();
        toastContext.addToast({
          status: "Success",
          message: "Default View Updated.",
        });
      },
    }
  );

  useEffect(() => {
    if (adminsData && theUser) {
      const id = theUser?.admin ? theUser?.admin : theUser?._id;
      const findCurrentUser = adminsData.filter(
        (user: { _id: string }) => user._id === id
      );
      if (findCurrentUser && findCurrentUser.length > 0) {
        setCurrentUser(findCurrentUser[0]);
        districtDataRefetch();
      }
    }
  }, [adminsData, theUser]);

  useEffect(() => {
    // check if user CAN have integrations before requesting integration info
    if (
      (districtData?.dmLicense?.has_integral ||
        user?.deltamath_integral_active) &&
      (districtData?.dmLicense?.integrations || hasIntegralAtAll)
    ) {
      refetchCredentialData();
    }
  }, [districtData, hasIntegralAtAll]);

  const viewOptions = [
    { id: "admin", title: "Admin" },
    { id: "teacher", title: "Teacher" },
  ];

  return (
    <>
      <div className="divide-y divide-gray-200">
        {currentUser && (
          <>
            <div className="space-y-1">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                User Details
              </h3>
            </div>

            <div className="mt-6">
              <dl className="divide-y divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-md font-medium text-gray-500">Name</dt>
                  <dd className="text-md mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">
                      <DemoMode
                        value={`${currentUser.first} ${currentUser.last}`}
                        type="person_full"
                      />
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-md font-medium text-gray-500">Email</dt>
                  <dd className="text-md mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">
                      <DemoMode value={currentUser.email} type="person_email" />
                    </span>
                  </dd>
                </div>
                {userCanChangePassword && (
                  <div className="bg-gray-50 px-4 py-3 sm:px-6">
                    <button
                      type="submit"
                      onClick={() => setShowChangePassword(true)}
                      className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Change Password
                    </button>
                  </div>
                )}

                <div className="py-8">
                  <label className="text-base font-medium text-gray-900">
                    Default View
                  </label>
                  <fieldset className="mt-4">
                    <legend className="sr-only">Notification method</legend>
                    <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                      {viewOptions.map((opt) => (
                        <div key={opt.id} className="flex items-center">
                          <input
                            id={opt.id}
                            name="notification-method"
                            checked={userDefaultView === opt.id}
                            onChange={() => {
                              setUserDefaultView(opt.id);
                            }}
                            type="radio"
                            className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <label
                            htmlFor={opt.id}
                            className="ml-3 block text-sm font-medium text-gray-700"
                          >
                            {opt.title}
                          </label>
                        </div>
                      ))}
                      <button
                        type="submit"
                        disabled={userDefaultView === theUser.defaultView}
                        onClick={() =>
                          updateDefaultView.mutate(
                            JSON.stringify({
                              _id: userAdminID,
                              defaultView: userDefaultView,
                            })
                          )
                        }
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-500 disabled:opacity-50"
                      >
                        Save
                      </button>
                    </div>
                  </fieldset>
                </div>
              </dl>
            </div>
          </>
        )}

        {showChangePassword && (
          <ChangePassword setShowChangePassword={setShowChangePassword} />
        )}
        {credentialData && credentialData?.lti_credentials && (
          <>
            <div className="space-y-1 pt-8">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                LTI Integration Credentials
              </h3>
            </div>

            <div className="mt-6">
              <dl className="divide-y divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-md font-medium text-gray-500">Key</dt>
                  <dd className="text-md mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">
                      <DemoMode
                        value={credentialData?.lti_credentials?.key}
                        type="guid"
                      />
                    </span>
                  </dd>
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-md font-medium text-gray-500">Secret</dt>
                  <dd className="text-md mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0">
                    <span className="flex-grow">
                      <DemoMode
                        value={credentialData?.lti_credentials?.secret}
                        type="guid"
                      />
                    </span>
                  </dd>
                </div>
              </dl>
            </div>
          </>
        )}
      </div>
    </>
  );
}
