import { useContext, useEffect, useRef, useState } from "react";
import md5 from "../../utils/md5";
import StandardSkills from "../StandardSkills";
import {
  checkForCustomFiles,
  generateProblemScripts,
  // displayProblem,
  processlines,
  resizeKatex,
} from "../../utils";
import {
  QuestionMarkCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import clsx from "clsx";
import Answer from "../answerForm/Answer";
import ReactTooltip from "react-tooltip";
import "katex/dist/katex.min.css";
import renderMathInElement from "../../utils/auto-render";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import { clone, debounce, get, isPlainObject } from "lodash";
import axios from "axios";
import { possibleNames, possiblePronouns } from "./unique-strings";
import { convertMathToBraille } from "../../utils/render-braille-string";
import BrailleConversionWrapper from "../../utils/BrailleConversionWrapper";
import Problem from "../standard-problems/Problem";
import { getFilePath } from "../../../utils";

type Props = {
  selectedTitle: string;
  programmer: string;
};

const LoadModule = (props: Props) => {
  const [problemData, setProblemData] = useState<any>(null);
  const [problemParsed, setProblemParsed] = useState<any>();
  const [currentProblem, setCurrentProblem] = useState<any>({
    correct: undefined,
    solution: undefined,
    complete: false, // is the user done with this problem, no matter right or wrong?
    nextProblem: undefined,
  });
  const scoreHeader = useRef<null | HTMLDivElement>(null);
  const mathBlockContent = useRef<null | HTMLDivElement>(null);
  const [showSolution, setShowSolution] = useState<boolean>(false);
  const [katexResizingData, setKatexResizingData] = useState<any>({});
  const { isMfeLoaded, setIsMfeLoaded, setLoadingData } = useContext(
    StudentSectionsContext
  );
  const [scriptsLoaded, setScriptsLoaded] = useState<boolean>(false);
  const problemRef = useRef<Record<string, any>>({
    id: "Testing",
    questionCode: false,
    questionScript: false,
    solutionCode: false,
    solutionScript: false,
  });
  const [answer, setAnswer] = useState<Array<string> | object>([""]);
  const [formattedAnswer, setFormattedAnswer] = useState<string>("");
  const [customAnswerData, setCustomAnswerData] = useState<
    Record<string, unknown>
  >({});
  const [isCheckAnswerLoading, setIsCheckAnswerLoading] =
    useState<boolean>(false);

  useEffect(() => {
    setProblemData(null);
    setProblemParsed(undefined);
    setShowSolution(false);
    // setIsMfeLoaded(false);
    setCurrentProblem({
      correct: undefined,
      solution: undefined,
      complete: false, // is the user done with this problem, no matter right or wrong?
      nextProblem: undefined,
      maxProblemsOneDone: undefined,
    });
    problemRef.current = {
      id: "Testing",
      questionCode: false,
      questionScript: false,
      solutionCode: false,
      solutionScript: false,
    };
  }, [props.selectedTitle]);

  /* On browser resize, call resizeKatex() to readjust the KaTeX width */
  const handleResize = () => {
    setKatexResizingData((prevState: any) => ({
      ...prevState,
      [showSolution ? "solution" : "question"]: resizeKatex(
        showSolution,
        prevState
      ),
    }));
  };

  const debounce_resize = debounce(handleResize, 150);
  const refreshEvent = (event: KeyboardEvent) => {
    if (
      (event.key === "s" || event.key === "r") &&
      (event.metaKey || event.ctrlKey)
    ) {
      // console.log('save it and run:::');
      event.preventDefault();
      load();
    }
  };

  useEffect(() => {
    window.addEventListener("resize", debounce_resize);
    return () => {
      window.removeEventListener("resize", debounce_resize);
    };
  });

  useEffect(() => {
    setIsMfeLoaded(false); // resets math field elements (mathquills) for each new problem
    if (problemData) {
      const unique_strings: { [key: string]: number } = {}; //would only be for testing

      localStorage.setItem(
        "programmerCurrentModule",
        props.selectedTitle ?? ""
      ); // in case of refresh, will remember which module we are on
      const testingModule = true;

      if (problemData.showProblemManager) {
        problemData.showProblemManager.typesShowing = false;
      }
      // put in order of most recently edited or run
      // this.moduleNames.splice(this.moduleNames.indexOf(skillcode), 1);
      // this.moduleNames.unshift(skillcode);
      // this.testCodeForRerun = createProblemCode;
      const createProblemCode: string = problemData.main.includes(
        "SKIP_REPLACE_SLASHES"
      )
        ? problemData.main
        : problemData.main
            .replaceAll("\\", "\\\\")
            .replaceAll("\\\\\\\\", "\\\\")
            .replaceAll("\\\\'", "\\'")
            .replaceAll('\\\\"', '\\"');

      localStorage.setItem("code_saved", JSON.stringify(problemData));
      // forPrint = forPrint && createProblemCode.indexOf('forPrint') > -1; // only set this flag if the main.js makes some distinction, AND this is one of the last 100 problems to be made
      const problem = new (window as any).ProgrammerFunctions();
      (window as any).problem = problem;
      for (const i in problem.exposed) {
        (window as any)[i] = problem.exposed[i];
      }

      const error = false;
      try {
        // eval(this.savedCode['Custom Functions'].main+'//# sourceURL=Custom_Functions.js');
        const testing = testingModule;
        let createProblemObj: any = {};

        eval(
          createProblemCode +
            "\ncreateProblemObj.createProblem = createProblem;\
        //# sourceURL=main.js" +
            new Date().getTime() +
            "\n"
        );

        // fix code and redo the whole thing
        const codeString = createProblemObj.createProblem.toString();
        const endBrace = codeString.length - 1;
        let newCreateProblemCode: any =
          codeString.substr(0, endBrace) +
          "\n\n\treturn {data: data}; // this was added this automatically...\n}" +
          codeString.substr(endBrace + 1);
        newCreateProblemCode = newCreateProblemCode.replaceAll(
          "return;",
          "return {data: data}; // this was added this automatically..."
        );
        newCreateProblemCode = createProblemCode.replaceAll(
          createProblemObj.createProblem.toString(),
          newCreateProblemCode
        );

        createProblemObj = {};
        eval(
          newCreateProblemCode +
            "\ncreateProblemObj.createProblem = createProblem;\
        //# sourceURL=main.js" +
            new Date().getTime() +
            "\n"
        );
        //# sourceURL=main.js'+(new Date().getTime())+'\n')

        const types = [];
        problem.types = { type: 0, type2: 0, type3: 0 };
        // assuming we try to recreate a problem
        let savedTypeSeeds;
        let savedRandOrder;
        //savedTypeSeeds = [110557, 197150, 426173, 26849, 939162, 558624, 809310, 671021, 264127, 887843, 186930, 31035, 665333, 703998, 452955, 696215, 893164, 541335, 599667, 512336, 799936];
        const typeSeeds = [];
        for (let iii = 0; iii <= 20; iii++) {
          const seed = savedTypeSeeds
            ? savedTypeSeeds[iii]
            : (window as any).rand(1, 1000000);
          typeSeeds.push(seed);
          types.push(new (window as any).TypeClass(seed));
        }

        const start = new Date().getTime();
        let data;
        const fails = [];

        let succeed;
        let stopTime;
        let unique_string_failures = 0;
        const unique_string_failures_thresh = 400;
        do {
          do {
            problem.exposed.newDoc({
              typeSeeds,
              savedRandOrder: clone(savedRandOrder),
            });
            data = {}; // data = forPrint ? { forPrint: true } : { }; // main.js and custom.js can check for this to do things differently for the last 100 problems
            problem.problem.skillcode = props.selectedTitle;
            (window as any).DeltaGraph.prototype.getJSProblem = function () {
              return problem.problem;
            };

            succeed = createProblemObj.createProblem(types, data); // succeed should hold data variable (returned from createProblem when successful)
            if (succeed === "done") {
              // return 'done';
              break;
            }
            fails.push(succeed);
            stopTime = problem.settings.maxTime || 5;
          } while (
            (succeed === false || !isNaN(succeed * 1)) &&
            new Date().getTime() - start < stopTime * 1000
          );

          // must be done here because it is used to determine uniqueness
          problem.problem.skillcode = props.selectedTitle;
          problem.problem.data = problem.settings;
          delete problem.problem.settings;

          // to simulate that number class or other classes can't be saved in JSON
          try {
            problem.problem.data.data = JSON.parse(
              JSON.stringify(succeed.data)
            );
          } catch (e) {
            console.log(e);
            console.log(
              "ALERT",
              'You seem to have an infinite "return false" loop for one or more "types".'
            );
            // return false;
            throw e;
            //throw 'You seem to have an infinite "return false" loop for one or more "types"';
          }
          problem.problem.unique_string = getUniqueString(problem.problem);
          unique_string_failures++;
          // if(unique_string_failures%10000 === 0 ) { // && !existing_unique_strings
          // 	console.log('unique_string_failures: '+unique_string_failures);
          // }
        } while (
          unique_strings[problem.problem.unique_string] &&
          unique_string_failures < unique_string_failures_thresh
        ); //  bigCount < 10000000 &&

        const typeString = problem.problem.typeDescription
          ? " (type: " +
            problem.problem.typeDescription[get(problem.problem, "type.type")] +
            ")"
          : "";

        const typesObj: any = {};
        let typesCount = 0;
        for (let iii = 0; iii <= 20; iii++) {
          if (types[iii].val !== false) {
            if (typesCount === 0) {
              typesObj.type = types[iii].val;
            } else if (typesCount === 1) {
              typesObj.type2 = types[iii].val;
            } else if (typesCount === 2) {
              typesObj.type3 = types[iii].val;
            }
            typesCount++;
          }
        }
        if (typesCount && problem.problem.type === undefined) {
          problem.problem.type = typesObj;
        }
        // if(existingProblem) problem.problem.problemToReplace = existingProblem._id;

        if (problem.problem.ansType) {
          if (problem.problem.ansType === "mathquill") {
            problem.problem.ansType = 1;
          } else if (problem.problem.ansType == 3) {
            problem.problem.data.form = false;
            problem.problem.data.simplify = false;
            problem.problem.ansType = 1;
            problem.problem.data.ansType3 = true; // factoring
          } else if (problem.problem.ansType == 4) {
            problem.problem.ansType = 1;
            problem.problem.data.simplify = false;
            problem.problem.data.ansType4 = true;
          }
        }

        if (testingModule) {
          // makeForPrint is just sending problems to server, so include the custom files
          // window.problem_copy = _.cloneDeep(problem.problem);
          problem.problem._id = "t" + new Date().getTime();
          problem.problem.test = true;
          if (
            !problem.settings.sharedExternalFile ||
            problem.settings.sharedExternalFileExtend
          ) {
            problem.problem.htmlTemplates = problemData.html;
            const programmer_name = problemData.programmer_name;
            for (const prop in problem.problem.htmlTemplates) {
              if (prop.indexOf("__") == -1) {
                problem.problem.htmlTemplates[
                  "_" + programmer_name + "__" + prop
                ] = problem.problem.htmlTemplates[prop];
              }
            }
          }
          if (problemData.code) {
            problem.problem.customCode = {
              code: problemData.code.includes("SKIP_REPLACE_SLASHES")
                ? problemData.code
                : problemData.code
                    .replaceAll("\\", "\\\\")
                    .replaceAll("\\\\\\\\", "\\\\")
                    .replaceAll("\\\\'", "\\'")
                    .replaceAll('\\\\"', '\\"'),
            };
          }
        }
        problem.problem.javascript = true;

        // separate answerLines from lines
        const { answerLines, lines } = splitAnswerLines(problem.problem.lines);
        problem.problem.lines = lines;
        problem.problem.answerLines = answerLines;

        (window as any).DeltaGraph.prototype.getJSProblem = function () {
          return false;
        };

        (async () => {
          const files = await checkForCustomFiles(problem.problem);
          problem.problem = generateProblemScripts(files, problem.problem);

          setProblemParsed(problem);
        })();
      } catch (err) {
        console.log("ERROR", err);
      }
    }
  }, [problemData]);

  const getUniqueString = (problem: any) => {
    let realData: any = {};
    if (
      problem.data &&
      problem.data.data &&
      !Array.isArray(problem.data.data) &&
      Object.keys(problem.data.data).length
    ) {
      realData = clone(problem.data.data);

      if (
        problem.data.data.uniqueness !== undefined &&
        isPlainObject(problem.data.data.uniqueness)
      ) {
        // programmer is defining what uniqueness of a problem is (so point labels don't make two identical problems "different" artificially)
        const type = problem.type ? problem.type.type : undefined;
        if (type !== undefined)
          problem.data.data.uniqueness.deltamathProblemType = type;
        // if(problem.data.data.forPrint) problem.data.data.uniqueness.forPrint = problem.data.data.forPrint;
        return md5(JSON.stringify(problem.data.data.uniqueness)); // should we somehow try to sort this by property name? I'm guessing we're fine here
      }

      if (problem.data.leftLatex) realData.leftLatex = problem.data.leftLatex;
      if (problem.data.rightLatex) realData.rightLatex = problem.data.leftLatex;
    }
    const arr = [
      problem["prompt"] || null,
      problem["qlines"] || null,
      realData || null,
      problem["inlineQuestionCode"]
        ? problem["inlineQuestionCode"]
            .replace(/canvas\d{2,}/g, "canvas")
            .replace(/labels\d{2,}/g, "labels")
        : null,
    ];
    if (
      problem["ansType"] == 2 &&
      Array.isArray(problem["choices"]) &&
      problem["choices"][problem["answers"]] !== undefined
    ) {
      // multiple choice
      arr.push(problem["choices"][problem["answers"]]);
    }

    let str = JSON.stringify(arr).replace(/:\[\]/g, ":{}"); // getting problems from DB created in PHP may have [] instead of {} since empty array and object are represented the same way. as long as we use the same function to compare two problems, even if :[] is legit, they will be the same string and hash, which is all that matters
    str = str
      .replace(new RegExp(possibleNames.join("|"), "g"), "NAME")
      .replace(new RegExp(possiblePronouns.join("|"), "gi"), "PRONOUN");
    return md5(str);
  };

  const checkProblemViewed = (options: any = {}) => {
    if (problemParsed) {
      const questionCode =
        options.questionCode !== undefined ? options.questionCode : undefined;
      const questionScript =
        options.questionScript !== undefined
          ? options.questionScript
          : undefined;
      const solutionCode =
        options.solutionCode !== undefined ? options.solutionCode : undefined;
      const solutionScript =
        options.solutionScript !== undefined
          ? options.solutionScript
          : undefined;

      problemRef.current = {
        ...problemRef.current,
        ...(questionCode !== undefined
          ? { questionCode: questionCode }
          : {
              questionCode: problemRef.current.questionCode || false,
            }),
        ...(questionScript !== undefined
          ? { questionScript: questionScript }
          : {
              questionScript: problemRef.current.questionScript || false,
            }),
        ...(solutionCode !== undefined
          ? { solutionCode: solutionCode }
          : {
              solutionCode: problemRef.current.solutionCode || false,
            }),
        ...(solutionScript !== undefined
          ? { solutionScript: solutionScript }
          : {
              solutionScript: problemRef.current.solutionScript || false,
            }),
      };
    }
  };

  const load = () => {
    if (!props.selectedTitle || !props.programmer) {
      return;
    }

    problemRef.current = {
      id: "Testing",
      questionCode: false,
      questionScript: false,
      solutionCode: false,
      solutionScript: false,
    };

    setProblemParsed(undefined);
    setCurrentProblem({
      correct: undefined,
      solution: undefined,
      complete: false, // is the user done with this problem, no matter right or wrong?
      nextProblem: undefined,
      maxProblemsOneDone: undefined,
    });
    // setShowSolution(false);
    setKatexResizingData({});
    setAnswer([""]);
    setFormattedAnswer("");
    setCustomAnswerData({});

    axios
      .post(
        `http://localhost:8002/get_module`,
        {
          programmer_name: props.programmer,
          skillcode: props.selectedTitle,
        },
        {
          withCredentials: false,
        }
      )
      .then(function (response) {
        if (response.status === 200) {
          if (!response.data.main) {
            return "no main"; // like if it's only .php for now
          }
          setProblemData(response.data);
        }
      })
      .catch(function (response) {
        console.error("ERROR", response);
      });
  };

  const focusEvents = useRef<Map<Element, () => void>>(new Map());
  const mqLatexMap = useRef<Map<any, string>>(new Map());
  const [customMqFocus, setCustomMqFocus] = useState<string | null>(null);

  const createMQ = () => {
    const mqWrappers = document.querySelectorAll(".mathquill-editable");
    if (mqWrappers.length) {
      /* Set global config defaults */
      (window as any).MQ.config({
        restrictMismatchedBrackets: true,
        spaceBehavesLikeTab: true,
        sumStartsWithNEquals: false,
        supSubsRequireOperand: true,
        charsThatBreakOutOfSupSub: problemParsed?.problem?.data
          ?.binomialExponent
          ? ""
          : "+-=<>",
        autoCommands: "pi theta sqrt sum ge le approx pm nthroot infty cup",
        autoOperatorNames:
          "sin cos tan arccos arcsin arctan lim csc sec cot log ln Ans ans or undefined DNE",
      });

      mqWrappers.forEach((el: Element) => {
        /* Create MQ Element */
        const mq = (window as any).MQ.MathField(el);

        /* Configure MQ handler to prevent long decimals */
        mq.config({
          handlers: {
            edit: (mq: any) => {
              const latex = mq.latex();
              if (/\.\d{8,}/.test(latex))
                mq.latex(mqLatexMap.current.get(mq.id));
              else mqLatexMap.current.set(mq.id, mq.latex());
            },
          },
        });

        /* Add focus in event listener */
        const textareaEl = el.querySelectorAll("textarea")[0];
        const focusInFunc = () => setCustomMqFocus(el.id);
        textareaEl.addEventListener("focusin", focusInFunc);
        /* Update the event listeners in focusEvents ref map */
        focusEvents.current.set(textareaEl, focusInFunc);
      });
    }
    setIsMfeLoaded(true); // change state for loading mq
  };

  /* Create event listeners for common btns, based on the currently focused MathQuill field */
  useEffect(() => {
    const commonBtns = document.querySelectorAll("button.common-button");
    const commonBtnClick = (btnLatex: string, customMqFocus: string | null) => {
      const node =
        customMqFocus !== null
          ? (window as any).MQ(document.getElementById(customMqFocus))
          : undefined;
      if (node) {
        // replace all parentheses and square brackets with correct latex
        btnLatex = btnLatex
          .replaceAll("(", "\\left(")
          .replaceAll(")", "\\right)")
          .replaceAll("[", "\\left[")
          .replaceAll("]", "\\right]");
        // account for special buttons
        if (btnLatex.includes(",")) {
          // such as [,] for interval notation
          node.write(btnLatex).keystroke("Left Left");
        } else if (btnLatex.includes("cuberoot")) {
          node
            .cmd("nthroot")
            .typedText("3")
            .keystroke("Tab")
            .keystroke("Left Right");
        } else if (btnLatex.includes("nthroot")) {
          node.cmd("nthroot").keystroke("Left Right");
        } else if (btnLatex.includes("sqrt")) {
          if (btnLatex === "\\sqrt{}") btnLatex = "sqrt";
          node.cmd(btnLatex);
        } else {
          // all other latex
          node.write(btnLatex);
        }
        node.focus();
      }
    };
    const eventListenerMap = new Map();
    if (commonBtns.length) {
      commonBtns.forEach((btn: Element) => {
        const quill = btn.attributes.getNamedItem("quill")?.value;
        const btnLatex = quill ? quill : (btn as HTMLElement).innerText;
        const btnEventListener = () => commonBtnClick(btnLatex, customMqFocus);
        eventListenerMap.set(btn, btnEventListener);
        btn.addEventListener("click", btnEventListener);
      });
    }
    return () => {
      eventListenerMap.forEach(
        (evListenerFunc: () => void, element: Element) => {
          element.removeEventListener("click", evListenerFunc);
        }
      );
    };
  }, [customMqFocus, problemParsed]);

  const checkAnswer = () => {
    if (problemParsed.problem.ansType.toString().toLowerCase() === "custom") {
      // const correct = (window as any).problem.problem.problemScripts
      //   .answerScripts(answer)
      //   //.answerScripts(document.getElementById("innerAnswerForm"))
      //   .checkAnswer(answer);

      // Note: when checkAnswer was invoked twice (once in CustomAnswer and once above), dmKAS was not reliably checking the answer (looks like dmKAS might modify the data passed in??)
      // const correct = customAnswerData as any;
      // if (!correct || correct.correct === 0) {
      //   // changed false to 0
      //   setLoadingData((prevState: any) => ({
      //     ...prevState,
      //     isShowing: true,
      //     error: true,
      //     title: "Notice",
      //     message:
      //       correct?.messages && correct.messages.length > 0
      //         ? correct.messages[0]
      //         : `Your answer is not correct. Try to find your mistake.`,
      //   }));
      //   if (document.getElementById("innerAnswerForm")) {
      //     renderMathInElement(document.getElementById("innerAnswerForm"));
      //   }
      //   return;
      // }

      setCurrentProblem({
        ...currentProblem,
        correct: customAnswerData?.correct === 1 ? true : false,
        complete: true,
      });
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: false,
      }));
      setIsCheckAnswerLoading(false);
      // window.deltaGraphs2 = [];
      setShowSolution(true);
      // load();
    } else {
      const correct = (window as any).dmKAS.equal(
        answer,
        problemParsed.problem.answers,
        problemParsed.problem?.data
      );
      setCurrentProblem({
        ...currentProblem,
        correct: correct,
        complete: true,
      });
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: false,
      }));
      setIsCheckAnswerLoading(false);
      // window.deltaGraphs2 = [];
      setShowSolution(true);
      // load();
    }
    // generate log_data for displaying the answer
    setProblemParsed((prevState: any) => ({
      ...prevState,
      problem: {
        ...prevState.problem,
        log_data: {
          sol: formattedAnswer,
          ans: answer,
          ...(problemParsed.problem?.data?.data?.answerData
            ? { data: problemParsed.problem.data.data.answerData }
            : {}),
          ...(customAnswerData?.messages
            ? { messages: customAnswerData.messages }
            : {}),
        },
      },
    }));
  };

  const inlineQuestionCode = () => {
    return `
      window.deltaGraphs2 = [];
      ${problemParsed.problem?.inlineQuestionCode};`;
  };

  const inlineSolutionCode = () => {
    return `
      window.deltaGraphs = [];
      ${problemParsed.problem?.inlineSolutionCode};`;
  };

  const displayQuestion = () => {
    // hide .answerData on custom assignments when the data doesn't exist
    if (
      problemParsed?.problem?.data?.data?.answerData &&
      problemParsed?.problem?.data?.data.answerData?.show !== false
    ) {
      document
        .querySelector("#problem_page .answerData")
        ?.classList.remove("hidden");
      // rerun solution script with appropriate answerData
      checkProblemViewed({ solutionScript: false });
    } else {
      document
        .querySelector("#problem_page .answerData")
        ?.classList.add("hidden");
    }

    // These are possibly used by inlineSolutionCode or inlineQuestionCode when eval()
    // Guarantees data & page are in scope.
    // !!DO NOT DELETE!!

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data = problemParsed?.problem?.data?.data;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const page = showSolution
      ? document.getElementById("question_page")
      : document.getElementById("problem_page");

    if (
      problemParsed &&
      problemParsed.problem?.data?.inlineFirst === undefined
    ) {
      if (!problemRef?.current?.questionCode) {
        eval(inlineQuestionCode());
        checkProblemViewed({ questionCode: true });
        createMQ(); // after the question code is evaluated, create MathQuill boxes
      }

      if (
        problemParsed.problem?.problemScripts &&
        !problemRef?.current?.questionScript &&
        problemRef?.current?.questionCode
      ) {
        problemParsed.problem?.problemScripts?.questionScripts(
          document.getElementById("question_page")
        );
        checkProblemViewed({ questionScript: true });
      }

      if (showSolution && !problemRef?.current?.solutionCode) {
        eval(inlineSolutionCode());
        checkProblemViewed({ solutionCode: true });
      }

      if (
        problemParsed.problem?.problemScripts &&
        showSolution &&
        !problemRef?.current?.solutionScript
      ) {
        problemParsed.problem?.problemScripts?.solutionScripts(
          document.getElementById("problem_page")
        );
        checkProblemViewed({ solutionScript: true });
      }
    } else if (problemParsed.problem?.data?.inlineFirst === false) {
      if (
        problemParsed.problem?.problemScripts &&
        !problemRef?.current?.questionScript
      ) {
        problemParsed.problem?.problemScripts?.questionScripts(
          document.getElementById("question_page")
        );
        checkProblemViewed({ questionScript: true });
      }

      if (
        !problemRef?.current?.questionCode &&
        problemRef?.current?.questionScript
      ) {
        eval(inlineQuestionCode());
        checkProblemViewed({ questionCode: true });
        createMQ(); // after the question code is evaluated, create MathQuill boxes
      }

      if (
        problemParsed.problem?.problemScripts &&
        showSolution &&
        !problemRef?.current?.solutionScript
      ) {
        problemParsed.problem?.problemScripts?.solutionScripts(
          document.getElementById("problem_page")
        );
        checkProblemViewed({ solutionScript: true });
      }

      if (
        showSolution &&
        !problemRef?.current?.solutionCode &&
        problemRef?.current?.solutionScript
      ) {
        eval(inlineSolutionCode());
        checkProblemViewed({ solutionCode: true });
      }
    }
    // increase input width as more data is typed in
    eval(`$('.display-problem input:not(.no-auto-grow)').autoGrowInput();`);
  };

  window.renderMathInElement = renderMathInElement;

  useEffect(() => {
    if (
      problemParsed &&
      (((problemParsed.problem?.problemScripts !== undefined ||
        problemParsed.problem?.inlineQuestionCode ||
        problemParsed.problem?.inlineSolutionCode) &&
        problemParsed.problem?.ansType === "custom") ||
        (problemParsed.problem?.ansType !== "custom" &&
          problemParsed.problem?.ansType !== undefined))
    ) {
      displayQuestion();
      // createMQ();
    }
    // if (!skillData.isVideo)
    // if (problemParsed && problemParsed.problem)
    if (document.getElementById("mathBlock")) {
      renderMathInElement(document.getElementById("mathBlock"));
    }

    setKatexResizingData((prevState: any) => ({
      ...prevState,
      [showSolution ? "solution" : "question"]: resizeKatex(
        showSolution,
        prevState
      ),
    }));

    return () => {
      /* Remove all event listeners from MathQuill boxes */
      if (focusEvents.current.size) {
        focusEvents.current.forEach((listener, element) => {
          element.removeEventListener("focusin", listener);
        });
      }
    };
  }, [problemParsed, showSolution]);

  const loadScripts = () => {
    if (!scriptsLoaded) {
      // A few of the lbodner modules use abs() which was undefined
      if (!(window as any).abs) {
        (window as any).abs = Math.abs;
      }

      const script3 = document.createElement("script");
      script3.src = getFilePath(
        `/scripts/programmerFunctions.js?t=${new Date().getTime()}`
      );
      script3.type = "text/javascript";
      script3.async = true;
      script3.charset = "utf-8";
      document.getElementsByTagName("head")[0].appendChild(script3);

      script3.onload = function () {
        const script = document.createElement("script");
        script.src = getFilePath("/scripts/ace.js");
        script.type = "text/javascript";
        script.async = false;
        script.charset = "utf-8";
        document.getElementsByTagName("head")[0].appendChild(script);

        script.onload = function () {
          const script2 = document.createElement("script");
          script2.src = getFilePath("/scripts/snippets.js");
          script2.type = "text/javascript";
          script2.async = true;
          script2.charset = "utf-8";
          document.getElementsByTagName("head")[0].appendChild(script2);

          script2.onload = function () {
            const requireScript = document.createElement("script");
            requireScript.src = getFilePath("/scripts/ext-language_tools.js");
            requireScript.type = "text/javascript";
            requireScript.async = true;
            requireScript.charset = "utf-8";
            document.getElementsByTagName("head")[0].appendChild(requireScript);

            requireScript.onload = function () {
              (window as any).ace
                .require("ace/snippets")
                .snippetManager.register(
                  (window as any).deltaSnippets,
                  "javascript"
                );
              (window as any).ace
                .require("ace/snippets")
                .snippetManager.register(
                  (window as any).deltaSnippetsHTML,
                  "html"
                );

              setScriptsLoaded(true);
            };
          };
        };
      };
    }
  };

  useEffect(() => {
    loadScripts();
  }, []);

  return (
    <BrailleConversionWrapper onKeydown={refreshEvent}>
      <div>
        <div className="mt-6 flex flex-1 gap-2" ref={scoreHeader}>
          <button
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-400"
            onClick={load}
            disabled={!props.programmer || !props.selectedTitle}
          >
            New Problem
          </button>
          <button
            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-50"
            onClick={() => setShowSolution(!showSolution)}
          >
            Show Solution
          </button>
        </div>
        {problemParsed && problemParsed.problem && (
          <>
            <div
              className="relative mt-10 bg-white px-2 py-9 shadow-md sm:px-5 md:px-9"
              ref={mathBlockContent}
              id="mathBlock"
            >
              <div className="flex content-center justify-between gap-x-2">
                <h2
                  id="problemPrompt"
                  dangerouslySetInnerHTML={{
                    __html: problemParsed.problem?.prompt
                      ? problemParsed.problem.prompt
                      : null,
                  }}
                ></h2>
                <div className="icon">
                  {currentProblem?.correct === undefined && (
                    <>
                      <QuestionMarkCircleIcon className="w-14 text-gray-500" />
                      <span className="sr-only">unanswered</span>
                    </>
                  )}
                  {currentProblem?.correct === true && (
                    <>
                      <CheckCircleIcon className="w-14 text-green-500" />
                      <span className="sr-only">correct</span>
                    </>
                  )}
                  {currentProblem?.correct === false && (
                    <>
                      <XCircleIcon className="w-14 text-red-500" />
                      <span className="sr-only">incorrect</span>
                    </>
                  )}
                </div>
              </div>
              <div
                id="question_page"
                className={clsx(
                  "display-problem question-page",
                  showSolution ? "sr-only" : null
                )}
                aria-hidden={showSolution}
              >
                {/* {displayProblem(
                  processlines(problemParsed.problem?.qlines),
                  problemParsed.problem,
                  katexResizingData["question"],
                  "question"
                )} */}
                <Problem
                  displayData={processlines(problemParsed.problem?.qlines)}
                  problemData={problemParsed.problem}
                  resizingData={katexResizingData["question"]}
                  locString="question"
                />
              </div>
              <div
                id="problem_page"
                className={clsx(
                  "display-problem problem-page", // TODO: double check these
                  !showSolution ? "sr-only" : null
                )}
                aria-hidden={!showSolution}
              >
                {problemParsed.problem?.solution !== undefined ? (
                  // ? displayProblem(
                  //     processlines(problemParsed.problem?.solution),
                  //     problemParsed.problem,
                  //     katexResizingData["solution"],
                  //     "solution"
                  //   )
                  <Problem
                    displayData={processlines(problemParsed.problem?.solution)}
                    problemData={problemParsed.problem}
                    resizingData={katexResizingData["solution"]}
                    locString="solution"
                  />
                ) : (
                  // : displayProblem(
                  //   processlines(problemParsed.problem?.lines),
                  //   problemParsed.problem,
                  //   katexResizingData["solution"],
                  //   "solution"
                  // )}
                  <Problem
                    displayData={processlines(problemParsed.problem?.lines)}
                    problemData={problemParsed.problem}
                    resizingData={katexResizingData["solution"]}
                    locString="solution"
                  />
                )}
              </div>
              <div className="relative mt-8 rounded bg-gray-100 p-4 sm:p-6">
                <>
                  <Answer
                    key={"problem:" + problemParsed.problem?._id}
                    correct={currentProblem?.correct ? 1 : 0}
                    complete={currentProblem?.complete}
                    showSolution={showSolution}
                    isCheckAnswerLoading={isCheckAnswerLoading}
                    answer={answer}
                    setAnswer={setAnswer}
                    formattedAnswer={formattedAnswer}
                    setFormattedAnswer={setFormattedAnswer}
                    setCustomAnswerData={setCustomAnswerData}
                    customMqFocus={customMqFocus}
                    setCustomMqFocus={setCustomMqFocus}
                    callback={checkAnswer}
                    problem={problemParsed.problem}
                  />
                  {/* {problemData?.attempts?.max > 1 && !showSolution ? (
                  <>
                    <div
                      className="attempts mt-4 inline-block text-sm text-gray-500"
                      data-effect="solid"
                      data-for="attempts-tooltip"
                      data-tip="The number of attempts on this specific problem before you are marked incorrect"
                    >
                      attempt {problemData?.attempts?.used + 1} out of{" "}
                      {problemData?.attempts?.max}
                    </div>
                    <ReactTooltip
                      id="attempts-tooltip"
                      className="max-w-[14rem] text-xs"
                    />
                  </>
                ) : null} */}
                </>
              </div>
            </div>
          </>
        )}

        {/* {problemParsed && problemParsed.problem && displayProblem(problemParsed.problem, )} */}
        {/* {problemParsed && problemParsed.problem && (
        <StandardSkills
          problemData={problemParsed.problem}
          setProblemData={() => {
            undefined;
          }}
          currentProblem={currentProblem}
          setCurrentProblem={setCurrentProblem}
          showSolution={false}
          setShowSolution={() => {
            undefined;
          }}
          showExample={false}
          setShowExample={() => {
            undefined;
          }}
          setShowVideo={() => {
            undefined;
          }}
          scoreHeader={scoreHeader}
          showNextProblem={() => {
            undefined;
          }}
          setShowNextProblem={() => {
            undefined;
          }}
        />
      )} */}
      </div>
    </BrailleConversionWrapper>
  );
};

/**
 * Splits out lines entries which contain the string 'answerData'
 * @param lines Instance of IProblem['lines'].
 */
function splitAnswerLines(lines: any[]) {
  const answerLines: any[] = [];
  const problemLines: any[] = [];
  lines.forEach((line) => {
    if (line.html && line.html.includes("answerData")) {
      answerLines.push(line);
    } else {
      problemLines.push(line);
    }
  });

  return {
    answerLines,
    lines: problemLines,
  };
}

export default LoadModule;
