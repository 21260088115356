import clsx from "clsx";
import { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";

export default function devtools() {
  return {
    getForceNew: () => JSON.parse(localStorage.getItem("force_new") || "false"),
    setForceNew: (flag: boolean) =>
      localStorage.setItem("force_new", JSON.stringify(flag)),
    // getLocalCustom: () =>
    //   JSON.parse(localStorage.getItem("local_custom") || "false"),
    // setLocalCustom: (flag: boolean) =>
    //   localStorage.setItem("local_custom", JSON.stringify(flag)),
  };
}

export function DevOptions(): JSX.Element {
  const [forceNewEnabled, setForceNewEnabled] = useState(
    devtools().getForceNew()
  );
  // const [localCustomEnabled, setLocalCustomEnabled] = useState(
  //   devtools().getLocalCustom()
  // );

  useEffect(() => {
    devtools().setForceNew(forceNewEnabled);
  }, [forceNewEnabled]);

  // useEffect(() => {
  //   devtools().setLocalCustom(localCustomEnabled);
  // }, [localCustomEnabled]);

  return (
    <div className="mx-1 mt-14 flex justify-evenly border-2 p-2 md:mx-24">
      <Switch.Group as="div" className="flex items-center">
        <Switch
          checked={forceNewEnabled}
          onChange={setForceNewEnabled}
          className={clsx(
            forceNewEnabled ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
          )}
        >
          <span
            aria-hidden="true"
            className={clsx(
              forceNewEnabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-3 text-sm">
          <span className="font-medium text-gray-900">Force New Problem</span>{" "}
          <span className="text-gray-500">(on refresh)</span>
        </Switch.Label>
      </Switch.Group>
    </div>
  );
}
