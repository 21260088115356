import { useEffect } from "react";
import { useMutation } from "react-query";
import { useParams } from "react-router-dom";
import { deltamathAPI } from "../../../manager/utils";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useUserContext } from "../../../shared/contexts/UserContext";

type LtiMeta = {
  resource_link_id?: string;
  ltiLaunchType?: "assignment" | "course";
  lis_result_sourcedid?: string;
  lis_outcome_service_url?: string;
  consumer_key?: string;
  lms_id?: string;
  lti_int_id?: string;
  req: any;
  launched_assignment_id?: number;
};

export interface ILtiContext {
  context_id: string;
  context_label: string;
  context_title: string;
  context_type: string;
  consumer_key: string;
  resource_link_title: string;
  resource_link_id: string;
  lis_outcome_service_url?: string;
  lis_result_sourcedid?: string;
  isLtiResourceLaunch?: boolean;
  lms_id?: string;
  lti_int_id?: string;
  due_at_timestamp: number;
}

const LtiLogin = () => {
  const { ltiId: ltiId } = useParams();
  const userContext = useUserContext();

  const ltiAuth = useMutation(() => {
    return axios.post(
      `${deltamathAPI()}/lti_auth`,
      {
        params: {
          auth_code: ltiId,
          version: `405`,
          iframe: true,
        },
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  });

  // const lti_auth_data_json = localStorage.getItem("lti_auth_data");

  // useEffect(() => {
  //   if (lti_auth_data_json) {
  //     const lti_auth_data = JSON.parse(lti_auth_data_json);
  //       return handleLtiAuthData(lti_auth_data);
  //   } else {
  //     ltiAuth.mutate();
  //   }
  // }, [])

  useEffect(() => {
    ltiAuth.mutate();
  }, [ltiId]);

  useEffect(() => {
    if (ltiAuth.status === "success") {
      if (ltiAuth.data && ltiAuth.data.data) {
        const data: {
          loginData: any;
          url: string;
          lti_context?: ILtiContext;
          assignment?: any;
          studentAssignmentsStarted: boolean;
          req: any;
        } = ltiAuth.data.data as any;

        const decodedJwt: any =
          data.loginData && data.loginData.jwt
            ? jwt_decode(data.loginData.jwt)
            : undefined;

        if (data.loginData) {
          if (data.loginData.jwt) {
            userContext.setJWT(data.loginData.jwt);
          }
          if (data.loginData.user) {
            localStorage.setItem("user", JSON.stringify(data.loginData.user));
          }
        }
        if (data.lti_context) {
          localStorage.setItem(
            "lti_assignment_payload",
            JSON.stringify({
              lti_context: data.lti_context,
              assignment: data.assignment,
              studentAssignmentsStarted: data.studentAssignmentsStarted,
              isLtiResourceLaunch: data.lti_context.isLtiResourceLaunch,
              url: data.url,
            })
          );
        }
        if (
          decodedJwt &&
          decodedJwt.data &&
          decodedJwt.data.ltiLock &&
          decodedJwt.data.ltiLock.taId
        ) {
          window.location.href = `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_STUDENT_LINK}/link/${decodedJwt.data.ltiLock.taId}`;
        } else {
          window.location.href = `${process.env.REACT_APP_URL_PREFIX}${process.env.REACT_APP_STUDENT_LINK}`;
        }
      }
    }
  }, [ltiAuth.status]);

  return <div></div>;
};
export default LtiLogin;
