export default function BookClosedIcon({
  strokeColor = "#2F52E9", // dm-brand-blue-500
}: {
  classes?: string;
  strokeColor?: string;
}): JSX.Element {
  return (
    <svg viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1085_9260)">
        <path
          d="M38.5 8V40H14.5C13.4391 40 12.4217 39.5786 11.6716 38.8284C10.9214 38.0783 10.5 37.0609 10.5 36V12C10.5 10.9391 10.9214 9.92172 11.6716 9.17157C12.4217 8.42143 13.4391 8 14.5 8H38.5Z"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.5 32H14.5C13.4391 32 12.4217 32.4214 11.6716 33.1716C10.9214 33.9217 10.5 34.9391 10.5 36"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.5 16H30.5"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1085_9260">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
