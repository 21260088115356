import { IconKeyboard } from "@tabler/icons-react";
import { useContext } from "react";
import { Tooltip } from "../../../shared/Tooltip";
import StudentSectionsContext from "../../_context/StudentSectionsContext";

export default function VirtualKeyboard(): JSX.Element {
  const { showKeyboard, setShowKeyboard, showCalculator, setShowCalculator } =
    useContext(StudentSectionsContext);
  return (
    <>
      <Tooltip message={"Virtual keyboard"}>
        <button
          type="button"
          aria-label="Virtual keyboard"
          className="inline-flex items-center rounded-md border border-transparent bg-gray-200 px-2 py-1 text-dm-blue hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-dm-lightest-blue focus:ring-offset-2"
          onClick={() => {
            if (!showKeyboard && showCalculator) {
              setShowKeyboard(true);
              setShowCalculator(false);
            } else {
              setShowKeyboard(!showKeyboard);
            }
          }}
        >
          <IconKeyboard className="h-7" aria-hidden="true" />
        </button>
      </Tooltip>
    </>
  );
}
