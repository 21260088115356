import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, XIcon } from "@heroicons/react/outline";

type DmConfirmModalProps = {
  showModal: boolean;
  setShowModal: React.Dispatch<boolean>;
  isSubmitDisabled: boolean;
  headline: string;
  subHeadline: string;
  message: any;
  cancelMessage?: string;
  confirmMessage?: string;
  modalAction: any;
  ariaLabelMath?: string;
};

export default function DmConfirmModal({
  showModal,
  setShowModal,
  isSubmitDisabled,
  headline,
  subHeadline,
  message,
  cancelMessage,
  confirmMessage,
  modalAction,
  ariaLabelMath,
}: DmConfirmModalProps): JSX.Element {
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  const confirmButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <Transition.Root show={showModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={setShowModal}
        initialFocus={confirmButtonRef}
        aria-label={createAriaLabel(headline, subHeadline, ariaLabelMath)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-dm-lightest-blue focus:ring-offset-2"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-[#def0f6] sm:mx-0 sm:h-10 sm:w-10">
                      <CheckIcon
                        className="h-6 w-6 text-dm-alt-blue"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      {/* <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        {headline}
                      </Dialog.Title> */}
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        {headline}
                      </h3>
                      {/* <Dialog.Description className="text-md mt-2 text-gray-700">
                        Are you sure you want to submit this answer?
                      </Dialog.Description> */}
                      <p className="text-md mt-2 text-gray-700">
                        {subHeadline}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2">{message}</div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    ref={confirmButtonRef}
                    disabled={isSubmitDisabled}
                    type="button"
                    className="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-dm-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-dm-dark-blue focus:outline-none focus:ring-2 focus:ring-dm-lightest-blue focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      setShowModal(false);
                      modalAction();
                    }}
                  >
                    {confirmMessage || "Yes"}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dm-lightest-blue focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setShowModal(false)}
                    ref={cancelButtonRef}
                  >
                    {cancelMessage || "No"}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

/* **************** */
/* Helper Functions */
/* **************** */

/* Function to generate aria-description based on the provided message */
const createAriaLabel = (
  headline: string,
  subHeadline: string,
  ariaLabelMath?: string
) => {
  const descArr: Array<string> = [];
  descArr.push(`${headline}: ${subHeadline}`);
  // if the answer format is HTML (instead of latex), don't add the answer string to the aria label
  let stuAns = "";
  if (ariaLabelMath && ariaLabelMath.substring(0, 1) !== "<") {
    try {
      stuAns = window.renderA11yString(ariaLabelMath);
    } catch (e) {
      console.log("Error", e);
    } finally {
      descArr.push(stuAns);
    }
  }
  return descArr.join(" ");
};
