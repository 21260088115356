import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formatNumber, useDMQuery } from "../../utils";
import SchoolDetails from "./school/SchoolDetails";
import InviteAdmins from "./school/InviteAdmins";
import CurrentAdmins from "./school/CurrentAdmins";
import InviteTeachers from "./school/InviteTeachers";
import SchoolTabs from "./school/SchoolTabs";
import CurrentTeachers from "./school/CurrentTeachers";
import { DemoMode } from "../../utils/demo";

export default function Index() {
  const navigate = useNavigate();
  const params = useParams();

  const [school, setSchool] = useState({ schoolName: "", schoolid: "" });
  const [teachers, setTeachers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [pendingTeacherInvites, setPendingTeacherInvites] = useState([]);

  const [teacherDataForTable, setTeacherDataForTable] = useState();

  const { data: pendingInvitesData } = useDMQuery({
    path: "/admin_new/data/pendingInvites",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: teacherData, isLoading: teacherDataisLoading } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: schoolData } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: teacherCountsData } = useDMQuery({
    path: "/admin_new/data/teacherCounts",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: adminsData } = useDMQuery({
    path: "/admin_new/data/admins",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  // format teacherData to provide "accessor" key for each column
  useEffect(() => {
    if (teacherData && schoolData && teacherCountsData) {
      const school = schoolData.find(
        (school: { _id: string; schoolid: string }) =>
          school.schoolid === params.school_id
      );
      if (school && teacherCountsData) {
        const newData = teacherData
          .filter(
            (teacher: { schoolPlus: string[]; _id: number; admin: string }) =>
              teacher.schoolPlus.includes(school.schoolid) &&
              (!teacher.admin ||
                (teacher.admin && getStudentCount(teacher._id) > 0))
          )
          .map((teacher: any) => ({
            teacher: {
              id: teacher.teachercode,
              first: teacher.first,
              last: teacher.last,
            },
            schoolPlus: teacher.schoolPlus,
            last_login: teacher.last_login,
            assignments: getAssignmentsCount(teacher._id),
            num_students: getStudentCount(teacher._id),
            annual_logs: teacher.annual_logs,
            showDeleteButton: teacher.showDeleteButton,
          }));

        setTeacherDataForTable(newData);
      }
    }
  }, [teachers, schoolData, teacherCountsData]); // use off teachers so its reset when url is change (see other useEffect)

  useEffect(() => {
    setAdmins([]);
    if (adminsData) {
      const results = adminsData.filter(
        (a: { account_type: string; schoolIDs: string[] }) =>
          (a.account_type === "super_school" || a.account_type === "school") &&
          params.school_id &&
          a.schoolIDs.includes(params.school_id)
      );
      results.forEach((a: { account_type: string }, index: number) => {
        if (a.account_type !== "super_school") {
          results[index]["showDeleteButton"] = true;
        }
      });
      setAdmins(results);
    }
  }, [adminsData, params]);

  useEffect(() => {
    if (pendingInvitesData && school) {
      const pendingTeacherInvites = pendingInvitesData.filter(
        (teacher: { schools: string[] }) =>
          teacher.schools.includes(school.schoolid)
      );
      setPendingTeacherInvites(pendingTeacherInvites);
    } else {
      setPendingTeacherInvites([]);
    }
  }, [pendingInvitesData, school]);

  useEffect(() => {
    if (schoolData && teacherData && teacherCountsData) {
      const school = schoolData.find(
        (school: { _id: string; schoolid: string }) =>
          school.schoolid === params.school_id
      );
      if (school) {
        setSchool(school);
        const teachers = teacherData.filter(
          (teacher: { schoolPlus: string[]; _id: number; admin: string }) =>
            teacher.schoolPlus.includes(school.schoolid) &&
            (!teacher.admin ||
              (teacher.admin && getStudentCount(teacher._id) > 0))
        );
        setTeachers(teachers);
        if (teachers) {
          // No Teachers, default to "Invite Teachers"
          if (
            typeof params.selected_tab === "undefined" &&
            teacherData &&
            teachers
          ) {
            if (teachers && teachers.length === 0) {
              navigate(
                `${process.env.REACT_APP_ADMIN_LINK}/schools/${params.school_id}/invite-teachers`,
                {
                  replace: true,
                }
              );
            } else {
              navigate(
                `${process.env.REACT_APP_ADMIN_LINK}/schools/${params.school_id}/current-teachers`,
                {
                  replace: true,
                }
              );
            }
          }
        }
      }
    }
  }, [schoolData, teacherData, teacherCountsData, params.school_id]);

  function getStudentCount(teacher_id: number) {
    return teacherCountsData &&
      typeof teacherCountsData[teacher_id]?.students != "undefined"
      ? formatNumber(parseInt(teacherCountsData[teacher_id]?.students))
      : 0;
  }

  function getAssignmentsCount(teacher_id: number) {
    return teacherCountsData &&
      typeof teacherCountsData[teacher_id]?.assignments != "undefined"
      ? formatNumber(parseInt(teacherCountsData[teacher_id]?.assignments))
      : 0;
  }

  return (
    <>
      {school && schoolData && (
        <>
          <h2 className="text-xl font-medium leading-6 text-gray-900">
            <DemoMode
              value={school.schoolName}
              type="school_name"
              district_size={schoolData.length}
            />
          </h2>

          {teachers && pendingTeacherInvites && admins && (
            <SchoolTabs
              pendingTeacherInvites={pendingTeacherInvites}
              teachers={teachers}
              admins={admins}
            />
          )}

          {params.selected_tab === "current-teachers" && (
            <CurrentTeachers
              teachers={teachers}
              teacherDataForTable={teacherDataForTable}
              teacherDataisLoading={teacherDataisLoading}
            />
          )}
          {params.selected_tab === "invite-teachers" && (
            <>
              {pendingTeacherInvites && (
                <InviteTeachers
                  school={school}
                  pendingTeacherInvites={pendingTeacherInvites}
                />
              )}
            </>
          )}
          {params.selected_tab === "current-admins" && (
            <CurrentAdmins admins={admins} school_id={params.school_id} />
          )}
          {params.selected_tab === "school-details" && (
            <>
              {teachers && (
                <SchoolDetails teachers={teachers} school={school} />
              )}
            </>
          )}

          {params.selected_tab === "invite-admins" && (
            <>
              {params.school_id && (
                <InviteAdmins school_id={params.school_id} />
              )}
            </>
          )}
        </>
      )}
    </>
  );
}
