import DMKey from "./DMKey";
import KeyboardControls from "./KeyboardControls";

const NumericalKeyboard = ({
  shift,
  input,
  focusedInput,
  setShift,
  setShowNumeric,
  previousAnswer,
  setShowFuncs,
  handleEnter,
}: {
  shift: boolean;
  input: any;
  focusedInput: any;
  setShift: (state: boolean) => void;
  setShowNumeric: (state: boolean) => void;
  previousAnswer: number;
  showFuncs: boolean;
  setShowFuncs: (state: boolean) => void;
  handleEnter: () => void;
}) => {
  return (
    <>
      <div className="col col-span-1 space-y-1">
        <div className="grid grid-cols-4 space-x-1">
          {shift ? (
            <>
              <DMKey
                btnText={"\\gt"}
                ariaLabel={"greater-than"}
                focusedInput={focusedInput}
                inputsRef={input}
              />
              <DMKey
                btnText={"\\lt"}
                ariaLabel={"less-than"}
                focusedInput={focusedInput}
                inputsRef={input}
              />
              <DMKey
                btnText={"\\ge"}
                ariaLabel={"greater-than-or-equal-to"}
                focusedInput={focusedInput}
                inputsRef={input}
              />
              <DMKey
                btnText={"\\le"}
                ariaLabel={"less-than-or-equal-to"}
                focusedInput={focusedInput}
                inputsRef={input}
              />
            </>
          ) : (
            <>
              <DMKey
                btnText={"x"}
                ariaLabel={"x"}
                focusedInput={focusedInput}
                inputsRef={input}
              />
              <DMKey
                btnText={"y"}
                ariaLabel={"y"}
                focusedInput={focusedInput}
                inputsRef={input}
              />
              <DMKey
                btnText={"("}
                btnOutput={"("}
                btnCmd={true}
                ariaLabel={"open-parens"}
                focusedInput={focusedInput}
                inputsRef={input}
              />
              <DMKey
                btnText={")"}
                btnCmd={true}
                ariaLabel={"close-parens"}
                focusedInput={focusedInput}
                inputsRef={input}
              />
            </>
          )}
        </div>
        <div className="grid grid-cols-4 space-x-1">
          {shift ? (
            <DMKey
              btnText={"!"}
              ariaLabel={"factorial"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          ) : (
            <DMKey
              btnText={"="}
              ariaLabel={"equals"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          )}
          {shift ? (
            <DMKey
              btnText={"\\sqrt[n]{x}"}
              btnOutput={"\\nthroot"}
              ariaLabel={"nth-root"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          ) : (
            <DMKey
              btnText={"\\sqrt{x}"}
              btnOutput={"\\sqrt{}"}
              ariaLabel={"square-root"}
              moveCursor={{ dir: "Left", num: 1 }}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          )}
          {shift ? (
            <DMKey
              btnText={"\\sqrt[3]{x}"}
              btnOutput={"\\sqrt[3]{}"}
              ariaLabel={"cube-root"}
              moveCursor={{ num: 1, dir: "Left" }}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          ) : (
            <DMKey
              btnText={"a^2"}
              btnOutput={"\\^{2}"}
              ariaLabel={"squared"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          )}
          {shift ? (
            <DMKey
              btnText={"\\lvert a\\rvert"}
              btnOutput={"| \\ |"}
              ariaLabel={"absolute-value"}
              moveCursor={{ dir: "Left", num: 1 }}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          ) : (
            <DMKey
              btnText={"a^b"}
              btnOutput={"\\^{}"}
              ariaLabel={"to-the-power-of"}
              moveCursor={{ dir: "Left", num: 1 }}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          )}
        </div>
        <div className="grid grid-cols-4 space-x-1">
          {shift ? (
            <>
              <DMKey
                btnText={"a_n"}
                btnOutput={"\\_{}"}
                ariaLabel={"a-sub-n"}
                moveCursor={{ num: 1, dir: "Left" }}
                focusedInput={focusedInput}
                inputsRef={input}
              />
              <DMKey
                btnText={"\\theta"}
                ariaLabel={"theta"}
                focusedInput={focusedInput}
                inputsRef={input}
              />
            </>
          ) : (
            <button
              onClick={() => setShowFuncs(true)}
              aria-label={"show-functions-keyboard"}
              className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            >
              Funcs
            </button>
          )}
          {shift ? (
            <DMKey
              btnText={"e"}
              ariaLabel={"eulers-number"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          ) : (
            <DMKey
              btnText={"Ans"}
              btnOutput={previousAnswer ? previousAnswer.toString() : ""}
              ariaLabel={"previous-answer"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          )}
          {shift ? (
            <DMKey
              btnText={"i"}
              ariaLabel={"i"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          ) : (
            <DMKey
              btnText={","}
              ariaLabel={"comma"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          )}
        </div>
        <div className="grid grid-cols-4 space-x-1">
          <button
            onClick={() => setShift(!shift)}
            aria-label={"shift"}
            className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
          >
            SHIFT
          </button>
          <button
            onClick={() => setShowNumeric(false)}
            aria-label={"show-alphabetical-keyboard"}
            className="border-1 col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
          >
            ABC
          </button>
        </div>
      </div>
      <div className="col col-span-1 space-y-1">
        <div className="grid grid-cols-4 space-x-1">
          <DMKey
            btnText={"7"}
            ariaLabel={"7"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText={"8"}
            ariaLabel={"8"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText={"9"}
            ariaLabel={"9"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText={"\\div"}
            btnOutput={"/"}
            ariaLabel={"divide"}
            btnCmd={true}
            focusedInput={focusedInput}
            inputsRef={input}
          />
        </div>
        <div className="grid grid-cols-4 space-x-1">
          <DMKey
            btnText={"4"}
            ariaLabel={"4"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText={"5"}
            ariaLabel={"5"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText={"6"}
            ariaLabel={"6"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText={"\\times"}
            ariaLabel={"multiply"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
        </div>
        <div className="grid grid-cols-4 space-x-1">
          <DMKey
            btnText={"1"}
            ariaLabel={"1"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText={"2"}
            ariaLabel={"2"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText={"3"}
            ariaLabel={"3"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText={"-"}
            ariaLabel={"minus"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
        </div>
        <div className="grid grid-cols-4 space-x-1">
          <DMKey
            btnText={"0"}
            ariaLabel={"0"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText="."
            btnOutput={"\\."}
            ariaLabel={"dot"} //decimal?
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText="\\\pi"
            btnOutput={"\\pi"}
            btnCmd={true}
            ariaLabel={"pi"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
          <DMKey
            btnText={"+"}
            ariaLabel={"plus"}
            focusedInput={focusedInput}
            inputsRef={input}
          />
        </div>
      </div>
    </>
  );
};

export default NumericalKeyboard;
