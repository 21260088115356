import axios from "axios";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../manager/utils";
import { useUserContext } from "../../shared/contexts/UserContext";

const HelpVideos = () => {
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const [onboardingUrl, setOnboardingUrl] = useState<string>("");
  const [onboardingSubtitleUrl, setOnboardingSubtitleUrl] =
    useState<string>("");
  const [usageReportUrl, setUsageReportUrl] = useState<string>("");
  const [usageReportSubtitleUrl, setUsageReportSubtitleUrl] =
    useState<string>("");
  const [performanceReportUrl, setPerformanceReportUrl] = useState<string>("");
  const [performanceReportSubtitleUrl, setPerformanceReportSubtitleUrl] =
    useState<string>("");
  const [diagnosticUrl, setDiagnosticUrl] = useState<string>("");
  const [diagnosticSubtitleUrl, setDiagnosticSubtitleUrl] =
    useState<string>("");

  const getCookies = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/get_cloudfront_cookies", body, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        const domain =
          process.env.REACT_APP_API_URL === "https://www.deltamath.com/api"
            ? "com"
            : "io";
        const root = `https://video.deltamath.${domain}`;
        setOnboardingUrl(
          `${root}/admin_portal_onboarding/Default/HLS/admin_portal_onboarding.m3u8`
        );
        setOnboardingSubtitleUrl(
          `${root}/captions/admin_portal_onboarding.mp4.vtt`
        );
        setUsageReportUrl(
          `${root}/admin_portal_student_usage/Default/HLS/admin_portal_student_usage.m3u8`
        );
        setUsageReportSubtitleUrl(
          `${root}/captions/admin_portal_student_usage.mp4.vtt`
        );
        setPerformanceReportUrl(
          `${root}/admin_portal_student_performance_reports/Default/HLS/admin_portal_student_performance_reports.m3u8`
        );
        setPerformanceReportSubtitleUrl(
          `${root}/captions/admin_portal_student_performance_reports.mp4.vtt`
        );
        setDiagnosticUrl(
          `${root}/admin_portal_pushed_assignments/Default/HLS/admin_portal_pushed_assignments.m3u8`
        );
        setDiagnosticSubtitleUrl(
          `${root}/captions/admin_portal_pushed_assignments.mp4.vtt`
        );
      },
      onError: () => {
        console.log("error");
        // addToast({
        //   status: "Error",
        //   message: "There was an error with the request",
        // });
      },
    }
  );

  useEffect(() => {
    getCookies.mutate(JSON.stringify({}));
  }, []);

  return (
    <div>
      <div className="m-6 mb-16 text-center">
        <p className="text-4xl font-semibold text-dm-light-blue">
          Admin Portal Help Videos
        </p>
      </div>
      <div className="grid grid-cols-1 gap-12 md:grid-cols-2">
        <div className="flex flex-col justify-between gap-6">
          <div className="flex flex-col gap-6">
            <h5 className="text-xl font-medium text-dm-light-blue">
              Onboarding
            </h5>
            <p>Manage teacher and admins on your license.</p>
          </div>
          {onboardingUrl && onboardingSubtitleUrl && (
            <ReactPlayer
              url={onboardingUrl}
              controls={true}
              style={{
                maxWidth: "100%",
              }}
              height="100%"
              config={{
                file: {
                  hlsOptions: {
                    forceHLS: true,
                    xhrSetup: function (xhr: { withCredentials: boolean }) {
                      xhr.withCredentials = true;
                    },
                  },
                  attributes: { crossOrigin: "use-credentials" },
                  tracks: [
                    {
                      label: "English",
                      kind: "captions",
                      src: onboardingSubtitleUrl,
                      srcLang: "en",
                      default: false,
                    },
                  ],
                },
              }}
            />
          )}
        </div>
        <div className="flex flex-col justify-between gap-6">
          <div className="flex flex-col gap-6">
            <h5 className="text-xl font-medium text-dm-light-blue">
              Student Usage Reports
            </h5>
            <p>
              View student usage over time, compared between
              schools/teachers/sections.
            </p>
          </div>
          {usageReportUrl && usageReportSubtitleUrl && (
            <ReactPlayer
              url={usageReportUrl}
              controls={true}
              style={{
                maxWidth: "100%",
              }}
              height="100%"
              config={{
                file: {
                  hlsOptions: {
                    forceHLS: true,
                    xhrSetup: function (xhr: { withCredentials: boolean }) {
                      xhr.withCredentials = true;
                    },
                  },
                  attributes: { crossOrigin: "use-credentials" },
                  tracks: [
                    {
                      label: "English",
                      kind: "captions",
                      src: usageReportSubtitleUrl,
                      srcLang: "en",
                      default: false,
                    },
                  ],
                },
              }}
            />
          )}
        </div>
        <div className="flex flex-col justify-between gap-6">
          <div className="flex flex-col gap-6">
            <h5 className="text-xl font-medium text-dm-light-blue">
              Student Performance Reports
            </h5>
            <p>
              View detailed student performance data by school/teacher/section.
            </p>
          </div>
          {performanceReportUrl && performanceReportSubtitleUrl && (
            <ReactPlayer
              url={performanceReportUrl}
              controls={true}
              style={{
                maxWidth: "100%",
              }}
              height="100%"
              config={{
                file: {
                  hlsOptions: {
                    forceHLS: true,
                    xhrSetup: function (xhr: { withCredentials: boolean }) {
                      xhr.withCredentials = true;
                    },
                  },
                  attributes: { crossOrigin: "use-credentials" },
                  tracks: [
                    {
                      label: "English",
                      kind: "captions",
                      src: performanceReportSubtitleUrl,
                      srcLang: "en",
                      default: false,
                    },
                  ],
                },
              }}
            />
          )}
        </div>
        <div className="flex flex-col justify-between gap-6">
          <div className="flex flex-col gap-6">
            <h5 className="text-xl font-medium text-dm-light-blue">
              Diagnostics and Shared Assessments
            </h5>
            <p>
              Create diagnostics and/or shared assessments by pushing
              assignments to specific schools/teachers/sections.
            </p>
          </div>
          {diagnosticUrl && diagnosticSubtitleUrl && (
            <ReactPlayer
              url={diagnosticUrl}
              controls={true}
              style={{
                maxWidth: "100%",
              }}
              height="100%"
              config={{
                file: {
                  hlsOptions: {
                    forceHLS: true,
                    xhrSetup: function (xhr: { withCredentials: boolean }) {
                      xhr.withCredentials = true;
                    },
                  },
                  attributes: { crossOrigin: "use-credentials" },
                  tracks: [
                    {
                      label: "English",
                      kind: "captions",
                      src: diagnosticSubtitleUrl,
                      srcLang: "en",
                      default: false,
                    },
                  ],
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default HelpVideos;
