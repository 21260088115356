import React from "react";
import {
  SubStandard,
  SkillCodes,
} from "DeltaMathAPI/routers/standards.router/api";

/**
 * Recursively prints out the standards sheet
 */
const StandardTree = (props: {
  standard: SubStandard;
  depth: number;
  path: string;
  skillcodes: SkillCodes;
}) => {
  const { standard, depth, skillcodes } = props;
  let { path } = props;
  path = path.concat(standard.description);

  const printLabel = (label: string | undefined, depth: number) => {
    if (depth === 2) return label ? " (" + label + ")" : "";
    else if ([3, 4, 5, 6].includes(depth)) {
      if (label && label.trim() === label) return label + ":";
      if (label && label.trim() !== label) return "\u2022"; // Bullet Point
      return "";
    }
  };

  // Because tailwind doesnt seem to like string manipulation (aka: `pl-${n}` does not work).
  const getPadding = (depth: number) => {
    if (depth === 2) return "pl-8";
    if (depth === 3) return "pl-16";
    if (depth === 4) return "pl-24";
    else if (depth === 5) return "pl-32";
    else if (depth === 6) return "pl-40";
    else return "";
  };

  return (
    <>
      <div className="bg-white">
        <div className="flow-root px-4">
          {depth === 1 && (
            <h4
              id={path}
              className="dm-dark-blue mt-0 mb-2 text-3xl font-normal leading-relaxed hover:bg-dm-lightest-blue hover:text-white"
            >
              {standard.description}
            </h4>
          )}
          {depth === 2 && (
            <div className={getPadding(depth)}>
              <h5
                id={path}
                className="text-1xl dm-dark-blue mt-0 mb-2 font-semibold leading-relaxed hover:bg-dm-lightest-blue hover:text-white"
              >
                {standard.description} {printLabel(standard.label, depth)}
              </h5>
              <ModuleList standard={standard} skillcodes={skillcodes} />
            </div>
          )}
          {depth > 2 && (
            <div className={getPadding(depth)}>
              <h5
                id={path}
                className="hover:bg-dm-lightest-blue hover:text-white"
              >
                <span className="text-1xl dm-dark-blue mt-0 mb-0 inline-block font-semibold leading-relaxed">
                  {printLabel(standard.label, depth)}
                </span>
                <span className="leading-relaxed"> {standard.description}</span>
              </h5>
              <ModuleList standard={standard} skillcodes={skillcodes} />
            </div>
          )}
        </div>
      </div>
      {standard.data &&
        Object.entries(standard.data).map(([k, v]) => (
          <StandardTree
            key={k}
            standard={v}
            depth={depth + 1}
            path={path.concat(".data.")}
            skillcodes={skillcodes}
          />
        ))}
    </>
  );
};

/**
 * Prints out  alist of modules for a given standard.
 */
const ModuleList = (props: {
  standard: SubStandard;
  skillcodes: SkillCodes;
}) => {
  const { standard, skillcodes } = props;
  return (
    <>
      {(standard.module_data || [])
        .sort((a, b) => {
          const aMap = (skillcodes[a]?.name || a).toLowerCase();
          const bMap = (skillcodes[b]?.name || b).toLowerCase();
          return aMap < bMap ? -1 : aMap > bMap ? 1 : 0;
        })
        .map((m) => (
          <div
            key={m}
            className="text flex w-full items-center rounded-md border border-gray-300 pt-2 pb-2 pl-3 pr-3 shadow-sm sm:text-sm"
          >
            <div className="flex-none">{skillcodes[m]?.name || m}:</div>
            <div className="grow"></div>
            <div className="flex-none">
              {skillcodes[m]?.courses.map((c) => (
                <div key={c} className="ml-1 inline-flex">
                  {c}
                </div>
              ))}
            </div>
          </div>
        ))}
    </>
  );
};

export default StandardTree;
