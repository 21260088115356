import React, { useEffect } from "react";
import axios from "axios";
import clsx from "clsx";
import { useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import { deltamathAPI } from "../../manager/utils";
import DeltaMathAccordion from "../../shared/DeltaMathAccordion";
import { useDMQuery } from "../../utils";
import LoginAs from "./LoginAs";
import { DeltaMathSelect } from "../../shared/DeltaMathSelect";
import { DemoMode, demoString } from "../../utils/demo";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useUserContext } from "../../shared/contexts/UserContext";

export default function Teacher({ schools }: { schools: any }) {
  const params = useParams();
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [hasIntegral, setHasIntegral] = React.useState(false);
  const [teacher, setTeacher] = React.useState<any>();
  const [teacherSectionsSummaryData, setTeacherSectionsSummaryData] =
    React.useState<any>();
  const [filterOptions, setFilterOptions] = React.useState<any>();
  const [filteredAssignmentData, setFilteredAssignmentData] =
    React.useState<any>();
  const [filterValue, setFilterValue] = React.useState<string>("all");

  const { data } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const {
    data: teacherSectionsSummary,
    refetch: teacherSectionsRefetch,
    isLoading: teacherSectionsIsLoading,
  } = useDMQuery({
    cacheKey: ["teacher_sections_summary", `${params.teachercode}`],
    path: "/admin/teacher_sections_summary",
    method: "post",
    requestBody: {
      params: {
        teachercode: params.teachercode,
      },
    },
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false,
      onSettled(data: any) {
        setTeacherSectionsSummaryData(data);
      },
    },
  });

  useEffect(() => {
    teacherSectionsRefetch();
  }, [hasIntegral]);

  const syncTeacherSectionSummary = useMutation(
    (body: string) => {
      return axios.post(
        deltamathAPI() + "/admin/teacher_sections_summary",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSettled: (data: any) => {
        if (
          data.data.error ===
          "Teacher rosters can only be manually synced once per hour."
        ) {
          toastContext.addToast({
            status: "Error",
            message: data.data.error,
          });
        } else setTeacherSectionsSummaryData(data.data);
      },
    }
  );

  const { data: assignmentsData, isLoading: assignmentsIsLoading } = useDMQuery(
    {
      path: `/admin_new/data/assignments?teachercode=${params.teachercode}`,
      queryOptions: {
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false,
      },
    }
  );

  useEffect(() => {
    setFilteredAssignmentData([]);
    if (assignmentsData && filterValue !== "all") {
      const filteredAssignments = assignmentsData.filter(
        (assignment: any) => assignment?.status === filterValue
      );
      setFilteredAssignmentData(filteredAssignments);
    } else {
      setFilteredAssignmentData(assignmentsData);
    }

    const assignmentsFilterOptions = [
      {
        key: "all",
        val: "All",
      },
    ];

    if (assignmentsData) {
      if (
        assignmentsData.filter((a: any) => a?.status === "Past-due").length > 0
      ) {
        assignmentsFilterOptions.push({
          key: "Past-due",
          val: "Past-due",
        });
      }

      if (
        assignmentsData.filter((a: any) => a?.status === "Upcoming").length > 0
      ) {
        assignmentsFilterOptions.push({
          key: "Upcoming",
          val: "Upcoming",
        });
      }

      setFilterOptions(assignmentsFilterOptions);
    }
  }, [assignmentsData, filterValue]);

  useEffect(() => {
    if (data) {
      const teacher = data.find(
        (s: { _id: string; teachercode: string }) =>
          s.teachercode == params.teachercode
      );
      setTeacher(teacher);
    }
  }, [data]);

  function timeConverter(UNIX_timestamp: number) {
    const a = new Date(UNIX_timestamp * 1000);
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return a.toLocaleTimeString("en-US", options);
  }

  useEffect(() => {
    if (teacher && schools) {
      teacher?.schoolPlus.forEach((school: any) => {
        const results = schools.filter(function (entry: { schoolid: string }) {
          return entry.schoolid === school;
        });
        if (results.length > 0 && results[0]?.licenseTier === "integral") {
          setHasIntegral(true);
        }
      });
    }
  }, [teacher, schools]);

  return (
    <>
      <div className="inline-flex">
        <h2 className="text-lg font-medium leading-6 text-gray-900">
          Teacher:{" "}
          <DemoMode
            value={`${teacher?.first} ${teacher?.last}`}
            type="person_full"
          />{" "}
          (Teacher Code:{" "}
          <DemoMode value={teacher?.teachercode} type="teachercode" />)
        </h2>

        {!assignmentsIsLoading && teacherSectionsSummary?.canManualSync && (
          <button
            onClick={() =>
              syncTeacherSectionSummary.mutate(
                JSON.stringify({
                  params: {
                    teachercode: params.teachercode,
                    syncFirst: true,
                  },
                })
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
          </button>
        )}
      </div>

      <p className="my-2 text-sm text-gray-500">
        {teacher?.last_login > 0 && (
          <>Last Login: {timeConverter(teacher?.last_login)}</>
        )}
      </p>

      {teacher?.teachercode &&
        (teacher?.first || teacher?.last) &&
        hasIntegral &&
        teacher.teachercode !== user?.teachercode && (
          <LoginAs
            teachercode={teacher.teachercode}
            first={teacher.first}
            last={teacher.last}
          />
        )}

      {hasIntegral && teacherSectionsIsLoading && <>LOADING</>}

      {hasIntegral && teacherSectionsSummaryData && (
        <h2 className="mt-5 border-b-2 border-solid border-slate-400 text-lg font-medium leading-6 text-gray-900">
          Current Term Class Rosters:
        </h2>
      )}

      {(hasIntegral &&
        !teacherSectionsIsLoading &&
        teacherSectionsSummaryData?.sections?.length === 0) ||
        (hasIntegral &&
          !teacherSectionsIsLoading &&
          !teacherSectionsSummaryData?.sections && <>No Sections Found</>)}

      {teacherSectionsSummaryData &&
        teacherSectionsSummaryData.sections &&
        teacherSectionsSummaryData.sections.map(
          (section: {
            sectionName: string;
            studentNames: string[];
            sectionId: string;
          }) => {
            return (
              <div key={section.sectionId}>
                <DeltaMathAccordion
                  title={`${demoString({
                    value: section.sectionName,
                    type: "section_name",
                  })} - ${section.studentNames.length} students`}
                  showExpand={section.studentNames.length > 0}
                  content={
                    <ul className="ml-8 list-disc">
                      {section.studentNames.map((studentName: string) => {
                        return (
                          <li
                            key={`${section.sectionId}-student-${studentName}`}
                            className="mr-3 p-1 text-base text-gray-500"
                          >
                            <DemoMode value={studentName} type="person_full" />
                          </li>
                        );
                      })}
                    </ul>
                  }
                />
              </div>
            );
          }
        )}
      <div className="grid grid-cols-4 border-b-2 border-solid border-slate-400">
        <h2 className="col-span-3 mt-8 text-lg font-medium leading-6 text-gray-900">
          Current Term Assignments
        </h2>
        {filteredAssignmentData && filterOptions && (
          <div className="mb-1">
            <DeltaMathSelect
              label={"Filter by Status"}
              defaultVal={filterValue}
              onChangeFn={(value) => setFilterValue(value)}
              options={filterOptions}
              value={filterValue}
            />
          </div>
        )}
      </div>

      <div className="pt-4">
        <div className="">
          {assignmentsIsLoading && <p>Loading...</p>}

          {!assignmentsIsLoading && assignmentsData?.length === 0 && (
            <>No Assignments Found</>
          )}

          <table className="text-footnote light ml-4 table-auto border-spacing-2 sm:w-full 2xl:w-4/5">
            {!assignmentsIsLoading &&
              filteredAssignmentData &&
              filteredAssignmentData.map(
                (assignment: {
                  _id: number;
                  name: string;
                  type: string;
                  avgGrade: string;
                  is_test?: boolean;
                  status: string;
                  count: number;
                  avgComplete: string;
                  sections: string[];
                  skills: { name: string; required: number }[];
                }) => {
                  let assignmentType = "";
                  if (assignment.type === "correction") {
                    assignmentType = "Correction";
                  } else if (assignment.type === "standard") {
                    if (assignment.is_test) {
                      assignmentType = "Test";
                    } else {
                      assignmentType = "Standard";
                    }
                  }
                  return (
                    <tr className="" key={assignment._id}>
                      <td className="w-96 py-4">{assignment.name}</td>
                      <td>
                        <span
                          className={clsx(
                            "inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium",
                            assignmentType === "Standard" &&
                              "bg-blue-100 text-blue-800",
                            assignmentType === "Correction" &&
                              "bg-red-100 text-red-800",
                            assignmentType === "Test" &&
                              "bg-gray-100 text-gray-800"
                          )}
                        >
                          {assignmentType}
                        </span>
                      </td>

                      <td>
                        <span
                          className={clsx(
                            "inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium",
                            assignment?.status === "Upcoming" &&
                              "bg-green-100 text-green-800",
                            assignment?.status === "Past-due" &&
                              "bg-red-100 text-red-800",
                            assignment?.status === "" && ""
                          )}
                        >
                          {assignment?.status}
                        </span>
                      </td>
                      <td>
                        {assignment.avgComplete > "0.00" && (
                          <span
                            className={
                              "ml-1 inline-flex items-center rounded-md bg-blue-100 px-2.5 py-0.5 text-sm font-medium text-gray-800"
                            }
                          >
                            <span className="hidden md:inline">
                              Completion:&nbsp;
                            </span>
                            {`${assignment.avgComplete}%`}
                          </span>
                        )}
                      </td>

                      {teacher && (
                        <td>
                          <Link
                            to={`${process.env.REACT_APP_ADMIN_LINK}/teacher/${teacher.teachercode}/assignment/${assignment._id}/overview`}
                            className="inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          >
                            View&nbsp;
                            <span className="hidden xl:inline">
                              {" "}
                              Assignment Details
                            </span>
                          </Link>
                        </td>
                      )}
                    </tr>
                  );
                }
              )}
          </table>
        </div>
      </div>
    </>
  );
}
