import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";

const tailwindColors = ["lime", "dmyellow", "amber", "orange", "red"];
const opacities = [200, 300, 400, 500, 600];

const LegendDialog = ({
  isOpen,
  setIsOpen,
  axisOption,
}: {
  isOpen: boolean;
  setIsOpen: (value: any) => void;
  axisOption: string;
}) => {
  return (
    <Dialog
      className="relative z-50"
      open={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="h-3/4 w-11/12 overflow-y-scroll rounded bg-white p-4">
          <div className="inline-flex w-full justify-between">
            <Dialog.Title className={"inline-flex text-lg"}>
              Help
              <QuestionMarkCircleIcon
                className={"m-1.5 mt-1 h-5 w-5 flex-shrink-0"}
              />
            </Dialog.Title>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6 hover:cursor-pointer"
              onClick={() => setIsOpen(!isOpen)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <br />
          <div className="justify-evenly lg:grid lg:grid-cols-2">
            <div className="sm:col-span-1">
              <div>
                <span className="font-bold">Fluency</span> for each standard is
                calculated on a scale from 1-20. It is a metric that takes into
                account both completion and accuracy percentage of each student
                on content correlated to the selected standard. The metric is
                curved according to analysis of hundreds of millions of student
                assignments across DeltaMath nationally such that a fluency
                score of 15/20 represents the median fluency for each content
                item.
              </div>
              <br />
              <div>
                <span className="font-bold">Completion</span> represents the
                average of students’ final scores on assignment items correlated
                to the selected standard. For example, if a teacher requires 5
                questions correct on a given item, a student achieves a
                completion of 100% if they end up with 5 questions correct, even
                if they got some questions wrong along the way.
              </div>
              <br />
              <div>
                <span className="font-bold"># of Data Points</span> is a metric
                that tells you the quantity of data that a fluency or completion
                score is based on. A single “data point” is one student’s
                performance on one content item on one assignment, which would
                be a single question for a test, but could be multiple similar
                questions for a standard assignment. For example, if a given
                section had 20 students, who completed 3 assignments, where each
                assignment had 2 content items correlated to a selected
                standard, that would represent 120 “data points”.
              </div>
              <br />
            </div>

            <div className="grid justify-items-center">
              <p className="font-bold">Color Legend</p>
              <div className="inline-flex lg:-mt-24 xl:-mt-8 2xl:-mt-0">
                <div
                  className="mr-2 inline-flex rotate-180 justify-center lg:-mt-40"
                  style={{ writingMode: "vertical-rl" }}
                >
                  <span className="mb-4 hidden sm:block">{`Low ${axisOption}`}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mb-4 h-6 w-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3"
                    />
                  </svg>

                  <span> {`High ${axisOption}`}</span>
                </div>

                <div>
                  <div className="">
                    {" "}
                    {tailwindColors.map((color: string) => {
                      return (
                        <div className="">
                          <div className="inline-flex">
                            {opacities.map((shade: any) => (
                              <div
                                className={clsx(
                                  `bg-${color}-${shade}`,
                                  "mr-1 h-8 w-8 rounded-sm sm:h-16 sm:w-16"
                                )}
                              ></div>
                            ))}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="inline-flex sm:ml-16">
                    <span className="hidden sm:block">Less Data</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="mx-2 h-6 w-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                      />
                    </svg>

                    <span>More Data</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default LegendDialog;
