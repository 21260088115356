import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDMQuery } from "../../../utils";
import { getStartEndDate } from "../StudentUsageUtils";
import clsx from "clsx";
import { ChartSquareBarIcon } from "@heroicons/react/outline";
import CalendarIcon from "@heroicons/react/solid/CalendarIcon";

export const StudentPerformanceNav = ({
  selectedStandard,
  selectedCourse,
  selectedDataScope,
  selectedSchools,
  selectedTeachers,
  selectedSections,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: {
  selectedStandard: any;
  selectedCourse: any;
  selectedDataScope: any;
  selectedSchools: any;
  selectedTeachers: any;
  selectedSections: any;
  startDate: Date | null;
  setStartDate: any;
  endDate: Date | null;
  setEndDate: any;
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const { yesterday, lastWeek, thirtyDays, yearToDate } = getStartEndDate();

  const [dateRange, setDateRange] = useState<string>("year-to-date");
  const [canViewData, setCanViewData] = useState<boolean>(true);

  const { data: schoolData } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      enabled: true,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: teacherData } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });

  const { data: sectionData } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  const SelectedStandardTag = () => {
    return (
      <span
        key="scope-schools"
        className={
          "ml-4 inline-flex cursor-pointer items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 md:mt-2 lg:mt-0"
        }
      >
        <span className="text-left font-semibold">
          {selectedStandard.name
            ? selectedStandard.name
            : selectedStandard.label}
        </span>
      </span>
    );
  };

  const ScopeBlock = () => {
    const numberOfSchoolsSelected = Object.keys(selectedSchools).length;
    const numberOfTeachersSelected = Object.keys(selectedTeachers).length;
    const numberOfSectionsSelected = Object.keys(selectedSections).length;

    if (selectedDataScope === "Schools" && numberOfSchoolsSelected) {
      return (
        <>
          {numberOfSchoolsSelected > 0 &&
            Array.isArray(schoolData) &&
            schoolData.length > 0 && (
              <span
                key="scope-schools"
                className={
                  "ml-4 inline-flex cursor-pointer items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 md:mt-2 lg:mt-0"
                }
              >
                <span className="text-left font-semibold">
                  {schoolData.length === numberOfSchoolsSelected ? (
                    <>All Schools Selected</>
                  ) : (
                    <>
                      {numberOfSchoolsSelected} School
                      {numberOfSchoolsSelected > 1 ? "s" : ""} Selected
                    </>
                  )}
                </span>
              </span>
            )}
        </>
      );
    } else if (selectedDataScope === "Teachers" && numberOfTeachersSelected) {
      return (
        <>
          {numberOfTeachersSelected > 0 &&
            Array.isArray(teacherData) &&
            teacherData.length > 0 && (
              <span
                key="scope-teachers"
                className={
                  "ml-4 inline-flex cursor-pointer items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 md:mt-2 lg:mt-0"
                }
              >
                <span className="text-left font-semibold">
                  {teacherData.length === numberOfTeachersSelected ? (
                    <>All Teachers Selected</>
                  ) : (
                    <>
                      {numberOfTeachersSelected} Teacher
                      {numberOfTeachersSelected > 1 ? "s" : ""} Selected
                    </>
                  )}
                </span>
              </span>
            )}
        </>
      );
    } else if (selectedDataScope === "Sections" && numberOfSectionsSelected) {
      return (
        <>
          {numberOfSectionsSelected > 0 &&
            Array.isArray(sectionData) &&
            sectionData.length > 0 && (
              <span
                key="scope-sections"
                className={
                  "ml-4 inline-flex cursor-pointer items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 md:mt-2 lg:mt-0"
                }
              >
                <span className="text-left font-semibold">
                  {sectionData.length === numberOfSectionsSelected ? (
                    <>All Sections Selected</>
                  ) : (
                    <>
                      {numberOfSectionsSelected} Section
                      {numberOfSectionsSelected > 1 ? "s" : ""} Selected
                    </>
                  )}
                </span>
              </span>
            )}
        </>
      );
    }
    return <></>;
  };

  // Sets Start and End Date when a date range selection is made
  useEffect(() => {
    if (dateRange === "last-week") {
      setStartDate(lastWeek);
      setEndDate(yesterday);
    } else if (dateRange === "thirty-days") {
      setStartDate(thirtyDays);
      setEndDate(yesterday);
    } else if (dateRange === "year-to-date") {
      setStartDate(yearToDate);
      setEndDate(yesterday);
    }
  }, [dateRange]);

  useEffect(() => {
    if (selectedStandard) {
      if (
        (selectedDataScope === "Schools" &&
          Object.keys(selectedSchools).length === 0) ||
        (selectedDataScope === "Teachers" &&
          Object.keys(selectedTeachers).length === 0) ||
        (selectedDataScope === "Sections" &&
          Object.keys(selectedSections).length === 0)
      ) {
        setCanViewData(false);
      } else setCanViewData(true);
    } else setCanViewData(false);
  }, [
    selectedDataScope,
    selectedStandard,
    selectedSchools,
    selectedSections,
    selectedTeachers,
  ]);

  return (
    <nav className="my-2 grid space-x-4 pb-2 sm:grid-cols-1 lg:grid-cols-3">
      <button
        onClick={() =>
          navigate(
            `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/data-scope`
          )
        }
        className={clsx(
          params.data === "data-scope"
            ? "text-gray-60 border-indigo-500"
            : "border-gray-300 text-gray-500 hover:text-gray-700",
          "col-span-1 border-b-2 px-1 py-4 text-center text-sm font-medium text-gray-500 hover:border-gray-700 hover:bg-slate-100 hover:text-dm-blue"
        )}
      >
        Data Scope
        {selectedDataScope && <ScopeBlock />}
      </button>
      <button
        onClick={() =>
          navigate(
            `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/select-standard`
          )
        }
        className={clsx(
          params.data === "select-standard"
            ? "text-gray-60 border-indigo-500"
            : "border-gray-300 text-gray-500 hover:text-gray-700",
          "col-span-1 border-b-2 px-1 py-4 text-center text-sm font-medium text-gray-500 hover:border-gray-700 hover:bg-slate-100 hover:text-dm-blue"
        )}
      >
        Select Standard
        {selectedStandard && <SelectedStandardTag />}
      </button>
      <button
        className={clsx(
          params.data === "view-data" || params.data === "view-global-data"
            ? "text-gray-60 border-indigo-500"
            : "border-gray-300 text-gray-500 hover:text-gray-700",
          "group relative col-span-1 border-b-2 px-1 py-4 text-center text-sm font-medium text-gray-500 hover:border-gray-700 hover:bg-slate-100 hover:text-dm-blue disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-white"
        )}
        disabled={!canViewData}
        onClick={() => {
          if (selectedStandard.description !== selectedCourse.description) {
            navigate(
              `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/view-data`
            );
          } else if (selectedCourse) {
            navigate(
              `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/view-global-data`
            );
          }
        }}
      >
        <ChartSquareBarIcon
          className="-ml-1 mr-2 inline h-8 w-8"
          aria-hidden="true"
        />
        View Data
        {!canViewData && (
          <div className="invisible absolute -top-10 ml-8 items-center text-left group-hover:visible group-hover:delay-300">
            <span className="whitespace-no-wrap relative z-10 block bg-black p-2 text-xs text-white shadow-lg">
              Please select a Data Scope and Standard to view data.
            </span>
            <div className="align-center -my-2 mx-auto h-3 w-3 rotate-45 bg-black"></div>
          </div>
        )}
      </button>
      {/* <div className="col-span-1 border bg-gray-300 py-4 text-center text-sm font-medium text-gray-500">
        <div className="inline-flex">
          <CalendarIcon className="-ml-1 mr-2 inline h-8 w-8" />
          <p className="text-md mt-1">Viewing Current School Year</p>
        </div>
      </div> */}
    </nav>
  );
};
