export const possibleNames: string[] = [
  "Noah",
  "Liam",
  "Jacob",
  "Mason",
  "William",
  "Ethan",
  "Michael",
  "Alexander",
  "Jayden",
  "Daniel",
  "Elijah",
  "Aiden",
  "Benjamin",
  "Matthew",
  "Jackson",
  "Logan",
  "David",
  "Anthony",
  "Joseph",
  "Joshua",
  "Andrew",
  "Gabriel",
  "Samuel",
  "Christopher",
  "John",
  "Dylan",
  "Isaac",
  "Ryan",
  "Nathan",
  "Carter",
  "Caleb",
  "Luke",
  "Christian",
  "Hunter",
  "Henry",
  "Owen",
  "Landon",
  "Jack",
  "Wyatt",
  "Jonathan",
  "Eli",
  "Isaiah",
  "Sebastian",
  "Jaxon",
  "Julian",
  "Brayden",
  "Gavin",
  "Levi",
  "Aaron",
  "Oliver",
  "Jordan",
  "Evan",
  "Connor",
  "Jeremiah",
  "Cameron",
  "Adrian",
  "Robert",
  "Tyler",
  "Colton",
  "Austin",
  "Jace",
  "Angel",
  "Dominic",
  "Josiah",
  "Brandon",
  "Ayden",
  "Kevin",
  "Zachary",
  "Parker",
  "Blake",
  "Jose",
  "Chase",
  "Grayson",
  "Jason",
  "Ian",
  "Bentley",
  "Adam",
  "Xavier",
  "Cooper",
  "Justin",
  "Nolan",
  "Hudson",
  "Easton",
  "Carson",
  "Nathaniel",
  "Jaxson",
  "Kayden",
  "Brody",
  "Lincoln",
  "Tristan",
  "Damian",
  "Camden",
  "Juan",
  "Alang",
  "Cai",
  "Fwam",
  "Suav",
  "Francisco",
  "Carlos",
  "Luis",
  "Pedro",
  "Santiago",
  "Andres",
  "Miguel",
  "Alejandro",
  "Alonso",
  "Arturo",
  "Moussa",
  "Abdoulaye",
  "Amadou",
  "Mamadou",
  "Boubacar",
  "Deion",
  "Jamar",
  "Jevonte",
  "Kadeem",
  "Keshawn",
  "Lavaughn",
  "Marques",
  "Montraie",
  "Rashon",
  "Tyshawn",
  "Hakeem",
  "Jayden",
  "Khalil",
  "Lamonte",
  "Rashaad",
  "Anand",
  "Mohal",
  "Qasim",
  "Samir",
  "Ajay",
  "Arun",
  "Jai",
  "Kiran",
  "Rahul",
  "Bo",
  "Feng",
  "Jin",
  "Fabian",
  "Jeriel",
  "Josue",
  "Dalvin",
  "Aaden",
  "Bashir",
  "Magan",
  "Yusuf",
  "Omar",
  "Jamal",
  "Hassan",
  "Tariq",
  "Malik",
  "Nasim",
  "Adriel",
  "Tyee",
  "Sophia",
  "Emma",
  "Olivia",
  "Isabella",
  "Ava",
  "Mia",
  "Emily",
  "Abigail",
  "Madison",
  "Elizabeth",
  "Charlotte",
  "Avery",
  "Sofia",
  "Chloe",
  "Ella",
  "Harper",
  "Amelia",
  "Aubrey",
  "Addison",
  "Evelyn",
  "Natalie",
  "Grace",
  "Hannah",
  "Zoey",
  "Victoria",
  "Lillian",
  "Lily",
  "Brooklyn",
  "Samantha",
  "Layla",
  "Zoe",
  "Audrey",
  "Leah",
  "Allison",
  "Anna",
  "Aaliyah",
  "Savannah",
  "Gabriella",
  "Camila",
  "Aria",
  "Kaylee",
  "Scarlett",
  "Hailey",
  "Arianna",
  "Riley",
  "Nevaeh",
  "Sarah",
  "Claire",
  "Sadie",
  "Peyton",
  "Aubree",
  "Serenity",
  "Ariana",
  "Penelope",
  "Alyssa",
  "Bella",
  "Taylor",
  "Alexa",
  "Kylie",
  "Mackenzie",
  "Caroline",
  "Kennedy",
  "Autumn",
  "Lucy",
  "Ashley",
  "Madelyn",
  "Violet",
  "Stella",
  "Brianna",
  "Maya",
  "Skylar",
  "Ellie",
  "Julia",
  "Sophie",
  "Katherine",
  "Mila",
  "Khloe",
  "Paisley",
  "Annabelle",
  "Alexandra",
  "Nora",
  "Melanie",
  "London",
  "Gianna",
  "Naomi",
  "Eva",
  "Faith",
  "Madeline",
  "Lauren",
  "Nicole",
  "Ruby",
  "Makayla",
  "Kayla",
  "Lydia",
  "Piper",
  "Sydney",
  "Jocelyn",
  "Morgan",
  "Chee",
  "Houa",
  "Kaj",
  "Mav",
  "Taub",
  "Mariana",
  "Valeria",
  "Ximena",
  "Xochitl",
  "Julieta",
  "Guadalupe",
  "Genesis",
  "Micaela",
  "Fatoumata",
  "Mariam",
  "Hawa ",
  "Khadija",
  "Deondra",
  "Kadeesha",
  "Keilantra",
  "Latanya",
  "Nakeisha",
  "Shandra",
  "Shaquana",
  "Tamika",
  "Tanisha",
  "Zahra",
  "Zendaya",
  "Kehlani",
  "Brianna",
  "Indigo",
  "Malika",
  "Shaniece",
  "Deepa",
  "Nabhitha",
  "Rajani",
  "Avani",
  "Jaya",
  "Meena",
  "Vani",
  "Yasmin",
  "Ai Mi",
  "Bao",
  "Mei Mei",
  "Xin",
  "Yaritza",
  "Arianys",
  "Janelys",
  "Dianelys",
  "Mayerlin",
  "Nachelle",
  "Bilquis",
  "Casho",
  "Fawzia",
  "Idil",
  "Aisha",
  "Amira",
  "Nayeli",
  "Salma",
  "Myesha",
  "Enola",
  "Tallulah",
];
export const possiblePronouns: string[] = [
  "he",
  "his",
  "him",
  "her",
  "hers",
  "she",
  "boy",
  "girl",
];
