import { Dialog, Transition } from "@headlessui/react";
import { Fragment, Dispatch, SetStateAction } from "react";

type Props = {
  isShowing: boolean;
  setIsShowing: Dispatch<SetStateAction<any>>;
  handleShowSolutionsData: any;
  taId: any;
  skill?: string;
};

export default function SeeSolutionsModal({
  isShowing = false,
  setIsShowing,
  handleShowSolutionsData,
  taId,
  skill = undefined,
}: Props): JSX.Element {
  function handleData() {
    handleShowSolutionsData(taId, skill);
    closeModal();
  }
  function closeModal() {
    setIsShowing(false);
  }

  return (
    <Transition show={isShowing} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="mb-3 text-lg font-medium leading-6 text-gray-900"
                >
                  Warning
                </Dialog.Title>
                <Dialog.Description className="text-sm text-gray-500">
                  Are you sure you are done with this test?
                </Dialog.Description>

                <div className="mt-4 flex justify-end gap-1">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={handleData}
                  >
                    Yes I'm Done
                  </button>
                  <button
                    type="button"
                    className="bg-white-100 inline-flex justify-center rounded-md border px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
