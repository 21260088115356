import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Transition } from "@headlessui/react";
import { DmLoadingSpinner } from "../../../manager/utils/functions";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useDMQuery } from "../../../utils";
import DeltaMathToggle from "./DeltaMathToggle";
import {
  DisplayPerformanceGraphs,
  GraphDataInterface,
  NoDataMessage,
} from "./DisplayPerformanceGraphs";
import { ProgressError } from "./DisplayPerformanceGraphs";
import LegendDialog from "./LegendDialog";
import ScatterPlot from "./ScatterPlot";
import DmAlertModal from "../../../student/components/answerForm/DmAlertModal";
import { useUserContext } from "../../../shared/contexts/UserContext";

interface IndividualProgressParams {
  skillcodes: string[];
  series: string[];
  schoolIds: string[];
  teacherIds: string[];
  sectionIds: string[];
}

const DisplayGlobalPerformanceGraph = ({
  dataScope,
  requestParams,
  selectedStandard,
  setRequestParams,
  filterValue,
  setFilterValue,
  startDate,
  endDate,
}: {
  dataScope: string;
  requestParams: any;
  selectedStandard: any;
  setRequestParams: (value: any) => void;
  filterValue: string;
  setFilterValue: (value: string) => void;
  startDate: any;
  endDate: any;
}) => {
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const navigate = useNavigate();
  const toastContext = useDeltaToastContext();

  //   const [haveResults, setHaveResults] = useState(false);
  const [showFluency, setShowFluency] = useState(true);
  const [progressError, setProgressError] = useState<ProgressError>();
  const [graphData, setGraphData] = useState<GraphDataInterface[]>();

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState<JSX.Element>();
  const [specificSk, setSpecificSk] = useState<string>();
  const [individualProgressParams, setIndividualProgressParams] =
    useState<IndividualProgressParams>();

  const { data, refetch, isLoading, error } = useDMQuery({
    cacheKey: [`/admin_new/data/progressByStandards`, requestParams],
    path: `/admin_new/data/progressByStandards`,
    requestBody: requestParams,
    method: "post",
    additionalHeaders: {
      Authorization: `Bearer ${token}`,
    },
    queryOptions: {
      enabled: false,
      onError: (err: ProgressError) => setProgressError(err),
      retry: 0,
    },
  });

  // Switching to view-data and we have request params, actually make the API request
  useEffect(() => {
    if (requestParams) {
      if (
        (typeof requestParams.teacherIds !== "undefined" &&
          requestParams.teacherIds.length > 0) ||
        (typeof requestParams.sectionIds !== "undefined" &&
          requestParams.sectionIds.length > 0) ||
        (typeof requestParams.schoolIds !== "undefined" &&
          requestParams.schoolIds.length > 0)
      ) {
        refetch();
      }
    } else {
      navigate(
        `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/data-scope`,
        {
          replace: true,
        }
      );
    }
  }, [requestParams]);

  useEffect(() => {
    if (data) {
      /** parse only total data */
      for (const entry in data) {
        if (entry === "data") {
          const uniqueStudents = data[entry]["uniqueStudents"];
          const newGraphData: GraphDataInterface[] = [];
          const standardMap = data[entry]["standardToData"];
          for (const standard in standardMap) {
            const thisNumStudentAssignments =
              standardMap[standard]["numStudentAssignments"];
            if (thisNumStudentAssignments > 0) {
              newGraphData.push({
                label: standard,
                data: {
                  flu: standardMap[standard]["flu"] || 0,
                  perc: standardMap[standard]["perc"] || 0,
                  problemsPerStudent:
                    thisNumStudentAssignments / uniqueStudents || 0,
                },
              });
            }
          }
          setGraphData(newGraphData);
        }
      }
    }
  }, [data]);

  const [legendOpen, setLegendOpen] = useState(false);
  const handleInfoClick = () => {
    setLegendOpen(!legendOpen);
  };

  useEffect(() => {
    if (requestParams?.skillCodesToStandards && specificSk) {
      const skillsArr = Object.keys(requestParams.skillCodesToStandards);
      const specificSkills: string[] = [];
      skillsArr.forEach((sk: string) => {
        if (requestParams.skillCodesToStandards[sk].indexOf(specificSk) != -1) {
          specificSkills.push(sk);
        }
      });

      const newIndiParams = {
        series: requestParams.series,
        schoolIds: requestParams.schoolIds,
        teacherIds: requestParams.teacherIds,
        sectionIds: requestParams.sectionIds,
        skillcodes: specificSkills,
      };
      setIndividualProgressParams(newIndiParams);
    }
  }, [specificSk]);

  const handleDataPointSelect = (value: any) => {
    if (Array.isArray(value) && value.length > 1) {
      setModalMessage(
        <>
          {value.map((standard: string) => (
            <button
              className="mb-4 inline-flex w-full justify-center rounded-md border border-transparent bg-dm-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-dm-dark-blue focus:outline-none focus:ring-2 focus:ring-dm-lightest-blue focus:ring-offset-2 sm:text-sm"
              onClick={() => {
                setSpecificSk(standard);
                setShowModal(false);
              }}
            >
              {standard}
            </button>
          ))}
        </>
      );
      setShowModal(true);
    } else setSpecificSk(value[0]);
  };

  const handleChooseSk = (sk: any) => {
    setSpecificSk(sk);
  };

  return (
    <div>
      {error && !isLoading && (
        <div className="text-red-500">{progressError?.message}</div>
      )}
      {isLoading && <DmLoadingSpinner message="Loading..." />}
      {!error && !isLoading && typeof graphData === "undefined" && (
        <NoDataMessage />
      )}
      {graphData && !isLoading && (
        <>
          <div className="sm:flex sm:flex-row sm:justify-between">
            <button
              className="relative z-10 mt-2 flex flex-row text-sm text-gray-400"
              onClick={handleInfoClick}
            >
              <span className="mt-2 whitespace-nowrap">Help </span>
              <div>
                <QuestionMarkCircleIcon
                  className={
                    "group mt-2.5 h-4 w-4 flex-shrink-0 hover:cursor-pointer"
                  }
                />
              </div>
            </button>
            <Transition
              as="div"
              show={true}
              appear={true}
              enter="transition-opacity duration-1000"
              enterFrom="transform opacity-0"
              enterTo="transform opacity-100"
              leave="transition-opacity duration-1000"
              leaveFrom="transform opacity-100"
              leaveTo="transform opacity-0"
            >
              <LegendDialog
                isOpen={legendOpen}
                setIsOpen={setLegendOpen}
                axisOption={showFluency ? "Fluency" : "Completion"}
              />
            </Transition>
            <h1 className="font-bold underline">
              {" "}
              {selectedStandard.name
                ? selectedStandard.name
                : selectedStandard.label}
            </h1>
            <div className="mb-2">
              <DeltaMathToggle
                optionA="Completion"
                optionB="Fluency"
                aSelected={!showFluency}
                onChangeFn={() => setShowFluency(!showFluency)}
              />
            </div>
          </div>
          <ScatterPlot
            title={`${
              showFluency ? "Fluency" : "Completion"
            } by Standard for Selected ${dataScope}`}
            data={graphData}
            yAxisVariable={showFluency ? "flu" : "perc"}
            onClick={handleDataPointSelect}
          />
          <DmAlertModal
            showModal={showModal}
            setShowModal={setShowModal}
            showButton={false}
            headline={"Select a specific standard."}
            message={modalMessage}
            modalAction={handleChooseSk}
          />
          {individualProgressParams && specificSk && (
            <>
              <h1 className="mt-2 w-full border-t-2 p-2 text-center">
                Selected Standard: <strong>{specificSk}</strong>
              </h1>
              <p className="pb-4 text-center text-sm text-gray-600">
                {getDescriptionByLabel(selectedStandard, specificSk)}
              </p>
              <DisplayPerformanceGraphs
                dataScope={dataScope}
                requestParams={individualProgressParams}
                setRequestParams={setIndividualProgressParams}
                filterValue={filterValue}
                setFilterValue={setFilterValue}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

const getDescriptionByLabel: any = (standard: any, label: string) => {
  if (standard.module_data) {
    if (standard.label === label) {
      return standard.description;
    }
  } else if (standard.data) {
    return Object.keys(standard.data).map((id: any) =>
      getDescriptionByLabel(standard.data[id], label)
    );
  }
};

export default DisplayGlobalPerformanceGraph;
