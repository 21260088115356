import { useContext } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { FolderIcon } from "@heroicons/react/outline";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { useParams, NavLink } from "react-router-dom";
import clsx from "clsx";
import { getSectionData } from "../utils";
import { SectionDataStatus } from "../_constants";

export default function ArchivedCourses() {
  const { dmSectionsData, dmAssignmentData } = useContext(
    StudentSectionsContext
  );

  const { sectionId: activeSection } = useParams();

  const archivedSections = dmSectionsData?.filter(
    (item: any) => item.term !== "current"
  );

  if (!archivedSections.length) return null;

  const isCurrentArchive = () => {
    return !!dmSectionsData?.filter(
      (item: any) => item._id == activeSection && item.term !== "current"
    ).length;
  };

  return (
    <Disclosure
      as="div"
      key="archiveCourses"
      className="space-y-1"
      defaultOpen={isCurrentArchive()}
    >
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`group flex w-full items-center p-2 text-sm ${
              open ? "bg-dm-brand-blue-100" : ""
            }`}
          >
            <FolderIcon
              className={`mr-2 h-6 w-6 flex-shrink-0 text-dm-gray-200 hover:text-dm-gray-600 ${
                open ? "text-dm-gray-600" : ""
              }`}
              aria-hidden="true"
            />
            <span className="flex-1 text-left text-dm-gray-600">
              Archived Courses
            </span>
            {open ? (
              <ChevronUpIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            ) : (
              <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            )}
          </Disclosure.Button>
          <Disclosure.Panel className="space-y-1">
            {archivedSections.map((item: any) => {
              const assignmentList = getSectionData(
                SectionDataStatus.upcoming,
                item._id,
                dmAssignmentData
              );

              return (
                <NavLink
                  key={item._id}
                  to={`${item._id}`}
                  className={({ isActive }) =>
                    clsx(
                      isActive
                        ? "bg-dm-brand-blue-100 font-bold text-dm-gray-800"
                        : "text-dm-gray-200 hover:text-dm-gray-600",
                      "group mb-1 flex items-center rounded-md p-2 text-sm last:mb-0 hover:font-bold hover:text-dm-gray-800"
                    )
                  }
                  // className={({ isActive }) =>
                  //   clsx(
                  //     isActive
                  //       ? "bg-gray-100 text-gray-900"
                  //       : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                  //     "group flex items-center rounded-md px-3 py-2 pl-11 text-sm"
                  //   )
                  // }
                  aria-current={item.current ? "page" : undefined}
                >
                  {({ isActive }) => (
                    <>
                      <i className="far fa-chalkboard mr-2 w-6 flex-shrink-0 text-center"></i>
                      <span
                        className={`truncate ${
                          isActive ? "text-dm-gray-800" : "text-dm-gray-600"
                        }`}
                      >
                        {item.name}
                      </span>
                      {assignmentList &&
                        Object.keys(assignmentList)?.length > 0 && (
                          <span
                            className={clsx(
                              "bg-dm-error-500 text-white",
                              "ml-auto inline-block rounded-full px-3 py-0.5 text-xs"
                            )}
                          >
                            {Object.keys(assignmentList).length}
                          </span>
                        )}
                    </>
                  )}
                  {/* <span className="truncate">{item.name}</span> */}
                  {/* {({ isActive }) => (
                  <>
                    <span className="truncate">{item.name}</span>
                  </>
                )} */}
                </NavLink>
              );
            })}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
