import React, { useCallback, useEffect, useState } from "react";
import { Disclosure, Switch } from "@headlessui/react";
import clsx from "clsx";
import {
  CogIcon,
  UserGroupIcon,
  DocumentTextIcon,
  DocumentReportIcon,
  OfficeBuildingIcon,
  QuestionMarkCircleIcon,
  DocumentAddIcon,
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { getAdminParams } from "./utils";
import { useQueryClient } from "react-query";
import { deltamathAPI } from "../manager/utils";
import axios from "axios";
import { useDeltaToastContext } from "../shared/contexts/ToasterContext";
import { DemoMode } from "../utils/demo";
import { useUserContext } from "../shared/contexts/UserContext";
import Cookies from "js-cookie";
import { getFilePath } from "../utils";

export default function SideNavigation({
  schools,
  hasIntegralAtAll,
  demoModeEnabled,
  setDemoModeEnabled,
}: {
  schools: any;
  hasIntegralAtAll: boolean;
  demoModeEnabled: boolean;
  setDemoModeEnabled: any;
}) {
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const adminParams = getAdminParams();
  const queryClient = useQueryClient();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const admin = JSON.parse(localStorage.getItem("admin") || "{}");
  const token = userContext.getJWT();
  const [isCustomerService, setIsCustomerService] = useState(false);

  useEffect(() => {
    if (demoModeEnabled) {
      localStorage.setItem("demo_mode_enabled", "true");
    } else {
      localStorage.removeItem("demo_mode_enabled");
    }
  }, [demoModeEnabled]);

  useEffect(() => {
    setIsCustomerService(!!localStorage.getItem("customer_service_token"));
  }, []);

  const navigation = [
    {
      name: "District Admins",
      icon: UserGroupIcon,
      href: `${process.env.REACT_APP_ADMIN_LINK}/district/current-admins`,
      roles: ["district", "super_district"],
      integralOnly: false,
    },
    {
      name: "School Summary",
      icon: OfficeBuildingIcon,
      href: `${process.env.REACT_APP_ADMIN_LINK}/schools`,
      roles: ["district", "super_district", "school", "super_school"],
      integralOnly: false,
    },
    {
      name: "Pushed Assigments",
      roles: ["district", "super_district", "school", "super_school"],
      integralOnly: true,
      icon: DocumentTextIcon,
      children: [
        {
          name: "Create Assignment",
          href: `${process.env.REACT_APP_ADMIN_LINK}/push-assignment`,
          roles: ["district", "super_district", "school", "super_school"],
        },
        {
          name: "View Assignments",
          href: `${process.env.REACT_APP_ADMIN_LINK}/view-pushed-assignments`,
          roles: ["district", "super_district", "school", "super_school"],
        },
      ],
    },
    {
      name: "Reports",
      icon: DocumentReportIcon,
      roles: ["district", "super_district", "school", "super_school"],
      integralOnly: true,
      children: [
        {
          name: "Student Usage",
          href: `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/data-scope`,
        },
        {
          name: "Student Performance",
          href: `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/data-scope`,
        },
      ],
    },
    {
      name: "Profile",
      icon: CogIcon,
      href: `${process.env.REACT_APP_ADMIN_LINK}/profile`,
      roles: ["district", "super_district", "school", "super_school"],
      integralOnly: false,
    },
  ];

  return (
    <nav className="mt-4 px-3">
      <div className="space-y-1">
        {navigation.map((item) =>
          !item.children
            ? item.roles &&
              item.roles.length > 0 &&
              item?.roles.includes(adminParams.account_type) &&
              (!item?.integralOnly ||
                (item?.integralOnly && hasIntegralAtAll)) && (
                <div key={item.name}>
                  <Link
                    to={item.href}
                    className={
                      location.pathname === item.href
                        ? "text-md group flex w-full items-center rounded-md py-2 pl-2 font-medium text-white underline"
                        : "text-md group flex w-full items-center rounded-md py-2 pl-2 font-medium text-white hover:underline"
                    }
                  >
                    {item.icon && (
                      <item.icon
                        className={"mr-3 h-6 w-6 flex-shrink-0 text-white"}
                        aria-hidden="true"
                      />
                    )}
                    {item.name}
                  </Link>
                </div>
              )
            : item.roles &&
              item?.roles.length > 0 &&
              item?.roles.includes(adminParams.account_type) &&
              (!item?.integralOnly ||
                (item?.integralOnly && hasIntegralAtAll)) && (
                <Disclosure as="div" key={item.name} className="space-y-1">
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={
                          "text-md group flex w-full items-center rounded-md py-2 pl-2 pr-1 text-left font-medium text-white focus:outline-none"
                        }
                      >
                        {item.icon && (
                          <item.icon
                            className="mr-3 h-6 w-6 flex-shrink-0 text-white"
                            aria-hidden="true"
                          />
                        )}
                        <span className="flex-1">{item.name}</span>
                        <svg
                          className={clsx(
                            open ? "rotate-90 text-gray-400" : "text-gray-300",
                            "ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out group-hover:text-gray-400"
                          )}
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                        </svg>
                      </Disclosure.Button>
                      {(open ||
                        item.children.find(
                          (element) => element.href === location.pathname
                        )) && (
                        <Disclosure.Panel className="space-y-1" static>
                          {item.children.map((subItem) => (
                            <Disclosure.Button
                              key={subItem.name}
                              className={
                                location.pathname === subItem.href
                                  ? "group flex w-full items-center rounded-md py-2 pl-11 text-sm font-medium text-white underline"
                                  : "group flex w-full items-center rounded-md py-2 pl-11 text-sm font-medium text-white hover:underline"
                              }
                            >
                              <Link to={subItem.href}>{subItem.name}</Link>
                            </Disclosure.Button>
                          ))}
                        </Disclosure.Panel>
                      )}
                    </>
                  )}
                </Disclosure>
              )
        )}

        <div>
          <span
            onClick={async () => {
              const adminTeacherMissing =
                !user.isTeacherAdmin && admin && !admin.isTeacherAdmin;
              if (adminTeacherMissing) {
                const request = await axios.post(
                  deltamathAPI() + "/admin/create_teacher_admin",
                  {},
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                if (!request?.data?.success) {
                  toastContext.addToast({
                    status: "Error",
                    message: request.data.message,
                  });
                } else {
                  window.location.href = `${process.env.REACT_APP_TEACHER_LINK}`;
                }
              } else {
                window.location.href = `${process.env.REACT_APP_TEACHER_LINK}`;
              }
            }}
            className={
              "text-md group flex w-full cursor-pointer items-center rounded-md py-2 pl-2 font-medium text-white hover:underline"
            }
          >
            <img
              width="24"
              height="13"
              className="mr-3"
              src={getFilePath("/images/DeltaMath-Logo-only.png")}
              alt="DeltaMath Admin Portal"
            />
            Teacher Application
          </span>
        </div>

        <div>
          <Link
            to={`${process.env.REACT_APP_ADMIN_LINK}/help-videos`}
            className={
              "text-md group flex w-full cursor-pointer items-center rounded-md py-2 pl-2 font-medium text-white hover:underline"
            }
          >
            <QuestionMarkCircleIcon
              className={"mr-3 h-6 w-6 flex-shrink-0 text-white"}
            />
            Help Videos
          </Link>
        </div>

        {isCustomerService &&
          ["super_district", "district"].includes(adminParams.account_type) && (
            <div>
              <Link
                to={`${process.env.REACT_APP_ADMIN_LINK}/district/add-school`}
                className="text-md group flex w-full cursor-pointer items-center rounded-md py-2 pl-2 font-medium text-white hover:underline"
              >
                <DocumentAddIcon
                  className={"mr-3 h-6 w-6 flex-shrink-0 text-white"}
                />
                Add School to District
              </Link>
            </div>
          )}
        {isCustomerService && (
          <Switch.Group as="div" className="flex items-center">
            <Switch
              checked={demoModeEnabled}
              onChange={() => {
                setDemoModeEnabled(!demoModeEnabled);
              }}
              className={clsx(
                demoModeEnabled ? "bg-indigo-600" : "bg-gray-200",
                "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              )}
            >
              <span
                aria-hidden="true"
                className={clsx(
                  demoModeEnabled ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                )}
              />
            </Switch>
            <Switch.Label as="span" className="my-2 ml-3">
              <span className="text-md font-medium text-white">Demo Mode</span>
            </Switch.Label>
          </Switch.Group>
        )}

        <div>
          <span
            onClick={() => {
              queryClient.invalidateQueries();
              queryClient.removeQueries();
              userContext.clearJWT();
              localStorage.removeItem("admin");
              localStorage.removeItem("user");
              Cookies.remove("refresh_token_javascript");
              window.location.href = `${process.env.REACT_APP_HOMEPAGE_LINK}`;
            }}
            className={
              "text-md group flex w-full cursor-pointer items-center rounded-md py-2 pl-2 font-medium text-white hover:underline"
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mr-3 h-6 w-6 flex-shrink-0 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
              />
            </svg>
            Logout
          </span>
        </div>
      </div>
      {schools && schools.length > 1 && (
        <div className="mt-8">
          <h3
            className="border-b-2 px-3 text-xs font-semibold uppercase tracking-wider text-white"
            id="desktop-teams-headline"
          >
            School{schools.length > 1 && <>s</>}
          </h3>
          <div className="mt-1 space-y-1" role="group">
            {schools.map((school: { schoolid: string; schoolName: string }) => (
              <Link
                key={school.schoolid}
                to={`${process.env.REACT_APP_ADMIN_LINK}/schools/${school.schoolid}`}
                className="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-white hover:underline"
              >
                <DemoMode
                  value={school.schoolName}
                  type="school_name"
                  district_size={schools.length}
                />
              </Link>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
}
