import React, { useEffect, useState } from "react";
import { useDMQuery } from "../../../utils";
import { timeConverter } from "../../../utils";

export const CurrentLicense = ({
  nces_id,
  name,
  zip,
}: {
  nces_id?: string;
  name?: string;
  zip?: string;
}) => {
  const [districtLicense, setDistrictLicense] = useState<any>();
  const [schoolLicense, setSchoolLicense] = useState<any>();
  const params = new URLSearchParams({
    ...(nces_id && { id: nces_id }),
    ...(name && { name }),
    ...(zip && { zip }),
  });

  const { data } = useDMQuery({
    path: `/manager_new/license/lookup?${params}`,
    customerServiceHeader: true,
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 5,
    },
  });

  useEffect(() => {
    setSchoolLicense(undefined);
    setDistrictLicense(undefined);
    if (data) {
      if (
        data.District &&
        data.District.districtID &&
        data.District.districtID == nces_id &&
        data.District.dmLicense
      ) {
        setDistrictLicense(data.District);
      } else if (data.Schools && data.Schools.length >= 1) {
        const matchedSchoolLicense = data.Schools.filter(
          (s: { match: any }) => !!s.match
        );
        if (matchedSchoolLicense.length >= 1) {
          setSchoolLicense(matchedSchoolLicense[0]);
        }
      }
    }
  }, [data]);

  return (
    <>
      <div className="mt-6">
        <div className="md:grid md:grid-cols-4 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Current License
              </h3>
              <p className="mt-1 text-sm text-gray-600"></p>
            </div>
          </div>
          <div className="mt-5 rounded border-2 shadow-xl md:col-span-3 md:mt-0">
            <div className="overflow-hidden">
              <div className="bg-white px-4 py-5 sm:p-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="">
                    {!districtLicense && !schoolLicense && (
                      <>
                        <h2>No Current School or District License Found</h2>
                      </>
                    )}
                    {districtLicense && districtLicense?.dmLicense?.invoice && (
                      <>
                        <h2 className="text-lg font-medium leading-6 text-gray-900">
                          District License:
                        </h2>

                        <ul>
                          <li>
                            Invoice:{" "}
                            <strong>{districtLicense.dmLicense.invoice}</strong>
                          </li>
                          <li>
                            Expiration:{" "}
                            <strong>
                              {districtLicense?.dmLicense?.expiration &&
                                timeConverter(
                                  districtLicense.dmLicense.expiration
                                )}
                            </strong>
                          </li>
                          <li>
                            Integrations:{" "}
                            <strong>
                              {districtLicense.dmLicense.integrations
                                ? districtLicense.dmLicense.integrations
                                : "none"}
                            </strong>
                          </li>
                          <li>
                            License Tier:{" "}
                            <strong>
                              {districtLicense.dmLicense.has_integral
                                ? "Integral"
                                : "Plus"}
                            </strong>
                          </li>
                          <li>
                            License Type:{" "}
                            <strong>
                              {districtLicense.dmLicense.license_type}
                            </strong>
                          </li>
                          <li>
                            Number of Students:{" "}
                            <strong>
                              {districtLicense.dmLicense.num_students}
                            </strong>
                          </li>
                          <li>
                            Current Admin:{" "}
                            <strong>
                              {districtLicense.match.name}

                              {districtLicense.match.email && (
                                <> - {districtLicense.match.email}</>
                              )}
                            </strong>
                          </li>
                        </ul>
                      </>
                    )}

                    {schoolLicense && (
                      <>
                        <h2 className="text-lg font-medium leading-6 text-gray-900">
                          School License
                        </h2>
                        <ul>
                          <li>
                            Invoice:{" "}
                            <strong>{schoolLicense?.dmLicense?.invoice}</strong>
                          </li>
                          {schoolLicense?.schoolid && (
                            <li>
                              School ID:{" "}
                              <strong>{schoolLicense?.schoolid}</strong>
                            </li>
                          )}
                          <li>
                            Expiration:{" "}
                            <strong>
                              {schoolLicense?.dmLicense?.expiration &&
                                timeConverter(
                                  schoolLicense.dmLicense.expiration
                                )}
                            </strong>
                          </li>
                          <li>
                            Integrations:{" "}
                            <strong>
                              {schoolLicense?.dmLicense?.integrations
                                ? schoolLicense.dmLicense.integrations
                                : "none"}
                            </strong>
                          </li>
                          <li>
                            License Tier:{" "}
                            <strong>
                              {schoolLicense?.dmLicense?.has_integral
                                ? "Integral"
                                : "Plus"}
                            </strong>
                          </li>
                          {schoolLicense?.dmLicense?.num_students > 0 && (
                            <li>
                              Number of Students:{" "}
                              <strong>
                                {schoolLicense?.dmLicense?.num_students}
                              </strong>
                            </li>
                          )}

                          {schoolLicense?.dmLicense?.num_teachers && (
                            <li>
                              Number of Teachers:{" "}
                              <strong>
                                {schoolLicense?.dmLicense?.num_teachers}
                              </strong>
                            </li>
                          )}
                          <li>
                            Current Admin:{" "}
                            <strong>
                              {schoolLicense?.match?.name}

                              {schoolLicense?.match?.email && (
                                <> - {schoolLicense.match.email}</>
                              )}
                            </strong>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentLicense;
