export default function Document({
  classes = "w-5",
  strokeColor = "#9CA3AF",
}: {
  classes?: string;
  strokeColor?: string;
}): JSX.Element {
  return (
    <svg
      className={classes}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 14.876 21.763 L 4.532 21.763 C 3.922 21.763 3.338 21.521 2.906 21.088 C 2.475 20.658 2.233 20.074 2.233 19.464 L 2.233 3.374 C 2.233 2.764 2.475 2.18 2.906 1.748 C 3.338 1.317 3.922 1.075 4.532 1.075 L 18.323 1.075 C 18.933 1.075 19.517 1.317 19.949 1.748 C 20.38 2.18 20.622 2.764 20.622 3.374 L 20.622 10.269"
        stroke={strokeColor}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 6.412 5.777 L 16.442 5.777"
        stroke={strokeColor}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 6.412 10.791 L 12.159 10.791"
        stroke={strokeColor}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 6.412 15.807 L 12.159 15.807"
        stroke={strokeColor}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 19.473 22.911 L 19.473 22.925"
        stroke={strokeColor}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 19.473 19.464 C 19.988 19.462 20.488 19.287 20.892 18.968 C 21.296 18.649 21.582 18.203 21.703 17.702 C 21.824 17.201 21.774 16.675 21.56 16.205 C 21.346 15.737 20.981 15.353 20.524 15.118 C 20.066 14.883 19.543 14.81 19.039 14.912 C 18.535 15.011 18.081 15.28 17.749 15.672"
        stroke={strokeColor}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
}
