import React, { useEffect, useState } from "react";

export function ExtensionRequestModal({
  schoolData,
  districtData,
}: {
  schoolData: any;
  districtData: any;
}) {
  const [showContactUsBanner, setShowContactUsBanner] =
    useState<boolean>(false);
  const [expires, setExpires] = useState<any>(false);
  const now = new Date().getTime() / 1000;

  useEffect(() => {
    if (
      typeof districtData !== "undefined" &&
      typeof schoolData !== "undefined"
    ) {
      const latestSchoolExpiration = Math.max(
        ...schoolData
          .filter((s: any) => !!s.dmLicense)
          .map((s: any) => s?.dmLicense?.expiration)
      );
      const districtExpiration = districtData?.dmLicense?.expiration
        ? parseInt(districtData?.dmLicense?.expiration)
        : 0;
      const latestExpiration = Math.max(
        latestSchoolExpiration,
        districtExpiration
      );
      if (latestExpiration <= 1672509540) {
        // expiration before 12/31/22
        setShowContactUsBanner(true);
        const expirationToSet = new Date(
          latestExpiration * 1000
        ).toLocaleDateString("en-US");
        setExpires(expirationToSet);
      }
    }
  }, [districtData, schoolData]);

  return (
    <>
      {showContactUsBanner && (
        <div className="relative bg-sky-400">
          <div className="ml-64 py-3 px-3 sm:ml-0 sm:px-6 md:ml-64 lg:px-8">
            <div className="text-center sm:px-16">
              <p className="font-medium text-white">
                <span className="">
                  Your License {expires > now ? `expired ` : "is expiring "}
                  on {expires}.
                </span>
                <span className="block sm:ml-2 sm:inline-block">
                  Please reach out to{" "}
                  <a
                    href="mailto:orders@deltamath.com"
                    className="underline hover:no-underline"
                  >
                    orders@deltamath.com
                  </a>{" "}
                  to get a quote or request an extension.
                </span>
              </p>
            </div>
          </div>
        </div>
      )}

      {/* {showExpiringBanner && (
        <div className="relative bg-sky-400">
          <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div className="pr-16 text-center sm:px-16">
              <p className="font-medium text-white">
                <span className="">
                  Your License extension expires on 10/15/2022.
                </span>
                <span className="block sm:ml-2 sm:inline-block">
                  Contact us at{" "}
                  <a className="font-bold text-white underline" href="">
                    orders@deltamath.com
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://www.deltamath.com/teachers-schools?createQuote=true"
                    target="_blank"
                    className="font-bold text-white underline"
                  >
                    Create a Quote Now
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Request a Two Week Extension
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          To request a temporary extension, we require that you
                          have a valid quote for the 22-23 school year that you
                          intend on purchasing.
                        </p>
                        <p className="pt-4 text-sm text-gray-500">
                          If you do not have a quote,{" "}
                          <a
                            className="text-indigo-500 underline hover:no-underline"
                            href="https://www.deltamath.com/teachers-schools?createQuote=true"
                            target="_blank"
                          >
                            click here
                          </a>{" "}
                          to request one using our automated system. Supply a
                          valid quote number to get a two week extension on this
                          license.
                        </p>
                        <div>
                          <label
                            htmlFor="quoteNumber"
                            className="mt-4 block text-sm font-medium text-gray-700"
                          >
                            Quote Number
                          </label>
                          <div className="mt-1">
                            <input
                              type="number"
                              min="10000"
                              max="25000"
                              required
                              onChange={(e) => {
                                setQuoteNumber(parseInt(e.target.value));
                              }}
                              name="quoteNumber"
                              id="quoteNumber"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                            <p className="h-4 text-red-700">
                              {quoteError || " "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      disabled={!!quoteError}
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-500 disabled:opacity-50 sm:text-sm"
                      onClick={() => {
                        extendLicense.mutate(
                          JSON.stringify({
                            quoteNumber,
                          })
                        );
                      }}
                    >
                      Request an Extension
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
    </>
  );
}
