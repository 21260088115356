import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Column, Row } from "react-table";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { useDMQuery } from "../../utils";
import { formatNumber } from "../utils";

export const Quote = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [stateSearch, setStateSearch] = useState(
    searchParams.get("state") || "NY"
  );
  const [schoolSearch, setSchoolSearch] = useState(
    searchParams.get("school") || ""
  );

  const searchRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLSelectElement>(null);
  useEffect(() => {
    if (searchRef.current) searchRef.current.value = schoolSearch;
    if (stateRef.current) stateRef.current.value = stateSearch;
  }, []);

  return (
    <>
      <div className="px-2 md:px-0">
        <h1 className="py-4 text-2xl font-semibold text-gray-900 md:mx-2">
          Quote
        </h1>
        <form
          action={`/${stateSearch}/${schoolSearch}`}
          onSubmit={async (e) => {
            e.preventDefault();
          }}
        >
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
            <div className="grid grid-cols-1 md:grid-cols-8">
              <input
                type="text"
                id="school-search"
                name="search"
                placeholder="District or School Name"
                ref={searchRef}
                // value={schoolSearch || ""}
                // onChange={(e) => setSchoolSearch(e.target.value)}
                className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-l-md"
              />
              <select
                id="state"
                name="state"
                placeholder="State"
                required
                ref={stateRef}
                defaultValue="NY"
                className="-ml-px block border-gray-300 text-sm"
              >
                <option disabled value="-">
                  Select State
                </option>
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
              </select>
              <button
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  setSchoolSearch(searchRef.current?.value || "");
                  setStateSearch(stateRef.current?.value || "");
                  setSearchParams({
                    state: stateRef.current?.value || "",
                    school: searchRef.current?.value || "",
                  });
                }}
                className="relative -ml-px items-center space-x-2 border border-gray-300 bg-gray-50 px-4 py-2 text-center text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 md:rounded-r-md"
              >
                <span>{"Search"}</span>
              </button>
            </div>
          </div>
        </form>

        <DistrictDisplay
          stateSearch={stateSearch}
          schoolSearch={schoolSearch}
        />
      </div>
    </>
  );
};

export const DistrictDisplay = ({
  stateSearch,
  schoolSearch,
}: {
  stateSearch: string;
  schoolSearch: string;
}) => {
  const { status, data, isFetching } = useDMQuery({
    path: "/manage",
    cacheKey: ["quote", stateSearch, schoolSearch],
    method: "post",
    requestBody: {
      type: "search-school-or-district",
      customer_service_token: localStorage.getItem("customer_service_token"),
      search: schoolSearch,
      state: stateSearch,
    },
    additionalHeaders: {
      "Content-Type": "application/json",
    },
    queryOptions: {
      enabled: Boolean(stateSearch !== "" && schoolSearch !== ""),
      staleTime: 1000 * 60 * 15,
    },
  });

  const columns: Array<Column> = useMemo(
    () => [
      {
        id: "view",
        Cell: ({ row }: { row: Row }) =>
          row ? (
            <span>
              <Link
                to={
                  row.values.districtID
                    ? `district/${row.values.districtID}`
                    : `school/${row.values.id}`
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
              </Link>
            </span>
          ) : null,
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: (props: any) => (
          <span>
            {props.row.values.type === "district" ? "District" : "School"}
          </span>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Level",
        accessor: "lowGrade",
        Cell: (props: any) => (
          <span className="pl-4">{`${props.row.values.lowGrade} - ${props.row.values.highGrade}`}</span>
        ),
      },
      {
        id: "highGrade",
        accessor: "highGrade",
        Cell: () => <span></span>,
      },
      {
        Header: "Size",
        accessor: "students",
        Cell: (props: any) => (
          <span>{formatNumber(props.row.values.students)}</span>
        ),
      },
      {
        Header: "Address",
        accessor: "address",
        Cell: ({ cell: { value } }: { cell: { value: any } }) => (
          <>
            <div>{value.street}</div>
            <div>
              {value.city}
              {", "}
              {value.state} {value.zip}
            </div>
          </>
        ),
      },
      {
        Header: "NCES ID",
        accessor: "id",
      },
      {
        Header: "Link",
        accessor: "url",
        Cell: ({ cell: { value } }: { cell: { value: any } }) => (
          <a className="text-teal-300" href={value} target="_blank">
            SchoolDigger
          </a>
        ),
      },
      {
        id: "districtID",
        accessor: "districtID",
      },
    ],
    []
  );
  return (
    <>
      <div>
        {status === "loading"
          ? "Loading..."
          : status === "error"
          ? "Error..."
          : data &&
            Array.isArray(data) &&
            data.length > 0 && (
              <>
                <div>
                  <DeltaMathTable columns={columns} data={data} />
                </div>
                <div>{isFetching ? "Background Updating..." : " "}</div>
              </>
            )}
      </div>
    </>
  );
};

export default Quote;
