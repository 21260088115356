import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.css";
import ManagerApp from "./manager/App";
import AdminApp from "./admin/App";
import NotesApp from "./notes/App";
import StudentApp from "./student/App";
import Upcoming from "./student/components/sections/Upcoming";
import PastDue from "./student/components/sections/PastDue";
import Completed from "./student/components/sections/Completed";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToasterContextProvider } from "./shared/contexts/ToasterContext";
import Profile from "./student/components/Profile";
import LtiLogin from "./student/components/lti/LtiLogin";
import { UserContextProvider } from "./shared/contexts/UserContext";

const queryClient = new QueryClient();

const root = createRoot(document.getElementById("root") as Element);
root.render(
  <UserContextProvider>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <ToasterContextProvider>
        <BrowserRouter basename={process.env.REACT_APP_URL_PREFIX}>
          <Routes>
            <Route
              index
              element={
                <Navigate replace to={`${process.env.REACT_APP_ADMIN_LINK}`} />
              }
            />
            <Route path={`manager/*`} element={<ManagerApp />} />
            <Route
              path={`${process.env.REACT_APP_ADMIN_LINK}/*`}
              element={<AdminApp />}
            />
            <Route path={`notes/*`} element={<NotesApp />} />
            <Route
              path={`${process.env.REACT_APP_STUDENT_LINK}/link?/:teacherId`}
              element={<StudentApp section="link" />}
            />
            <Route
              path={`${process.env.REACT_APP_STUDENT_LINK}/:sectionId/:teacherId/:skillCode`}
              element={<StudentApp section="solve" />}
            />
            <Route
              path={`${process.env.REACT_APP_STUDENT_LINK}/lti/:ltiId`}
              element={<LtiLogin />}
            />
            <Route
              path={`${process.env.REACT_APP_STUDENT_LINK}/*`}
              element={<StudentApp section="main" />}
            >
              <Route path="profile" element={<Profile />} />

              <Route
                path=":sectionId/"
                element={<Navigate replace to="upcoming" />}
              />
              <Route path=":sectionId/upcoming/*" element={<Upcoming />} />
              <Route path=":sectionId/past-due/*" element={<PastDue />} />
              <Route path=":sectionId/completed/*" element={<Completed />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ToasterContextProvider>
    </QueryClientProvider>
  </UserContextProvider>
);
