import React, { Fragment, useEffect } from "react";
import { Routes, Route, useSearchParams } from "react-router-dom";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { Dialog, Disclosure, Transition } from "@headlessui/react";
import axios from "axios";
import FormData from "form-data";
import ErrorBoundary from "../shared/ErrorBoundary";
import {
  TrashIcon,
  DocumentIcon,
  CloudUploadIcon,
} from "@heroicons/react/outline";
import { deltamathAPI } from "../manager/utils";

// import { ITeacher } from "@deltamath/deltamath-types";

function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const maxNumberImages = 50;
const maxImageFileSize = 10485760; // 10MB

function DemoUploader({
  sessionId,
  setCompleteMessage,
}: {
  sessionId: string;
  setCompleteMessage: (message: string) => void;
}) {
  const [images, setImages] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [open, setOpen] = React.useState(false);

  const onChange = (imageList: ImageListType) => {
    setImages(imageList as never[]);
  };

  const uploadFiles = () => {
    setUploading(true);
    const imagesPayload = images.map(
      (image: { dataURL: string; file: { name: string; type: string } }) => {
        return {
          filename: image.file.name,
          type: image.file.type,
          data: image.dataURL,
        };
      }
    );

    axios
      .post(
        deltamathAPI() + "/notes/document",
        {
          imagesPayload,
          sessionId,
        },
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
            if (percentCompleted === 100) {
              // showUploadProgress();
            }
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setCompleteMessage(
            "Upload complete! Thanks! You may now close this window."
          );
          setImages([]);
          setUploading(false);
        }
      })
      .catch((error) => {
        setImages([]);
        setUploading(false);
        console.error(error);
      });
  };

  return (
    <div className="App">
      <Transition.Root show={uploading || open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div>
                    <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
                      <div className="flex">
                        <div
                          className="mr-4 h-4 w-4 animate-bounce rounded-full bg-gray-400 p-2"
                          style={{ animationDelay: "0.1s" }}
                        ></div>
                        <div
                          className="mr-4 h-4 w-4 animate-bounce rounded-full bg-gray-600 p-2"
                          style={{ animationDelay: "0.2s" }}
                        ></div>
                        <div
                          className="h-4 w-4 animate-bounce rounded-full bg-gray-800 p-2"
                          style={{ animationDelay: "0.3s" }}
                        ></div>
                      </div>
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Uploading Work.
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="text-sm text-gray-500">
                          {uploading && (
                            <>
                              {uploadProgress !== 100 && (
                                <p>Uploading... {uploadProgress}%</p>
                              )}
                              {uploadProgress === 100 && (
                                <p>Processing files.</p>
                              )}
                            </>
                          )}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumberImages}
        acceptType={["jpg", "jpeg", "png"]}
        maxFileSize={maxImageFileSize}
      >
        {({
          imageList,
          onImageUpload,
          errors,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <div className="upload__image-wrapper">
            <div className="sm:pt-5">
              <div className="mt-3 sm:col-span-2">
                <button
                  className="w-full"
                  style={isDragging ? { color: "red" } : undefined}
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <div className="flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex justify-center text-sm text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Click or Drag Images Here</span>
                        </label>
                      </div>
                      <p className="text-xs text-gray-500">
                        Upload up to {maxNumberImages} images (PNG, JPG)
                        <br /> up to {formatBytes(maxImageFileSize)} each.
                      </p>
                    </div>
                  </div>
                </button>
              </div>
            </div>

            {images.length > 0 && (
              <div className="mt-4 flex">
                <button
                  onClick={onImageRemoveAll}
                  type="button"
                  className="inline-flex items-center rounded-md border border-gray-300 bg-white px-2 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <TrashIcon className="mr-2 block h-6 w-6" />
                  <span>Remove all images</span>
                </button>
                <button
                  onClick={() => uploadFiles()}
                  type="button"
                  className="ml-6 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <CloudUploadIcon className="mr-2 block h-6 w-6" />
                  <span>Upload Your Work</span>
                </button>
              </div>
            )}

            {errors && (
              <div>
                {errors.maxNumber && (
                  <span>
                    Number of selected images exceed maximum allowed number
                  </span>
                )}
                {errors.acceptType && (
                  <span>Your selected file type is not allowed</span>
                )}
                {errors.maxFileSize && (
                  <span>
                    Selected file size exceeded {formatBytes(maxImageFileSize)}
                  </span>
                )}
              </div>
            )}

            <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
              <ul
                role="list"
                className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
              >
                {imageList.map((image, index) => (
                  <li key={index} className="image-item relative">
                    <div
                      className={
                        "group aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
                      }
                    >
                      <img
                        src={image.dataURL}
                        alt=""
                        width="100"
                        className="pointer-events-none object-cover"
                      />
                    </div>
                    <p className="pointer-events-none mt-2 mb-4 block truncate text-sm font-medium text-gray-900">
                      {image?.file?.name}
                    </p>
                    <div className="flex">
                      <button
                        type="button"
                        onClick={() => onImageUpdate(index)}
                        className="flex-1 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <DocumentIcon className="float-left mr-3 block h-6 w-full" />
                        Update
                      </button>
                      <button
                        onClick={() => onImageRemove(index)}
                        type="button"
                        className="ml-3 flex-1 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <TrashIcon className="float-left mr-3 block h-6 w-full" />
                        Remove
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </section>
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

function Uploader() {
  const [validSession, setValidSession] = React.useState(false);
  const [completeMessage, setCompleteMessage] = React.useState("");
  const [expiringSoonMessage, setExpiringSoonMessage] = React.useState("");
  const [previousPagesUploaded, setPreviousPagesUploaded] = React.useState(0);
  const [sessionExpiresInSeconds, setSessionExpiresInSeconds] =
    React.useState(0);

  const [activeTab, setActiveTab] = React.useState("photos");
  const [selectedPDF, setSelectedPDF] = React.useState<File>();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id") || "";

  useEffect(() => {
    if (sessionExpiresInSeconds > 0) {
      const pageLoaded = Date.now();

      const timer = setInterval(() => {
        const durationOnPage = Date.now() - pageLoaded;
        const showMessageAt = (sessionExpiresInSeconds - 60 * 5) * 1000; // five minutes before expiration
        const logUserOutAt = (sessionExpiresInSeconds - 30) * 1000; // thirty seconds before expiration

        if (durationOnPage > showMessageAt) {
          setExpiringSoonMessage(
            "Your session is expiring in less than five minutes. Please save your work."
          );
        }
        if (durationOnPage > logUserOutAt) {
          fetch(deltamathAPI() + "/notes/session/delete/" + id, {
            method: "GET",
            credentials: "include",
            mode: "no-cors",
            keepalive: true,
          });
          setExpiringSoonMessage("");
          setValidSession(false);
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [sessionExpiresInSeconds]);

  async function checkUserSession() {
    if (!id) {
      return { valid: false };
    } else {
      const response = await axios.get(
        deltamathAPI() + `/notes/session?id=${id}`
      );
      const validSessionObject = await response.data;
      return validSessionObject;
    }
  }

  const uploadPDF = () => {
    if (selectedPDF) {
      const formData = new FormData();
      formData.append("pdf_file", selectedPDF);
      formData.append("session_id", id);
      axios({
        method: "post",
        url: deltamathAPI() + "/notes/pdf",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          if (response.status === 200) {
            setCompleteMessage(
              "Upload complete! Thanks! You may now close this window."
            );
          }
        })
        .catch(function (response) {
          console.error(response);
        });
    }
  };

  useEffect(() => {
    checkUserSession().then((sessionObject) => {
      setValidSession(sessionObject.valid);
      setPreviousPagesUploaded(sessionObject.pages || 0);
      setSessionExpiresInSeconds(sessionObject.expires_in_seconds);
    });
  });

  useEffect(() => {
    const eventHandler = () => {
      fetch(deltamathAPI() + "/notes/session/delete/" + id, {
        method: "GET",
        credentials: "include",
        mode: "no-cors",
        keepalive: true,
      });
    };
    if (process.env.NODE_ENV !== "development") {
      window.addEventListener("unload", eventHandler, true);
    }
    return () => {
      window.removeEventListener("unload", eventHandler, true);
    };
  }, []);

  return (
    <div>
      {completeMessage && (
        <div className="px-2 py-8 text-center text-xl font-medium text-green-600">
          {completeMessage}
        </div>
      )}

      {expiringSoonMessage && (
        <div className="px-2 py-8 text-center text-xl font-medium text-dm-blue">
          {expiringSoonMessage}
        </div>
      )}

      {!validSession && !completeMessage && (
        <p className="pt-6 pl-20">
          Sorry, you don't have a valid session, please login from your
          assignment page to start an upload.
        </p>
      )}

      {validSession && !completeMessage && (
        <>
          <div className="sm:block">
            <nav
              className="relative z-0 flex divide-x divide-gray-200 rounded-lg shadow"
              aria-label="Tabs"
            >
              <span
                onClick={() => setActiveTab("photos")}
                className={`${
                  activeTab === "photos"
                    ? "rounded-l-lg text-gray-900"
                    : "text-gray-500 hover:text-gray-700"
                } group relative min-w-0 flex-1 cursor-pointer overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10`}
                aria-current="page"
              >
                <span>Photos</span>
                <span
                  aria-hidden="true"
                  className={`${
                    activeTab === "photos" ? "bg-indigo-500" : "bg-transparent"
                  } absolute inset-x-0 bottom-0 h-0.5`}
                ></span>
              </span>

              <span
                onClick={() => setActiveTab("pdf")}
                className={`${
                  activeTab === "pdf"
                    ? "rounded-l-lg text-gray-900"
                    : "text-gray-500 hover:text-gray-700"
                } group relative min-w-0 flex-1 cursor-pointer overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10`}
              >
                <span>PDF</span>
                <span
                  aria-hidden="true"
                  className={`${
                    activeTab === "pdf" ? "bg-indigo-500" : "bg-transparent"
                  } absolute inset-x-0 bottom-0 h-0.5`}
                ></span>
              </span>
            </nav>
          </div>

          {previousPagesUploaded > 0 && (
            <p className="py-4 text-center">
              New work will be added to the{" "}
              <strong>{previousPagesUploaded} pages</strong> that have already
              been uploaded.
              <br />
              If you would like to view or delete the current work, do so from
              your student account.
            </p>
          )}
          {activeTab === "photos" && (
            <DemoUploader
              sessionId={id}
              setCompleteMessage={setCompleteMessage}
            />
          )}
          {activeTab === "pdf" && (
            <div className="mt-1 sm:col-span-2 sm:mt-8">
              <div className="flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                <div className="text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        accept="application/pdf"
                        onChange={(event) => {
                          if (event.target.files) {
                            setSelectedPDF(event.target.files[0]);
                          }
                        }}
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">
                    Up to {formatBytes(maxImageFileSize)}
                  </p>
                  <button
                    onClick={() => uploadPDF()}
                    type="button"
                    disabled={!selectedPDF}
                    className="ml-6 mt-12 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-2 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-500 disabled:opacity-50"
                  >
                    <CloudUploadIcon className="mr-2 block h-6 w-6" />
                    <span>Upload Your Work PDF</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default function App() {
  useEffect(() => {
    document.title = "DeltaMath Student Work Uploader";
  }, []);

  return (
    <>
      <Disclosure as="nav" className="bg-dm-blue">
        {() => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 space-x-4 sm:items-center md:items-start">
                    <h2 className="px-3 py-2 text-xl font-medium text-gray-300">
                      DeltaMath Student Work Uploader
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>

      <div className="mx-auto px-2 sm:px-6 lg:px-8">
        {
          <Routes>
            <Route
              path="/"
              element={
                <ErrorBoundary>
                  <Uploader />
                </ErrorBoundary>
              }
            />
          </Routes>
        }
      </div>
    </>
  );
}
