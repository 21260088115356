import React from "react";
import { NavLink } from "react-router-dom";

type Props = {
  section?: any;
  type: "correction" | "test" | "standard";
};

const AssignmentAccordionButton = (props: Props) => {
  return (
    <NavLink
      key={`button-${props.section?.ta?._id}`}
      to={`${process.env.REACT_APP_STUDENT_LINK}/link/${props.section?.ta?._id}`}
      className="rounded bg-dm-brand-blue-500 px-8 py-1 font-bold leading-6 text-white hover:bg-dm-brand-blue-600"
    >
      Start{" "}
      {props.type === "correction"
        ? "Test Correction"
        : props.type === "test"
        ? "Test"
        : "Assignment"}
    </NavLink>
  );
};

export default AssignmentAccordionButton;
