import React, { useEffect, useState } from "react";
import { formatNumber, useDMQuery } from "../../../utils";

export default function SchoolDetails({
  teachers,
  school,
}: {
  teachers: any;
  school: any;
}) {
  const [studentsCount, setStudentsCount] = useState(0);
  const [assignmentsCount, setAssignmentsCount] = useState(0);
  const [problemsCount, setProblemsCount] = useState(0);
  const [expires, setExpires] = useState("");
  const [licenseTier, setLicenseTier] = useState("");
  const [licenseType, setLicenseType] = useState("");

  const { data: adminsData } = useDMQuery({
    path: "/admin_new/data/admins",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: districtData, refetch: districtDataRefetch } = useDMQuery({
    path: "/admin_new/data/district",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false, // We only want to make this request if we're a district admin, we check that in a useEffect below
    },
  });

  const { data: schoolData } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });
  const { data: teacherCountsData } = useDMQuery({
    path: "/admin_new/data/teacherCounts",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  useEffect(() => {
    if (teacherCountsData && teachers) {
      let studentsSubtotal = 0;
      let assignmentsSubtotal = 0;
      let problemsSubtotal = 0;

      for (let i = 0; i < teachers.length; i++) {
        const teacherDataObject = teacherCountsData[teachers[i]["_id"]];
        studentsSubtotal += teacherDataObject.students;
        assignmentsSubtotal += teacherDataObject.assignments;
        problemsSubtotal += teachers[i]["annual_logs"];
      }
      setStudentsCount(studentsSubtotal);
      setAssignmentsCount(assignmentsSubtotal);
      setProblemsCount(problemsSubtotal);
    }
  }, [teacherCountsData, teachers]);

  useEffect(() => {
    // First check the District License
    if (districtData?.dmLicense?.expiration) {
      const districtExpiration = new Date(
        districtData?.dmLicense?.expiration * 1000
      ).toLocaleDateString("en-US");
      setLicenseType("District");
      setExpires(districtExpiration);
      setLicenseTier(
        districtData?.dmLicense?.has_integral ? "Integral" : "Plus"
      );
    }

    // Then check school license
    if (schoolData && schoolData[0].dmLicense?.expiration) {
      if (
        schoolData[0].dmLicense?.has_integral ||
        (districtData?.dmLicense?.expiration &&
          schoolData[0]?.dmLicense?.expiration >
            districtData?.dmLicense?.expiration) ||
        !districtData?.dmLicense?.expiration
      ) {
        const currentSchoolData = schoolData.filter(
          (s: any) => s.schoolid === school.schoolid
        );
        if (
          currentSchoolData.length === 1 &&
          currentSchoolData[0]?.dmLicense?.expiration
        ) {
          const schoolExpiration = new Date(
            currentSchoolData[0]?.dmLicense?.expiration * 1000
          ).toLocaleDateString("en-US");
          setLicenseType("School");
          setExpires(schoolExpiration);
          setLicenseTier(
            currentSchoolData[0].dmLicense.has_integral ? "Integral" : "Plus"
          );
        }
      }
    }
  }, [districtData]);

  useEffect(() => {
    if (adminsData) {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      const id = user.admin ? user.admin : user._id;
      const findCurrentUser = adminsData.filter(
        (user: { _id: string }) => user._id === id
      );
      if (findCurrentUser && findCurrentUser.length > 0) {
        districtDataRefetch();
      }
    }
  }, [adminsData]);

  return (
    <>
      <div className="mt-8 shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white py-6 px-4 sm:p-6">
          {school && (
            <>
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  School Details
                </h3>
              </div>
              <div className="mt-6">
                <dl className="divide-y divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-md font-medium text-gray-500">
                      Students
                    </dt>
                    <dd className="text-md mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">
                        {formatNumber(studentsCount)}
                      </span>
                    </dd>
                  </div>{" "}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-md font-medium text-gray-500">
                      Assignments
                    </dt>
                    <dd className="text-md mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">
                        {formatNumber(assignmentsCount)}
                      </span>
                    </dd>
                  </div>{" "}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-md font-medium text-gray-500">
                      Problems this Year
                    </dt>
                    <dd className="text-md mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">
                        {formatNumber(problemsCount)}
                      </span>
                    </dd>
                  </div>
                  {/* <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-md font-medium text-gray-500">
                      License Type
                    </dt>
                    <dd className="text-md mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{licenseType}</span>
                    </dd>
                  </div> */}
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-md font-medium text-gray-500">
                      License Expires
                    </dt>
                    <dd className="text-md mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{expires}</span>
                    </dd>
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-md font-medium text-gray-500">
                      License Tier
                    </dt>
                    <dd className="text-md mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0">
                      <span className="flex-grow">{licenseTier}</span>
                    </dd>
                  </div>
                </dl>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
