export default function Correction({
  classes = "w-5",
}: {
  classes?: string;
}): JSX.Element {
  return (
    <svg
      className={classes}
      viewBox="0 0 19 10"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0002 5.00021L18.0002 5.00021"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M10 9H18" strokeWidth="2" strokeLinecap="round" />
      <path d="M10 1H18" strokeWidth="2" strokeLinecap="round" />
      <path d="M5.9176 1C5.9176 0.447715 5.46989 0 4.9176 0C4.36532 0 3.9176 0.447715 3.9176 1V3.92096L1 3.92096C0.447715 3.92096 0 4.36867 0 4.92096C0 5.47324 0.447715 5.92096 0.999999 5.92096H3.9176V8.83856C3.9176 9.39084 4.36532 9.83856 4.9176 9.83856C5.46989 9.83856 5.9176 9.39084 5.9176 8.83856V5.92096H8.83856C9.39084 5.92096 9.83856 5.47324 9.83856 4.92096C9.83856 4.36867 9.39084 3.92096 8.83856 3.92096L5.9176 3.92096V1Z" />
    </svg>
  );
}
