import React, { useState, useEffect, Fragment } from "react";
import { Routes, Route, NavLink, Link } from "react-router-dom";
import clsx from "clsx";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Login from "./components/Login";
import CreateCode from "./components/CreateCode";
import Search from "./components/Search";
import SiteInfo from "./components/SiteInfo";
import LicenseLookup from "./components/LicenseLookup";
import Purgatory from "./components/Purgatory";
import SiteLicense from "./components/SiteLicense";
import ErrorBoundary from "../shared/ErrorBoundary";
import Quote from "./components/Quote";
import QuoteDistrict from "./components/QuoteDistrict";
import Invoice from "./components/Invoice";
import InvoicesReport from "./components/InvoicesReport";
import Standards from "./components/standards/Standards";
import AppSwitcher from "../shared/AppSwitcher";
import InvoiceSearch from "./components/InvoiceSearch";
import EstimateInfo from "./components/EstimateInfo";
import { useQueryClient } from "react-query";
import { deltamathAPI } from "./utils";
import axios from "axios";
import Heaven from "./components/Heaven";
import NcesFlags from "./components/NcesFlags";
import StateTaxReport from "./components/StateTaxReport";
import { getFilePath } from "../utils";

export default function App() {
  const queryClient = useQueryClient();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    document.title = "DeltaMath Manager";
  }, []);

  const navigation = [
    { name: "Search", href: `search` },
    { name: "Create Code", href: `create-code` },
    { name: "Site License", href: `site-license` },
    { name: "Site Info", href: `site-info` },
    { name: "License Lookup", href: `license-lookup` },
    { name: "Standards", href: `standards` },
  ];

  const smallNavigation = [
    { name: "Quote Purgatory", href: `purgatory` },
    { name: "Quote Heaven", href: "heaven" },
    { name: "Quote Lookup", href: `quote-lookup` },
    { name: "Quote", href: `` },
    { name: "Search", href: `search` },
    { name: "Invoices Report", href: `invoices-report` },
    { name: "Create Code", href: `create-code` },
    { name: "Site License", href: `site-license` },
    { name: "Site Info", href: `site-info` },
    { name: "Standards", href: `standards` },
    { name: "State Tax Report", href: `state-tax-report` },
  ];

  const prefetchSiteInfo = async () => {
    await queryClient.prefetchQuery("site-info", async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: "get-site-info",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return request.data;
    });
  };

  useEffect(() => {
    if (localStorage.getItem("customer_service_token")) {
      setLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      prefetchSiteInfo();
    }
  }, [loggedIn]);

  return (
    <>
      <Disclosure as="nav" className="bg-dm-blue">
        {({ open }) => (
          <>
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {loggedIn && (
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  )}
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  {loggedIn && (
                    <div className="flex-shrink-0">
                      <Menu as="div" className="relative mt-2 flex-shrink-0">
                        <div>
                          <Menu.Button className="flex rounded-full text-sm text-white focus:outline-none">
                            <span className="sr-only">Open user menu</span>
                            <img
                              className="block h-8 w-auto"
                              src={getFilePath("/flav3.png")}
                              alt="DeltaMath"
                            />
                          </Menu.Button>
                        </div>
                        <AppSwitcher
                          loggedIn={loggedIn}
                          setLoggedIn={setLoggedIn}
                        />
                      </Menu>
                    </div>
                  )}
                  <div className="flex flex-shrink-0 space-x-4 sm:items-center md:items-start">
                    <h2 className="px-3 py-2 text-xl font-medium text-gray-300">
                      DeltaMath Manager
                    </h2>
                  </div>
                </div>

                <div className="absolute inset-y-0 right-0 hidden items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 md:block">
                  {loggedIn && (
                    <>
                      <Menu as="span" className="relative z-50">
                        <div className="mr-4 inline-block">
                          <Menu.Button className="rounded-full text-sm text-white focus:outline-none">
                            <span className="sr-only">Open user menu</span>
                            Quotes
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute left-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item key={"admin-portal"}>
                              <Link
                                className="block px-4 py-2 text-sm text-gray-700"
                                to=""
                              >
                                School / District Search
                              </Link>
                            </Menu.Item>
                            <Menu.Item key={"manager"}>
                              <Link
                                className="block px-4 py-2 text-sm text-gray-700"
                                to="quote-lookup"
                              >
                                Lookup
                              </Link>
                            </Menu.Item>
                            <Menu.Item key={"teacher-app"}>
                              <Link
                                className="block px-4 py-2 text-sm text-gray-700"
                                to="purgatory"
                              >
                                Purgatory
                              </Link>
                            </Menu.Item>
                            <Menu.Item key={"quote-heaven"}>
                              <Link
                                className="block px-4 py-2 text-sm text-gray-700"
                                to="heaven"
                              >
                                Heaven
                              </Link>
                            </Menu.Item>
                            <Menu.Item key={"invoices-report"}>
                              <Link
                                className="block px-4 py-2 text-sm text-gray-700"
                                to="invoices-report"
                              >
                                Invoices Report
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link
                                className="block px-4 py-2 text-sm text-gray-700"
                                to="nces-flags"
                              >
                                Flag NCES Id
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link
                                className="text-grey-700 block px-4 py-2 text-sm"
                                to="state-tax-report"
                              >
                                State Tax Report
                              </Link>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>

                      {navigation.map((item) => (
                        <NavLink
                          key={item.name}
                          to={item.href}
                          className={({ isActive }) =>
                            clsx(
                              "rounded-md px-3 py-2 text-sm font-medium hover:bg-gray-700 hover:text-white",
                              isActive
                                ? "text-white"
                                : "text-gray-300 hover:text-white"
                            )
                          }
                        >
                          {item.name}
                        </NavLink>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {loggedIn && (
                  <>
                    {/* <InvoiceSearch /> */}
                    {smallNavigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                          clsx(
                            "block rounded-md px-3 py-2 text-base font-medium",
                            isActive
                              ? "text-white"
                              : "text-gray-300 hover:text-white"
                          )
                        }
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {/* bg-sky-100 */}
      <div className="mx-auto min-h-screen bg-slate-200 px-2 pb-20 sm:px-6 lg:px-8">
        {loggedIn && (
          <Routes>
            <Route
              path="/"
              element={
                <ErrorBoundary>
                  <Quote />
                </ErrorBoundary>
              }
            />
            <Route
              path="search"
              element={
                <ErrorBoundary>
                  <Search />
                </ErrorBoundary>
              }
            />
            <Route
              path="create-code"
              element={
                <ErrorBoundary>
                  <CreateCode />
                </ErrorBoundary>
              }
            />
            <Route
              path="site-license"
              element={
                <ErrorBoundary>
                  <SiteLicense />
                </ErrorBoundary>
              }
            />
            <Route
              path="site-info"
              element={
                <ErrorBoundary>
                  <SiteInfo />
                </ErrorBoundary>
              }
            />
            <Route
              path="license-lookup"
              element={
                <ErrorBoundary>
                  <LicenseLookup />
                </ErrorBoundary>
              }
            />
            <Route
              path="license-lookup/id/:id"
              element={
                <ErrorBoundary>
                  <LicenseLookup />
                </ErrorBoundary>
              }
            />
            <Route
              path="license-lookup/admin_email/:admin_email"
              element={
                <ErrorBoundary>
                  <LicenseLookup />
                </ErrorBoundary>
              }
            />
            <Route
              path="standards"
              element={
                <ErrorBoundary>
                  <Standards />
                </ErrorBoundary>
              }
            />
            <Route
              path="standards/:child"
              element={
                <ErrorBoundary>
                  <Standards />
                </ErrorBoundary>
              }
            />
            <Route
              path="standards/:child/:id"
              element={
                <ErrorBoundary>
                  <Standards />
                </ErrorBoundary>
              }
            />
            <Route
              path="purgatory"
              element={
                <ErrorBoundary>
                  <Purgatory />
                </ErrorBoundary>
              }
            />
            <Route
              path="state-tax-report"
              element={
                <ErrorBoundary>
                  <StateTaxReport />
                </ErrorBoundary>
              }
            />
            <Route
              path="heaven"
              element={
                <ErrorBoundary>
                  <Heaven />
                </ErrorBoundary>
              }
            />
            <Route
              path="heaven/:year"
              element={
                <ErrorBoundary>
                  <Heaven />
                </ErrorBoundary>
              }
            />
            <Route
              path="/:type/:id"
              element={
                <ErrorBoundary>
                  <QuoteDistrict />
                </ErrorBoundary>
              }
            />
            <Route
              path="/invoices-report"
              element={
                <ErrorBoundary>
                  <InvoicesReport />
                </ErrorBoundary>
              }
            />

            <Route
              path="/quote-lookup"
              element={
                <ErrorBoundary>
                  <InvoiceSearch />
                </ErrorBoundary>
              }
            />
            <Route
              path="/quote-lookup/:quoteNumber"
              element={
                <ErrorBoundary>
                  <Invoice />
                </ErrorBoundary>
              }
            />
            <Route
              path="/line-items"
              element={
                <ErrorBoundary>
                  <EstimateInfo />
                </ErrorBoundary>
              }
            />
            <Route
              path="/line-items/:quoteNumber"
              element={
                <ErrorBoundary>
                  <EstimateInfo />
                </ErrorBoundary>
              }
            />
            <Route
              path="/nces-flags"
              element={
                <ErrorBoundary>
                  <NcesFlags />
                </ErrorBoundary>
              }
            />
          </Routes>
        )}

        {!loggedIn && <Login setLoggedIn={setLoggedIn} />}
      </div>
    </>
  );
}
