import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { getAdminId } from "../../../admin/utils";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import DeltaMathConfirmation from "../../../shared/DeltaMathConfirmation";
import { TrashIcon } from "@heroicons/react/outline";
import { DemoMode } from "../../../utils/demo";
import { useUserContext } from "../../../shared/contexts/UserContext";

export default function InviteTeachers({
  school,
  pendingTeacherInvites,
}: {
  school: any;
  pendingTeacherInvites: any;
}) {
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  const [teacherEmails, setTeacherEmails] = useState("");
  const [emailError, setEmailError] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [showRemoveInviteConfirmation, setShowRemoveInviteConfirmation] =
    useState(false);
  const [inviteToDelete, setInviteToDelete] = useState({
    _id: "",
    email: "",
    schools: [],
  });

  const inviteTeachers = useMutation(
    (body: { emails: string[]; schoolid: string; user: string }) => {
      return axios.post(deltamathAPI() + "/admin/invite_teachers", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess() {
        setTeacherEmails("");
        queryClient.invalidateQueries(["/admin_new/data/teacher"]);
        queryClient.invalidateQueries([
          "/admin_new/data/teach/admin_new/data/teacher",
        ]);
        queryClient.invalidateQueries(["/admin_new/data/teacherCounts"]);
        queryClient.invalidateQueries(["/admin_new/data/pendingInvites"]);

        toastContext.addToast({
          status: "Success",
          message: "Email Invitation Sent",
        });
        setSubmitting(false);
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "There was an error sending your invite.",
        });
        setSubmitting(false);
      },
    }
  );

  const removeTeacherInvite = useMutation(
    /** body = { email: string, schoolid: string, userId: string } */
    (body: string) => {
      return axios.post(deltamathAPI() + "/admin/remove_pending_invite", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("/admin_new/data/pendingInvites");
        setShowRemoveInviteConfirmation(false);
        toastContext.addToast({
          status: "Success",
          message: "Invite Removed Successfully",
        });
      },
      onSettled: () => {
        setShowRemoveInviteConfirmation(false);
      },
    }
  );

  useEffect(() => {
    setShowRemoveInviteConfirmation(false);
  }, [removeTeacherInvite.isSuccess]);

  const pendingTeacherInviteColumns = React.useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_email" />;
        },
      },
      {
        Header: "Delete",
        accessor: "showDeleteButton",
        disableSortBy: true,
        Cell: (props: { row: { original: any } }) => (
          <>
            <span
              onClick={() => {
                setShowRemoveInviteConfirmation(true);
                setInviteToDelete(props.row.original);
              }}
            >
              <TrashIcon className="h-6 w-full cursor-pointer text-gray-600" />
            </span>
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <div className="mt-8 shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Invite Teachers
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              List up to 50 email addresses separated by commas.
            </p>
          </div>

          <div className="grid grid-cols-4 gap-6">
            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <label
                htmlFor="email_addresses"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address(es)
              </label>
              <input
                type="text"
                name="email_addresses"
                id="email_addresses"
                value={teacherEmails}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => {
                  const value = e.target.value;
                  setTeacherEmails(value);
                  const emails = value
                    ? value.replace(/\s/g, "").split(",")
                    : ""; // remove whitespace and split by comma
                  const regex =
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                  setEmailError("");
                  if (emails) {
                    if (emails.length > 50) {
                      setEmailError(
                        "You can only invite up to 50 teachers at once."
                      );
                    }
                    emails.forEach((email) => {
                      if (!regex.test(email)) {
                        setEmailError("Please enter a valid email address.");
                      }
                    });
                  }
                }}
              />
              {emailError && <p>{emailError}</p>}
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6">
          <button
            type="submit"
            disabled={submitting}
            onClick={() => {
              if (getAdminId()) {
                setSubmitting(true);
                inviteTeachers.mutate({
                  emails: teacherEmails.replace(/\s/g, "").split(","),
                  schoolid: school.schoolid,
                  user: getAdminId(),
                });
              }
            }}
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-50"
          >
            Send Invite
            {teacherEmails.includes(",") &&
              teacherEmails.split(",").length > 1 && <>(s)</>}
            {submitting && (
              <svg
                className="ml-3 mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
          </button>
        </div>
      </div>

      <h3 className="mt-8 text-lg font-medium leading-6 text-gray-900">
        Pending Teacher Invites
      </h3>

      {pendingTeacherInvites && pendingTeacherInvites.length === 0 && (
        <p>No pending invites found.</p>
      )}

      {pendingTeacherInvites && pendingTeacherInvites.length > 0 && (
        <>
          <DeltaMathTable
            columns={pendingTeacherInviteColumns}
            data={pendingTeacherInvites}
          />
        </>
      )}

      {showRemoveInviteConfirmation && inviteToDelete && (
        <DeltaMathConfirmation
          title={`Remove Invite`}
          message={
            <>
              Are you sure you want to remove{" "}
              <DemoMode value={inviteToDelete.email} type="person_email" />
              's Teacher invite?"
            </>
          }
          confirm="Remove"
          confirmAction={() => {
            if (!user.admin) {
              toastContext.addToast({
                status: "Error",
                message: "You don't have permissions to remove this invite.",
              });
            } else {
              const body = JSON.stringify({
                email: inviteToDelete.email,
                schoolid: school.schoolid,
                userId: user.admin,
              });
              removeTeacherInvite.mutate(body);
            }
          }}
          cancel="Cancel"
          cancelAction={() => {
            setShowRemoveInviteConfirmation(false);
            setInviteToDelete({ _id: "", email: "", schools: [] });
          }}
        />
      )}
    </>
  );
}
