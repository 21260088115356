import React, { useState } from "react";
import { useMutation } from "react-query";
import { deltamathAPI, getDatesArray } from "../utils";
import axios from "axios";
import { DmLoadingSpinner } from "../utils/functions";
import { TIMEZONES_FOR_PICKER } from "../utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";

interface SiteLicensePayload {
  license_type: string;
  nces_id: string;
  tier: string;
  school_name: string;
  integrations: string;
  note: string;
  num_students: string;
  expiration: number;
  revenue: string;
  invoice: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  admin_first: string;
  admin_last: string;
  admin_email: string;
  site_timezone: string;
}

const nextDates = getDatesArray({});

export const SiteLicense = () => {
  const toastContext = useDeltaToastContext();
  const [payload, setPayload] = useState<SiteLicensePayload>({
    license_type: "school",
    school_name: "",
    nces_id: "",
    tier: "",
    integrations: "",
    note: "",
    num_students: "",
    expiration: nextDates[0].getTime() / 1000,
    revenue: "",
    invoice: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    country: "United States",
    admin_first: "",
    admin_last: "",
    admin_email: "",
    site_timezone: "",
  });
  const [showTimezone, setShowTimezone] = useState<boolean>(false);

  const mutation = useMutation(
    "site_license",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/admin/create_site",
        JSON.stringify({
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          license_type:
            payload?.license_type === "school_custom"
              ? "school"
              : payload?.license_type,
          nces_id: payload?.nces_id,
          tier: payload?.tier,
          site_name: payload?.school_name,
          site_timezone: payload?.site_timezone,
          meta: {
            integrations: payload?.integrations,
            note: payload?.note,
            num_students: payload?.num_students,
            expiration: payload?.expiration,
            revenue: payload?.revenue,
            invoice: payload?.invoice,
          },
          site_address: {
            street: payload?.street,
            city: payload?.city,
            state: payload?.state,
            zip: payload?.zip,
          },
          admin_first: payload?.admin_first,
          admin_last: payload?.admin_last,
          admin_email: payload?.admin_email,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return request.data;
    },
    {
      onSuccess(data) {
        if (data?.message?.indexOf("timezone") !== -1) {
          toastContext.addToast({
            message: data?.message,
            status: "Warning",
          });
          setShowTimezone(true);
        }
        if (data?.success) {
          toastContext.addToast({
            message: data.message,
            status: "Success",
          });

          handleClearForm();
        }
        if (!data?.success) {
          toastContext.addToast({
            message: data.message,
            status: "Error",
          });
        }
      },
    }
  );

  const handleClearForm = () => {
    setPayload({
      license_type: "",
      nces_id: "",
      tier: "",
      school_name: "",
      integrations: "",
      note: "",
      num_students: "",
      expiration: nextDates[0].getTime() / 1000,
      revenue: "",
      invoice: "",
      street: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      admin_first: "",
      admin_last: "",
      admin_email: "",
      site_timezone: "",
    });
    setShowTimezone(false);
  };

  if (mutation.error)
    return <div>{`An error has occurred: ${mutation.error}`}</div>;

  return (
    <>
      <div className="px-2 md:px-0">
        <h1 className="py-4 text-2xl font-semibold text-gray-900 md:mx-2">
          Site License
        </h1>

        {mutation.isLoading && <DmLoadingSpinner message="Loading..." />}

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="mt-5 md:col-span-3 md:mt-0">
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  mutation.mutate();
                }}
              >
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-8 gap-6">
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="license_type"
                          className="block text-sm font-medium text-gray-700"
                        >
                          License type
                        </label>
                        <select
                          id="license_type"
                          name="license_type"
                          value={payload.license_type || ""}
                          onChange={(e) => {
                            handleClearForm();
                            setPayload({
                              ...payload,
                              license_type: e.target.value,
                            });
                          }}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value="school">School License</option>
                          <option value="district_6_12">District (6-12)</option>
                          <option value="district_9_12">District (9-12)</option>
                          <option value="district_6_8">District (6-8)</option>
                          <option value="district_7_12">District (7-12)</option>
                          <option value="district_7_8">District (7-8)</option>
                          <option value="school_custom">
                            School (No NCES ID)
                          </option>
                        </select>
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        {payload.license_type !== "school_custom" ? (
                          <>
                            <label
                              htmlFor="last-name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              NCES ID
                            </label>
                            <input
                              type="text"
                              name="nces-id"
                              id="nces-id"
                              required={
                                payload.license_type !== "school_custom"
                              }
                              value={payload.nces_id || ""}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  nces_id: e.target.value,
                                })
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </>
                        ) : (
                          <>
                            <label
                              htmlFor="school_name"
                              className="block text-sm font-medium text-gray-700"
                            >
                              School Name{" "}
                            </label>
                            <input
                              type="text"
                              name="school_name"
                              id="school_name"
                              value={payload.school_name || ""}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  school_name: e.target.value,
                                })
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </>
                        )}
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="integrations"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Tier
                        </label>
                        <select
                          id="tier"
                          name="tier"
                          value={payload.tier || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              tier: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value="plus">Plus</option>
                          <option value="integral">Integral</option>
                        </select>
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="integrations"
                          className={
                            payload?.tier === "integral"
                              ? "block text-sm font-medium text-gray-700"
                              : "hidden"
                          }
                        >
                          Integrations (if applicable)
                        </label>
                        <input
                          type="text"
                          name="integrations"
                          id="integrations"
                          placeholder="Comma separated list"
                          value={payload?.integrations || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              integrations: e.target.value,
                            })
                          }
                          className={
                            payload?.tier === "integral"
                              ? "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              : "hidden"
                          }
                        />
                      </div>

                      {payload?.license_type === "school_custom" && (
                        <>
                          <div className="col-span-8 md:col-span-2">
                            <label
                              htmlFor="school-address"
                              className="block text-sm font-medium text-gray-700"
                            >
                              School Address
                            </label>
                            <input
                              type="text"
                              name="school-address"
                              id="school-address"
                              autoComplete="street-address"
                              value={payload.street || ""}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  street: e.target.value,
                                })
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-8 md:col-span-2">
                            <label
                              htmlFor="city"
                              className="block text-sm font-medium text-gray-700"
                            >
                              City
                            </label>
                            <input
                              type="text"
                              name="city"
                              id="city"
                              value={payload.city || ""}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  city: e.target.value,
                                })
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-8 md:col-span-1">
                            <label
                              htmlFor="region"
                              className="block text-sm font-medium text-gray-700"
                            >
                              State
                            </label>
                            <input
                              type="text"
                              name="state"
                              id="state"
                              value={payload.state || ""}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  state: e.target.value,
                                })
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>

                          <div className="col-span-8 md:col-span-1">
                            <label
                              htmlFor="postal-code"
                              className="block text-sm font-medium text-gray-700"
                            >
                              ZIP / Postal code
                            </label>
                            <input
                              type="text"
                              name="postal-code"
                              id="postal-code"
                              value={payload.zip || ""}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  zip: e.target.value,
                                })
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                          <div className="col-span-8 md:col-span-2">
                            <label
                              htmlFor="postal-code"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Country
                            </label>
                            <input
                              type="text"
                              name="country"
                              id="country"
                              value={payload.country || ""}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  country: e.target.value,
                                })
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          </div>
                        </>
                      )}
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="admin-first"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Admin First Name
                        </label>
                        <input
                          type="text"
                          name="admin-first"
                          id="admin-first"
                          required
                          value={payload.admin_first || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              admin_first: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="admin-last"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Admin Last Name
                        </label>
                        <input
                          type="text"
                          name="admin-last"
                          id="admin-last"
                          required
                          value={payload.admin_last || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              admin_last: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="admin-email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Admin Email
                        </label>
                        <input
                          type="text"
                          name="admin-email"
                          id="admin-email"
                          required
                          value={payload.admin_email || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              admin_email: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="note"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Note (optional)
                        </label>
                        <input
                          type="text"
                          name="note"
                          id="note"
                          autoComplete="note"
                          value={payload.note || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              note: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="num-students"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Quote # Students
                        </label>
                        <input
                          type="text"
                          name="num-students"
                          id="num-students"
                          value={payload.num_students || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              num_students: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="expiration"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Expiration
                        </label>
                        <select
                          id="expiration"
                          name="expiration"
                          value={payload.expiration || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              expiration: parseInt(e.target.value),
                            })
                          }
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          {nextDates.map((date, index) => {
                            return (
                              <option
                                key={index}
                                value={Math.floor(date.getTime() / 1000)}
                              >
                                {`October ${date.getDate()}, ${date.getFullYear()}`}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="revenue"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Revenue
                        </label>
                        <input
                          type="text"
                          name="revenue"
                          id="revenue"
                          value={payload.revenue || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              revenue: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="invoice"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Invoice Number
                        </label>
                        <input
                          type="text"
                          name="invoice"
                          id="invoice"
                          value={payload.invoice || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              invoice: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      {
                        <div className="col-span-8 md:col-span-2">
                          <label
                            htmlFor="site_timezone"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Timezone
                          </label>
                          <select
                            id="site_timezone"
                            name="site_timezone"
                            className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) =>
                              setPayload({
                                ...payload,
                                site_timezone: e.target.value,
                              })
                            }
                          >
                            {Object.entries(TIMEZONES_FOR_PICKER).map(
                              ([key, zone]) => {
                                return (
                                  <option key={zone} value={zone}>
                                    {key}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      disabled={mutation.isLoading}
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Activate Site License
                    </button>{" "}
                    <button
                      type="button"
                      disabled={mutation.isLoading}
                      className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => handleClearForm()}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SiteLicense;
