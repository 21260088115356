import { useContext, useEffect, useRef, useState } from "react";
import CalculatorRow from "./CalculatorRow";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import AlphabeticalKeyboard from "./AlphabeticalKeyboard";
import NumericalKeyboard from "./NumericalKeyboard";
import KeyboardControls from "./KeyboardControls";
import FunctionsKeyboard from "./FunctionsKeyboard";
import { XIcon } from "@heroicons/react/outline";
import FracModal from "./FracModal";
const dmKAS = (window as any).dmKAS;

export const evaluate = (latex: string) => {
  return dmKAS.valCalculator(latex);
};

const DMKeyboard = ({
  close,
  enter,
  input,
  focusedInput,
  showCalculator,
}: {
  close: () => void;
  input?: any;
  focusedInput: any;
  enter?: () => void;
  showCalculator: boolean;
}) => {
  const [showNumeric, setShowNumeric] = useState<boolean>(true);
  const [showFuncs, setShowFuncs] = useState<boolean>(false);
  const [showRad, setShowRad] = useState<boolean>(false);
  const [shift, setShift] = useState<boolean>(false);
  const [answer, setAnswer] = useState<any>("...");
  const [previousAnswer, setPreviousAnswer] = useState(0);
  const [showFrac, setShowFrac] = useState(false);
  /** Should these be accessed through context, because they are provided?
   * Or passed as props since DMKeyboard is a direct child of <App />  */
  // const {
  // showCalculator,
  // globalInputsMap: input,
  // globalFocusedInput: focusedInput,
  // } = useContext(StudentSectionsContext);
  const { handleGlobalFocus, globalInputsMap, globalFocusedInput } = useContext(
    StudentSectionsContext
  );
  const inputsRef = useRef<Map<string, any> | null>(input || null);

  /* Math Input Field Event Handler */
  const handleChange = (mq: any): void => {
    const inputStr = mq.latex();
    if (inputStr) parseAnswer(inputStr);
  };

  const parseAnswer = (latex: string) => {
    let fullAnswer = dmKAS.valCalculator(latex);
    if (latex === "") {
      setAnswer("...");
      fullAnswer = 0;
      return;
    }
    try {
      dmKAS.setMode(showRad ? "radian" : "degree");
      fullAnswer = dmKAS.valCalculator(latex);
    } catch (e) {
      setAnswer("...");
      fullAnswer = 0;
    }
    if (isNaN(fullAnswer)) {
      setAnswer("...");
      fullAnswer = 0;
      return;
    }

    setAnswer(displayAnswer(fullAnswer));
  };

  useEffect(() => {
    /** update dmKAS mode and recalculate answer on Radian/Degree selection */
    if (showRad) {
      dmKAS.setMode("radian");
    } else dmKAS.setMode("degree");
    if (
      globalInputsMap &&
      globalFocusedInput &&
      globalInputsMap.get(globalFocusedInput)
    ) {
      handleChange(globalInputsMap.get(globalFocusedInput));
    }
  }, [showRad]);

  useEffect(() => {
    if (!focusedInput) {
      handleGlobalFocus(globalInputsMap.keys().next().value);
    }
  }, []);

  const handleEnter = () => {
    if (answer) setPreviousAnswer(answer);
    /** also want to add a row above the input row showing the previous expression */
  };

  return (
    <div
      id="dm-keyboard"
      className="z-100000 fixed bottom-0 left-0 mt-10 w-full rounded-lg border border-black bg-gray-200 shadow-md sm:p-4"
    >
      {answer && (
        <FracModal
          decimal={answer}
          open={showFrac}
          setOpen={() => setShowFrac(!showFrac)}
        />
      )}
      {showCalculator && (
        <div className="left-0 top-0 my-4">
          <CalculatorRow
            ref={(node: any) => {
              if (!inputsRef.current) inputsRef.current = new Map();
              const map = inputsRef.current;
              if (node) {
                map.set("math-input-0", node);
              } else {
                map.delete("math-input-0");
              }
            }}
            handleChange={handleChange}
            answer={answer}
          />
        </div>
      )}
      <div className="absolute right-0 top-0 -mt-8 mr-2 sm:mr-4 sm:mt-2">
        <button onClick={() => close()}>
          <XIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      {showNumeric ? (
        <div className="mx-2 mt-8 grid grid-cols-3 space-x-3 sm:mx-12 sm:my-0">
          {showFuncs ? (
            <>
              <FunctionsKeyboard
                shift={shift}
                showRad={showRad}
                input={input}
                focusedInput={focusedInput}
                showFuncs={showFuncs}
                showFrac={showFrac}
                setShowFuncs={setShowFuncs}
                setShift={setShift}
                setShowNumeric={setShowNumeric}
                setShowRad={setShowRad}
                setShowFrac={setShowFrac}
              />
            </>
          ) : (
            <NumericalKeyboard
              shift={shift}
              input={input}
              focusedInput={focusedInput}
              setShift={setShift}
              setShowNumeric={setShowNumeric}
              previousAnswer={previousAnswer}
              showFuncs={showFuncs}
              setShowFuncs={setShowFuncs}
              handleEnter={handleEnter}
            />
          )}
          <div className="col col-span-1">
            <KeyboardControls
              input={input}
              focusedInput={focusedInput}
              handleEnter={handleEnter}
            />
          </div>
        </div>
      ) : (
        <AlphabeticalKeyboard
          shift={shift}
          input={input}
          focusedInput={focusedInput}
          setShift={setShift}
          setShowNumeric={setShowNumeric}
          handleEnter={handleEnter}
        />
      )}
    </div>
  );
};

export default DMKeyboard;

function displayAnswer(num: number): string {
  if (num === undefined) return "";
  let result: number | string = parseFloat(num.toPrecision(10));
  const absNum = Math.abs(num);
  if (absNum < Math.pow(10, 10) && absNum >= Math.pow(10, -1)) {
    // number between 0.1 and 1000
    return result.toString();
  } else if (absNum >= Math.pow(10, 21)) {
    // number very big
    return "overflow";
  } else if (absNum <= Math.pow(10, -13)) {
    // number very close to 0
    return "0";
  } else if (absNum < Math.pow(10, -1)) {
    // num between 0 and 0.1 -- use scientific notation at 6 decimal places
    if (absNum > Math.pow(10, -6)) {
      return (result + "").substring(0, 11 + (num < 0 ? 1 : 0));
    } else {
      result = num.toExponential() + "";
      const allowedLength = 12 + (num < 0 ? 1 : 0);
      if (result.length > allowedLength) {
        // must truncate
        const pos = result.search("e");
        const end = result.substring(pos);
        return result.substring(0, allowedLength - end.length) + end;
      } else return result;
    }
  }
  const log = Math.floor(Math.log(Math.abs(result)) / Math.log(10));
  const str = result + "";
  let start;
  let substr;
  if (num < 0) {
    start = str.substring(0, 2);
    substr = str.substring(2, 6);
  } else {
    start = str.substring(0, 1);
    substr = str.substring(1, 6);
  }
  let i: number;
  for (i = substr.length - 1; i > 0; i--) {
    console.log(i);
    if (substr[i] !== "0") break;
  }
  return start + "." + substr.substring(0, i + 1) + "e" + log;
}
