import { useState, useContext } from "react";
import ReactTooltip from "react-tooltip";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import YourSolution from "./YourSolution";
import DmAlertModal from "./DmAlertModal";
import DmConfirmModal from "./DmConfirmModal";
import SimpleAnswer from "./SimpleAnswer";
import MultipleChoiceAnswer from "./MultipleChoiceAnswer";
import CustomAnswer from "./CustomAnswer";
import FormattedAnswer from "./FormattedAnswer";
import VirtualKeyboard from "./VirtualKeyboard";

type AnswerProps = {
  correct: number | undefined;
  complete: boolean;
  showSolution: boolean;
  setShowSolution?: any;
  isCheckAnswerLoading: boolean;
  answer: Array<string> | object;
  setAnswer: React.Dispatch<React.SetStateAction<object | string[]>>;
  formattedAnswer: string;
  setFormattedAnswer: (str: string) => void;
  setCustomAnswerData: React.Dispatch<
    React.SetStateAction<Record<string, unknown>>
  >;
  customMqFocus: string | null;
  setCustomMqFocus: React.Dispatch<React.SetStateAction<string | null>>;
  callback: () => void;
  problem: any; // TS TODO
  katexResizingData?: any;
};

export default function Answer({
  correct,
  complete,
  showSolution,
  setShowSolution,
  isCheckAnswerLoading,
  answer,
  setAnswer,
  formattedAnswer,
  setFormattedAnswer,
  setCustomAnswerData,
  customMqFocus,
  setCustomMqFocus,
  callback: checkAnswerFunc,
  problem,
  katexResizingData,
}: AnswerProps): JSX.Element {
  // destructuring problem
  const { ansType, choices, data: problemData } = problem || {};

  // destructuring problem.data (or problemData)
  const {
    guided,
    commonButtons,
    leftLatex,
    rightLatex,
    answerWidth,
    multipleSolutionsPossible,
    multipleSolutionsBtns,
    choicesPerLine,
    inlineChoices,
    allowcomma: allowComma,
    allowCommasForNumbers,
    binomialExponent,
    setNotation,
  } = problemData || {};

  // defining variables from props
  const warnings = {
    ...problem?.warnings,
    simplifyRadicalWarning: problem?.data?.simplifyRadicalWarning,
    studentWarningFunction: problem?.data?.studentWarningFunction,
  };
  const answerForm = problem?.htmlTemplates
    ? problem?.htmlTemplates[
        problem?.data?.answerFormTemplate
          ? problem?.data?.answerFormTemplate.slice(0, -5)
          : problem?.skillcode + "_AnswerForm"
      ]
    : undefined;
  const answerScripts = problem?.problemScripts?.answerScripts;

  // state
  const [showAnswerPreview, setShowAnswerPreview] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [isGuided, setIsGuided] = useState(
    guided !== undefined ? guided : false
  );
  const { setLoadingData } = useContext(StudentSectionsContext);

  const endGuided = (msg?: string) => {
    setIsGuided(false);
    // Line below not tested (cannot find a module that includes a msg...)
    if (msg !== undefined) window.alertMath(msg);
  };
  /* Set global function */
  window.endGuided = endGuided;

  const handleSubmit = () => {
    setLoadingData((prevState: any) => ({
      ...prevState,
      isShowing: true,
    }));
    checkAnswerFunc();
  };

  return (
    <>
      {showSolution ? (
        <>
          <YourSolution
            correct={correct}
            complete={complete}
            ansType={ansType}
            leftLatex={leftLatex}
            rightLatex={rightLatex}
            noSolutionText={
              multipleSolutionsPossible
                ? multipleSolutionsBtns
                  ? multipleSolutionsBtns[0]
                  : "No solution"
                : "Answer left blank"
            }
            choices={choices}
            setNotation={setNotation}
            problem={problem}
            katexResizingData={katexResizingData}
            setShowSolution={setShowSolution}
          />
        </>
      ) : (
        <>
          <DmAlertModal
            showModal={showAlert}
            setShowModal={setShowAlert}
            headline={"Notice"}
            message={alertMsg}
            buttonText={"Okay"}
            modalAction={() => {
              console.log("Okay pressed!");
            }}
          />
          <DmConfirmModal
            showModal={showAnswerPreview}
            setShowModal={setShowAnswerPreview}
            isSubmitDisabled={isCheckAnswerLoading}
            headline={"Verify Solution"}
            subHeadline="Are you sure you want to submit this answer?"
            message={
              <FormattedAnswer
                ansType={ansType}
                answer={answer}
                leftLatex={leftLatex}
                rightLatex={rightLatex}
                noSolutionText={
                  multipleSolutionsPossible
                    ? multipleSolutionsBtns
                      ? multipleSolutionsBtns[0]
                      : "No solution"
                    : "Answer left blank"
                }
                choices={choices}
                setNotation={setNotation}
                customMsg={formattedAnswer}
                setFormattedAnswer={setFormattedAnswer}
              />
            }
            modalAction={handleSubmit}
            ariaLabelMath={formattedAnswer}
          />
          {/* Answer area header */}
          {problem !== undefined && (
            <>
              <div className="mb-8 flex flex-col justify-start gap-x-4 gap-y-2 sm:flex-row sm:items-center">
                <div className="text-lg">Answer</div>
                {problem?.attempts?.max > 1 ? (
                  <>
                    <div
                      className="attempts text-sm text-gray-500"
                      data-effect="solid"
                      data-for="attempts-tooltip"
                      data-tip="The number of attempts on this specific problem before you are marked incorrect"
                    >
                      Attempt {problem?.attempts?.used + 1} out of{" "}
                      {problem?.attempts?.max}
                    </div>
                    <ReactTooltip
                      id="attempts-tooltip"
                      className="max-w-[14rem] text-xs"
                    />
                  </>
                ) : null}
              </div>
              {(ansType === 1 || (ansType === "custom" && customMqFocus)) && (
                <div id="keyboard" className="absolute right-4 top-4">
                  <VirtualKeyboard />
                </div>
              )}
            </>
          )}
          {isGuided ? (
            <>
              <div className="answerArea">
                You must answer all questions above in order to submit.
              </div>
            </>
          ) : null}
        </>
      )}
      {ansType === 1 && !isGuided ? (
        <SimpleAnswer
          className={showSolution ? "hidden" : "block"}
          commonButtons={commonButtons}
          leftLatex={leftLatex}
          rightLatex={rightLatex}
          answerWidth={answerWidth}
          multipleSolutionsPossible={multipleSolutionsPossible}
          multipleSolutionsBtns={multipleSolutionsBtns}
          allowComma={allowComma}
          allowCommasForNumbers={allowCommasForNumbers}
          binomialExponent={binomialExponent}
          warnings={warnings}
          setAnswer={setAnswer}
          setAlertMsg={setAlertMsg}
          setShowAlert={setShowAlert}
          setShowAnswerPreview={setShowAnswerPreview}
          problemData={problemData}
        />
      ) : null}
      {ansType === 2 && !isGuided ? (
        <MultipleChoiceAnswer
          className={showSolution ? "hidden" : "block"}
          choices={choices}
          choicesPerLine={choicesPerLine}
          inlineChoices={inlineChoices}
          setAnswer={setAnswer}
          setShowAnswerPreview={setShowAnswerPreview}
        />
      ) : null}
      {ansType === "custom" ? (
        <CustomAnswer
          className={showSolution || isGuided ? "hidden" : "block"}
          answerForm={answerForm}
          answerScripts={answerScripts}
          setCustomMsg={setFormattedAnswer}
          setShowAnswerPreview={setShowAnswerPreview}
          setAnsData={setCustomAnswerData}
          setAnswer={setAnswer}
          customMqFocus={customMqFocus}
          setCustomMqFocus={setCustomMqFocus}
        />
      ) : null}
    </>
  );
}
