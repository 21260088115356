import axios from "axios";
import { useQuery } from "react-query";
import { deltamathAPI } from "../manager/utils";
import { useUserContext } from "../shared/contexts/UserContext";

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

export const reportError = ({ message }: { message: string }) => {
  console.error(message);
};

export const isRequestFromProd = (origin: string) =>
  origin.includes("www.deltamath.com") ? true : false;

export const isRequestFromDev = (origin: string) =>
  origin.includes("dev.deltamath.com") ? true : false;

export const isRequestFromReviewapp = (origin: string) =>
  origin &&
  (origin.includes("deltamath-manager.pages.dev") ||
    origin.includes("home-afv.pages.dev") ||
    origin.includes("deltamath-frontend.pages.dev"));

export const getFilePath = (path: string) => {
  if (
    isRequestFromDev(window.location.origin) ||
    isRequestFromProd(window.location.origin)
  ) {
    // For now, REACT_APP_URL_PREFIX has a trailing slash in production, but not in dev.
    const separator =
      `${process.env.REACT_APP_URL_PREFIX}`.slice(-1) === "/" ? "" : "/";
    return `${process.env.REACT_APP_URL_PREFIX}${separator}manager/${path}`;
  } else {
    return path;
  }
};

export const executeQuery = async (queryObject: {
  path: string;
  params?: Record<string, unknown>;
  method?: "get" | "post" | "put" | "delete";
  requestBody?: Record<string, unknown>;
  additionalHeaders?: Record<string, unknown>;
  customerServiceHeader?: boolean;
}) => {
  const {
    path,
    params,
    method,
    requestBody,
    additionalHeaders,
    customerServiceHeader,
  } = queryObject;
  // const userContext = useUserContext();
  let token: string | null = null;
  if (typeof customerServiceHeader !== "undefined" && customerServiceHeader) {
    token = localStorage.getItem("customer_service_token");
  }
  const axiosConfig: Record<string, unknown> = {
    url: deltamathAPI() + path,
    // withCredentials: true,
    headers: {
      "Content-Type": "application/json",
      ...(token && { Authorization: `Bearer ${token}` }),
      ...additionalHeaders,
    },
  };
  if (typeof params !== "undefined") {
    axiosConfig.params = params;
  }
  if (typeof method !== "undefined") {
    axiosConfig.method = method;
  }
  if (typeof requestBody !== "undefined" && method !== "get") {
    axiosConfig.data = requestBody;
  }
  try {
    const request = await axios.request(axiosConfig);
    // if (request.headers["x-deltamath-new-token"]) {
    //   userContext.setJWT(request.headers["x-deltamath-new-token"]);
    // }
    // if (
    //   request.data.error ===
    //   "You must sign into deltamath.com to renew your authentication token."
    // ) {
    //   userContext.clearJWT();
    //   localStorage.removeItem("admin");
    //   localStorage.removeItem("user");
    //   window.location.reload();
    // }
    return request.data;
  } catch (error: any) {
    if (error.response.status === 500) {
      // userContext.clearJWT();
      // localStorage.removeItem("admin");
      // localStorage.removeItem("user");
      window.location.href = `${process.env.REACT_APP_TEACHER_LINK}`;
    }
    reportError({ message: getErrorMessage(error) }); //log error to console
    throw error.response.data; // return { error } from useQuery()
  }
};

// I know that this is bad practice, but until we  have a good way to share types between the API and frontend, going to use this
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDMQuery<T = any>(queryObject: {
  path: string;
  params?: Record<string, unknown>;
  cacheKey?: string | string[];
  method?: "get" | "post" | "put" | "delete";
  requestBody?: Record<string, unknown>;
  queryOptions?: Record<string, unknown>;
  additionalHeaders?:
    | { "Content-Type": "multipart/form-data" }
    | Record<string, unknown>;
  customerServiceHeader?: boolean;
}) {
  const {
    path,
    params,
    cacheKey,
    method,
    requestBody,
    queryOptions,
    additionalHeaders,
    customerServiceHeader,
  } = queryObject;
  if (!queryObject.path) {
    throw new Error("useDMQuery requires a path");
  }
  return useQuery<T>(
    cacheKey || path,
    async () =>
      executeQuery({
        path,
        params,
        method,
        requestBody,
        additionalHeaders,
        customerServiceHeader,
      }),
    queryOptions
  );
}

export const formatNumber = (num: number) => {
  return num == Math.round(num)
    ? num.toLocaleString()
    : num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export function timeConverter(UNIX_timestamp: number) {
  if (UNIX_timestamp) {
    const a = new Date(UNIX_timestamp * 1000);
    return new Intl.DateTimeFormat("en-US").format(a);
  }
  return "";
}

export function isTokenExpired(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const { exp } = JSON.parse(jsonPayload);
  const expired = Date.now() >= exp * 1000;
  return expired;
}
