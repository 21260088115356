import clsx from "clsx";
import { useEffect, useState } from "react";
import { demoString } from "../../../utils/demo";
import { getAdminParams } from "../../utils";

const HeaderCard = ({
  dataScope,
  title,
  meta,
  sameSchool,
}: {
  dataScope: string;
  title: string;
  meta?: {
    teachers?: string | string[];
    schools?: string | string[];
  };
  sameSchool?: boolean;
}) => {
  const admin = getAdminParams();
  const [showSchools, setShowSchools] = useState(true);

  useEffect(() => {
    if (
      admin.account_type === "school" ||
      admin.account_type === "super_school"
    ) {
      if (admin.schoolIDs.length <= 1) {
        setShowSchools(false);
      }
    }
  }, [admin]);

  return (
    <div className="my-auto">
      <span
        className={clsx(
          title === "Total" ? "text-center text-xl font-bold" : "text-left",
          "font-semibold sm:col-span-1"
        )}
      >
        {" "}
        {title === "Total" ? (
          <>Total</>
        ) : dataScope === "Schools" ? (
          <>
            {demoString({
              value: title,
              type: "school_name",
            })}
          </>
        ) : dataScope === "Sections" ? (
          <>
            {demoString({
              value: title,
              type: "section_name",
            })}
          </>
        ) : (
          <>
            {demoString({
              value: title,
              type: "person_full",
            })}
          </>
        )}
      </span>{" "}
      {dataScope === "Teachers" ? (
        <>
          {showSchools && admin.schoolIDs.length > 1 && (
            <span className="text-xs text-gray-600">
              {!Array.isArray(meta?.schools) && meta?.schools && (
                <p>
                  -
                  {demoString({ value: meta?.schools[0], type: "school_name" })}
                </p>
              )}
              {Array.isArray(meta?.schools) &&
                meta?.schools &&
                meta?.schools.length > 1 && (
                  <p>
                    -
                    {demoString({
                      value: meta?.schools[0],
                      type: "school_name",
                    })}
                    ...
                  </p>
                )}
            </span>
          )}
        </>
      ) : dataScope === "Sections" ? (
        <span className="group relative text-xs text-gray-600">
          {sameSchool &&
            Array.isArray(meta?.schools) &&
            meta?.schools?.length === 1 && (
              <p>
                -{demoString({ value: meta?.schools[0], type: "school_name" })}
              </p>
            )}
          {sameSchool &&
            Array.isArray(meta?.schools) &&
            meta?.schools &&
            meta?.schools?.length > 1 && (
              <p>
                -{demoString({ value: meta?.schools[0], type: "school_name" })}
                ...
              </p>
            )}
          {Array.isArray(meta?.teachers) &&
            meta?.teachers &&
            meta?.teachers?.length === 1 && (
              <p>
                -{demoString({ value: meta?.teachers[0], type: "person_full" })}
              </p>
            )}
          {Array.isArray(meta?.teachers) &&
            meta?.teachers &&
            meta?.teachers.length > 1 && (
              <p>
                -{demoString({ value: meta?.teachers[0], type: "person_full" })}
                ...
              </p>
            )}
          {Array.isArray(meta?.teachers) &&
            meta?.teachers &&
            meta?.teachers.length > 1 && (
              <div className="invisible absolute -top-20 w-48 items-center text-left group-hover:visible group-hover:delay-300">
                <span className="relative z-10 mx-2 block rounded bg-gray-500 p-2 text-xs text-white shadow-lg">
                  {meta?.teachers.map((teacher: string) => (
                    <p className="w-full">
                      {demoString({ value: teacher, type: "person_full" })}
                    </p>
                  ))}
                </span>
                <div className="align-center -my-2 mx-auto h-3 w-3 rotate-45 bg-gray-500"></div>
              </div>
            )}
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default HeaderCard;
