import Line from "./Line";
import HiddenLines from "./HiddenLines";

export default function Problem({
  displayData,
  problemData,
  resizingData,
  locString, // TEMPORARY FOR DEV TESTING OF SPOKEN KATEX
}: {
  displayData: any;
  problemData: any;
  resizingData?: any;
  locString?: string;
}): JSX.Element {
  if (!displayData?.length || problemData === undefined) return <></>;

  const groupedDisplayData: Array<Array<any>> = [];
  let prevHidden = false;
  displayData.forEach((line: any, index: number) => {
    const currHidden = line.format?.hidden ? true : false;
    if (prevHidden && currHidden) {
      groupedDisplayData[groupedDisplayData.length - 1]?.push({
        index,
        line,
        currHidden,
      });
    } else {
      groupedDisplayData.push([{ index, line, currHidden }]);
    }
    prevHidden = currHidden;
  });

  return (
    <div className="relative pt-8">
      {groupedDisplayData.map((lineChunk, index: number) =>
        lineChunk[0].currHidden === false ? (
          <Line
            key={"line" + index}
            lineData={lineChunk[0]}
            problemData={problemData}
            resizingData={resizingData}
            locString={locString}
          />
        ) : (
          <HiddenLines
            key={"line_group" + index}
            lineDataArray={lineChunk}
            problemData={problemData}
            resizingData={resizingData}
            locString={locString}
          />
        )
      )}
    </div>
  );
}
