import { useState, useRef, useEffect, useContext, Fragment } from "react";
import { useMutation, useQueryClient, UseMutationResult } from "react-query";
import axios, { AxiosResponse } from "axios";
import { debounce, merge } from "lodash";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckCircleIcon } from "@heroicons/react/solid";
// import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
// import ReactTooltip from "react-tooltip";
import clsx from "clsx";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { deltamathAPI, deltamathServerlessAPI } from "../../manager/utils";
import { useDMQuery } from "../../utils";
import { resizeKatexLine, updateFullAssignmentData } from "../utils";
import renderMathInElement from "../utils/auto-render";
import { useUserContext } from "../../shared/contexts/UserContext";
// TODO: get types from backend!

export default function TimedModule({
  solveSkill,
}: {
  solveSkill: any;
}): JSX.Element {
  const userContext = useUserContext();
  const [score, setScore] = useState<number>(0);
  const [showStart, setShowStart] = useState<boolean>(true);
  const [problemIndex, setProblemIndex] = useState<number | null>(null);
  const [problems, setProblems] = useState<any>(null);
  const [answer, setAnswer] = useState<number | undefined>(undefined);
  const [correctAnswer, setCorrectAnswer] = useState<number | undefined>(
    undefined
  );
  const [solutionText, setSolutionText] = useState<string[] | undefined>(
    undefined
  );
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [scorePercentage, setScorePercentage] = useState<number | null>(null);
  const [record, setRecord] = useState<number | null>(null);
  const {
    setLoadingData,
    activeSection,
    dmAssignmentData,
    setDmAssignmentData,
  } = useContext(StudentSectionsContext); // For error handling!
  const checkSum = useRef<string | null>(null);
  const { required, seconds, credit } =
    solveSkill.ta.skills[solveSkill.ta.skillName];
  const bestTime =
    solveSkill?.sa?.currentSkill?.record !== undefined
      ? solveSkill.sa.currentSkill.record
      : 0;
  const token = userContext.getJWT(); // for authorization

  /* time for display */
  const [startTime, setStartTime] = useState<number | null>(null);
  const [now, setNow] = useState<number | null>(null);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const timerText = useRef<string>("");

  /* problem duration timer (for API) */
  const problemStartTime = useRef<number | null>(null);

  /* return trip timer (for API) */
  const returnTripStartTime = useRef<number | null>(null);
  const returnTripTime = useRef<number>(0);

  /* Fetch info for timed module */
  const { status, data, error } = useTimedModuleQuery(solveSkill);
  if (error) {
    setLoadingData((prevState: any) => ({
      ...prevState,
      isShowing: true,
      error: true,
      title: "Error",
      message: `${
        (error as any)?.response?.data?.message ||
        (error as any)?.message ||
        (error as any)?.error ||
        "Something went wrong. Please try again."
      }`,
    }));
  }
  if (data?.assignment) {
    updateFullAssignmentData(
      data.assignment,
      data.assignment.ta._id,
      activeSection,
      dmAssignmentData,
      setDmAssignmentData
    );
  }

  /* Check the validity of the session */
  const checkSession = useCheckSession(token);

  /* Check answer mutation */
  const checkAnswer = useCheckAnswer();

  /* Submit timed problem mutation */
  const submitTimedProblem = useSubmitTimedProblem(token);

  /* Function to execute if the session is valid */
  const startSession = () => {
    problemStartTime.current = Date.now(); // start the problem duration timer
    // start the display timer
    setStartTime(Date.now());
    setNow(Date.now());
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setNow(Date.now());
    }, 10);
    setShowStart(false);
    // show the first problem in the set
    setProblems(data.problems);
    setProblemIndex(0);
    // reset answers for the new session
    setAnswer(undefined);
    setCorrectAnswer(undefined);
    setSolutionText(undefined);
    setIsComplete(false);
    setScorePercentage(null);
    setRecord(null);

    setScore(0);
  };

  /* Function to stop a session */
  const stopSession = (problemSetComplete?: boolean) => {
    if (problemSetComplete) setIsComplete(true);
    if (intervalRef.current) clearInterval(intervalRef.current);
    problemStartTime.current = null; // reset the problem duration timer

    // reset the return trip timer
    returnTripTime.current = 0;
    returnTripStartTime.current = null;

    timerText.current = "";

    checkSum.current = null; // reset the checkSum token to be null
    setShowStart(true);
  };

  /* Start button handler */
  const handleStartStop = () => {
    if (showStart) {
      // user pressed start
      // check if the session token (checkSum) is valid, if yes, start the session
      checkSession.mutate(
        JSON.stringify({
          checkSum: data?.checkSum,
        }),
        {
          onSuccess: (response) => {
            if (response.data.valid === true) return startSession();

            setLoadingData((prevState: any) => ({
              ...prevState,
              isShowing: true,
              error: true,
              title: "Error",
              message:
                "Your session is inactive. Please press start to try again.",
            }));
          },
          onError: (error: any) => {
            setLoadingData((prevState: any) => ({
              ...prevState,
              isShowing: true,
              error: true,
              title: "Error",
              message: `${
                error?.response?.data?.message ||
                error?.message ||
                error?.error ||
                ""
              }`,
            }));
          },
        }
      );
    } else {
      // user pressed stop

      if (problemIndex === null || problemStartTime.current === null)
        return stopSession();

      const problemDuration = Date.now() - problemStartTime.current;

      // call checkTimedAnswer endpoint with no answer field, since the student pressed stop without selecting an answer
      checkAnswer.mutate(
        JSON.stringify({
          checkSum:
            checkSum.current === null ? data?.checkSum : checkSum.current,
          pid: problems[problemIndex].id,
          duration: problemDuration / 1000,
          rtt:
            problemIndex === problems.length - 1
              ? returnTripTime.current / 1000
              : undefined,
        }),
        {
          onError: (error: any) => {
            setLoadingData((prevState: any) => ({
              ...prevState,
              isShowing: true,
              error: true,
              title: "Error",
              message: `${
                error?.response?.data?.message ||
                error?.message ||
                error?.error ||
                ""
              }`,
            }));
          },
        }
      );

      stopSession();
    }
  };

  /* Check answer handler */
  const handleCheckAnswer = (index: number) => {
    if (problemIndex === null || problemStartTime.current === null) return;
    setAnswer(index); // set the student answer
    // stop the problem duration timer
    const problemDuration = Date.now() - problemStartTime.current;
    problemStartTime.current = null;
    // pause the display timer
    if (intervalRef.current) clearInterval(intervalRef.current);
    // start the return trip timer
    if (problemIndex !== problems.length - 1)
      returnTripStartTime.current = Date.now();

    checkAnswer.mutate(
      JSON.stringify({
        checkSum: checkSum.current === null ? data?.checkSum : checkSum.current,
        pid: problems[problemIndex].id,
        answer: index,
        duration: problemDuration / 1000,
        rtt:
          problemIndex === problems.length - 1
            ? returnTripTime.current / 1000
            : undefined,
      }),
      {
        onSuccess: (response) => {
          if (response.data.correct) {
            // the student was correct
            checkSum.current = response.data.checkSum; // reset token
            setScore(score + 1);

            if (!response.data.finished) {
              // the student is not finished with the problem set
              // stop the return trip timer and increment it
              if (returnTripStartTime.current !== null) {
                returnTripTime.current +=
                  Date.now() - returnTripStartTime.current;
              }
              returnTripStartTime.current = null;

              // move to the next problem in the set
              if (problemIndex !== null) setProblemIndex(problemIndex + 1);

              // restart the display timer
              if (startTime !== null && returnTripStartTime.current !== null) {
                setStartTime(
                  startTime + (Date.now() - returnTripStartTime.current)
                );
              }
              intervalRef.current = setInterval(() => {
                setNow(Date.now());
              }, 10);

              // start the problem duration timer
              problemStartTime.current = Date.now();
            } else {
              // the student is finished with the problem set
              // submit the problem
              submitTimedProblem.mutate(
                JSON.stringify({
                  checkSum: checkSum.current,
                  last_edit: solveSkill.ta.last_edit,
                }),
                {
                  onSuccess: (response) => {
                    // if the assignment data is included, update the entire assignment
                    if (response.data?.assignment) {
                      updateFullAssignmentData(
                        response.data.assignment,
                        response.data.assignment.ta._id,
                        activeSection,
                        dmAssignmentData,
                        setDmAssignmentData
                      );
                    } else if (response.data?.submissionResponse) {
                      const assignmentIndex = dmAssignmentData[
                        activeSection
                      ].findIndex(
                        (assignment: any) =>
                          assignment.ta._id === solveSkill?.ta?._id
                      );
                      if (assignmentIndex > -1) {
                        const newAssignmentObj = { ...dmAssignmentData };
                        // merge the submission response with the existing assignment data
                        newAssignmentObj[activeSection][assignmentIndex].sa =
                          merge(
                            newAssignmentObj[activeSection][assignmentIndex].sa,
                            response.data.submissionResponse
                          );
                        // reset the dmAssignmentData
                        setDmAssignmentData(newAssignmentObj);
                      }
                    }
                    const studentData =
                      response?.data?.submissionResponse?.data[
                        solveSkill.ta.skillName
                      ];
                    if (studentData !== undefined) {
                      if (studentData.score !== undefined)
                        setScorePercentage(studentData.score);
                      if (studentData.record !== undefined)
                        setRecord(studentData.record);
                    }
                  },
                  onError: (error: any) => {
                    console.log(
                      "Error message in submitTimedProblem.mutate",
                      error
                    );
                    setLoadingData((prevState: any) => ({
                      ...prevState,
                      isShowing: true,
                      error: true,
                      title: "Error",
                      message: `${
                        error?.response?.data?.message ||
                        error?.message ||
                        error?.error ||
                        ""
                      }`,
                    }));
                  },
                }
              );
              // stop the current session
              stopSession(true);
            }
          } else {
            // the student was incorrect
            if (response.data.ans !== undefined) {
              setCorrectAnswer(response.data.ans);
            }
            if (response.data.solution !== undefined) {
              setSolutionText(response.data.solution);
            }
            stopSession();
          }
        },
        onError: (error: any) => {
          console.log("Error message in checkAnswer.mutate", error);
          setLoadingData((prevState: any) => ({
            ...prevState,
            isShowing: true,
            error: true,
            title: "Error",
            message: `${
              error?.response?.data?.message ||
              error?.message ||
              error?.error ||
              ""
            }`,
          }));
          stopSession();
        },
      }
    );
  };

  /* If the seconds array does not have a first element of "0", then the student has a limit */
  const hasTimeLimit = Number(seconds[0]) !== 0;

  /* Time to display */
  let secondsPassed = 0;
  if (startTime !== null && now !== null) {
    secondsPassed = (now - startTime) / 1000;
    if (hasTimeLimit) {
      // screen readers should announce halfway point and 20 seconds remaining
      if (Math.round(secondsPassed) === Math.round(Number(seconds[0]) / 2)) {
        timerText.current = "Your time is halfway up.";
      }
      if (Math.round(secondsPassed) === Math.round(Number(seconds[0]) - 20)) {
        timerText.current = "You have 20 seconds remaining.";
      }
    }
  }

  return (
    <>
      <div
        className="relative mt-10 bg-white px-2 py-9 shadow-md sm:px-5 md:px-9"
        aria-live="assertive"
        aria-relevant="text"
      >
        <div className="timed-problem-controls">
          {/* Show time for modules that have an assigned time limit */}
          {hasTimeLimit && (
            <>
              <div className="flex justify-between gap-4">
                <div className="basis-1/3 text-left">
                  Time to beat: {seconds[0]} seconds
                </div>
                <div className="basis-2/3 text-center">
                  <Popover className="relative">
                    <Popover.Button className="inline-flex items-center gap-x-1 rounded-md text-sm font-semibold leading-6 text-gray-900 ring-gray-200 ring-offset-8 focus:ring-1">
                      <span>How is my grade calculated?</span>
                      <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute left-1/2 z-10 mt-3 flex w-screen max-w-max -translate-x-1/2 px-4">
                        <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
                          <div className="grid grid-cols-2 justify-items-center gap-x-6 gap-y-1 p-2">
                            <div
                              key="seconds"
                              className="group relative flex rounded-lg p-1 font-bold"
                            >
                              Seconds
                            </div>
                            <div
                              key="credit"
                              className="group relative flex rounded-lg p-1 font-bold"
                            >
                              Grade
                            </div>
                            {seconds.map((item: number, index: number) => (
                              <>
                                <div
                                  key={item}
                                  className="group relative flex rounded-lg p-1"
                                >
                                  {item}
                                </div>
                                <div
                                  key={credit[index] + "%"}
                                  className="group relative flex rounded-lg p-1"
                                >
                                  {credit[index] + "%"}
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                  {/* <a
                    data-tip
                    data-for="grade-tooltip"
                    className="cursor-pointer"
                  >
                    <div className="flex items-center justify-center gap-1">
                      <span>How is my grade calculated?</span>
                      <QuestionMarkCircleIcon className="h-5 w-5 text-gray-500" />
                    </div>
                  </a>
                  <ReactTooltip id="grade-tooltip" effect="solid">
                    <div className="grid grid-cols-2 gap-x-4">
                      <div key="seconds" className="font-bold">
                        Seconds
                      </div>
                      <div key="credit" className="font-bold">
                        Grade
                      </div>
                      {seconds.map((el: number, index: number) => (
                        <>
                          <div key={el}>{el}</div>
                          <div key={credit[index] + "%"}>
                            {credit[index] + "%"}
                          </div>
                        </>
                      ))}
                    </div>
                  </ReactTooltip> */}
                </div>
              </div>
              <div className="mt-6 flex justify-between gap-4">
                <div className="basis-1/3 text-left" role="timer">
                  Current Time: {secondsPassed.toFixed(1)}
                </div>
                <div className="flex basis-2/3 justify-evenly gap-4">
                  <div>
                    Best Time: {bestTime !== 0 ? bestTime.toFixed(1) : "---"}
                  </div>
                  <div>
                    Score: {score}/{required}
                  </div>
                </div>
              </div>
            </>
          )}
          <div className={clsx(Number(seconds[0]) !== 0 && "mt-6")}>
            <button
              type="button"
              className="rounded-md bg-dm-alt-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-[#8ccae2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dm-alt-blue"
              onClick={handleStartStop}
              disabled={checkSession.isLoading || status === "loading"}
              aria-label="Start and stop button"
            >
              {showStart ? "Start" : "Stop"}
            </button>
            {!hasTimeLimit && (
              <span className="ml-6">
                Score: {score}/{required}
              </span>
            )}
          </div>
        </div>
        {hasTimeLimit && (
          <div role="alert" className="sr-only">
            {timerText.current}
          </div>
        )}
        {problems !== null &&
          problemIndex !== null &&
          problemIndex < problems.length && (
            <TimedProblem
              key={problems[problemIndex].id}
              problem={problems[problemIndex]}
              handleCheckAnswer={handleCheckAnswer}
              answer={answer}
              correctAnswer={correctAnswer}
              solutionText={solutionText}
              btnsDisabled={showStart}
              isComplete={isComplete}
              checkAnswer={checkAnswer}
              score={score}
              required={required}
            />
          )}
        {isComplete && scorePercentage !== null && record !== null && (
          <>
            <div className="mt-6 rounded-md bg-green-100 p-4 text-green-800">
              <div className="text-center">
                {hasTimeLimit
                  ? `Your best time is ${record.toFixed(1)} seconds.`
                  : ""}{" "}
                Your grade on this skill is now {scorePercentage}%.
              </div>
              {scorePercentage !== 100 && (
                <div className="mt-2 text-center">
                  Keep trying for 100% by getting {required}{" "}
                  {required === 1 ? "question" : "questions"} correct in less
                  than {seconds[0]} seconds.
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}

// /* JSX for each problem */
function TimedProblem({
  problem,
  answer,
  handleCheckAnswer,
  correctAnswer,
  solutionText,
  btnsDisabled,
  isComplete,
  checkAnswer,
  score,
  required,
}: {
  problem: any;
  answer?: number;
  handleCheckAnswer: (index: number) => void;
  correctAnswer?: number;
  solutionText?: string[];
  btnsDisabled: boolean;
  isComplete: boolean;
  checkAnswer: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    string,
    unknown
  >;
  score: number;
  required: number;
}): JSX.Element {
  const {
    question,
    questionText,
    choices,
    questionMathJax,
    choicesMathJax,
    questionSize,
    choicesSize,
    showImage,
    showImage2,
    script,
    scriptInput,
    script2,
    scriptInput2,
  } = problem;
  const problemRef = useRef<HTMLDivElement>(null);
  const questionGraphRef = useRef<HTMLDivElement>(null);
  const solutionGraphRef = useRef<HTMLDivElement>(null);
  const scriptFunc2 = useRef<any>(null);
  const katexRefs = useRef<Map<string, any> | null>(null);

  const showSolution = correctAnswer !== undefined;

  const refCallback = (node: any, key: string) => {
    if (!katexRefs.current) katexRefs.current = new Map();
    const map = katexRefs.current;
    if (node) {
      map.set(key, node);
    } else {
      map.delete(key);
    }
  };

  const handleResize = () => {
    const elementMap = katexRefs.current ? katexRefs.current : new Map();
    elementMap.forEach((value: any) => {
      resizeKatexLine(value.element, value.size);
    });
  };

  const resizeDebounce = debounce(handleResize, 150);

  /* Render the math */
  useEffect(() => {
    if (problemRef.current) {
      renderMathInElement(problemRef.current, { colorIsTextColor: true });
    }
    const resizeNeeded = problemRef.current?.querySelector(".katex") !== null;
    if (resizeNeeded) {
      handleResize();
      window.addEventListener("resize", resizeDebounce);
    }
    return () => {
      if (resizeNeeded) window.removeEventListener("resize", resizeDebounce);
    };
  }, [problem, solutionText]);

  /* Render graphs on the question page */
  useEffect(() => {
    if (questionGraphRef.current && showImage && script) {
      scriptInput["graphid1"] = "graph-question";

      if (script2) {
        // unique to inequalitiesFromGraphPlane module
        // ensure that the graph variable exists within eval scope for the functions below, as script and script2 both access (and modify) that common variable
        let graph;
        const parameterizedScript = script.replace(
          /^\s*function\s*\(\s*\)\s*\{/,
          `(scriptInputs) => { let { ${Object.keys(
            scriptInput
          )} } = scriptInputs;`
        );
        const parameterizedScript2 = script2.replace(
          /^\s*function\s*\(\s*\)\s*\{/,
          `(scriptInputs) => { let { ${Object.keys(
            scriptInput2
          )} } = scriptInputs;`
        );
        const newScript = eval(parameterizedScript);
        const newScript2 = eval(parameterizedScript2);
        scriptFunc2.current = newScript2;
        newScript(scriptInput);
      } else {
        const newScript = eval(
          script.replace(
            /^\s*function\s*\(\s*\)\s*\{/,
            `(scriptInputs) => { let { ${Object.keys(
              scriptInput
            )} } = scriptInputs;`
          )
        );
        newScript(scriptInput);
      }
      // remove col-sm-9, an automatically added class to DeltaGraph objects
      const elements = document.querySelectorAll("div.canv-div-wrapper");
      elements.forEach((el: Element) => {
        el.classList.remove("col-sm-9");
      });
    }
  }, [showImage, script, scriptInput, script2, scriptInput2]);

  /* Render graphs if a student is incorrect */
  useEffect(() => {
    // if script2 exists, run the script2 function
    if (correctAnswer !== undefined && scriptFunc2.current) {
      scriptFunc2.current(scriptInput2);
    } else if (solutionGraphRef.current && showImage2 && script) {
      scriptInput["graphid2"] = "graph-solution";
      const newScript = eval(
        script.replace(
          /^\s*function\s*\(\s*\)\s*\{/,
          `(scriptInputs) => { let { ${Object.keys(
            scriptInput
          )} } = scriptInputs;`
        )
      );
      newScript(scriptInput);
      // remove col-sm-9, an automatically added class to DeltaGraph objects
      const elements = document.querySelectorAll("div.canv-div-wrapper");
      elements.forEach((el: Element) => {
        el.classList.remove("col-sm-9");
      });
    }
  }, [correctAnswer, showImage2, script]);

  /* Adds event listeners to detect keyboard shortcuts */
  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      const answerMap = generateAnswerMap(choices.length);
      if (answerMap[e.code] !== undefined) {
        handleCheckAnswer(answerMap[e.code]);
      }
    };
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleCheckAnswer, choices]);

  const generateAnswerMap = (numChoices: number) => {
    const map: Record<string, number> = {};
    for (let i = 0; i < numChoices; i++) {
      map["Digit" + (i + 1)] = i;
      map["Numpad" + (i + 1)] = i;
    }
    return map;
  };

  /* Returns an array of subarrays with answer choices and corresponding keys to press */
  const generateKeyboardShortcutArr = (numChoices: number) => {
    const arr: [string, number][] = [];
    const words: string[] = ["first", "second", "third", "fourth"];
    for (let i = 1; i <= numChoices; i++) {
      arr.push([words[i - 1], i]);
    }
    return arr;
  };

  const generateAriaQuestionText = () => {
    const textArr: Array<string> = [];
    textArr.push(`Your current score is: ${score} out of ${required}.`);
    if (questionText) textArr.push(questionText);
    if (question) {
      let ariaQuestion = question;
      if (questionMathJax) {
        try {
          ariaQuestion = window.renderA11yString(question.toString());
        } catch (e) {
          console.log("Error", e);
        }
      }
      textArr.push(ariaQuestion);
    }
    textArr.push("The answer choices are:");
    choices.forEach((choice: string, index: number) => {
      let ariaChoice = choice;
      if (questionMathJax) {
        try {
          ariaChoice = window.renderA11yString(choice.toString());
        } catch (e) {
          console.log("Error", e);
        }
      }
      textArr.push(ariaChoice + ",");
      textArr.push(`press ${index + 1} to select.`);
    });
    return textArr.join(" ");
  };

  const generateAriaSolutionText = () => {
    const textArr: Array<string> = [];
    textArr.push(`Your score is: ${score} out of ${required}.`);
    if (isComplete) {
      textArr.push("You have successfully answered all required problems.");
    } else if (
      answer !== undefined &&
      correctAnswer !== undefined &&
      correctAnswer !== answer
    ) {
      textArr.push("Your answer was:");
      let ariaAnswer = choices[answer];
      if (questionMathJax) {
        try {
          ariaAnswer = window.renderA11yString(choices[answer].toString());
        } catch (e) {
          console.log("Error", e);
        }
      }
      textArr.push(ariaAnswer);
      textArr.push("The correct answer was:");
      let ariaCorrectAnswer = choices[correctAnswer];
      if (questionMathJax) {
        try {
          ariaCorrectAnswer = window.renderA11yString(
            choices[correctAnswer].toString()
          );
        } catch (e) {
          console.log("Error", e);
        }
      }
      textArr.push(ariaCorrectAnswer);
    } else {
      return "";
    }
    return textArr.join(" ");
  };

  return (
    <>
      <div ref={problemRef} className="problem-body timed-body">
        <div className="timed-question-area">
          <div className="sr-only" aria-live="assertive" aria-atomic={true}>
            {generateAriaQuestionText()}
          </div>
          {questionText && <div className="mt-6">{questionText}</div>}
          {question && (
            <div
              className="mt-6"
              style={{ fontSize: questionSize }}
              ref={(node: any) =>
                refCallback({ element: node, size: questionSize }, "question")
              }
            >
              {questionMathJax ? "\\[" + question + "\\]" : question}
            </div>
          )}
          {showImage && (
            <div ref={questionGraphRef} className="mt-6">
              <svg id="graph-question"></svg>
            </div>
          )}
        </div>
        <div
          className={clsx(
            "timed-choices-area relative mt-6 grid w-full justify-stretch gap-4",
            choices.length === 4
              ? "grid-cols-1 sm:grid-cols-2 xl:grid-cols-4"
              : `sm:grid-cols-${choices.length} grid-cols-1`
          )}
        >
          {choices.map((choice: string, index: number) => (
            <button
              type="button"
              key={`${choice}: ${index}`}
              style={{ fontSize: choicesSize }}
              className={clsx(
                "rounded-md px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300",
                showSolution && answer === index && "bg-red-100",
                ((showSolution && correctAnswer === index) ||
                  (isComplete && answer === index)) &&
                  "bg-green-100",
                ((!showSolution && !(isComplete && answer === index)) ||
                  (showSolution &&
                    answer !== index &&
                    correctAnswer !== index)) &&
                  "bg-white",
                !btnsDisabled && !checkAnswer.isLoading && "hover:bg-gray-50"
              )}
              onClick={() => handleCheckAnswer(index)}
              disabled={btnsDisabled || checkAnswer.isLoading}
              ref={(node: any) =>
                refCallback(
                  { element: node, size: choicesSize },
                  "ansChoice" + String(index)
                )
              }
            >
              {choicesMathJax ? "\\[" + choice + "\\]" : choice}
            </button>
          ))}
        </div>
        <div className="timed-explanation-area">
          <div className="sr-only" aria-live="assertive" aria-atomic={true}>
            {generateAriaSolutionText()}
          </div>
          {showImage2 && correctAnswer !== undefined && (
            <div ref={solutionGraphRef} className="mt-6">
              <svg id="graph-solution"></svg>
            </div>
          )}
          {solutionText?.map((solutionLine: string, index: number) => {
            // if the solution text is html, render the html directly
            if (solutionLine.substring(0, 1) === "<") {
              return (
                <div
                  key={solutionLine + index}
                  className="mt-[10px] text-center"
                  dangerouslySetInnerHTML={{ __html: solutionLine }}
                  ref={(node: any) =>
                    refCallback({ element: node }, "solution" + String(index))
                  }
                ></div>
              );
            } else {
              // render the solutionLine as math
              return (
                <div
                  className="mt-6 text-center"
                  key={solutionLine + index}
                  ref={(node: any) =>
                    refCallback({ element: node }, "solution" + String(index))
                  }
                >
                  {"\\(" + solutionLine + "\\)"}
                </div>
              );
            }
          })}
        </div>
        <Popover className="relative">
          <Popover.Button className="mt-6 inline-flex items-center gap-x-1 rounded-md text-sm font-semibold leading-6 text-gray-900 ring-gray-200 ring-offset-8 focus:ring-1">
            <span>Keyboard shortcuts</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 flex w-screen max-w-max -translate-x-1/2 px-4 sm:left-1/4">
              <div className="w-screen max-w-xs flex-auto overflow-hidden rounded-xl bg-white text-sm leading-6 shadow-lg">
                <div className="grid grid-cols-1 gap-y-4 p-4">
                  {generateKeyboardShortcutArr(choices.length).map(
                    (el: [string, number]) => (
                      <div key={el[0]}>
                        Press{" "}
                        <span
                          key={el[1]}
                          className="rounded-sm border border-gray-400 bg-gray-100 p-2 font-mono"
                        >
                          {el[1]}
                        </span>{" "}
                        to choose the {el[0]} answer.
                      </div>
                    )
                  )}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </>
  );
}

/* ************ */
/* Custom Hooks */
/* ************ */

/* Custom Hook for submitting the timed problem */
// TODO: return type for endpoint from backend!
const useSubmitTimedProblem = (token: string | null) => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/student/submitTimedProblem", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("timedProblem");
      },
      // TODO: error handling!
      onError: (error) => {
        console.log("Error in submitTimedProblem:", error);
        queryClient.invalidateQueries("timedProblem");
      },
    }
  );
};

/* Custom Hook for checking the answer */
// TODO: return type for endpoint from backend!
const useCheckAnswer = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: string) => {
      return axios.post(deltamathServerlessAPI() + "/checkTimedAnswer", body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    {
      onSuccess: (response) => {
        if (
          response.data.ans !== undefined ||
          response.data.stopped !== undefined
        ) {
          queryClient.invalidateQueries("timedProblem");
        }
      },
      onError: (error) => {
        console.log("Error in checkTimedAnswer:", error);
        queryClient.invalidateQueries("timedProblem");
      },
    }
  );
};

/* Custom Hook for checking the validity of the timed module session */
// TODO: return type for endpoint from backend!
const useCheckSession = (token: string | null) => {
  const queryClient = useQueryClient();

  return useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/student/checkTimedSession", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: (response) => {
        // invalidate the queries for timed problem to get a fresh token
        if (!response.data.valid) {
          queryClient.invalidateQueries("timedProblem");
        }
      },
      onError: (error) => {
        console.log("Error in checkTimedSession:", error);
        queryClient.invalidateQueries("timedProblem");
      },
    }
  );
};

/* Custom Hook to query for the timed problem data */
// TODO: return type for endpoint from backend!
const useTimedModuleQuery = (solveSkill: any) => {
  return useDMQuery({
    path: `/student/timedProblem/${solveSkill.ta._id}`,
    cacheKey: [
      "timedProblem",
      solveSkill.ta._id,
      solveSkill.ta.skillName,
      solveSkill.ta.last_edit,
    ],
    params: {
      sk: solveSkill.ta.skillName,
      last_edit: solveSkill.ta.last_edit,
    },
    queryOptions: {
      refetchOnWindowFocus: false,
    },
  });
};
