import { useContext, useEffect, useRef, useState } from "react";
import { Transition } from "@headlessui/react";
import axios from "axios";
import ReactPlayer from "react-player";
import { useMutation } from "react-query";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { SkillDataType } from "../_constants";
import { skillDataDisplay } from "../utils";
import { useUserContext } from "../../shared/contexts/UserContext";
import jwt_decode from "jwt-decode";
import { useDMQuery } from "../../utils";
import StudentSectionsContext from "../_context/StudentSectionsContext";

const HelpVideo = ({ solveSkill }: { solveSkill: any }): JSX.Element => {
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const { dmAssignmentData, setDmAssignmentData, activeSection } = useContext(
    StudentSectionsContext
  );
  const token = userContext.getJWT();
  const videoRef = useRef<ReactPlayer>(null);
  const skillData = skillDataDisplay(solveSkill.ta.skillName, solveSkill);

  const decodedJwt: any = token ? jwt_decode(token) : undefined;

  const [videoUrl, setVideoUrl] = useState<string>("");
  const [subtitleUrl, setSubtitleUrl] = useState<string>("");

  const [restrictVideo, setRestrictVideo] = useState<boolean>(false);

  const [intervalRef, setIntervalRef] = useState<any>();
  const saRef = useRef<IStudentAssignment>(solveSkill.sa);
  const [sk, setSk] = useState<string>(solveSkill.ta.skillName);
  const [seeking, setSeeking] = useState<boolean>(false);
  const [played, setPlayed] = useState<number>(0);
  const [videoDuration, setVideoDuration] = useState<number>(0);
  const [timeStarted, setTimeStarted] = useState<number>();
  const [trackerSpan, setTrackerSpan] = useState<any>([
    { width: "100%", color: "indianred" },
  ]);
  const [grade, setGrade] = useState<number>();

  useEffect(() => {
    if (sk.substring(0, 6) === "custom") {
      setSk(sk.substring(7));
    }
  }, []);

  useEffect(() => {
    return () => {
      handlePause();
    };
  }, [timeStarted]);

  useEffect(() => {
    solveSkill.sa.video_data &&
      setTrackerSpan(getPercentageSpan(solveSkill.sa.video_data[sk]));
    solveSkill.sa.video_data &&
      setGrade(solveSkill.sa?.video_data[sk]?.grade || 0);
  }, [sk]);

  const { refetch, error } = useDMQuery({
    path: `/student/checkLock/${solveSkill.ta._id}`,
    method: "post",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      retry: false,
    },
  });

  const getCookies = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/get_cloudfront_cookies", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        let skill = solveSkill?.ta?.skillName;
        if (skill.substring(0, 6) === "custom") skill = skill.substring(7);
        setSk(skill);

        if (skillData.type.indexOf("youtube") !== -1) {
          setVideoUrl(getVideoUrl({ skillData: skillData, skill: sk }).url);
          setSubtitleUrl(
            getVideoUrl({ skillData: skillData, skill: sk }).subtitleUrl
          );
        } else {
          const domain =
            process.env.REACT_APP_API_URL === "https://www.deltamath.com/api"
              ? "com"
              : "io";
          const root = `https://video.deltamath.${domain}`;
          setVideoUrl(`${root}/${skill}/Default/HLS/${skill}.m3u8`);
          setSubtitleUrl(`${root}/captions/${skill}.mp4.vtt`);
        }
      },
      onError: () => {
        console.log("error");
      },
    }
  );

  useEffect(() => {
    getCookies.mutate(JSON.stringify({}));
  }, [solveSkill]);

  const updateVideoData = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/student/updateVideoProgress", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess(data: any) {
        /**
         * The old endpoint, /update_student_video_progress returned the updated assignment object, which was then used
         * to set the client side variable, thus making sure the values were in sync after each update.  They should
         * be the same already, but this seems like a good additional safety measure to include.
         */
        if (data.data.assignment) {
          /** update existing ref of student assignment data to match returned values */
          saRef.current = {
            ...saRef.current,
            grade: data.data.assignment.grade,
            complete: data.data.assignment.complete,
            video_data: {
              ...saRef.current.video_data,
              [sk]: {
                ...saRef?.current?.video_data?.[sk],
                ...data.data.assignment.video_data[sk],
                // grade:   Math.round(num / denom * 100),
              },
            },
          };

          /** update dmAssignment data to reflect new video progress data */
          dmAssignmentData[activeSection].filter(
            (assignment: any, index: number) => {
              if (assignment.ta._id === solveSkill.ta._id) {
                const assignmentObj = { ...dmAssignmentData };
                assignmentObj[activeSection][index].sa = {
                  ...assignmentObj[activeSection][index].sa,
                  ...saRef.current,
                };
                setDmAssignmentData({ ...assignmentObj });
              }
            }
          );
        }
        if (data?.data?.message) {
          console.log({ message: data.data.message });
          toastContext.addToast({
            status: "Error",
            message: "There was an issue saving your video progress.",
          });
        }
      },
    }
  );

  /**
   * update the video tracker with boolean values as the video is watched
   */
  const vidInterval = () => {
    if (
      saRef.current.video_data[sk].tracker !== undefined &&
      videoRef.current
    ) {
      const now = Math.round(videoRef.current.getCurrentTime() / 5) * 5;

      const newTracker: { [key: number]: boolean } = {
        ...saRef.current.video_data[sk].tracker,
        [now]: true,
      };

      saRef.current = {
        ...saRef.current,
        video_data: {
          ...saRef.current.video_data,
          [sk]: {
            ...saRef.current.video_data[sk],
            tracker: { ...newTracker },
          },
        },
      };
      setGrade(computeGradeFromTracker(newTracker));
      setTrackerSpan(getPercentageSpan(saRef.current.video_data[sk]));
    }
  };

  const handlePause = () => {
    if (!timeStarted) {
      return;
    }

    clearInterval(intervalRef);
    let denom = 0;
    let num = 0;

    if (!saRef.current.video_data) {
      saRef.current = {
        ...saRef.current,
        video_data: {
          [sk]: {
            grade: 0,
          },
        },
      };
    } else if (!saRef.current.video_data[sk]) {
      saRef.current = {
        ...saRef.current,
        video_data: {
          [sk]: {
            grade: 0,
          },
        },
      };
    }

    if (!saRef.current.video_data[sk]) {
      saRef.current.video_data[sk] = { grade: 0 };
    }
    if (saRef.current.video_data[sk].grade != 100) {
      for (const i in saRef.current.video_data[sk].tracker) {
        denom++;
        if (saRef.current.video_data[sk].tracker[i]) num++;
      }
    } else {
      num = 1;
      denom = 1;
    }
    const timeWatched = new Date().getTime() - timeStarted; // duration since video started playing
    const videoDataTemp = saRef.current.video_data[sk];
    videoDataTemp.grade = Math.round((num / denom) * 100);
    // this will make the /updateVideoProgress request fail if the grade is 100
    if (videoDataTemp.grade === 100) {
      delete videoDataTemp.tracker;
    }

    const progressPayload = {
      taId: solveSkill.ta._id /** The teacher assignment id. */,
      sk: sk /** The skill code which is indexing the ta.skills[sk] object. */,
      video_sk: sk /** The skill code of the video which is being watched. */,
      tracker:
        videoDataTemp.tracker /** The tracker object which tracks their progress in 5 second intervals. */,
      seconds:
        timeWatched /
        1000 /** The number of seconds watched **since the last post was made**. */,
      duration: videoDuration /** The total duration of the video. */,

      last_edit: timeStarted,
    };

    if (timeWatched >= 1000) {
      // just do this where is IS NaN: example: custom_averageRateOfChange
      const duration = videoDuration;
      const seconds = timeWatched / 1000;
      // let original_sk;
      // if (isNaN(parseInt(sk.substring(6)))) {
      //   original_sk = sk.substring(7);
      // } else {
      //   original_sk = sk;
      // }
      // progressPayload.video_sk = original_sk;
      progressPayload.seconds = seconds;
      progressPayload.duration = duration;
    }
    updateVideoData.mutate(JSON.stringify(progressPayload));
  };

  const handleSeekMouseDown = (e: any) => {
    setSeeking(true);
  };

  const handleSeekChange = (e: any) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e: any) => {
    setSeeking(false);
    if (videoRef?.current) {
      videoRef.current.seekTo(parseFloat(e.target.value));
    }
  };

  const handleStart = () => {
    setTimeStarted(new Date().getTime());
    if (videoRef.current) {
      setVideoDuration(videoRef.current.getDuration());
    }
  };

  const handleProgress = (state: any) => {
    if (!seeking) {
      setPlayed(state.played);
    }
  };

  useEffect(() => {
    if (error) {
      setRestrictVideo(true);
    }
  }, [error]);

  const handlePlay = () => {
    // If jwt is locked to different taId then we restrict the video
    // by just setting url to undefined in react player
    if (
      decodedJwt &&
      decodedJwt.data &&
      decodedJwt.data.lock &&
      decodedJwt.data.lock.taId !== solveSkill.ta._id
    ) {
      setRestrictVideo(true);
    } else {
      // Refetch on play to check if student is locked again
      refetch();
    }

    setTimeStarted(new Date().getTime());
    const duration = videoRef.current?.getDuration();

    if (!saRef.current || !duration) {
      console.log("no assignment found");
      return;
    }

    if (!saRef.current.video_data) {
      saRef.current = {
        ...saRef.current,
        video_data: {
          [sk]: {
            grade: 0,
          },
        },
      };
    } else if (!saRef.current.video_data[sk]) {
      saRef.current = {
        ...saRef.current,
        video_data: {
          [sk]: {
            grade: 0,
          },
        },
      };
    }

    if (!saRef.current.video_data[sk]) {
      saRef.current.video_data[sk] = { grade: 0 };
    }
    if (
      saRef.current.video_data[sk].tracker === undefined &&
      saRef.current.video_data[sk].grade !== 100
    ) {
      const newTracker: { [key: number]: boolean } = { 0: true };
      for (let sec = 5; sec < duration - 5; sec += 5) {
        newTracker[sec] = false;
      }
      saRef.current = {
        ...saRef.current,
        video_data: {
          ...saRef.current.video_data,
          [sk]: {
            ...saRef.current.video_data[sk],
            tracker: { ...newTracker },
          },
        },
      };
    }
    clearInterval(intervalRef);
    setIntervalRef(setInterval(vidInterval, 2000));
  };

  return (
    <Transition
      as="div"
      show={true}
      appear={true}
      enter="transform transition ease-in-out duration-500 sm:duration-700"
      enterFrom="-translate-y-full"
      enterTo="translate-y-0"
      leave="transform transition ease-in-out duration-500 sm:duration-700"
      leaveFrom="translate-y-0"
      leaveTo="-translate-y-full"
    >
      <div id="help-video" className="mt-10 w-1/2 bg-white p-9 shadow-md">
        {videoUrl && subtitleUrl && (
          <ReactPlayer
            ref={videoRef}
            url={!restrictVideo ? videoUrl : undefined}
            controls={true}
            style={{
              margin: "0 auto auto",
              maxWidth: "100%",
            }}
            height="100%"
            onPlay={handlePlay}
            onPause={handlePause}
            onStart={handleStart}
            onProgress={handleProgress}
            config={{
              file: {
                hlsOptions: {
                  forceHLS: true,
                  forceSafariHLS: true,
                  xhrSetup: function (xhr: { withCredentials: boolean }) {
                    xhr.withCredentials = true;
                  },
                },
                attributes: { crossOrigin: "use-credentials" },
                tracks: [
                  {
                    label: "English",
                    kind: "captions",
                    src: subtitleUrl,
                    srcLang: "en",
                    default: false,
                  },
                ],
              },
            }}
          />
        )}
        <input
          type="range"
          value={played}
          min={0}
          max={0.999999}
          step="any"
          onMouseDown={handleSeekMouseDown}
          onChange={handleSeekChange}
          onMouseUp={handleSeekMouseUp}
          className="w-full border-r-2 accent-gray-500"
        />
        {Array.isArray(trackerSpan) && (
          <div className="relative">
            {trackerSpan?.map((section: any, index: number) => {
              return (
                <span
                  key={`${section.width}-${index}`}
                  className="inline-block h-6 w-2"
                  style={{
                    backgroundColor: `${section.color}`,
                    width: `${section.width}`,
                  }}
                ></span>
              );
            })}
            <span className="absolute right-4">{grade}%</span>
          </div>
        )}
      </div>
    </Transition>
  );
};

export default HelpVideo;

/**
 * Compute the grade on the assignment from the provided tracker.
 * @param tracker object indicating which 5 second increments of the video have been watched.
 */
function computeGradeFromTracker(tracker: Record<number, boolean>) {
  let count = 0;
  let countTrue = 0;
  for (const key in tracker) {
    count += 1;
    if (tracker[key]) {
      countTrue += 1;
    }
  }
  return Math.round((countTrue / count) * 100);
}

function getPercentageSpan(data: any) {
  if (!data) {
    console.log("no data");
    return;
  }
  const arr = [];
  const green = "seagreen";
  const red = "indianred";

  if (data.grade == 100) {
    // grade = 100;
    return [{ width: "100%", color: green }];
  }

  let tracker;
  if (data.tracker) tracker = data.tracker;
  else if (data.grade == 0) {
    // this.grade = 0;
    return [{ width: "100%", color: red }];
  } else {
    return;
  }

  const total = Object.keys(tracker).length;
  let x = tracker[0];
  let count = 0;
  let countTrue = 0;
  for (const sec in tracker) {
    if (tracker[sec]) countTrue++;
    if (x == tracker[sec]) {
      count++;
      continue;
    }
    arr.push({ width: (count / total) * 100 + "%", color: x ? green : red });

    x = tracker[sec];
    count = 1;
  }
  arr.push({ width: (count / total) * 100 + "%", color: x ? green : red });
  // const grade = Math.round((countTrue / total) * 100);
  return arr;
}

// should import this from @backend-types
export interface IStudentAssignment {
  _id: number;
  student_id: number | string;
  teacher_id: number;
  teachercode: number;
  term: string;
  complete: number;
  grade: number;
  actuallyComplete: number;
  grades: number[];
  completes: number[];
  test: number;
  newtest: number;
  notes: boolean;
  first_action: number;
  last_action: number;
  last_improvement: number;
  time_completed: number;
  time_started: number;
  end_time: number;
  ended_early: boolean;
  solvedTestProblems: {
    [key: string]: number;
  };
  data: {
    [key: string]: any;
  };
  testdata: {
    [key: string]: number;
  };
  video_data: {
    [key: string]: any;
  };
  order?: string[];
  skills: {
    [key: string]: {
      required: number;
    };
  };
  extra: number;
  additional_minutes: number;
  additional_minutes_started: number;
  solutions_seen?: boolean;
  student_has_courses?: boolean; // can be attached in check_answer... not part of model
  delayAssignment?: boolean;
  lti_grade_passback?: {
    passback_timestamp?: number;
    lti_auth_req?: any;
    failure_timestamp?: number;
    success_timestamp?: number;
    passback_attempts?: number;
    grade?: number;
  };
  google_classroom_grade_passback?: any;
  resource_link_id?: string;
  solutionsAvailable?: boolean; // tacked on to object before sending to frontend
  sid: any;
}

const getVideoUrl = ({
  skillData,
  skill,
}: {
  skillData: SkillDataType;
  skill: string;
}) => {
  let url = "";
  let subtitleUrl = "";

  if (skill.substring(0, 6) === "custom") {
    skill = skill.substring(7);
  }
  const domain =
    process.env.REACT_APP_API_URL === "https://www.deltamath.com/api"
      ? "com"
      : "io";

  if (skillData.type === "youtube_video") {
    url = `https://www.youtube.com/watch?v=${skillData.video}`;
  } else if (skillData.type === "dm_video") {
    const root = `https://video.deltamath.${domain}`;
    url = `${root}/${skill}/Default/HLS/${skill}.m3u8`;
    subtitleUrl = `${root}/captions/${skill}.mp4.vtt`;
  }

  return { url, subtitleUrl };
};

const Duration = ({ seconds }: { seconds: number }) => {
  return <time dateTime={`P${Math.round(seconds)}S`}>{format(seconds)}</time>;
};

function format(seconds: number) {
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = pad(date.getUTCSeconds());
  if (hh) {
    return `${hh}:${pad(mm)}:${ss}`;
  }
  return `${mm}:${ss}`;
}

function pad(val: any) {
  return ("0" + val).slice(-2);
}
