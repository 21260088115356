import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import clsx from "clsx";
import { useRef, useState } from "react";
import { Bubble, getElementsAtEvent, getElementAtEvent } from "react-chartjs-2";
import { GraphDataInterface } from "./DisplayPerformanceGraphs";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const DATA = [
  {
    x: -10,
    y: 0,
  },
  {
    x: 0,
    y: 10,
  },
  {
    x: 10,
    y: 5,
  },
  {
    x: 0.5,
    y: 5.5,
  },
];

export const PERFORMANCE_DATA: GraphDataInterface[] = [
  {
    label: "Sep 2022",
    data: { flu: 10, perc: 10, problemsPerStudent: 300 },
  },
  {
    label: "Oct 2022",
    data: { flu: 10, perc: 10, problemsPerStudent: 300 },
  },
  {
    label: "Dec 2022",
    data: { flu: 16, perc: 60, problemsPerStudent: 300 },
  },
  {
    label: "Jan 2023",
    data: { flu: 18, perc: 85, problemsPerStudent: 300 },
  },
  {
    label: "Feb 2023",
    data: { flu: 15, perc: 20, problemsPerStudent: 500 },
  },
  {
    label: "Mar 2023",
    data: { flu: 20, perc: 99, problemsPerStudent: 50 },
  },
  {
    label: "Apr 2023",
    data: { flu: 8, perc: 50, problemsPerStudent: 75 },
  },
  {
    label: "May 2023",
    data: { flu: 13, perc: 30, problemsPerStudent: 275 },
  },
  {
    label: "Jun 2023",
    data: { flu: 0, perc: 28, problemsPerStudent: 50 },
  },
];

const colorSteps = [
  "rgb(255, 0, 0)",
  "rgb(255, 17, 0)",
  "rgb(255, 34, 0)",
  "rgb(255, 51, 0)",
  "rgb(255, 68, 0)",
  "rgb(255, 85, 0)",
  "rgb(255, 102, 0)",
  "rgb(255, 119, 0)",
  "rgb(255, 136, 0)",
  "rgb(255, 153, 0)",
  "rgb(255, 170, 0)",
  "rgb(255, 187, 0)",
  "rgb(255, 204, 0)",
  "rgb(255, 221, 0)",
  "rgb(255, 238, 0)",
  "rgb(255, 255, 0)",
  "rgb(204, 255, 0)",
  "rgb(153, 255, 0)",
  "rgb(102, 255, 0)",
  "rgb(51, 255, 0)",
  "rgb(0, 255, 0)",
];

// [red, orange, amber, dmyellow, lime]
const tailwindColors = ["#dc2626", "#EA580C", "#D97706", "#FFEB3B", "#65A30D"];

const getColor = (value: any, quotient: any) => {
  if (value === 0 || quotient === 0) return "#dc2626";
  let index = Math.floor((value / quotient) * tailwindColors.length);
  if (index > 4) index = index - 1;
  return tailwindColors[index];
};

const ScatterPlot = ({
  title,
  data,
  yAxisVariable,
  onClick,
}: {
  title: string;
  data: GraphDataInterface[];
  yAxisVariable: string;
  onClick?: (val: string[]) => void;
}) => {
  const chartRef = useRef();
  const [legendOpen, setLegendOpen] = useState(false);
  const [multiplePoints, setMultiplePoints] = useState(false);

  // ideally the title has an info icon that expands the graph legend
  const CreateTitle = (title: string) => {
    return (
      <div>
        <h3>{title}</h3>
        <QuestionMarkCircleIcon className={"mr-3 h-6 w-6 flex-shrink-0"} />
      </div>
    );
  };
  const chartData = {
    data: {
      labels: data.map((item: any) => item.label),
      datasets: [
        {
          label: title,
          data: data.map((entry: any) => ({
            x: entry.data.problemsPerStudent,
            y: entry.data[yAxisVariable],
            r: 7, //radius (size) of each point
          })),
          backgroundColor: data.map((entry: any) => {
            return getColor(
              entry.data[yAxisVariable],
              yAxisVariable === "flu" ? 20 : 100
            );
          }),
        },
      ],
    },
  };

  const options = {
    aspectRatio: 3,
    scales: {
      x: {
        // offset: true,
        min: 0,
        title: {
          display: true,
          text: "Number of Data Points per Student",
        },
        layout: {
          padding: 24,
        },
      },
      y: {
        offset: true,
        title: {
          display: true,
          text: yAxisVariable === "flu" ? "Fluency" : "Percentage",
        },
        min: 0,
        max: yAxisVariable === "flu" ? 20 : 100,
      },
    },
    plugins: {
      title: {
        display: true,
        text: title,
      },
      legend: {
        display: false,
      },
      autocolors: true,
      tooltip: {
        callbacks: {
          label: (context: { dataIndex: number; parsed: { y: any } }) => [
            data[context.dataIndex].label,
            clsx(
              yAxisVariable === "flu"
                ? `Fluency: ${context.parsed.y}`
                : `${context.parsed.y}%`
            ),
          ],
        },
      },
    },
    responsive: true,
    layout: {
      // padding: {
      //   left: 0,
      //   right: 0,
      //   top: 0,
      //   bottom: 0,
      // },
      autoPadding: true,
    },
  };

  const handleInfoClick = () => {
    setLegendOpen(!legendOpen);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
      className="chart-container mx-auto"
    >
      {chartData?.data && (
        <>
          <Bubble
            id="chart"
            ref={chartRef}
            data={chartData.data}
            options={options}
            onClick={(e) => {
              if (
                chartRef.current &&
                getElementsAtEvent(chartRef.current, e).length
              ) {
                const activeElement = getElementAtEvent(chartRef.current, e);

                const pointData = {
                  x: chartData.data.datasets[activeElement[0].datasetIndex]
                    .data[activeElement[0].index].x,
                  y: chartData.data.datasets[activeElement[0].datasetIndex]
                    .data[activeElement[0].index].y,
                };

                const selectedPointArr = checkForDuplicate(
                  pointData,
                  chartData.data.datasets[0].data
                );

                const skArr = selectedPointArr.map(
                  (index: number) => chartData.data.labels[index]
                );
                if (onClick) onClick(skArr);
              }
            }}
          />
        </>
      )}
    </div>
  );
};

export default ScatterPlot;

const checkForDuplicate = (
  pointData: { x: number; y: number },
  dataset: { x: number; y: number }[]
) => {
  const indices = [];
  for (let i = 0; i < dataset.length; i++) {
    if (dataset[i].x === pointData.x && dataset[i].y === pointData.y) {
      indices.push(i);
    }
  }
  return indices;
};
