import clsx from "clsx";
import { Combobox } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import { Key, ReactChild, ReactFragment, ReactPortal, useState } from "react";

export const DeltaMathSearchableComboBox = ({
  options,
  label,
  selectedValue,
  setValue,
}: {
  options: any;
  label?: string;
  selectedValue: any;
  setValue: any;
}) => {
  const [query, setQuery] = useState("");
  const filteredOptions =
    query === ""
      ? options
      : options.filter((the_option: { name: string }) => {
          return the_option.name.toLowerCase().includes(query.toLowerCase());
        });
  return (
    <Combobox
      as="div"
      className="grid grid-cols-3 gap-6"
      value={selectedValue}
      onChange={setValue}
    >
      <div>
        {label && (
          <Combobox.Label className="block text-sm font-medium text-gray-700">
            {label}
          </Combobox.Label>
        )}
        <div className="relative mt-1">
          <Combobox.Input
            className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-dm-blue focus:outline-none focus:ring-1 focus:ring-dm-blue sm:text-sm"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(option: any) => option?.name}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <SelectorIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {filteredOptions.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredOptions.map(
                (option: {
                  id: Key | null | undefined;
                  name:
                    | boolean
                    | ReactChild
                    | ReactFragment
                    | ReactPortal
                    | null
                    | undefined;
                }) => (
                  <Combobox.Option
                    key={option.id}
                    value={option}
                    className={({ active }) =>
                      clsx(
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                        active ? "bg-dm-blue text-white" : "text-gray-900"
                      )
                    }
                  >
                    <span className={clsx("block truncate")}>
                      {option.name}
                    </span>
                    {({ active, selected }) => (
                      <>
                        <span
                          className={clsx(
                            "block truncate",
                            selected && "font-semibold"
                          )}
                        >
                          {option.name}
                        </span>

                        {selected && (
                          <span
                            className={clsx(
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                              active ? "text-white" : "text-dm-blue"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        )}
                      </>
                    )}
                  </Combobox.Option>
                )
              )}
            </Combobox.Options>
          )}
        </div>
      </div>
    </Combobox>
  );
};
