export default function WaveIcon({
  strokeColor = "#2F52E9", // dm-brand-blue-500
}: {
  classes?: string;
  strokeColor?: string;
}): JSX.Element {
  return (
    <svg viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1085_9034)">
        <path
          d="M30.9849 20.6292L24.7349 9.80392C24.4033 9.22971 23.8573 8.81071 23.2168 8.6391C22.5764 8.4675 21.894 8.55733 21.3198 8.88885C20.7456 9.22038 20.3266 9.76642 20.155 10.4069C19.9834 11.0473 20.0732 11.7297 20.4047 12.3039L25.8214 21.6859"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.4047 12.3039L18.738 9.41714C18.5739 9.13282 18.3553 8.88361 18.0949 8.68376C17.8344 8.4839 17.5371 8.3373 17.22 8.25232C16.9029 8.16735 16.5722 8.14567 16.2467 8.18853C15.9212 8.23138 15.6073 8.33792 15.323 8.50208C15.0387 8.66623 14.7894 8.88477 14.5896 9.14523C14.3897 9.4057 14.2431 9.70297 14.1582 10.0201C14.0732 10.3372 14.0515 10.668 14.0944 10.9935C14.1372 11.319 14.2438 11.6328 14.4079 11.9171L21.4912 24.1858"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0745 14.8039C15.7429 14.2297 15.1969 13.8107 14.5564 13.6391C13.916 13.4675 13.2336 13.5573 12.6594 13.8889C12.0852 14.2204 11.6662 14.7664 11.4946 15.4069C11.323 16.0473 11.4128 16.7297 11.7443 17.3039L17.161 26.6859"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4111 20.1907C13.0796 19.6165 12.5336 19.1975 11.8931 19.0259C11.2527 18.8543 10.5703 18.9441 9.99608 19.2756C9.42186 19.6071 9.00287 20.1532 8.83126 20.7936C8.65965 21.4341 8.74949 22.1165 9.08101 22.6907L16.1643 34.9594C17.4904 37.2562 19.6746 38.9322 22.2364 39.6186C24.7982 40.3051 27.5278 39.9457 29.8246 38.6196L32.7114 36.953L32.4111 37.1263C33.8455 36.2985 35.0518 35.1274 35.9218 33.7182C36.7917 32.309 37.2981 30.7058 37.3953 29.0525C37.4068 28.8538 37.4178 28.655 37.4282 28.4562C37.4794 27.5048 37.4691 23.8369 37.3978 17.4502C37.3906 16.7991 37.1296 16.1766 36.6705 15.715C36.2113 15.2533 35.5902 14.9891 34.9392 14.9783C34.2456 14.9667 33.5681 15.1871 33.0141 15.6045C32.4601 16.022 32.0615 16.6126 31.8816 17.2825L30.9849 20.6292"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.6985 12.1888V12.1888C41.8027 13.3894 43.0808 15.6457 43.0287 18.0678L43.0263 18.1789"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M42.3743 8.29704V8.29704C45.5701 10.1825 47.5315 13.6166 47.5316 17.3272L47.5316 17.5721"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M11.1785 36.1888V36.1888C9.07422 34.9881 7.79613 32.7318 7.84825 30.3097L7.85064 30.1986"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M8.50269 40.0805V40.0805C5.30681 38.1951 3.34549 34.7609 3.3454 31.0503L3.34539 30.8054"
          stroke={strokeColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1085_9034">
          <rect
            width="48"
            height="48"
            fill="white"
            transform="matrix(1 0 0 -1 0.5 48)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
