import axios from "axios";
import React, { useState, Fragment, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { GetDeletedAssignments } from "../api/index";

import { deltamathAPI, getDatesArray } from "../utils";
import { DeltaMathSearchableTable } from "../../shared/DeltaMathSearchableTable";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";

interface TeacherSearchPayload {
  first: string;
  last: string;
  email: string;
  teachercode: string;
  schoolinfo_name: string;
  schoolinfo_city: string;
  schoolinfo_state: string;
  schoolinfo_zip: string;
  annual_logs: number;
  all_time_logs: number;
  deltamath_plus_subscription: string;
  deltamath_plus_subscription_auth_code: string;
  schoolPlus: [];
  has_integral: boolean;
}
interface APIPayload {
  first?: string;
  last?: string;
  email?: string;
  teachercode?: string;
  schoolinfo?: {
    name?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  deltamath_plus_subscription: string;
  deltamath_plus_subscription_auth_code: string;
}

interface MenuOptionsInterface {
  downgradeToFree: boolean;
  downgradeToPlus: boolean;
  upgradeToPlus: boolean;
  upgradeToIntegral: boolean;
  resetIpAddressList: boolean;
  undeleteAssignment: boolean;
  mergeAnotherAccount: boolean;
}

const menuReducer = (state: MenuOptionsInterface, action: { type: any }) => {
  switch (action.type) {
    case "reset":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
      };
    case "downgrade-to-free":
      return {
        downgradeToFree: true,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
      };
    case "downgrade-to-plus":
      return {
        downgradeToFree: false,
        downgradeToPlus: true,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
      };
    case "upgrade-to-plus":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: true,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
      };
    case "upgrade-to-integral":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: true,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
      };
    case "reset-ip-address-list":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: true,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
      };
    case "undelete-assignment":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: true,
        mergeAnotherAccount: false,
      };
    case "merge-another-account":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: true,
      };
    default:
      throw new Error();
  }
};

export const Search = () => {
  const toastContext = useDeltaToastContext();

  const token = localStorage.getItem("customer_service_token");
  const currentUnixTime = Math.floor(Date.now() / 1000);
  const october = getDatesArray({})[0];
  const octoberUnix = Math.floor(october.getTime() / 1000.0); // convert to UNIX epoch
  const queryClient = useQueryClient();
  const [viewingTeacher, setViewingTeacher] = useState<TeacherSearchPayload>({
    first: "",
    last: "",
    email: "",
    teachercode: "",
    schoolinfo_name: "",
    schoolinfo_city: "",
    schoolinfo_state: "",
    schoolinfo_zip: "",
    annual_logs: 0,
    all_time_logs: 0,
    deltamath_plus_subscription: "",
    deltamath_plus_subscription_auth_code: "",
    schoolPlus: [],
    has_integral: false,
  });
  const [payload, setPayload] = useState<TeacherSearchPayload>({
    first: "",
    last: "",
    email: "",
    teachercode: "",
    schoolinfo_name: "",
    schoolinfo_city: "",
    schoolinfo_state: "",
    schoolinfo_zip: "",
    annual_logs: 0,
    all_time_logs: 0,
    deltamath_plus_subscription: "",
    deltamath_plus_subscription_auth_code: "",
    schoolPlus: [],
    has_integral: false,
  });

  const restoreDefaults = () => {
    setPayload({
      first: "",
      last: "",
      email: "",
      teachercode: "",
      schoolinfo_name: "",
      schoolinfo_city: "",
      schoolinfo_state: "",
      schoolinfo_zip: "",
      annual_logs: 0,
      all_time_logs: 0,
      deltamath_plus_subscription: "",
      deltamath_plus_subscription_auth_code: "",
      schoolPlus: [],
      has_integral: false,
    });
    setViewingTeacher({
      first: "",
      last: "",
      email: "",
      teachercode: "",
      schoolinfo_name: "",
      schoolinfo_city: "",
      schoolinfo_state: "",
      schoolinfo_zip: "",
      annual_logs: 0,
      all_time_logs: 0,
      deltamath_plus_subscription: "",
      deltamath_plus_subscription_auth_code: "",
      schoolPlus: [],
      has_integral: false,
    });
    data = [];
  };

  const [menuState, dispatch] = React.useReducer(menuReducer, {
    downgradeToFree: false,
    downgradeToPlus: false,
    upgradeToPlus: false,
    upgradeToIntegral: false,
    resetIpAddressList: false,
    undeleteAssignment: false,
    mergeAnotherAccount: false,
  });

  const modifyAccount = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/manage", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("search");
        refetch();
        setViewingTeacher({
          first: "",
          last: "",
          email: "",
          teachercode: "",
          schoolinfo_name: "",
          schoolinfo_city: "",
          schoolinfo_state: "",
          schoolinfo_zip: "",
          annual_logs: 0,
          all_time_logs: 0,
          deltamath_plus_subscription: "",
          deltamath_plus_subscription_auth_code: "",
          schoolPlus: [],
          has_integral: false,
        });

        if (data.data.success) {
          toastContext.addToast({
            status: "Success",
            message: data.data.success,
          });
        }
        if (
          data.data.message &&
          JSON.stringify(data.data.message).includes("token")
        ) {
          toastContext.addToast({
            status: "Success",
            message: data.data.message,
          });
        }
      },
    }
  );

  const [revenue, setRevenue] = useState<number>(95);
  const [expiration, setExpiration] = useState<number>(octoberUnix);

  const getDeleteAssignments = GetDeletedAssignments({
    teacherCode: viewingTeacher.teachercode || "",
  });

  const [assignmentToUndelete, setAssignmentToUndelete] = useState<number>(-1);
  const [newTeacherCode, setNewTeacherCode] = useState("");

  /*eslint-disable prefer-const*/
  let { isLoading, error, data, refetch } = useQuery(
    "search",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: "get-info",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          first: payload?.first,
          last: payload?.last,
          email: payload?.email,
          teachercode: payload?.teachercode,
          city: payload?.schoolinfo_city,
          state: payload?.schoolinfo_state,
          zip: payload?.schoolinfo_zip,
          school: payload?.schoolinfo_name,
          annual_logs: payload?.annual_logs,
          all_time_logs: payload?.annual_logs,
          access_code: payload?.deltamath_plus_subscription_auth_code,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      /**
       * Sort teacher data by number of annual logs (descending)
       */
      Array.isArray(request.data) &&
        request.data.sort(
          (a: { annual_logs: number }, b: { annual_logs: number }) => {
            return b.annual_logs - a.annual_logs;
          }
        );
      /*
       * manager/get-info API returns schoolinfo data as a nested object.
       * This flattens the data into individual fields
       */
      const response =
        Array.isArray(request.data) &&
        request.data.map((d: APIPayload, index) => ({
          ...d,
          row_num: index + 1, //start at 1, not 0
          schoolinfo_name: d?.schoolinfo?.name,
          schoolinfo_city: d?.schoolinfo?.city,
          schoolinfo_state: d?.schoolinfo?.state,
          schoolinfo_zip: d?.schoolinfo?.zip,
        }));
      if (response) {
        return response;
      } else {
        return request.data;
      }
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (data && !Array.isArray(data)) {
      toastContext.addToast({
        status: "Error",
        message: data?.message,
      });
    }
  }, [data]);

  // This is poor error handling for the case that "{}" is returned from the API.
  if (!Array.isArray(data)) {
    data = [];
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    await refetch();
    if (error) {
      console.error(error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "row_num",
        totalWidth: 4,
        render: () => <span>#</span>,
      },
      {
        Header: "First",
        accessor: "first",
        totalLeft: 4,
      },
      {
        Header: "Last",
        accessor: "last",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Teacher Code",
        accessor: "teachercode",
      },
      {
        Header: "School",
        accessor: "schoolinfo_name",
      },
      {
        Header: "City",
        accessor: "schoolinfo_city",
      },
      {
        Header: "State",
        accessor: "schoolinfo_state",
      },
      {
        Header: "Zip",
        accessor: "schoolinfo_zip",
      },
      {
        Header: "License Type",
        accessor: "license_type",
      },
      {
        Header: "License Tier",
        accessor: "license_tier",
      },
      {
        Header: "Expiration",
        accessor: "license_expiration",
      },
      {
        Header: "Access Code",
        accessor: "deltamath_plus_subscription_auth_code",
      },
      {
        id: "schoolPlus",
        Header: "schoolPlus",
        accessor: "schoolPlus",
        Cell: () => <span className="hidden"> </span>,
      },
      {
        Header: "Annual Logs",
        accessor: "annual_logs",
      },
      {
        Header: "All Time Logs",
        accessor: "all_time_logs",
      },
    ],
    []
  );

  const selectTeacher = async (teacher: TeacherSearchPayload) => {
    dispatch({ type: "reset" });
    setViewingTeacher(teacher);
  };

  const modifyTeacher = (action: string, teacher: APIPayload) => {
    switch (action) {
      case "downgrade-to-free":
        dispatch({ type: "downgrade-to-free" });
        break;
      case "downgrade-to-plus":
        dispatch({ type: "downgrade-to-plus" });
        break;
      case "upgrade-to-plus":
        dispatch({ type: "upgrade-to-plus" });
        break;
      case "upgrade-to-integral":
        dispatch({ type: "upgrade-to-integral" });
        break;
      case "reset-ip-address-list":
        dispatch({ type: "reset-ip-address-list" });
        break;
      case "undelete-assignment":
        dispatch({ type: "undelete-assignment" });
        getDeleteAssignments.refetch();
        break;
      case "merge-another-account":
        dispatch({ type: "merge-another-account" });
        break;
    }
  };

  if (error) return <div>{`An error has occurred: ${error}`}</div>;
  return (
    <>
      <div className="px-2 md:px-0">
        <h1 className="py-4 text-2xl font-semibold text-gray-900 md:mx-2">
          Search
        </h1>

        {viewingTeacher && viewingTeacher.teachercode && (
          <div className="inline-flex font-semibold text-gray-900 md:mx-2">
            <div>
              <label className="block" htmlFor="options-menu">
                Viewing Teacher:
              </label>
              <Menu
                id="options-menu"
                as="div"
                className="relative z-50 mb-2 inline-block text-left"
              >
                <div>
                  <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    {viewingTeacher.first} {viewingTeacher.last} (
                    {viewingTeacher.teachercode})
                    <ChevronDownIcon
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {viewingTeacher &&
                        (viewingTeacher.deltamath_plus_subscription ||
                          parseInt(viewingTeacher.deltamath_plus_subscription) >
                            currentUnixTime) && (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={`${
                                  active || menuState.downgradeToFree
                                    ? "block px-4 py-2 text-sm text-indigo-700"
                                    : "block px-4 py-2 text-sm text-gray-700"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  modifyTeacher(
                                    "downgrade-to-free",
                                    viewingTeacher
                                  );
                                }}
                              >
                                Downgrade to Free Account
                              </a>
                            )}
                          </Menu.Item>
                        )}
                      {viewingTeacher && viewingTeacher.has_integral && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={`${
                                active || menuState.downgradeToPlus
                                  ? "block px-4 py-2 text-sm text-indigo-700"
                                  : "block px-4 py-2 text-sm text-gray-700"
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                modifyTeacher(
                                  "downgrade-to-plus",
                                  viewingTeacher
                                );
                              }}
                            >
                              Downgrade to Plus Account
                            </a>
                          )}
                        </Menu.Item>
                      )}
                      {viewingTeacher &&
                        !viewingTeacher.has_integral &&
                        (!viewingTeacher.deltamath_plus_subscription ||
                          parseInt(viewingTeacher.deltamath_plus_subscription) <
                            currentUnixTime) &&
                        !viewingTeacher?.schoolPlus?.length && (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={`${
                                  active || menuState.upgradeToPlus
                                    ? "block px-4 py-2 text-sm text-indigo-700"
                                    : "block px-4 py-2 text-sm text-gray-700"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  modifyTeacher(
                                    "upgrade-to-plus",
                                    viewingTeacher
                                  );
                                }}
                              >
                                Upgrade to Plus Account
                              </a>
                            )}
                          </Menu.Item>
                        )}
                      {viewingTeacher && !viewingTeacher.has_integral && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={`${
                                active || menuState.upgradeToIntegral
                                  ? "block px-4 py-2 text-sm text-indigo-700"
                                  : "block px-4 py-2 text-sm text-gray-700"
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                modifyTeacher(
                                  "upgrade-to-integral",
                                  viewingTeacher
                                );
                              }}
                            >
                              Upgrade to Integral Account
                            </a>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={`${
                              active || menuState.resetIpAddressList
                                ? "block px-4 py-2 text-sm text-indigo-700"
                                : "block px-4 py-2 text-sm text-gray-700"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              modifyTeacher(
                                "reset-ip-address-list",
                                viewingTeacher
                              );
                            }}
                          >
                            Reset IP Address List
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={`${
                              active || menuState.undeleteAssignment
                                ? "block px-4 py-2 text-sm text-indigo-700"
                                : "block px-4 py-2 text-sm text-gray-700"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              modifyTeacher(
                                "undelete-assignment",
                                viewingTeacher
                              );
                            }}
                          >
                            Undelete an Assignment
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={`${
                              active || menuState.mergeAnotherAccount
                                ? "block px-4 py-2 text-sm text-indigo-700"
                                : "block px-4 py-2 text-sm text-gray-700"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              modifyTeacher(
                                "merge-another-account",
                                viewingTeacher
                              );
                            }}
                          >
                            Merge Another Account into this one
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            {menuState.downgradeToFree && (
              <div className="mt-4">
                <button
                  type="submit"
                  id="downgrade-to-free"
                  name="confirm-undelete"
                  className="mb-2 ml-2 mt-0 block justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                  value="Submit"
                  color="secondary"
                  onClick={() => {
                    modifyAccount.mutate(
                      JSON.stringify({
                        customer_service_token: token,
                        type: "remove-plus",
                        teachercode: viewingTeacher.teachercode,
                      })
                    );
                    dispatch({ type: "reset" });
                  }}
                >
                  Downgrade to Free
                </button>
              </div>
            )}
            {menuState.downgradeToPlus && (
              <>
                <div>
                  <label className="ml-2 block" htmlFor="plus-expiration">
                    Expiration
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="plus-expiration"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Expiration Date"
                    value={expiration || 0}
                    onChange={(e) => setExpiration(parseInt(e.target.value))}
                  />
                </div>
                <div>
                  <label className="ml-2 block" htmlFor="plus-revenue">
                    Revenue
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="plus-revenue"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Cost"
                    value={revenue || 0}
                    onChange={(e) => setRevenue(parseInt(e.target.value))}
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    id="downgrade-to-plus"
                    name="confirm-undelete"
                    className="mb-2 ml-2 mt-0 block justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                    value="Submit"
                    color="secondary"
                    onClick={() => {
                      modifyAccount.mutate(
                        JSON.stringify({
                          customer_service_token: token,
                          type: "downgrade-to-plus",
                          teachercode: viewingTeacher.teachercode,
                          expiration: expiration,
                          revenue: revenue,
                        })
                      );
                      dispatch({ type: "reset" });
                    }}
                  >
                    Downgrade to Plus
                  </button>
                </div>
              </>
            )}
            {menuState.upgradeToPlus && (
              <>
                <div>
                  <label className="ml-2 block" htmlFor="plus-expiration">
                    Expiration
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="plus-expiration"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Expiration Date"
                    value={expiration || 0}
                    onChange={(e) => setExpiration(parseInt(e.target.value))}
                  />
                </div>
                <div>
                  <label className="ml-2 block" htmlFor="plus-revenue">
                    Revenue
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="plus-revenue"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Cost"
                    value={revenue || 0}
                    onChange={(e) => setRevenue(parseInt(e.target.value))}
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    name="confirm-undelete"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                    value="Submit"
                    color="secondary"
                    onClick={() => {
                      modifyAccount.mutate(
                        JSON.stringify({
                          customer_service_token: token,
                          type: "add-plus",
                          teachercode: viewingTeacher.teachercode,
                          revenue: revenue,
                          expiration: expiration,
                        })
                      );
                      dispatch({ type: "reset" });
                    }}
                  >
                    Upgrade to Plus
                  </button>
                </div>
              </>
            )}
            {menuState.upgradeToIntegral && (
              <>
                <div>
                  <label className="ml-2 block" htmlFor="integral-expiration">
                    Expiration
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="integral-expiration"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Expiration Date"
                    value={expiration || 0}
                    onChange={(e) => setExpiration(parseInt(e.target.value))}
                  />
                </div>
                <div>
                  <label className="ml-2 block" htmlFor="integral-revenue">
                    Revenue
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="integral-reveue"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Cost"
                    value={revenue || 0}
                    onChange={(e) => setRevenue(parseInt(e.target.value))}
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    name="confirm-undelete"
                    className="mb-2 ml-2 mt-2 block justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                    value="Submit"
                    color="secondary"
                    onClick={() => {
                      modifyAccount.mutate(
                        JSON.stringify({
                          customer_service_token: token,
                          type: "upgrade-to-integral",
                          teachercode: viewingTeacher.teachercode,
                          revenue: revenue,
                          expiration: expiration,
                        })
                      );
                      dispatch({ type: "reset" });
                    }}
                  >
                    Upgrade to Integral
                  </button>
                </div>
              </>
            )}
            {menuState.resetIpAddressList && (
              <>
                <div className="mt-4">
                  <button
                    type="submit"
                    name="confirm-undelete"
                    className="mt-6 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                    value="Submit"
                    color="secondary"
                    disabled={assignmentToUndelete === -1}
                    onClick={() => {
                      modifyAccount.mutate(
                        JSON.stringify({
                          customer_service_token: token,
                          type: "reset-ip",
                          teachercode: viewingTeacher.teachercode,
                        })
                      );
                      dispatch({ type: "reset" });
                    }}
                  >
                    Confirm Remove IP Whitelist
                  </button>
                </div>
              </>
            )}
            {menuState.undeleteAssignment &&
              Array.isArray(getDeleteAssignments.data?.data) && (
                <>
                  <select
                    className="mb-2 ml-2 mt-6 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    onChange={(e) =>
                      setAssignmentToUndelete(parseInt(e.target.value))
                    }
                  >
                    <option value="-1">Select an assignment</option>
                    {getDeleteAssignments.data.data.map((assignment: any) => {
                      return (
                        <option value={assignment._id}>
                          {assignment.name}
                        </option>
                      );
                    })}
                  </select>
                  <span className="relative z-0 mt-4 inline-flex rounded-md">
                    <button
                      type="submit"
                      name="confirm-undelete"
                      className="flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                      value="Submit"
                      color="secondary"
                      disabled={assignmentToUndelete === -1}
                      onClick={() => {
                        modifyAccount.mutate(
                          JSON.stringify({
                            customer_service_token: token,
                            type: "undelete_deleted_assignment",
                            teachercode: viewingTeacher.teachercode,
                            _id: assignmentToUndelete,
                          })
                        );
                        getDeleteAssignments.remove();
                      }}
                    >
                      Confirm Undelete Assignment
                    </button>
                  </span>
                </>
              )}
            {menuState.mergeAnotherAccount && (
              <>
                <div>
                  <label
                    className="ml-2 block"
                    htmlFor="merge-account-expiration"
                  >
                    Teachercode to remove
                  </label>
                  <input
                    type="text"
                    id="merge-account"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="teachercode to be removed (new)"
                    onChange={(e) => setNewTeacherCode(e.target.value)}
                  />
                </div>
                <span className="relative z-0 mt-4 inline-flex rounded-md">
                  <button
                    type="submit"
                    name="confirm-undelete"
                    className="mt-6 flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 sm:m-2"
                    value="Submit"
                    color="secondary"
                    onClick={() => {
                      modifyAccount.mutate(
                        JSON.stringify({
                          customer_service_token: token,
                          type: "merge-accounts",
                          teachercode: viewingTeacher.teachercode,
                          newteachercode: newTeacherCode,
                        })
                      );
                      dispatch({ type: "reset" });
                    }}
                  >
                    Confirm Merge Accounts
                  </button>
                </span>
              </>
            )}
          </div>
        )}

        {Array.isArray(data) && (
          <DeltaMathSearchableTable
            columns={columns}
            data={data}
            currentSearch={payload}
            updateSearch={setPayload}
            handleSubmit={handleSubmit}
            refetch={refetch}
            isLoading={isLoading}
            restoreDefaults={restoreDefaults}
            selectTeacher={selectTeacher}
          />
        )}
      </div>
    </>
  );
};

export default Search;
