import axios from "axios";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import StandardsManage from "./StandardsManage";
import StandardsSearch from "./StandardsSearch";
import {
  CourseStandard,
  SearchResults,
  SkillCodes,
} from "DeltaMathAPI/routers/standards.router/api";
import { deltamathAPI } from "../../utils";
import { handleErr } from "./common/util";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useUserContext } from "../../../shared/contexts/UserContext";

const Standards = () => {
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const params = useParams();
  const navigate = useNavigate();
  const token = userContext.getJWT();
  const [getSearchVal, setSearchVal] = useState<string>("");
  const [getSearchResults, setSearchResults] = useState<
    { searchData: SearchResults; searchVal: string } | undefined
  >(undefined);
  const [getActivated, setActivated] = useState<string>("");
  const [getStateCode, setStateCode] = useState<string>("AL");
  const [getStatus, setStatus] = useState<string>("live");
  const [getStandard, setStandard] = useState<CourseStandard | undefined>(
    undefined
  );

  useEffect(() => {
    // Default redirect
    if (!params.child) {
      navigate(`/manager/standards/manage`, {
        replace: true,
      });
    }
  }, [params]);

  useEffect(() => {
    if (getActivated) {
      navigate(`/manager/standards/${params.child}/${getActivated}`);
    }
  }, [getActivated]);

  // Get the skill code data
  const { data: skillcodes } = useQuery<SkillCodes>(
    `skillcodes`,
    async () => {
      return axios
        .request({
          method: "get",
          url: deltamathAPI() + `/standards/ref/skillcodes`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          toastContext.addToast({ status: "Error", message: handleErr(e) });
        });
    },
    {
      staleTime: 1000 * 60 * 15,
    }
  );

  return (
    <>
      <nav className="grid space-x-4 sm:grid-cols-1 lg:grid-cols-2">
        <button
          onClick={() => navigate(`/manager/standards/manage/${getActivated}`)}
          className={clsx(
            params.child === "manage"
              ? "text-gray-60 border-dm-light-blue"
              : "border-gray-300 text-gray-500 hover:text-gray-700",
            "col-span-1 border-b-2 px-1 py-4 text-center text-sm font-medium text-gray-500 hover:border-gray-700 hover:bg-slate-100 hover:text-dm-blue"
          )}
        >
          Manage
        </button>
        <button
          onClick={() => navigate(`/manager/standards/search`)}
          className={clsx(
            params.child === "search"
              ? "text-gray-60 border-dm-light-blue"
              : "border-gray-300 text-gray-500 hover:text-gray-700",
            "col-span-1 border-b-2 px-1 py-4 text-center text-sm font-medium text-gray-500 hover:border-gray-700 hover:bg-slate-100 hover:text-dm-blue"
          )}
        >
          Search
        </button>
      </nav>
      <div>
        {params && skillcodes && params.child === "manage" && (
          <StandardsManage
            getActivated={getActivated}
            setActivated={setActivated}
            getStateCode={getStateCode}
            setStateCode={setStateCode}
            getStatus={getStatus}
            setStatus={setStatus}
            getStandard={getStandard}
            setStandard={setStandard}
            setSearchResults={setSearchResults}
            skillcodes={skillcodes}
          />
        )}
        {params && skillcodes && params.child === "search" && (
          <StandardsSearch
            getSearchVal={getSearchVal}
            setSearchVal={setSearchVal}
            getSearchResults={getSearchResults}
            setSearchResults={setSearchResults}
            skillcodes={skillcodes}
          />
        )}
      </div>
    </>
  );
};

export default Standards;
