import axios from "axios";
import { useEffect, useState } from "react";
import LoadModule from "./load-module";

const CodeModules = () => {
  const [programmer, setProgrammer] = useState<string>("Please Select");
  const [selectedTitle, setSelectedTitle] = useState<string>();
  const [titleFilter, setTitleFilter] = useState<string>();

  const [moduleTitles, setModuleTitles] = useState<{
    [Key: string]: string[];
  }>();

  useEffect(() => {
    axios
      .request({
        method: "get",
        url: `http://localhost:8002/get_module_titles`,
        withCredentials: false,
        // responseType: "blob",
      })
      .then((response) => {
        if (response && response.data) {
          const allData = response.data;
          const phpKeys = Object.keys(allData["php"]);
          allData["php"] = phpKeys;
          setModuleTitles(allData);
        }
      });
  }, []);

  useEffect(() => {
    if (
      moduleTitles &&
      moduleTitles[programmer] &&
      moduleTitles[programmer].length > 0
    ) {
      setSelectedTitle(moduleTitles[programmer][0]);
    } else {
      setSelectedTitle(undefined);
    }
  }, [programmer]);

  const sortedTitles = moduleTitles
    ? moduleTitles[programmer] && moduleTitles[programmer].length > 1
      ? moduleTitles[programmer]
          .filter((x) => {
            if (titleFilter) {
              return x.toLowerCase().includes(titleFilter.toLowerCase());
            }
            return true;
          })
          .sort((a, b) => (a < b ? -1 : 1))
      : moduleTitles[programmer] || []
    : [];

  useEffect(() => {
    if (
      sortedTitles.length > 0 &&
      selectedTitle &&
      !sortedTitles.includes(selectedTitle)
    ) {
      setSelectedTitle(sortedTitles[0]);
    }
  }, [sortedTitles]);

  if (!moduleTitles) {
    return (
      <main id="main-content" className="flex-grow overflow-auto p-8">
        <div className="flex min-w-0 flex-1 flex-col gap-2 pb-2 sm:flex-row sm:items-end sm:gap-6">
          <h3 className="text-2xl font-bold leading-5 text-gray-900 sm:truncate sm:leading-7">
            No Modules have been found
          </h3>
        </div>
      </main>
    );
  }

  return (
    <main id="main-content" className="flex-grow overflow-auto p-8">
      <div className="flex min-w-0 flex-1 flex-col gap-2 pb-2 sm:flex-row sm:items-end sm:gap-6">
        <h3 className="text-2xl font-bold leading-5 text-gray-900 sm:truncate sm:leading-7">
          Code Modules
        </h3>
      </div>
      <div className="flex flex-1 gap-2">
        <select
          onChange={(e) => setProgrammer(e.target.value)}
          value={programmer}
        >
          <option value="Please Select">Please Select</option>
          {Object.keys(moduleTitles)
            .sort((a, b) => (a < b ? -1 : 1))
            .map((key) => (
              <option value={key}>{key}</option>
            ))}
        </select>
        {programmer !== "Please Select" && sortedTitles && (
          <>
            <select
              onChange={(e) => setSelectedTitle(e.target.value)}
              value={selectedTitle}
            >
              {sortedTitles.map((title) => (
                <option value={title}>{title}</option>
              ))}
            </select>
            <input
              type="search"
              onChange={(e) => setTitleFilter(e.target.value)}
            />
          </>
        )}
      </div>
      {programmer && selectedTitle && (
        <LoadModule programmer={programmer} selectedTitle={selectedTitle} />
      )}
    </main>
  );
};

export default CodeModules;
