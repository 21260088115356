import DmAlertModal from "../answerForm/DmAlertModal";
import { evaluate } from "./DMKeyboard";
const dec2Frac = (window as any).dec2frac;

const FracModal = ({
  decimal,
  open,
  setOpen,
}: {
  decimal: string;
  open: boolean;
  setOpen: (state: boolean) => void;
}) => {
  const fraction = dec2Frac(evaluate(decimal));

  return (
    <DmAlertModal
      showModal={open}
      setShowModal={setOpen}
      headline={"Decimal to Fraction"}
      message={`The decimal ${evaluate(
        decimal
      )} converts into the fraction ${fraction}`} //${fraction.num}/${fraction.den}`}
      modalAction={() => setOpen(!open)}
    />
  );
};

export default FracModal;
