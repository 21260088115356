import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { useDMQuery } from "../../utils";

type TLicenseType =
  | "District License (Grades 6-12)"
  | "District License (Grades 7-12)"
  | "District License (Grades 9-12)"
  | "District License (Grades 6-8)"
  | "District License (Grades 7-8)"
  | "School License";

export interface ISchoolPopData {
  students: any;
  lowGrade: any;
  highGrade: any;
  name: string;
  nces_id: string;
}
interface IPriceAndDiscountInfo {
  price: {
    plus: number;
    integral: number;
  };
  numberOfStudents?: number;
  discount: number;
  priceWithDiscount: {
    plus: number;
    integral: number;
  };
  minPrice?: {
    plus: number;
    integral: number;
  };
  schools?: ISchoolPopData[];
  licenseType?: TLicenseType;
  sendCustomerEmail?: boolean;
}
const PRORATED_DISCOUNT = 0.65; // 65% off (make it zero beginning of the year) updated for 3/15 switch to 65% off
const PRORATED_DISCOUNT_22_23 = 0;
export const DISTRICT_LICENSE_TO_GRADES: {
  [key: string]: { low: number; high: number };
} = {
  "District License (Grades 6-12)": {
    low: 6,
    high: 12,
  },
  "District License (Grades 7-12)": {
    low: 7,
    high: 12,
  },
  "District License (Grades 9-12)": {
    low: 9,
    high: 12,
  },
  "District License (Grades 6-8)": {
    low: 6,
    high: 8,
  },
  "District License (Grades 7-8)": {
    low: 7,
    high: 8,
  },
};

function getSchoolLowHigh(school: any): { low: number; high: number } {
  /**
   * returns the lowest and highest grades a school encompasses
   */
  let low = school.lowGrade;
  if (low === "K") low = 0;
  else if (low === "PK") low = -1;
  else low = parseInt(low);
  if (isNaN(low)) low = 6;

  let high = school.highGrade;
  if (high === "K") high = 0;
  else if (high === "PK") high = -1;
  else high = parseInt(school.highGrade);
  if (isNaN(high)) high = 12;
  return { low, high };
}

function getStudentGradesCounts(schools: any[]): {
  elemTotal: number;
  middleTotal: number;
  highTotal: number;
  studentsTotal: number;
} {
  let elemTotal = 0;
  let middleTotal = 0;
  let highTotal = 0;
  let studentsTotal = 0;
  for (const org of schools) {
    org.students = parseInt(org.students);
    if (org.students) studentsTotal += org.students;
    else continue;

    let low = org.lowGrade;
    if (low === "K") low = 0;
    else if (low === "PK") low = -1;
    else low = parseInt(low);
    if (isNaN(low)) low = 6;
    let high = parseInt(org.highGrade);
    if (isNaN(high)) high = 12;

    // relative proportion in PK-5, 6-8, 9-12
    let weight1 = 0;
    let weight2 = 0;
    let weight3 = 0;
    let totalWeight = 0;
    for (let i = low; i <= high; i++) {
      totalWeight++;
      if (i <= 5) weight1++;
      else if (i <= 8) weight2++;
      else weight3++;
    }
    elemTotal += Math.round((weight1 / totalWeight) * org.students);
    middleTotal += Math.round((weight2 / totalWeight) * org.students);
    highTotal += Math.round((weight3 / totalWeight) * org.students);
  }
  return { elemTotal, middleTotal, highTotal, studentsTotal };
}

function getPriceAndDiscount(payload: {
  num: number;
  licenseType?: TLicenseType;
  schools: ISchoolPopData[];
  sendCustomerEmail?: boolean;
}): IPriceAndDiscountInfo {
  let num = payload.num;
  const { licenseType, schools, sendCustomerEmail } = payload;
  const discounts = [0.9, 0.85, 0.8, 0.75, 0.7];
  const thresholds = [2499, 4999, 9999, 14999, 19999];

  if (num < 10) num = 0;
  else if (num < 25) num = 50;
  else if (num < 1000) num = Math.round(num / 50) * 50;
  else if (num < 20000) num = Math.round(num / 100) * 100;
  else if (num < 100000) num = Math.round(num / 500) * 500;
  else num = Math.round(num / 1000) * 1000;

  let index = -1;
  for (let i = 0; i < thresholds.length; i++) {
    if (thresholds[i] > num) break;
    index = i;
  }

  // const pricePerStudent = pricePerStudentBase * (1 - PRORATED_DISCOUNT_22_23);
  /** Don't think we need prorated discounts in here */

  const pricePerStudentBase = {
    plus: 1,
    integral: 2,
  };
  const price = {
    plus: num * pricePerStudentBase.plus,
    integral: num * pricePerStudentBase.integral,
  };

  // figure out min price based on schoolType, school counts, and selected license
  let minPrice = {
    plus: 0,
    integral: 0,
  };
  const tierBasePlus = 300;
  const tierBaseIntegral = 500;
  if (licenseType === "School License")
    minPrice = {
      plus: tierBasePlus,
      integral: tierBaseIntegral,
    };
  else
    minPrice = {
      plus: schools.length * tierBasePlus,
      integral: schools.length * tierBaseIntegral,
    };

  price.plus = Math.max(minPrice.plus, price.plus);
  price.integral = Math.max(minPrice.integral, price.integral);

  let discount = index == -1 ? 1 : discounts[index];
  const priceWithDiscount = {
    plus: price.plus * discount,
    integral: price.integral * discount,
  };

  const numberOfStudents = num;

  if (index != thresholds.length - 1) {
    // see if increasing the numbers to the next threshold gives a lower price
    const possibleNum = thresholds[index + 1] + 1; // add 1 to make the next threshold even
    if (
      priceWithDiscount.plus >
      possibleNum * pricePerStudentBase.plus * discounts[index + 1]
    ) {
      price.plus = possibleNum * pricePerStudentBase.plus;
      discount = discounts[index + 1];
      priceWithDiscount.plus =
        possibleNum * pricePerStudentBase.plus * discounts[index + 1];
    }
    if (
      priceWithDiscount.integral >
      possibleNum * pricePerStudentBase.integral * discounts[index + 1]
    ) {
      price.integral = possibleNum * pricePerStudentBase.integral;
      discount = discounts[index + 1];
      priceWithDiscount.integral =
        possibleNum * pricePerStudentBase.integral * discounts[index + 1];
    }
  }

  return {
    price,
    numberOfStudents,
    discount: Math.round((1 - discount) * 100),
    priceWithDiscount,
    minPrice,
    schools,
    licenseType,
    sendCustomerEmail,
  };
}

const QuoteDistrict = () => {
  const params = useParams();

  const [districtPricing, setDistrictPricing] = useState({
    six_twelve: {
      price: { plus: 0, integral: 0 },
      discount: 0,
      priceWithDiscount: { plus: 0, integral: 0 },
    },
    six_eight: {
      price: { plus: 0, integral: 0 },
      discount: 0,
      priceWithDiscount: { plus: 0, integral: 0 },
    },
    nine_twelve: {
      price: { plus: 0, integral: 0 },
      discount: 0,
      priceWithDiscount: { plus: 0, integral: 0 },
    },
  });
  const [districtHighTotal, setDistrictHighTotal] = useState(0);
  const [districtMiddleTotal, setDistrictMiddleTotal] = useState(0);

  const { status, data } = useDMQuery({
    path: "/manage",
    cacheKey: [
      "get-school-or-district-by-id",
      params.type || "type",
      params.id || "id",
    ],
    method: "post",
    requestBody: {
      type: "school-or-district-by-id",
      customer_service_token: localStorage.getItem("customer_service_token"),
      districtID: params.type === "district" ? params.id : "",
      schoolID: params.type === "school" ? params.id : "",
    },
    additionalHeaders: {
      "Content-Type": "application/json",
    },
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      enabled: Boolean(params.id),
      refetchOnWindowFocus: false,
    },
  });

  const [schoolsData, setSchoolsData] = useState<any>([]);
  // filter schools based on grades and license type

  const filterSchoolsByGrade = ({
    schools,
    licenseType,
  }: {
    schools: ISchoolPopData[];
    licenseType: string;
  }) => {
    const filteredSchools: ISchoolPopData[] = [];
    schools?.forEach((school: ISchoolPopData) => {
      const { low, high } = getSchoolLowHigh(school);
      // check if school overlaps with license, if so add it to filteredSchools
      if (params.type === "School License") filteredSchools.push(school);
      else {
        const gradeRange = DISTRICT_LICENSE_TO_GRADES[licenseType];
        const schoolInRange =
          (low >= gradeRange.low && low <= gradeRange.high) ||
          (high <= gradeRange.high && high >= gradeRange.low) ||
          (low < gradeRange.low && high > gradeRange.high);

        if (schoolInRange) filteredSchools.push(school);
      }
    });
    return filteredSchools;
  };

  useEffect(() => {
    //6-12 Student info
    const sixToTwelveSchools = filterSchoolsByGrade({
      schools: data?.schools,
      licenseType: "District License (Grades 6-12)",
    });
    const sixToTwelveGradeCounts = getStudentGradesCounts(sixToTwelveSchools);

    //6-8 Student Info
    const sixToEightSchools = filterSchoolsByGrade({
      schools: data?.schools,
      licenseType: "District License (Grades 6-8)",
    });
    const sixToEightGradeCounts = getStudentGradesCounts(sixToEightSchools);

    //9-12 Student Info
    const nineToTwelveSchools = filterSchoolsByGrade({
      schools: data?.schools,
      licenseType: "District License (Grades 9-12)",
    });
    const nineToTwelveGradeCounts = getStudentGradesCounts(nineToTwelveSchools);

    // 6-12 Pricing Info
    const sixToTwelvePricing = getPriceAndDiscount({
      num:
        sixToTwelveGradeCounts.middleTotal + sixToTwelveGradeCounts.highTotal,
      licenseType: "District License (Grades 6-12)",
      schools: sixToTwelveSchools,
      sendCustomerEmail: false,
    });

    // 6-8 Pricing Info
    const sixToEightPricing = getPriceAndDiscount({
      num: sixToEightGradeCounts.middleTotal,
      licenseType: "District License (Grades 6-8)",
      schools: sixToEightSchools,
      sendCustomerEmail: false,
    });
    setDistrictMiddleTotal(sixToEightGradeCounts.middleTotal);

    // 9-12 Pricing Info
    const nineToTwelvePricing = getPriceAndDiscount({
      num: nineToTwelveGradeCounts.highTotal,
      licenseType: "District License (Grades 9-12)",
      schools: nineToTwelveSchools,
      sendCustomerEmail: false,
    });
    setDistrictHighTotal(nineToTwelveGradeCounts.highTotal);

    setDistrictPricing({
      six_twelve: {
        price: {
          plus: sixToTwelvePricing.price.plus,
          integral: sixToTwelvePricing.price.integral,
        },
        discount: sixToTwelvePricing.discount || 0,
        priceWithDiscount: {
          plus: sixToTwelvePricing?.priceWithDiscount?.plus || 0,
          integral: sixToTwelvePricing?.priceWithDiscount?.integral || 0,
        },
      },
      six_eight: {
        price: {
          plus: sixToEightPricing.price.plus,
          integral: sixToEightPricing.price.integral,
        },
        discount: sixToEightPricing.discount || 0,
        priceWithDiscount: {
          plus: sixToEightPricing?.priceWithDiscount?.plus || 0,
          integral: sixToEightPricing?.priceWithDiscount?.integral || 0,
        },
      },
      nine_twelve: {
        price: {
          plus: nineToTwelvePricing.price.plus,
          integral: nineToTwelvePricing.price.integral,
        },
        discount: nineToTwelvePricing.discount || 0,
        priceWithDiscount: {
          plus: nineToTwelvePricing?.priceWithDiscount?.plus || 0,
          integral: nineToTwelvePricing?.priceWithDiscount?.integral || 0,
        },
      },
    });

    const ourSchools: any[] = [];
    data?.schools.map((org: ISchoolPopData) => {
      const {
        elemTotal: numElementaryStudents,
        middleTotal: numMiddleStudents,
        highTotal: numHighStudents,
      } = getStudentGradesCounts([org]);

      const priceInfo = getPriceAndDiscount({
        num: numMiddleStudents + numHighStudents,
        schools: [org],
      });

      ourSchools.push({
        ...org,
        gradespk_5: numElementaryStudents,
        grades6_8: numMiddleStudents,
        grades9_12: numHighStudents,
        full_price_plus: priceInfo.price.plus,
        full_price_integral: priceInfo.price.integral,
        discount: priceInfo.discount,
        final_price_plus: priceInfo.priceWithDiscount.plus,
        final_price_integral: priceInfo.priceWithDiscount.integral,
      });
    });
    setSchoolsData(ourSchools);
  }, [data]);

  const formatNumber = (num: number) => {
    if (isNaN(num)) {
      return "-";
    }
    return num == Math.round(num)
      ? num.toLocaleString()
      : num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  return (
    <React.Fragment>
      {status === "loading" ? (
        <div>Loading...</div>
      ) : status === "error" ? (
        <span>Error...</span>
      ) : (
        <>
          {data.district?.type === "district" && (
            // should this be aligned with the table edge or the table text?
            <table className="mb-4">
              <tbody>
                <tr className="space-x-2 divide-x divide-solid">
                  <td className="px-4 py-8">
                    <b>District:</b>
                    <div>{data.district.name}</div>
                    <div>
                      {`${data.district.address.street}, ${data.district.address.city}, 
                          ${data.district.address.state} ${data.district.address.zip}`}
                    </div>
                    <div>{data.district.phone}</div>
                    <div>
                      <a href={`${data.district?.url}`} target="_blank">
                        link
                      </a>
                    </div>
                  </td>

                  <td className="px-4 py-8">
                    <b>District 6-12</b>
                    <div>
                      {`(${formatNumber(
                        districtMiddleTotal + districtHighTotal
                      )} students)`}
                    </div>
                    <div>{`Original Plus Price: ${formatNumber(
                      districtPricing.six_twelve.price.plus
                    )}`}</div>
                    <div>{`Original Integral Price: ${formatNumber(
                      districtPricing.six_twelve.price.integral
                    )}`}</div>
                    <div>{`Discount: ${districtPricing.six_twelve.discount}%`}</div>
                    <div>{`Final Plus Price: ${formatNumber(
                      districtPricing.six_twelve.priceWithDiscount.plus
                    )}`}</div>
                    <div>{`Final Integral Price: ${formatNumber(
                      districtPricing.six_twelve.priceWithDiscount.integral
                    )}`}</div>
                  </td>

                  <td className="px-4 py-8">
                    <b>District 6-8</b>
                    <div>{`(${formatNumber(
                      districtMiddleTotal
                    )} students)`}</div>
                    <div>{`Original Plus Price: ${formatNumber(
                      districtPricing.six_eight.price.plus
                    )}`}</div>
                    <div>{`Original Integral Price: ${formatNumber(
                      districtPricing.six_eight.price.integral
                    )}`}</div>
                    <div>{`Discount: ${districtPricing.six_eight.discount}%`}</div>
                    <div>{`Final Plus Price: ${formatNumber(
                      districtPricing.six_eight.priceWithDiscount.plus
                    )}`}</div>
                    <div>{`Final Integral Price: ${formatNumber(
                      districtPricing.six_eight.priceWithDiscount.integral
                    )}`}</div>
                  </td>

                  <td className="px-4 py-8">
                    <b>District 9-12</b>
                    <div>{`(${formatNumber(districtHighTotal)} students)`}</div>
                    <div>{`Original Plus Price: ${formatNumber(
                      districtPricing.nine_twelve.price.plus
                    )}`}</div>
                    <div>{`Original Integral Price: ${formatNumber(
                      districtPricing.nine_twelve.price.integral
                    )}`}</div>
                    <div>{`Discount: ${districtPricing.nine_twelve.discount}%`}</div>
                    <div>{`Final Plus Price: ${formatNumber(
                      districtPricing.nine_twelve.priceWithDiscount.plus
                    )}`}</div>
                    <div>{`Final Integral Price: ${formatNumber(
                      districtPricing.nine_twelve.priceWithDiscount.integral
                    )}`}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {data && (
            <DeltaMathTable
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                },
                {
                  Header: "Level",
                  accessor: "lowGrade",
                  Cell: (props: any) => (
                    <span>{`${props.row.values.lowGrade} - ${props.row.values.highGrade}`}</span>
                  ),
                },
                {
                  //this is a hacky way of writing "highGrade" into row.values but not displaying it
                  id: "highGrade",
                  accessor: "highGrade",
                  Cell: () => <span></span>,
                },
                {
                  Header: "Students",
                  accessor: "students",
                  Cell: (props: any) => (
                    <span>{formatNumber(props.row.values.students)}</span>
                  ),
                },
                {
                  Header: "PK-5",
                  accessor: "gradespk_5",
                  Cell: (props: any) => (
                    <span>{formatNumber(props.row.values.gradespk_5)}</span>
                  ),
                },
                {
                  Header: "6-8",
                  accessor: "grades6_8",
                  Cell: (props: any) => (
                    <span>{formatNumber(props.row.values.grades6_8)}</span>
                  ),
                },
                {
                  Header: "9-12",
                  accessor: "grades9_12",
                  Cell: (props: any) => (
                    <span>{formatNumber(props.row.values.grades9_12)}</span>
                  ),
                },
                {
                  Header: "Full Price Plus",
                  accessor: "full_price_plus",
                  Cell: (props: any) => (
                    <span>{`$${formatNumber(
                      props.row.values.full_price_plus
                    )}`}</span>
                  ),
                },
                {
                  Header: "Full Price Integral",
                  accessor: "full_price_integral",
                  Cell: (props: any) => (
                    <span>{`$${formatNumber(
                      props.row.values.full_price_integral
                    )}`}</span>
                  ),
                },
                {
                  Header: "Discount",
                  accessor: "discount",
                  Cell: (props: any) => (
                    <span>{`${props.row.values.discount}%`}</span>
                  ),
                },
                {
                  Header: "Final Price Plus",
                  accessor: "final_price_plus",
                  Cell: (props: any) => (
                    <span>{`$${formatNumber(
                      props.row.values.final_price_plus
                    )}`}</span>
                  ),
                },
                {
                  Header: "Final Price Integral",
                  accessor: "final_price_integral",
                  Cell: (props: any) => (
                    <span>{`$${formatNumber(
                      props.row.values.final_price_integral
                    )}`}</span>
                  ),
                },
                {
                  Header: "NCES ID",
                  accessor: "id",
                },
                {
                  Header: "Link",
                  accessor: "url",
                  Cell: (props: any) => (
                    <a
                      className="text-teal-300"
                      href={props.row.values.url}
                      target="_blank"
                    >
                      {" "}
                      SchoolDigger{" "}
                    </a>
                  ),
                },
              ]}
              data={schoolsData}
            ></DeltaMathTable>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default QuoteDistrict;
