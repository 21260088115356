import {
  UebAsciiToBrailleMap,
  NemethAsciiToBrailleMap,
} from "./string-mappings";

export function convertMathToBraille(
  mathString: string,
  type: "UEB" | "Nemeth"
): string {
  const symbolsMap =
    type === "UEB" ? UebAsciiToBrailleMap : NemethAsciiToBrailleMap;

  let brailleString = "";
  let fractionMode = false;
  let fractionNumerator = "";

  for (let i = 0; i < mathString.length; i++) {
    const char = mathString[i].toUpperCase();

    if (symbolsMap.has(char)) {
      // Put # in front of the braille character so the user knows that it's a number
      // If it's a fraction you also do not need to put the # in front of the denominator
      const symbol =
        char.match(/[0-9]/i) &&
        (i == 0 || !mathString[i - 1].match(/[0-9]/i)) &&
        (i === 0 || mathString[i - 1] !== "/")
          ? `⠼${symbolsMap.get(char)}`
          : symbolsMap.get(char);

      if (symbol === symbolsMap.get("/")) {
        // Enter fraction mode
        fractionMode = true;
        fractionNumerator = brailleString;
        brailleString = "";
      } else if (fractionMode && symbol === symbolsMap.get("=")) {
        // Exit fraction mode and append the fraction
        const fractionDenominator = brailleString;
        brailleString =
          fractionNumerator +
          symbolsMap.get("fractionBar") +
          fractionDenominator;
        fractionMode = false;
      } else {
        brailleString += symbol;
      }
    } else {
      brailleString += char;
    }
  }

  if (fractionMode) {
    // Append any remaining numerator characters as an incomplete fraction
    brailleString =
      fractionNumerator + symbolsMap.get("fraction") + brailleString;
  }

  return brailleString;
}
