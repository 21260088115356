const CustomGraphingIcon = () => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <line
          y1="-1"
          x2="24"
          y2="-1"
          transform="matrix(4.37112e-08 1 1 -4.37112e-08 13 0)"
          stroke="#E3E8EF"
          strokeWidth="2"
        />
        <line
          y1="-1"
          x2="24"
          y2="-1"
          transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 0 11)"
          stroke="#E3E8EF"
          strokeWidth="2"
        />
        <path
          d="M19.5001 7.49939L19.1437 11.5978C18.8577 14.8874 14.1952 15.219 13.4989 11.9993V11.9993L12.3173 8.30642C11.431 5.53629 7.53212 5.55295 6.62923 8.33072L2.99895 19.4993"
          stroke="#9CA3AF"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.12795 17.8946C5.60743 17.6205 6.21831 17.7871 6.49238 18.2665C6.76645 18.746 6.59993 19.3569 6.12045 19.631L3.51592 21.1197C3.26408 21.2637 2.976 21.2861 2.71951 21.2054C2.4574 21.1449 2.22035 20.9793 2.07632 20.7273L0.587569 18.1228C0.313497 17.6433 0.480014 17.0324 0.959496 16.7584C1.43898 16.4843 2.04985 16.6508 2.32393 17.1303L3.34376 18.9145L5.12795 17.8946Z"
          fill="#9CA3AF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.5673 6.86683L18.1086 8.31389C17.7166 8.70286 17.0834 8.70034 16.6944 8.30827C16.3055 7.91619 16.308 7.28303 16.7001 6.89406L18.8298 4.7812C19.0357 4.5769 19.3082 4.4806 19.5768 4.49202C19.8455 4.4828 20.1171 4.58126 20.3213 4.78714L22.4342 6.91688C22.8231 7.30896 22.8206 7.94212 22.4285 8.33108C22.0365 8.72005 21.4033 8.71753 21.0143 8.32546L19.5673 6.86683Z"
          fill="#9CA3AF"
        />
      </g>
    </svg>
  );
};

export default CustomGraphingIcon;
