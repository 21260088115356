import { useCallback, useMemo, useContext, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import { Tab, Listbox } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { getActiveSectionData, getSectionData } from "../utils";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import clsx from "clsx";
import { SectionDataStatus } from "../_constants";

export default function Main(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    activeSection,
    dmSectionsData,
    dmAssignmentData,
    setCurrentProblemData,
    setShowPastDue,
  } = useContext(StudentSectionsContext);

  /* Define tab data */
  const tabs = [
    {
      name: "Upcoming",
      param: SectionDataStatus.upcoming,
      tabSectionData: getSectionData(
        SectionDataStatus.upcoming,
        activeSection,
        dmAssignmentData
      ),
    },
    {
      name: "Completed",
      param: SectionDataStatus.completed,
      tabSectionData: getSectionData(
        SectionDataStatus.completed,
        activeSection,
        dmAssignmentData
      ),
    },
  ];

  // Conditionally include the Past Due tab
  const pastDueAssignments = getSectionData(
    SectionDataStatus["past-due"],
    activeSection,
    dmAssignmentData
  );
  if (pastDueAssignments?.length) {
    tabs.splice(1, 0, {
      name: "Past Due",
      param: SectionDataStatus["past-due"],
      tabSectionData: pastDueAssignments,
    });
  }

  /* Resets currentProblemData */
  useEffect(() => {
    setCurrentProblemData(undefined);
  }, []);

  /* Sets state for showing the Past Due tab (showPastDue is used for empty state copy) */
  useEffect(() => {
    if (pastDueAssignments?.length) setShowPastDue(true);
    else setShowPastDue(false);
  }, [activeSection, dmAssignmentData]);

  const tabIndex = useCallback(() => {
    let currentTab = tabs.findIndex((object) => {
      return location.pathname.includes(object.param);
    });
    if (currentTab < 0) currentTab = 0;
    return currentTab;
  }, [location]);

  const onTabChange = (index: number): void => {
    if (tabs[index]) {
      navigate(`${activeSection}/${tabs[index].param}`);
    }
  };

  const onListboxChange = (event: any): void => {
    navigate(`${activeSection}/${event.param}`);
  };

  const sectionData: any = useMemo(
    () => getActiveSectionData(activeSection, dmSectionsData),
    [activeSection, dmSectionsData]
  );

  return (
    <>
      <main
        id="main-content"
        role="main"
        className="flex-grow overflow-auto p-8"
      >
        {typeof sectionData === "undefined" ? (
          <div className="pt-5 text-sm">
            <strong>Error: </strong> Section id <em>{activeSection}</em> doesn't
            exist or you do not have access to it.
          </div>
        ) : (
          <>
            <div className="flex min-w-0 flex-1 flex-col gap-2 pb-4 sm:flex-row sm:items-end sm:gap-6 sm:pb-8">
              <h1 className="text-2xl font-bold leading-5 text-gray-900 sm:truncate sm:leading-7">
                {sectionData?.name}
              </h1>
              <h2 className="text-sm text-gray-600">
                {sectionData?.teacherNames}
              </h2>
            </div>
            {/* Mobile Section Select */}
            <div className="relative sm:hidden">
              <Listbox value={tabs[tabIndex()]} onChange={onListboxChange}>
                <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                  <span className="block truncate">
                    {tabs[tabIndex()]?.name}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Listbox.Button>
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {tabs.map((tab) => (
                    <Listbox.Option
                      key={tab.param}
                      value={tab}
                      className={({ active }) =>
                        clsx(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-8 pr-4"
                        )
                      }
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={clsx(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {tab.name}
                          </span>

                          {selected ? (
                            <span
                              className={clsx(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 left-0 flex items-center pl-1.5"
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
            </div>
            {/* Desktop Tabs */}
            <div className="hidden sm:block">
              <Tab.Group
                selectedIndex={tabIndex()}
                onChange={onTabChange}
                defaultIndex={0}
              >
                <Tab.List className="border-gray-20 -mb-px flex space-x-8 border-b">
                  {tabs.map((tab: any) => {
                    return (
                      <Tab
                        key={tab.name}
                        className={({ selected }) =>
                          clsx(
                            "whitespace-nowrap border-b-2 px-3 py-2 text-sm focus:outline-none md:px-10 lg:px-16",
                            selected
                              ? "border-blue-700 text-blue-700"
                              : "border-transparent text-gray-600 hover:border-gray-200 hover:text-gray-700"
                          )
                        }
                      >
                        {({ selected }) => (
                          <>
                            {tab.name}
                            {tab.tabSectionData?.length ? (
                              <span
                                className={clsx(
                                  "ml-3 rounded-full px-2.5 py-0.5 text-xs",
                                  selected
                                    ? "bg-indigo-100 text-indigo-600"
                                    : "bg-gray-200 text-gray-900"
                                )}
                              >
                                {tab.tabSectionData.length}
                              </span>
                            ) : null}
                          </>
                        )}
                      </Tab>
                    );
                  })}
                </Tab.List>
              </Tab.Group>
            </div>
            <Outlet />
          </>
        )}
      </main>
    </>
  );
}
