import { faker } from "@faker-js/faker";
import { v4 as uuidv4 } from "uuid";

/**
 * Returns a hash code from a string
 * @param  {String} str The string to hash.
 * @return {Number}    A 32bit integer
 * @see http://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
 */
function hashCode(str: string) {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    const chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function demoString(arg: any) {
  const inDemoMode = localStorage.getItem("demo-mode") === "true";
  let response = arg.value;

  const school_identifier = [""];
  if (arg.district_size) {
    let i = 1;
    let counter = SCHOOL_NAMES.length;
    while (counter < arg.district_size) {
      school_identifier.push(intToRoman(i));
      i++;
      counter = counter * i;
    }
  }

  if (typeof response !== "undefined") {
    faker.seed(hashCode(response.toString()));

    if (inDemoMode) {
      switch (arg.type) {
        case "school_name":
          response =
            faker.helpers.arrayElement(SCHOOL_NAMES) +
            " School" +
            (school_identifier.length > 1
              ? " " + faker.helpers.arrayElement(school_identifier)
              : "");
          break;
        case "city":
          response = faker.address.cityName();
          break;
        case "section_name":
          response =
            faker.helpers.arrayElement([
              "Algebra",
              "Geometry",
              "Trigonometry",
              "Pre-Calculus",
              "Calculus",
            ]) +
            " Section: " +
            faker.datatype.number({ min: 1, max: 7 });
          break;
        case "teachercode":
          response = faker.finance.routingNumber();
          break;
        case "guid":
          response = uuidv4();
          break;
        case "person_full":
          response = faker.name.firstName() + " " + faker.name.lastName();
          break;
        case "person_first":
          response = faker.name.firstName();
          break;
        case "person_last":
          response = faker.name.lastName();
          break;
        case "person_email":
          response =
            faker.color.human().replace(/\s+/g, "-") +
            "-" +
            faker.datatype.number(100) +
            "@deltamath.com";
          break;

        default:
          response = "";
      }
    }
  }
  return response;
}

export function DemoMode({
  value,
  type,
  district_size,
}: {
  value: any;
  district_size?: any;
  type:
    | "city"
    | "guid"
    | "section_name"
    | "teachercode"
    | "person_full"
    | "person_first"
    | "person_last"
    | "person_email"
    | "school_name";
}) {
  return (
    <>
      {demoString({
        value,
        type,
        district_size,
      })}
    </>
  );
}

const SCHOOL_NAMES = [
  "Washington",
  "Adams",
  "Monroe",
  "Madison",
  "Harrison",
  "Tyler",
  "Lincoln",
  "Grant",
  "Garfield",
  "McKinley",
  "Roosevelt",
  "Truman",
  "Eisenhower",
  "Kennedy",
  "Ford",
  "Edison",
  "Marshall",
  "Mann",
  "Franklin",
  "Webster",
  "Stowe",
  "Dewey",
  "Parks",
  "King",
  "Einstein",
  "Douglass",
  "Tubman",
  "Anthony",
  "Angelou",
  "Wells",
  "Earhart",
  "Lorde",
  "Bridges",
  "Wheatley",
  "Mott",
  "Truth",
  "Tuban",
  "Barton",
  "Bascom",
  "Hurston",
  "Hopper",
  "Daly",
];

const intToRoman = (int: number) => {
  let roman = "";
  const map: { [key: string]: number } = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };

  for (const key in map) {
    roman += key.repeat(Math.floor(int / map[key]));
    int %= map[key];
  }

  return roman;
};
