import { useMemo, useContext } from "react";
import SectionEmptyState from "./SectionEmptyState";
import WaveIcon from "../icons/WaveIcon";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import { getActiveSectionData } from "../../utils";

export default function SectionWelcomeState(): JSX.Element {
  const { activeSection, dmSectionsData } = useContext(StudentSectionsContext);

  const sectionData: any = useMemo(
    () => getActiveSectionData(activeSection, dmSectionsData),
    [activeSection, dmSectionsData]
  );

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  return (
    <SectionEmptyState
      icon={<WaveIcon />}
      header={`Welcome${user?.first ? " " + user.first : ""}!`}
      subheader={
        sectionData?.teacherNames && sectionData?.teachers?.length === 1
          ? `${sectionData.teacherNames} has not assigned anything yet.`
          : "You have no assignments yet."
      }
      message="Check back later to view assignments or tests here."
    />
  );
}
