import { ListStandardsResponse } from "DeltaMathAPI/routers/standards.router/api";
import { useState } from "react";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";

/**
 * A list of clickable standards, writes back the selected standard id to activated
 */
const StandardsList = (props: {
  standards: Array<ListStandardsResponse>;
  activated: string;
  onClickFn: (e: React.MouseEvent<HTMLDivElement>) => void;
  changeSort: (_id: string, direction: 1 | -1) => Promise<void>;
}) => {
  const { standards, activated, onClickFn, changeSort } = props;
  const [getHover, setHover] = useState<string | undefined>();

  return (
    <>
      {standards && (
        <div className="">
          <div className="flow-root">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="float-right pb-1">
                  <button
                    type="button"
                    className="focus:shadow-outline-dm-lightest-blue mr-1 mt-0.5 inline-flex h-8 items-center rounded border border-transparent bg-dm-light-blue px-2.5 py-1.5 text-xs font-medium leading-4 text-white transition duration-150 ease-in-out hover:bg-dm-lightest-blue focus:border-dm-lightest-blue focus:outline-none active:bg-dm-lightest-blue"
                    onClick={() => changeSort(activated, -1)}
                    disabled={!activated}
                  >
                    Move Up
                  </button>
                  <button
                    type="button"
                    className="focus:shadow-outline-dm-lightest-blue mt-0.5 inline-flex h-8 items-center rounded border border-transparent bg-dm-light-blue px-2.5 py-1.5 text-xs font-medium leading-4 text-white transition duration-150 ease-in-out hover:bg-dm-lightest-blue focus:border-dm-lightest-blue focus:outline-none active:bg-dm-lightest-blue"
                    onClick={() => changeSort(activated, 1)}
                    disabled={!activated}
                  >
                    Move Down
                  </button>
                </div>
                <table className="min-w-full divide-y divide-gray-300 bg-white">
                  <tbody className="divide-y divide-gray-200">
                    {standards.map((standard: ListStandardsResponse) => (
                      <tr
                        key={standard._id}
                        onMouseEnter={() => setHover(standard._id)}
                        onMouseLeave={() => setHover(undefined)}
                      >
                        <td
                          id={standard._id}
                          onClick={onClickFn}
                          className={`whitespace-nowrap px-3 py-2 text-sm text-gray-500 hover:bg-dm-lightest-blue hover:text-white ${
                            activated === standard._id
                              ? "bg-dm-blue text-gray-300"
                              : ""
                          }`}
                        >
                          {standard.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StandardsList;
