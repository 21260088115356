import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeltaMathSelect } from "../../shared/DeltaMathSelect";
import { useDMQuery } from "../../utils";
import { StudentPerformanceNav } from "./student-performance/StudentPerformanceNav";
import { setIdsArray } from "./StudentUsage";
import { getStartEndDate, SelectDataScope } from "./StudentUsageUtils";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./performance.css";
import { CalendarIcon } from "@heroicons/react/solid";
import {
  SkillCodes,
  SubStandard,
} from "../../manager/components/standards/common/types";
import SelectStandard from "./student-performance/SelectStandard";
import { DisplayPerformanceGraphs } from "./student-performance/DisplayPerformanceGraphs";
import DisplayGlobalPerformanceGraph from "./student-performance/DisplayGlobalPerformanceGraph";
import { Transition } from "@headlessui/react";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";

interface GlobalStandardRequestInterface {
  skillCodesToStandards: Record<string, string[]>;
  sectionIds?: string[] | string;
  teacherIds?: string[] | string;
  schoolIds?: string[] | string;
  start?: string;
  end?: string;
  series?: string[] | string;
  fetchStandard?: boolean;
  fetchTest?: boolean;
  fetchPushed?: boolean;
}

export const filterOptions = [
  { key: "all", val: "All assignments and tests" },
  { key: "tests", val: "Tests only" },
  { key: "pushed-assignments", val: "Pushed assignments only" },
  // { key: "standard", val: "Standard" },
];

/**
 *
 * @param preferenceObj {
 * "fetchTests":boolean,
 * "fetchPushed":boolean,
 * "fetchStandard":boolean
 * } localStorage value of which assignment type to fetch
 * @returns the filterOption that corresponds to the preferred request type
 */
const parsePreferenceToFilter = (
  preferenceObj: { [key: string]: boolean } | Record<string, unknown>
) => {
  if (Object.keys(preferenceObj).length > 0) {
    const pref = Object.keys(preferenceObj).find(
      (key: string) => preferenceObj[key] === true
    );
    const parsedFilterVal =
      pref === "fetchTests"
        ? filterOptions[1]
        : pref === "fetchPushed"
        ? filterOptions[2]
        : pref === "fetchStandard"
        ? filterOptions[0]
        : filterOptions[0];
    return parsedFilterVal;
  } else return filterOptions[0];
};
/**
 * Takes a filterOption key value and returns an object request params to get API data for that filter selection
 * @param selectedKey key value of the selected data filterOption
 * @returns object with the selected filterOption's corresponding request params set
 */
const parseFilterToPreference = (selectedKey: string) => {
  let preferenceObj = {};
  selectedKey === "tests"
    ? (preferenceObj = {
        fetchTests: true,
        fetchPushed: false,
        fetchStandard: false,
      })
    : selectedKey === "pushed-assignments"
    ? (preferenceObj = {
        fetchTests: false,
        fetchPushed: true,
        fetchStandard: false,
      })
    : (preferenceObj = {
        fetchTests: undefined,
        fetchPushed: undefined,
        fetchStandard: undefined,
      });
  return preferenceObj;
};

export default function StudentPerformance({
  featureFlag,
  schoolsWithIntegral,
}: {
  featureFlag: boolean;
  schoolsWithIntegral: any;
}) {
  const { yesterday, yearToDate } = getStartEndDate();
  const params = useParams();
  const navigate = useNavigate();
  const toastContext = useDeltaToastContext();

  const [userStateCode, setUserStateCode] = useState<string>();

  /** initialize user preference to "All"*/
  const temp = {
    tempPreferences: {
      performanceViewPref: {
        fetchTests: false,
        fetchPushed: false,
        fetchStandard: false,
      },
    },
  };
  let tempPreferences = JSON.parse(
    localStorage.getItem("tempPreferences") || "{}"
  );
  Object.hasOwn(tempPreferences, "fetchPushed") === false
    ? (tempPreferences = temp)
    : null;

  const [startDate, setStartDate] = useState<Date | null>(yearToDate);
  const [endDate, setEndDate] = useState<Date | null>(yesterday);
  const [getCourse, setCourse] = useState<string>("");
  const [getActivated, setActivated] = useState("");
  const [selectedDataScope, setSelectedDataScope] = useState<string>("Schools");
  const [selectedSchools, setSelectedSchools] = useState<any>({});
  const [selectedTeachers, setSelectedTeachers] = useState<any>({});
  const [selectedSections, setSelectedSections] = useState<any>({});
  const [selectedStandard, setSelectedStandard] = useState<SubStandard>();
  const [selectCourseOptions, setSelectCourseOptions] = useState<any>();
  const [performanceParams, setPerformanceParams] = useState({});
  const [globalRequestParams, setGlobalRequestParams] =
    useState<GlobalStandardRequestInterface>();
  const [standardMap, setStandardMap] = useState<Record<string, string[]>>();
  // on load, filterValue should be initialized to user default preference if one exists in localStorage
  const [filterValue, setFilterValue] = useState<string>(
    tempPreferences?.performanceViewPref &&
      Object.keys(tempPreferences?.performanceViewPref).length > 1
      ? parsePreferenceToFilter(tempPreferences.performanceViewPref).val
      : "All"
  );

  /** check for existing data and user permissions and route accordingly on page load. */
  useEffect(() => {
    if (!params.data) {
      navigate(
        `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/data-scope`,
        {
          replace: true,
        }
      );
    } else if (params.data && params.data === "view-global-data") {
      !featureFlag &&
        navigate(
          `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/data-scope`,
          {
            replace: true,
          }
        );
    }
  }, [params]);

  const { data: schoolData, refetch: refetchSchoolData } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      enabled: true,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      onSuccess: (data: { address: { state: string } }[]) => {
        if (!userStateCode) {
          if (data[0].address?.state) {
            setUserStateCode(data[0].address.state);
          } else {
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            const userStateCode: string = user?.schoolinfo?.state;
            setUserStateCode(userStateCode);
          }
        }
      },
    },
  });

  const { data: districtData, refetch: refetchDistrict } = useDMQuery({
    path: "/admin_new/data/district",
    queryOptions: {
      enabled: true,
      staleTime: 1000 * 60 * 15,
      onSuccess: (data: { address: { state: string } }) => {
        if (data?.address?.state) {
          setUserStateCode(data.address.state);
        } else {
          refetchSchoolData();
        }
      },
    },
  });

  useEffect(() => {
    /** this shouldn't actually do anything */
    refetchDistrict();
  }, []);

  const { data: teacherData } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });

  const { data: sectionData } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  const { data: skillCodes } = useDMQuery({
    /** new skillcodes endpoint */
    path: "/standards/ref/skillcodes",
    queryOptions: {
      enabled: true,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: allStandards, refetch: refetchAllStandards } = useDMQuery({
    /** new standards endpoint  */
    path: `/standards/code/${userStateCode}`,
    queryOptions: {
      enabled: false,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  useEffect(() => {
    /** call standards endpoint when user state code has loaded */
    if (userStateCode) {
      refetchAllStandards();
    }
  }, [userStateCode]);

  const { data: standardData, refetch: refetchStandardData } = useDMQuery({
    /** NEW ENDPOINT (does not return anything on dev) */
    path: `/standards/${getCourse}`,
    queryOptions: {
      enabled: false,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  /** NEW LOGIC for creating "Select Course" dropdown options */
  useEffect(() => {
    /** set standards options for user based on /standards/:code response,
     * fetch individual standard data for the default standard selection
     */
    if (allStandards && allStandards.length) {
      const options = allStandards.map(
        (option: { name: string; _id: string }) => ({
          key: option._id,
          val: option.name,
        })
      );
      setSelectCourseOptions(options);
      options.length > 0 && setCourse(options[0].key);
      refetchStandardData();
    }
  }, [allStandards]);

  useEffect(() => {
    if (standardData?.data) {
      setSelectedStandard(standardData);
      setStandardMap(skillCodeToStandardMap(standardData));
    }
  }, [standardData]);

  useEffect(() => {
    if (
      params?.data === "view-data" &&
      !Object.hasOwn(performanceParams, "teacherIds") &&
      !Object.hasOwn(performanceParams, "sectionIds") &&
      !Object.hasOwn(performanceParams, "schoolIds")
    ) {
      navigate(
        `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/data-scope`,
        {
          replace: true,
        }
      );
    }

    if (
      params?.data === "view-global-data" &&
      globalRequestParams &&
      (typeof globalRequestParams.teacherIds === "undefined" ||
        globalRequestParams.teacherIds.length === 0) &&
      (typeof globalRequestParams.sectionIds === "undefined" ||
        globalRequestParams.sectionIds.length === 0) &&
      (typeof globalRequestParams.schoolIds === "undefined" ||
        globalRequestParams.schoolIds.length === 0)
    ) {
      navigate(
        `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/data-scope`,
        {
          replace: true,
        }
      );
    }

    /** get key/val pair from filterOptions */
    const currentFilter = filterOptions.find(
      (option: { key: string; val: string }) => option.val === filterValue
    ) || { key: "all", val: "All" };

    /** update requestParams value to include filter preference */
    const newFilterParams = parseFilterToPreference(currentFilter.key);
    setPerformanceParams({
      ...performanceParams,
      ...newFilterParams,
    });
  }, [params]);

  /**
   *  parses selected data scope and sets request parameters for a standard or skillcodes
   * @param param0 {
   *  dataScope: current selectedDataScope (might not be necessary)
   *  currentParams: any existing params (i.e. fetchTest, fetchPushed) that should persist
   *  requestObject: unique request fields (not shared between requests)
   *  setterFn: setState function to update request params,
   * }
   */
  const updateRequestParams = ({
    dataScope,
    currentParams,
    requestObject,
    setterFn,
  }: {
    dataScope: string;
    requestObject?: any;
    currentParams: any;
    setterFn: (value: any) => void;
  }) => {
    if (dataScope === "Schools" && selectedSchools && schoolsWithIntegral) {
      const selectedSchoolIds = setIdsArray(
        selectedSchools,
        schoolsWithIntegral,
        ["schoolid"]
      ).map((school) => school.schoolid);
      setterFn({
        ...currentParams,
        ...requestObject,
        teacherIds: undefined,
        sectionIds: undefined,
        schoolIds: selectedSchoolIds,
        series: selectedSchoolIds.length ? selectedSchoolIds : null,
      });
    } else if (selectedDataScope === "Teachers" && selectedTeachers) {
      const selectedTeacherCodes = setIdsArray(selectedTeachers, teacherData, [
        "_id",
      ]).map((teacher) => teacher._id);
      setterFn({
        ...currentParams,
        ...requestObject,
        schoolIds: undefined,
        sectionIds: undefined,
        teacherIds: selectedTeacherCodes,
        series: selectedTeacherCodes.length ? selectedTeacherCodes : null,
      });
    } else if (selectedDataScope === "Sections" && selectedSections) {
      const selectedSectionIds = setIdsArray(selectedSections, sectionData, [
        "sectionId",
      ]).map((section) => section.sectionId);
      setterFn({
        ...currentParams,
        ...requestObject,
        schoolIds: undefined,
        teacherIds: undefined,
        sectionIds: selectedSectionIds,
        series: selectedSectionIds.length ? selectedSectionIds : null,
      });
    }
  };

  useEffect(() => {
    //  Request all Standard info for a given data scope
    if (standardMap) {
      const req = {
        code: userStateCode,
        skillCodesToStandards: standardMap,
        start: "1659326400",
        end: `${Math.floor(Date.now() / 1000)}`,
        // DEV time span
        // start: "1577836800",
        // end: "1672531200",
      };
      updateRequestParams({
        dataScope: selectedDataScope,
        requestObject: req,
        currentParams: globalRequestParams,
        setterFn: setGlobalRequestParams,
      });
    }
  }, [
    standardMap,
    selectedTeachers,
    selectedSchools,
    selectedSections,
    selectedDataScope,
  ]);

  // We have school data, and nothing has been selected
  useEffect(() => {
    if (selectedSchools.length === 0 && schoolsWithIntegral?.length > 0) {
      const allSchools = Object.create({});
      for (let i = 0; i < schoolsWithIntegral.length; i++) {
        allSchools[i] = true;
      }
      setSelectedSchools(allSchools);
    }
  }, [schoolsWithIntegral]);

  const selectCourse = (value: string) => {
    setCourse(value);
  };

  useEffect(() => {
    /** because we are fetching data for standards (courses) individually via their _id,
     * refetch standard info every time a new "Course" option is selected
     * */
    if (getCourse) {
      refetchStandardData();
    }
  }, [getCourse]);

  const selectStandardFn = (skillcodes: string[]) => {
    if (skillcodes.length > 0) {
      updateRequestParams({
        dataScope: selectedDataScope,
        requestObject: { skillcodes: [...skillcodes] },
        currentParams: performanceParams,
        setterFn: setPerformanceParams,
      });
      navigate(
        `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/view-data`
      );
    }
  };
  const selectStandard = (
    e: React.MouseEvent<HTMLDivElement>,
    standard: any
  ) => {
    setActivated(e.currentTarget.id);
    setSelectedStandard(standard);
  };

  const close = () => {
    setActivated("");
  };

  const handleViewAllStandards = () => {
    const skillsToStandard =
      getCourse && standardData && skillCodeToStandardMap(standardData);
    setSelectedStandard(standardData);
    setStandardMap(skillsToStandard);
    if (skillsToStandard && selectedDataScope) {
      navigate(
        `${process.env.REACT_APP_ADMIN_LINK}/reports/student-performance/view-global-data`,
        {
          replace: true,
        }
      );
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Student Performance Reports
        </h3>
        <h3 className="text-align-right text-md group rounded-md bg-gray-50 px-3 py-2 font-medium leading-6 text-gray-900">
          <div className="invisible absolute right-72 items-center text-left group-hover:visible group-hover:delay-300">
            <span className="whitespace-no-wrap relative z-10 block bg-black p-2 text-xs text-white shadow-lg">
              Student Performance Report Data only <br /> available beginning
              August 2022.
            </span>
            <div className="z-9 my-auto -mr-1 -mt-10 ml-72 h-3 w-3 rotate-45 bg-black"></div>
          </div>
          <CalendarIcon className="dm-blue -ml-1 mb-1 mr-1 inline h-6 w-6" />
          Viewing Current School Year
        </h3>
      </div>

      <StudentPerformanceNav
        selectedStandard={selectedStandard}
        selectedCourse={getCourse}
        selectedDataScope={selectedDataScope}
        selectedSchools={selectedSchools}
        selectedTeachers={selectedTeachers}
        selectedSections={selectedSections}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      {params.data === "data-scope" && (
        <SelectDataScope
          schoolsWithIntegral={schoolsWithIntegral}
          selectedDataScope={selectedDataScope}
          setSelectedDataScope={setSelectedDataScope}
          selectedSchools={selectedSchools}
          setSelectedSchools={setSelectedSchools}
          selectedSections={selectedSections}
          setSelectedSections={setSelectedSections}
          selectedTeachers={selectedTeachers}
          setSelectedTeachers={setSelectedTeachers}
        />
      )}

      {params.data === "select-standard" && (
        <>
          {allStandards && selectCourseOptions && (
            <div className="flex">
              <div className="my-2 p-2 sm:w-full lg:w-72">
                <DeltaMathSelect
                  label="Select Course"
                  options={selectCourseOptions}
                  onChangeFn={selectCourse}
                  value={getCourse}
                  // defaultVal={
                  //   selectCourseOptions.find(
                  //     (item: { key: string; val: string }) =>
                  //       item.val === getCourse
                  //   ) || selectCourseOptions[0].key
                  // }
                />
              </div>
              {standardData && featureFlag && (
                <div className="mt-10">
                  <button
                    onClick={handleViewAllStandards}
                    disabled={!standardData}
                    className="rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    View All Standards for this Course
                  </button>
                </div>
              )}
            </div>
          )}
          <Transition
            as="div"
            show={true}
            appear={true}
            enter="transition-opacity duration-300"
            enterFrom="transform opacity-0"
            enterTo="transform opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="transform opacity-100"
            leaveTo="transform opacity-0"
          >
            {getCourse &&
              userStateCode &&
              standardData &&
              Array.isArray(standardData.order) &&
              standardData?.order?.map(
                (item: string) =>
                  standardData?.data &&
                  standardData?.data[item] && (
                    <SelectStandard
                      key={item}
                      standard={standardData.data[item]}
                      depth={1}
                      path="data."
                      activatedPath={getActivated}
                      rules={[]}
                      stateCode={userStateCode}
                      onClickFn={selectStandard}
                      selectFn={selectStandardFn}
                      closeFn={close}
                    />
                  )
              )}
          </Transition>
        </>
      )}

      {params.data === "view-data" && selectedDataScope && (
        <>
          <DisplayPerformanceGraphs
            dataScope={selectedDataScope}
            requestParams={performanceParams}
            setRequestParams={setPerformanceParams}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
          />
        </>
      )}

      {params.data === "view-global-data" &&
        selectedDataScope &&
        selectedStandard && (
          <>
            <DisplayGlobalPerformanceGraph
              dataScope={selectedDataScope}
              requestParams={globalRequestParams}
              selectedStandard={selectedStandard}
              setRequestParams={setGlobalRequestParams}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              startDate={undefined}
              endDate={undefined}
            />
          </>
        )}
    </>
  );
}

export const filterSkillCodes = (skillCodes: SkillCodes) => {
  const filter = skillCodes.years_order.reduce((acc, yearCode) => {
    const skills = Object.values(skillCodes.categories).reduce(
      (acc, category) => {
        if (category.yearcode === yearCode) {
          return Array.from(new Set([...acc, ...category.skills]));
        } else {
          return acc;
        }
      },
      [] as string[]
    );
    return Array.from(new Set([...acc, ...skills]));
  }, [] as string[]);
  for (const k in skillCodes.skills) {
    if (!filter.includes(k)) {
      delete skillCodes.skills[k];
    }
  }
};

/**
 * Get skill codes from a selected Standard
 * @param intersection: an array representing the intersection
 * of skills in all skills and the selected standard
 * @param skills: object containing all potential skill codes
 * @param standard: the standard whose skills are are parsing out
 * @return an array of all skills pertaining to the specified standard
 */
export const constructSkillCodesArray = (props: {
  intersection: string[];
  skills: SkillCodes;
  standard: any;
}) => {
  const { intersection, skills, standard } = props;
  if (standard?.data && standard.order) {
    standard.order.forEach((item: any) =>
      constructSkillCodesArray({
        intersection: intersection,
        skills: skills,
        standard: standard.data[item],
      })
    );
  } else {
    Object.keys(skills).forEach(
      (skill: any) =>
        standard.module_data?.includes(skill) && intersection.push(skill)
    );
  }
  return intersection;
};

const skillCodeToStandardMap = (standard: SubStandard) => {
  function getSkillcodeMap(
    obj: SubStandard,
    map: Record<string, string[]> = {}
  ): Record<string, string[]> {
    if (obj.module_data) {
      for (const sk of obj.module_data) {
        const match = map[sk];

        if (match)
          map[sk] = Array.from(
            new Set([...match, obj.label || obj.description])
          );
        else map[sk] = [obj.label || obj.description];
      }
      return map;
    } else {
      for (const k in obj.data) {
        map = getSkillcodeMap(obj.data[k], map);
      }
    }
    return map;
  }

  let skToStandardMap: Record<string, string[]> = {};
  for (const key in standard.data) {
    skToStandardMap = getSkillcodeMap(standard.data[key], skToStandardMap);
  }
  return skToStandardMap;
};
