import React from "react";
import { DemoMode } from "../../utils/demo";

export default function LoginAs({
  teachercode,
  first,
  last,
}: {
  teachercode: string;
  first: string;
  last: string;
}) {
  return (
    <p className="text-md truncate font-medium text-blue-900">
      <a
        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        href={`/app/impersonate?type=teacher&id=${teachercode}`}
      >
        Login as <DemoMode value={`${first} ${last}`} type="person_full" />
      </a>
    </p>
  );
}
