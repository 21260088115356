import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { deltamathAPI } from "../utils";
import { DuplicateIcon } from "@heroicons/react/solid";
import axios from "axios";
import { getDatesArray } from "../utils/index";
import { copyToClip } from "../../shared/DeltaMathModal";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";

interface SubmitCodePayload {
  purchaser: string;
  contact: string;
  subscriptions_original: number;
  subscriptions_remaining: number;
  revenue: number;
  invoice: string;
  code: string;
  note: string;
  expiration: number; //the API takes "expiration" as a parameter but stores and returns the same value as "expires"
  delay_code: string;
  license_tier: string;
  has_integral: boolean | undefined;
}

const now = Math.floor(new Date().getTime() / 1000.0);
const twoWeeks = now + 60 * 60 * 24 * (7 * 2); // 2 weeks in seconds
const fourWeeks = now + 60 * 60 * 24 * (7 * 4); // 4 weeks in seconds
const october = getDatesArray({})[0];
const octoberUnix = Math.floor(october.getTime() / 1000.0); // convert to UNIX epoch

export const CreateCode = () => {
  const toastContext = useDeltaToastContext();
  const [payload, setPayload] = useState<SubmitCodePayload>({
    purchaser: "",
    contact: "",
    subscriptions_original: 0,
    subscriptions_remaining: 0,
    revenue: 0,
    invoice: "",
    code: "",
    note: "",
    expiration: octoberUnix,
    delay_code: "no",
    license_tier: "",
    has_integral: undefined,
  });
  const [isEdit, setIsEdit] = useState<string>("new");
  const [codeToLookup, setCodeToLookup] = useState<string>();
  const [newCodeInfo, setNewCodeInfo] = useState({
    subscriptionsCreated: 0,
    codeCreated: "",
    newExpiration: "",
    license_tier: "",
  });
  const [open, setOpen] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);

  /*
   * For these 3 API reqests, a "password" field is included on the existing Manager but
   * not required to get a response (at least not it DEV).  Is that something we want to
   * enforce?
   */
  const {
    isLoading: newCodeIsLoading,
    error: newCodeError,
    data: newCodeData,
    refetch: refetchNewCode,
  } = useQuery(
    "generate_code",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: "generate-code",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setPayload({ ...payload, code: request.data.code });
      return request.data;
    },
    {
      enabled: false,
    }
  );

  useEffect(() => {
    if (newCodeData?.message) {
      toastContext.addToast({
        status: "Error",
        message: newCodeData.message,
      });
    }
  }, [newCodeData]);

  const {
    isLoading: lookupIsLoading,
    error: lookupError,
    data: lookupData,
    refetch: refetchLookup,
  } = useQuery(
    "lookup_code",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: "lookup-code",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          code: codeToLookup,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setPayload({ ...request.data, expiration: request.data.expires });
      return request.data;
    },
    {
      enabled: false,
      cacheTime: 0,
    }
  );

  const {
    isLoading: submitIsLoading,
    error: submitError,
    data: submitData,
    refetch: refetchSubmit,
  } = useQuery(
    "site_license",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: isEdit === "new" ? "new-code" : "edit-code",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          purchaser: payload.purchaser,
          contact: payload.contact,
          subscriptions_original: payload.subscriptions_original,
          subscriptions_remaining: payload.subscriptions_remaining,
          revenue: payload.revenue,
          invoice: payload.invoice,
          code: payload.code,
          license_tier: payload.license_tier,
          note: payload.note,
          expiration: payload.expiration,
          delay_code: payload.delay_code,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (request.status === 200) {
        setNewCodeInfo({
          subscriptionsCreated: payload.subscriptions_original,
          codeCreated: payload.code,
          license_tier:
            payload.license_tier === "integral" ? "Integral" : "Plus",
          newExpiration: payload.expiration
            ? new Date(payload.expiration * 1000).toLocaleDateString("en-US")
            : new Date(octoberUnix * 1000).toLocaleDateString("en-US"),
        });
        setOpen(true);
        const successBlock = document.getElementById("successMessage");
        successBlock && window.scrollTo(0, 400); //scroll to success message
      }
      return request.data;
    },
    {
      enabled: false,
    }
  );

  // not necessary but not useless either
  const clearFields = () => {
    setPayload({
      purchaser: "",
      contact: "",
      subscriptions_original: 0,
      subscriptions_remaining: 0,
      revenue: 0,
      invoice: "",
      code: "",
      note: "",
      expiration: 0,
      delay_code: "",
      license_tier: "",
      has_integral: undefined,
    });
  };

  useEffect(() => {
    if (submitData?.message !== undefined) {
      toastContext.addToast({
        status: "Error",
        message: `A submission error has occurred: ${submitData?.message}`,
      });
    }
  }, [submitData]);

  useEffect(() => {
    if (lookupData?.message !== undefined) {
      toastContext.addToast({
        status: "Error",
        message: `A Lookup error has occurred: ${lookupData?.message}`,
      });
    }
  }, [lookupData]);

  useEffect(() => {
    if (lookupError !== null) {
      toastContext.addToast({
        status: "Error",
        message: `A Lookup error has occurred: ${lookupError}`,
      });
    }
  }, [lookupError]);

  useEffect(() => {
    if (submitError !== null) {
      toastContext.addToast({
        status: "Error",
        message: `A Submit error has occurred: ${submitError}`,
      });
    }
  }, [submitError]);

  useEffect(() => {
    if (newCodeError !== null) {
      toastContext.addToast({
        status: "Error",
        message: `A New Code error has occurred: ${newCodeError}`,
      });
    }
  }, [newCodeError]);

  return (
    <>
      <div className="px-2 md:px-0">
        <h1 className="py-4 text-2xl font-semibold text-gray-900 md:mx-2">
          Create or Edit Access Code
        </h1>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-2 md:gap-8">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  await refetchSubmit();
                }}
              >
                <div className="overflow-hidden shadow sm:rounded-md">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-8 gap-8">
                      <div className={"col-span-8 md:col-span-4"}>
                        <label
                          htmlFor="access-code-new-or-edit"
                          className="block text-sm font-medium text-gray-700"
                        ></label>
                        <select
                          id="access-code-new-or-edit"
                          name="access-code-new-or-edit"
                          required
                          onChange={(e) => {
                            setIsEdit(e.target.value);
                            clearFields();
                          }}
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value="new">Create a new access code</option>
                          <option value="edit">Edit an access code</option>
                        </select>
                      </div>

                      {isEdit === "edit" && (
                        <div className="col-span-8 md:col-span-4">
                          <div>
                            <label
                              htmlFor="email"
                              className="block text-sm font-medium text-gray-700"
                            ></label>
                            <div className="mt-1 flex rounded-md shadow-sm">
                              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                                <input
                                  type="text"
                                  name="access_code"
                                  id="access_code"
                                  placeholder="Access code or invoice to look up"
                                  onChange={(e) =>
                                    setCodeToLookup(e.target.value)
                                  }
                                  className="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                />
                              </div>
                              <button
                                type="button"
                                onClick={() => refetchLookup()}
                                disabled={lookupIsLoading}
                                className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                              >
                                <span>
                                  {lookupIsLoading
                                    ? "Loading..."
                                    : "Lookup code"}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-span-8 md:col-span-4 md:col-start-1">
                        <label
                          htmlFor="purchaser"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Purchaser
                        </label>
                        <input
                          type="text"
                          name="purchaser"
                          id="purchaser"
                          placeholder="Name of school or district"
                          value={payload.purchaser || ""}
                          required
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              purchaser: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-8 md:col-span-4">
                        <label
                          htmlFor="contact"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Contact email (if applicable)
                        </label>
                        <input
                          type="text"
                          name="contact"
                          id="contact"
                          placeholder="Person who requested the quote or invoice"
                          value={payload.contact || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              contact: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="subscriptions_original"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Original Subscriptions
                        </label>
                        <input
                          type="number"
                          min="0"
                          name="subscriptions_original"
                          id="subscriptions_original"
                          placeholder=""
                          value={payload.subscriptions_original || 0}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              subscriptions_original: parseInt(e.target.value),
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="subscriptions_original"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Remaining Subscriptions
                        </label>
                        <input
                          type="number"
                          min="0"
                          name="subscriptions_remaining"
                          id="subscriptions_remaining"
                          placeholder=""
                          value={payload.subscriptions_remaining || 0}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              subscriptions_remaining: parseInt(e.target.value),
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="revenue"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Revenue
                        </label>
                        <input
                          type="number"
                          min="0"
                          name="revenue"
                          id="revenue"
                          placeholder=""
                          value={payload.revenue || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              revenue: parseInt(e.target.value),
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="invoice_number"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Invoice Number
                        </label>
                        <input
                          type="text"
                          name="invoive_number"
                          id="invoice_number"
                          placeholder=""
                          value={payload.invoice || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              invoice: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>

                      <div className="col-span-8 md:col-span-2">
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Access Code
                          </label>
                          <div className="mt-1 flex rounded-md shadow-sm">
                            <div className="relative flex flex-grow items-stretch focus-within:z-10">
                              <input
                                type="text"
                                name="access_code"
                                id="access_code"
                                placeholder=""
                                disabled={isEdit === "edit"}
                                value={payload.code || ""}
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    code: e.target.value,
                                  })
                                }
                                className="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              />
                            </div>
                            <button
                              type="button"
                              disabled={isEdit === "edit"}
                              onClick={() => refetchNewCode()}
                              className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                            >
                              <span>Generate</span>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="code-expiration"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Expiration
                        </label>
                        <input
                          type="text"
                          name="code-expiration"
                          id="code-expiration"
                          placeholder=""
                          value={payload.expiration || 0}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              expiration: parseInt(e.target.value),
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                        <span className="relative z-0 inline-flex rounded-md shadow-sm">
                          <button
                            type="button"
                            className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                            onClick={() =>
                              setPayload({
                                ...payload,
                                expiration: twoWeeks,
                              })
                            }
                          >
                            2 Weeks
                          </button>
                          <button
                            type="button"
                            className="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                            onClick={() =>
                              setPayload({
                                ...payload,
                                expiration: fourWeeks,
                              })
                            }
                          >
                            4 Weeks
                          </button>
                          <button
                            type="button"
                            className="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                            onClick={() =>
                              setPayload({
                                ...payload,
                                expiration: octoberUnix,
                              })
                            }
                          >
                            {`October ${october.getFullYear()}`}
                          </button>
                        </span>
                      </div>

                      <div className="col-span-8 md:col-span-1">
                        <label
                          htmlFor="delay_code"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Delay
                        </label>
                        <select
                          id="delay_code"
                          name="delay_code"
                          required
                          value={payload.delay_code}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              delay_code: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        >
                          <option value="no">No</option>
                          <option value="yes">Yes</option>
                        </select>
                      </div>

                      <div className="col-span-8 md:col-span-2">
                        <label
                          htmlFor="code-expiration"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Note
                        </label>
                        <input
                          type="text"
                          name="note"
                          id="note"
                          placeholder="Optional"
                          value={payload.note || ""}
                          onChange={(e) =>
                            setPayload({
                              ...payload,
                              note: e.target.value,
                            })
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        />
                      </div>
                      <div className="col-span-8 md:col-span-1">
                        <label
                          htmlFor="code-expiration"
                          className="block text-sm font-medium text-gray-700"
                        >
                          License Tier
                        </label>
                        <select
                          id="license-tier"
                          className="border-gray-300 md:rounded-md"
                          name="license-tier"
                          value={
                            payload.has_integral === true ||
                            payload.license_tier === "integral"
                              ? "integral"
                              : payload.has_integral === false ||
                                payload.license_tier === "plus"
                              ? "plus"
                              : ""
                          }
                          onChange={(e) => {
                            setPayload({
                              ...payload,
                              license_tier: e.target.value,
                            });
                            setSubmitDisabled(
                              e.target.value === "" ? true : false
                            );
                          }}
                        >
                          <option value=""> -- </option>
                          <option value="plus">Plus</option>
                          <option value="integral">Integral</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                    {isEdit === "edit" ? (
                      <button
                        type="submit"
                        disabled={
                          submitIsLoading || newCodeIsLoading || lookupIsLoading
                        }
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-500 disabled:opacity-50"
                      >
                        Complete Edit
                      </button>
                    ) : (
                      <button
                        type="submit"
                        disabled={
                          submitDisabled ||
                          submitIsLoading ||
                          newCodeIsLoading ||
                          lookupIsLoading
                        }
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-500 disabled:opacity-50"
                      >
                        Submit
                      </button>
                    )}{" "}
                    <button
                      type="button"
                      disabled={
                        submitIsLoading || newCodeIsLoading || lookupIsLoading
                      }
                      className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => {
                        clearFields();
                      }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {submitData?.success === true && open && (
          <>
            <h1 className="mt-2 py-4 text-2xl font-semibold text-gray-900 md:mx-2">
              Access Code Created
            </h1>
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-2 py-5 sm:p-6">
                <div>
                  {" "}
                  <div className="mt-2" id="successMessage">
                    <p className="text-sm text-gray-500">
                      I have created an access code for your teachers to use to
                      get access to{" "}
                      <b>
                        DeltaMath{" "}
                        {newCodeInfo?.license_tier &&
                          newCodeInfo.license_tier.charAt(0).toUpperCase() +
                            newCodeInfo.license_tier.slice(1)}
                        .
                      </b>
                    </p>
                    <p className="text-sm text-gray-500">They should:</p>
                    <ol className="ml-8 list-decimal text-left text-sm leading-6">
                      <li className="ext-sm pb-6 text-gray-500">
                        Create a free level DeltaMath teacher account if they
                        have not done so already
                      </li>
                      <li className="ext-sm pb-6 text-gray-500">
                        Sign in, go to TOOLS then LOGIN / SCHOOL INFORMATION to
                        type the code: <b>{`${newCodeInfo.codeCreated}`}</b>
                      </li>
                    </ol>
                    <p className="text-sm text-gray-500">
                      The code can be used{" "}
                      <b>{newCodeInfo.subscriptionsCreated}</b> time
                      {newCodeInfo.subscriptionsCreated > 1 ? "s" : ""} and the
                      subscription will expire on{" "}
                      <b>{newCodeInfo.newExpiration}</b>
                      {". "}Please let me know if you have any questions. Thank
                      you.{" "}
                    </p>
                  </div>
                  <div
                    className="mt-8 cursor-pointer text-center text-sm hover:text-slate-400"
                    onClick={() => {
                      const contentElement =
                        document.getElementById("successMessage");
                      if (contentElement) {
                        copyToClip(contentElement.innerHTML);
                      }
                    }}
                  >
                    Copy to Clipboard
                    <DuplicateIcon width="18" className="inline-block" />
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => {
                      clearFields();
                      setOpen(false);
                      window.scrollTo(0, 0); //scroll back to top of page when success message removed
                    }}
                  >
                    Reset Form
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CreateCode;
