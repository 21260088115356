import React, { Fragment, useState, useRef, useEffect } from "react";
import { Transition, Listbox, Combobox } from "@headlessui/react";
import {
  SelectorIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  PlusIcon,
} from "@heroicons/react/solid";
import { useVirtualizer } from "@tanstack/react-virtual";

/** Helper function to manage classes... */
function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

/**
 * A select box implemented using headlessui/listbox headlessui/combobox and tanstack/react-virtual to support rendering of large lists.
 * @param props Parameters to launch the select box
 * @returns Renders the select box.
 */
export const DeltaMathSelect = (props: {
  /** Label to print for the list box. */
  label?: string;
  /** The array of options to render. */
  options: SelectInput;
  /** Optional default value to select. */
  defaultVal?: string;
  /** Optional value to display if no value or default provided */
  placeholder?: string;
  /** Optional value binding to parent. */
  value?: string;
  /** Optional, a binding to the value typed into the combobox */
  input?: [string, React.Dispatch<React.SetStateAction<string>>];
  /** Function to call when a selection change occurs. */
  onChangeFn: (value: any) => void;
  /** Optional sort function to call on order change, when provided renders the sort controls. */
  sortFn?: (value: string[]) => void;
  /** Optional filterable boolean enables filtering of the select options. Note, cannot be used with sortFn. */
  filterable?: boolean;
  /** A custom select function to call when selecting items, only applies when filterable is true. */
  customSelectFn?: (value: string) => void;
}) => {
  const {
    label,
    options,
    defaultVal,
    placeholder,
    value,
    input,
    onChangeFn,
    sortFn,
    filterable,
    customSelectFn,
  } = props;
  if (filterable === true) {
    return (
      <DMComboBox
        label={label}
        options={options}
        defaultVal={defaultVal}
        placeholder={placeholder}
        value={value}
        input={input}
        onChangeFn={onChangeFn}
        customSelectFn={customSelectFn}
      />
    );
  } else {
    return (
      <DMListBox
        label={label}
        options={options}
        defaultVal={defaultVal}
        value={value}
        onChangeFn={onChangeFn}
        sortFn={sortFn}
      />
    );
  }
};

const DMComboBox = (props: {
  /** Label to print for the list box. */
  label?: string;
  /** The array of options to render. */
  options: SelectInput;
  /** Optional default value to select. */
  defaultVal?: string;
  /** Optional value binding to parent. */
  value?: string;
  /** Optional value to display if no value or default provided */
  placeholder?: string;
  /** Optional, a binding to the value typed into the combobox */
  input?: [string, React.Dispatch<React.SetStateAction<string>>];
  /** Function to call when a selection change occurs. */
  onChangeFn: (value: string) => void;
  /** A custom select function to call when selecting items. */
  customSelectFn?: (value: string) => void;
}) => {
  const {
    label,
    options,
    defaultVal,
    placeholder,
    value,
    input,
    onChangeFn,
    customSelectFn,
  } = props;

  const [query, setQuery] = input || useState("");
  const [filtered, setFiltered] = useState(options);
  const [selected, setSelected] = useState<SelectInput[0]>();

  useEffect(() => {
    if (defaultVal) {
      setSelected(options.find((x) => x.key === defaultVal) || options[0]);
    }
  }, [options]);

  useEffect(() => {
    if (value) {
      setSelected(options.find((x) => x.key === value) || options[0]);
    }
  }, [value]);

  useEffect(() => {
    const filteredOptions =
      query === ""
        ? options
        : options.filter((option) => {
            return option.val.toLowerCase().includes(query.toLowerCase());
          });
    setFiltered([...filteredOptions]);
  }, [query]);

  return (
    <Combobox
      value={selected}
      onChange={(value) => {
        onChangeFn(value.key);
      }}
    >
      {({ open }) => (
        <>
          {label && (
            <Combobox.Label className="block text-sm font-medium text-gray-700">
              {label}
            </Combobox.Label>
          )}
          <div className="relative mt-1">
            <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
              <Combobox.Input
                className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0"
                onChange={(event) => {
                  setQuery(event.target.value);
                }}
                displayValue={(option: SelectInput[0]) =>
                  input ? query : option?.val
                }
                placeholder={placeholder}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options>
                {filtered?.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  <VirtualizedComboboxOptions
                    options={filtered}
                    customSelectFn={customSelectFn}
                  />
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </>
      )}
    </Combobox>
  );
};

const VirtualizedComboboxOptions = (props: {
  options: SelectInput;
  customSelectFn?: (value: string) => void;
}) => {
  const { options, customSelectFn } = props;
  const parentRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: options.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    overscan: 5,
  });

  return (
    <div
      ref={parentRef}
      className="absolute z-10 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow: any) => (
          <Combobox.Option
            key={virtualRow.index}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
            }}
            className={({ active }) =>
              classNames(
                active ? "bg-dm-light-blue text-white" : "text-gray-900",
                "relative cursor-default select-none py-2 pl-3 pr-9"
              )
            }
            value={options[virtualRow.index]}
            disabled={!!customSelectFn}
          >
            {({ selected, active }) => (
              <>
                <span
                  className={classNames(
                    selected ? "font-semibold" : "font-normal",
                    "block truncate"
                  )}
                >
                  {options[virtualRow.index].val}
                </span>

                {selected && (
                  <span
                    className={classNames(
                      active ? "text-white" : "text-indigo-600",
                      "absolute inset-y-0 right-0 flex items-center pr-4"
                    )}
                  ></span>
                )}
                {!!customSelectFn && (
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                    <button
                      type="button"
                      className="rounded-md focus:outline-none focus:ring-dm-darkest-blue"
                      onClick={(e) => {
                        customSelectFn(options[virtualRow.index].key);
                        e.stopPropagation();
                      }}
                    >
                      <PlusIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </span>
                )}
              </>
            )}
          </Combobox.Option>
        ))}
      </div>
    </div>
  );
};

const DMListBox = (props: {
  /** Label to print for the list box. */
  label?: string;
  /** The array of options to render. */
  options: SelectInput;
  /** Optional default value to select. */
  defaultVal?: string;
  /** Optional value binding to parent. */
  value?: string;
  /** Function to call when a selection change occurs. */
  onChangeFn: (value: string) => void;
  /** Optional sort function to call on order change, when provided renders the sort controls. */
  sortFn?: (value: string[]) => void;
}) => {
  const { label, options, defaultVal, value, onChangeFn, sortFn } = props;

  const [selected, setSelected] = useState(
    options.find((x) => x.key === defaultVal) || options[0]
  );

  useEffect(() => {
    setSelected(options.find((x) => x.key === defaultVal) || options[0]);
  }, [options]);

  useEffect(() => {
    if (value) {
      setSelected(options.find((x) => x.key === value) || options[0]);
    }
  }, [value]);

  return (
    <Listbox
      value={selected}
      onChange={(value) => {
        onChangeFn(value.key);
      }}
    >
      {({ open }) => (
        <>
          {label && (
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
          )}
          <div className="relative mt-1">
            <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-dm-light-blue focus:outline-none focus:ring-1 focus:ring-dm-light-blue sm:text-sm">
              <span className="block truncate">{selected.val}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options>
                <VirtualizedListboxOptions
                  options={options}
                  defaultVal={defaultVal}
                  sortFn={sortFn}
                />
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

const VirtualizedListboxOptions = (props: {
  options: SelectInput;
  defaultVal?: string;
  sortFn?: (value: string[]) => void;
}) => {
  const { options, defaultVal, sortFn } = props;
  const parentRef = useRef<HTMLDivElement>(null);

  const [menuOptions, setMenuOptions] = useState(options);
  const [hover, setHover] = useState<SelectInput[0] | undefined>(
    options.find((x) => x.key === defaultVal) || options[0]
  );

  const moveUp = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: string
  ) => {
    const options = menuOptions;
    const place = options.map((x) => x.val).indexOf(value);
    if (place !== 0) {
      [options[place - 1], options[place]] = [
        options[place],
        options[place - 1],
      ];
      setMenuOptions([...options]);
      if (sortFn) {
        sortFn(options.map((x) => x.key));
      }
    }
    e.stopPropagation();
  };

  const moveDown = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: string
  ) => {
    const options = menuOptions;
    const place = options.map((x) => x.val).indexOf(value);
    if (place !== options.length - 1) {
      [options[place + 1], options[place]] = [
        options[place],
        options[place + 1],
      ];
      setMenuOptions([...options]);
      if (sortFn) {
        sortFn(options.map((x) => x.key));
      }
    }
    e.stopPropagation();
  };

  const rowVirtualizer = useVirtualizer({
    count: menuOptions.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    overscan: 5,
  });

  return (
    <div
      ref={parentRef}
      className="absolute z-10 mt-1 max-h-96 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: "100%",
          position: "relative",
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow: any) => (
          <Listbox.Option
            key={virtualRow.index}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`,
            }}
            className={({ active }) =>
              classNames(
                active ? "bg-dm-light-blue text-white" : "text-gray-900",
                "relative cursor-default select-none py-2 pl-3 pr-9"
              )
            }
            value={menuOptions[virtualRow.index]}
            onMouseEnter={() => setHover(menuOptions[virtualRow.index])}
          >
            {({ selected, active }) => (
              <>
                <span
                  className={classNames(
                    selected ? "font-semibold" : "font-normal",
                    "block truncate"
                  )}
                >
                  {menuOptions[virtualRow.index].val}
                </span>

                {selected && (
                  <span
                    className={classNames(
                      active ? "text-white" : "text-indigo-600",
                      "absolute inset-y-0 right-0 flex items-center pr-4"
                    )}
                  ></span>
                )}
                {hover &&
                  hover.key === menuOptions[virtualRow.index].key &&
                  props.sortFn && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-white">
                      <button
                        type="button"
                        className="rounded-md focus:outline-none focus:ring-dm-darkest-blue"
                        onClick={(e) =>
                          moveUp(e, menuOptions[virtualRow.index].val)
                        }
                      >
                        <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        className="rounded-md focus:outline-none focus:ring-dm-darkest-blue"
                        onClick={(e) =>
                          moveDown(e, menuOptions[virtualRow.index].val)
                        }
                      >
                        <ChevronDownIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                        />
                      </button>
                    </span>
                  )}
              </>
            )}
          </Listbox.Option>
        ))}
      </div>
    </div>
  );
};

/** Defines the input options for DeltaMathSelect */
export type SelectInput = Array<{
  key: string;
  val: string;
}>;
