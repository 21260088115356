import { Dispatch, Fragment, SetStateAction, useContext } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";

import {
  CalculatorIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/outline";
import clsx from "clsx";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { Link } from "react-router-dom";
import CustomGraphingIcon from "./icons/CustomGraphIcon";

const items = [
  {
    name: "Graphing Calculator",
    href: "/calculator/graphingcalc.html",
    icon: <CustomGraphingIcon />,
  },
];

export default function CalculatorButton(): JSX.Element {
  const { showKeyboard, setShowKeyboard, showCalculator, setShowCalculator } =
    useContext(StudentSectionsContext);

  return (
    <Disclosure
      as="div"
      key="archiveCourses"
      className="space-y-1"
      defaultOpen={false}
    >
      {({ open }) => (
        <>
          <div className="inline-flex w-full justify-between p-2 text-sm text-dm-gray-200 hover:text-dm-gray-600">
            <button
              aria-label="Calculator"
              type="button"
              onClick={() => {
                if (showKeyboard) {
                  setShowCalculator(!showCalculator);
                  setShowKeyboard(!showKeyboard);
                } else if (!showKeyboard) {
                  setShowKeyboard(true);
                  setShowCalculator(true);
                }
              }}
            >
              <i className="far fa-calculator mr-2 h-6 w-6 text-xl leading-none"></i>
              {/* <CalculatorIcon
                className="mr-2 h-6 w-6 text-dm-gray-200"
                aria-hidden="true"
              /> */}
              <span className="flex-1 text-left text-sm text-dm-gray-600">
                Calculator
              </span>
            </button>
            <Disclosure.Button className="group items-end">
              {open ? (
                <ChevronUpIcon
                  className="ml-2 h-5 w-5 text-dm-gray-200"
                  aria-hidden="true"
                />
              ) : (
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-dm-gray-200"
                  aria-hidden="true"
                />
              )}
            </Disclosure.Button>
          </div>

          <Disclosure.Panel className="space-y-1">
            {items.map((item, index) => (
              <Link
                to={item.href}
                key={`calckey-${index}`}
                className={clsx(
                  "group flex items-center rounded-md px-3 py-2 pl-11 text-sm text-dm-gray-600"
                )}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="mr-2 h-6 w-6">{item.icon}</span>
                <span className="text-dm-gray-600">{item.name}</span>
              </Link>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
