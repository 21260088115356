import DMKey from "./DMKey";
import KeyboardControls from "./KeyboardControls";

const AlphabeticalKeyboard = ({
  shift,
  input,
  focusedInput,
  setShift,
  setShowNumeric,
  handleEnter,
}: {
  shift: boolean;
  input: any;
  focusedInput: any;
  setShift: (state: boolean) => void;
  setShowNumeric: (state: boolean) => void;
  handleEnter: () => void;
}) => {
  return (
    <div className="grid grid-cols-8 space-x-3 sm:mx-12">
      {shift ? (
        <div
          aria-label={"alphabetical-keyboard-uppercase"}
          className="col col-span-6 space-y-1"
        >
          <div className="grid grid-cols-10 space-x-1">
            <DMKey
              btnText={"Q"}
              ansType={"icon"}
              ariaLabel={"uppercase Q"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"W"}
              ansType={"icon"}
              ariaLabel={"uppercase W"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"E"}
              ansType={"icon"}
              ariaLabel={"uppercase E"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"R"}
              ansType={"icon"}
              ariaLabel={"uppercase R"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"T"}
              ansType={"icon"}
              ariaLabel={"uppercase T"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"Y"}
              ansType={"icon"}
              ariaLabel={"uppercase Y"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"U"}
              ansType={"icon"}
              ariaLabel={"uppercase U"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"I"}
              ansType={"icon"}
              ariaLabel={"uppercase I"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"O"}
              ansType={"icon"}
              ariaLabel={"uppercase O"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"P"}
              ansType={"icon"}
              ariaLabel={"uppercase P"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          </div>
          <div className="grid grid-cols-10 space-x-1">
            <DMKey
              btnText={"\\$"}
              btnOutput={"\\$"}
              ariaLabel={"dollar sign"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"A"}
              ansType={"icon"}
              ariaLabel={"uppercase A"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"S"}
              ansType={"icon"}
              ariaLabel={"uppercase S"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"D"}
              ansType={"icon"}
              ariaLabel={"uppercase D"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"F"}
              ansType={"icon"}
              ariaLabel={"uppercase F"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"G"}
              ansType={"icon"}
              ariaLabel={"uppercase G"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"H"}
              ansType={"icon"}
              ariaLabel={"uppercase H"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"J"}
              ansType={"icon"}
              ariaLabel={"uppercase J"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"K"}
              ansType={"icon"}
              ariaLabel={"uppercase K"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"L"}
              ansType={"icon"}
              ariaLabel={"uppercase L"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          </div>
          <div className="grid grid-cols-10 space-x-1">
            <button
              onClick={() => setShift(!shift)}
              aria-label="shift"
              className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            >
              SHIFT
            </button>
            <DMKey
              btnText={"Z"}
              ansType={"icon"}
              ariaLabel={"uppercase Z"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"X"}
              ansType={"icon"}
              ariaLabel={"uppercase X"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"C"}
              ansType={"icon"}
              ariaLabel={"uppercase C"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"V"}
              ansType={"icon"}
              ariaLabel={"uppercase V"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"B"}
              ansType={"icon"}
              ariaLabel={"uppercase B"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"N"}
              ansType={"icon"}
              ariaLabel={"uppercase N"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"M"}
              ansType={"icon"}
              ariaLabel={"uppercase M"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={","}
              btnOutput={","}
              ariaLabel={"comma"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          </div>
          <div className="grid grid-cols-10 space-x-1">
            <button
              onClick={() => setShowNumeric(true)}
              aria-label={"show-numeric-keyboard"}
              className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            >
              1 2 3
            </button>
            <DMKey
              btnText={"["}
              btnOutput={"["}
              ariaLabel={"open bracket"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"]"}
              btnOutput={"]"}
              ariaLabel={"close bracket"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"SPACE"}
              ansType={"icon"}
              aria-label={"space"}
              btnOutput={"\\ "}
              btnCmd={true}
              ariaLabel={"space"}
              focusedInput={focusedInput}
              inputsRef={input}
              className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            />
            <DMKey
              btnText={"."}
              btnOutput={"\\."}
              ariaLabel={"period"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <button className="border-1 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400">
              \
            </button>
            <DMKey
              btnText={"'"}
              btnOutput={"'"}
              ariaLabel={"apostrophe"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"?"}
              btnOutput={"?"}
              ariaLabel={"question mark"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          </div>
        </div>
      ) : (
        <div className="col col-span-6 space-y-1">
          <div className="grid grid-cols-10 space-x-1">
            <DMKey
              btnText={"q"}
              ansType={"icon"}
              ariaLabel={"lowercase q"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"w"}
              ansType={"icon"}
              ariaLabel={"lowercase w"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"e"}
              ansType={"icon"}
              ariaLabel={"lowercase e"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"r"}
              ansType={"icon"}
              ariaLabel={"lowercase r"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"t"}
              ansType={"icon"}
              ariaLabel={"lowercase t"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"y"}
              ansType={"icon"}
              ariaLabel={"lowercase y"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"u"}
              ansType={"icon"}
              ariaLabel={"lowercase u"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"i"}
              ansType={"icon"}
              ariaLabel={"lowercase i"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"o"}
              ansType={"icon"}
              ariaLabel={"lowercase o"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"p"}
              ansType={"icon"}
              ariaLabel={"lowercase p"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          </div>
          <div className="grid grid-cols-10 space-x-1">
            <DMKey
              btnText={"\\$"}
              btnOutput={"\\$"}
              ariaLabel={"dollar sign"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"a"}
              ansType={"icon"}
              ariaLabel={"lowercase a"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"s"}
              ansType={"icon"}
              ariaLabel={"lowercase s"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"d"}
              ansType={"icon"}
              ariaLabel={"lowercase d"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"f"}
              ansType={"icon"}
              ariaLabel={"lowercase f"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"g"}
              ansType={"icon"}
              ariaLabel={"lowercase g"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"h"}
              ansType={"icon"}
              ariaLabel={"lowercase h"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"j"}
              ansType={"icon"}
              ariaLabel={"lowercase j"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"k"}
              ansType={"icon"}
              ariaLabel={"lowercase k"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"l"}
              ansType={"icon"}
              ariaLabel={"lowercase l"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          </div>
          <div className="grid grid-cols-10 space-x-1">
            <button
              onClick={() => setShift(!shift)}
              aria-label="shift"
              className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            >
              SHIFT
            </button>
            <DMKey
              btnText={"z"}
              ansType={"icon"}
              ariaLabel={"lowercase z"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"x"}
              ansType={"icon"}
              ariaLabel={"lowercase x"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"c"}
              ansType={"icon"}
              ariaLabel={"lowercase c"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"v"}
              ansType={"icon"}
              ariaLabel={"lowercase v"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"b"}
              ansType={"icon"}
              ariaLabel={"lowercase b"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"n"}
              ansType={"icon"}
              ariaLabel={"lowercase n"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"m"}
              ansType={"icon"}
              ariaLabel={"lowercase m"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={","}
              btnOutput={","}
              ariaLabel={"comma"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          </div>
          <div className="grid grid-cols-10 space-x-1">
            <button
              onClick={() => setShowNumeric(true)}
              aria-label={"show-numerical-keyboard"}
              className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            >
              1 2 3
            </button>
            <DMKey
              btnText={"["}
              btnOutput={"["}
              ariaLabel={"open bracket"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"]"}
              btnOutput={"]"}
              ariaLabel={"close bracket"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"SPACE"}
              ansType={"icon"}
              aria-label={"space"}
              btnOutput={"\\ "}
              btnCmd={true}
              ariaLabel={"space"}
              focusedInput={focusedInput}
              inputsRef={input}
              className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            />
            <DMKey
              btnText={"."}
              btnOutput={"\\."}
              ariaLabel={"period"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText="\"
              btnOutput="\"
              btnCmd={true}
              ansType={"icon"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"'"}
              btnOutput={"'"}
              ariaLabel={"apostrophe"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
            <DMKey
              btnText={"?"}
              btnOutput={"?"}
              ariaLabel={"question mark"}
              focusedInput={focusedInput}
              inputsRef={input}
            />
          </div>
        </div>
      )}
      <KeyboardControls
        input={input}
        focusedInput={focusedInput}
        handleEnter={handleEnter}
      />
    </div>
  );
};

export default AlphabeticalKeyboard;
