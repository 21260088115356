import clsx from "clsx";
import { ExclamationIcon } from "@heroicons/react/outline";

type MaxProblemsMessageProps = {
  showSolution: boolean;
  problemsLeft: number;
  maxProblems: number;
  requiredProblems: number;
};

export default function MaxProblemsMessage({
  showSolution,
  problemsLeft,
  maxProblems,
  requiredProblems,
}: MaxProblemsMessageProps): JSX.Element {
  const maxOne = maxProblems === 1;
  const manyProblemsLeft = !maxOne && problemsLeft > requiredProblems;
  const fewProblemsLeft =
    !maxOne && problemsLeft > 0 && problemsLeft <= requiredProblems;
  const noProblemsLeft = !maxOne && problemsLeft === 0;

  const topPosition = !manyProblemsLeft;

  return (
    <>
      <div
        className={clsx(
          "flex gap-4 border p-4",
          topPosition ? "rounded-t-lg" : "rounded-b-lg",
          fewProblemsLeft
            ? "border-dm-warning-800 bg-dm-warning-100"
            : "border-[#E4E6EA] bg-white"
        )}
      >
        <ExclamationIcon
          className={clsx(
            "w-6 self-start",
            fewProblemsLeft ? "text-dm-warning-800" : "text-dm-gray-200"
          )}
          aria-label="Warning"
        />
        <div>
          {maxOne &&
            `This ${
              showSolution ? "was" : "is"
            } the only question in this section.`}
          {manyProblemsLeft &&
            `You have up to ${problemsLeft} questions left to raise your score.`}
          {fewProblemsLeft &&
            `You only have ${problemsLeft} question${
              problemsLeft > 1 ? "s" : ""
            } left to raise your score.`}
          {noProblemsLeft &&
            `You have completed the maximum number of questions allowed by your teacher.`}
        </div>
      </div>
    </>
  );
}
