import { Transition } from "@headlessui/react";
import { Fragment, useState, useRef, useContext, useEffect } from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { deltamathAPI } from "../../manager/utils";
import { PlusCircleIcon, ArrowRightIcon } from "@heroicons/react/outline";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import clsx from "clsx";
import { useUserContext } from "../../shared/contexts/UserContext";

export default function AddNewClass() {
  const userContext = useUserContext();
  const [showField, setShowField] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const token = userContext.getJWT();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();
  const { sectionsRefresh, assignmentsRefresh, setLoadingData } = useContext(
    StudentSectionsContext
  );
  const [sectionId, setSectionId] = useState("");

  let timeout: ReturnType<typeof setTimeout> = setTimeout(() => "", 4000);
  //e: React.ChangeEvent<HTMLInputElement>
  //e: React.FormEvent<HTMLInputElement>
  const submitCode = (e: any) => {
    e.preventDefault();
    if (!sectionId) return;
    setSubmitDisabled(true);
    addSection.mutate(sectionId);
  };

  useEffect(() => {
    if (showField) {
      inputRef?.current?.focus();
    } else {
      if (inputRef?.current) inputRef.current.value = "";
      setSectionId("");
    }
  }, [showField]);

  const handleFocus = () => {
    clearTimeout(timeout);
  };

  const handleBlur = (e: any) => {
    clearTimeout(timeout);
    if (!e.currentTarget.contains(e.relatedTarget)) {
      timeout = setTimeout(() => setShowField(false), 4000);
    }
  };

  const handleChange = (e: any) => {
    clearTimeout(timeout);
    setSectionId(e.target.value);
  };

  // function closeModal() {
  //   setIsModalOpen(false);
  //   if (showField) {
  //     inputRef?.current?.focus();
  //   }
  // }

  const navigate = useNavigate();

  const addSection = useMutation(
    (sectionId: any) => {
      clearTimeout(timeout);
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: true,
      }));
      return axios.post(deltamathAPI() + `/student/join/${sectionId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: async (response: any) => {
        toastContext.addToast({
          status: "Success",
          message: "Class added successfully",
        });
        queryClient.invalidateQueries("/student/data/assignments");
        queryClient.invalidateQueries("/student/data/sections");
        await sectionsRefresh();
        await assignmentsRefresh();

        // setDmAssignmentData((prevState: any) => ({
        //   ...prevState,
        //   ...response,
        // }));
        clearTimeout(timeout);
        setShowField(false);
        if (inputRef?.current) inputRef.current.value = "";
        setSectionId("");
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: false,
        }));
        navigate(
          `${process.env.REACT_APP_STUDENT_LINK}/${response.data}/upcoming`
        );
      },
      onError: (error: any) => {
        clearTimeout(timeout);
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Error",
          message: error?.response?.data?.message,
        }));
      },
      onSettled: () => {
        setSubmitDisabled(false);
      },
    }
  );

  return (
    <>
      <div className="group relative h-[40px]">
        <Transition
          as={Fragment}
          show={!showField}
          enter="transition-opacity duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200 delay-75"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <button
            className="group absolute flex items-center rounded-md p-2 text-sm text-dm-brand-blue-500 hover:text-dm-brand-blue-600"
            onClick={() => setShowField(true)}
          >
            <PlusCircleIcon
              className="mr-2 h-6 w-6 flex-shrink-0"
              aria-hidden="true"
            />
            <span className="truncate">Add New Class</span>
          </button>
        </Transition>

        <Transition
          as={Fragment}
          show={showField}
          enter="transition-opacity duration-300 delay-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute flex w-full rounded-md shadow-sm"
            onBlur={handleBlur}
          >
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <input
                ref={inputRef}
                type="text"
                name="classcode"
                id="classcode"
                className="block w-full rounded-none rounded-l-md border-gray-300 placeholder-gray-300 focus:border-dm-brand-blue-500 focus:ring-dm-brand-blue-500 sm:text-sm"
                placeholder="Enter Code (XXXX-XXXX)"
                disabled={submitDisabled}
                onFocus={handleFocus}
                onClick={handleFocus}
                onChange={handleChange}
              />
            </div>
            <button
              type="button"
              className={clsx(
                submitDisabled || !sectionId
                  ? "cursor-not-allowed bg-dm-brand-blue-500"
                  : "bg-slate-700 enabled:hover:bg-slate-800",
                "relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 p-2 text-sm text-white focus:border-dm-brand-blue-500 focus:outline-none focus:ring-1 focus:ring-dm-brand-blue-500"
              )}
              onClick={submitCode}
              disabled={submitDisabled}
              onFocus={handleFocus}
              aria-label="Submit code for new class"
            >
              {submitDisabled ? (
                "..."
              ) : (
                <ArrowRightIcon className="h-4 w-4" aria-hidden="true" />
              )}
            </button>
          </div>
        </Transition>
      </div>
    </>
  );
}
