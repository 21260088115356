import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { isFuture } from "date-fns";
import { timeConverter, useDMQuery } from "../../utils";
import { deltamathAPI, getDatesArray, getDefaultDateSelected } from "../utils";
import { DmModal, DmLoadingSpinner } from "../utils/functions";
import { InvoiceSearch } from "./InvoiceSearch";
import { TIMEZONES_FOR_PICKER, US_STATES } from "../utils";
import EstimateInfo from "./EstimateInfo";
import CurrentLicense from "./quote-lookup/CurrentLicense";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";

export interface ActivationSummary {
  numStudents: number;
  numTeachers: number;
  adminEmail?: string;
  type?: string;
  tier: string;
  rostering: string;
  lms: string;
}

export const Invoice = () => {
  const toastContext = useDeltaToastContext();
  const params = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const nextDates = getDatesArray({ max: 3 });
  const defaultDate = getDefaultDateSelected({}).getTime() / 1000;

  const [edit, setEdit] = useState(true);
  const [
    showSuccessMessageWithThisUpdate,
    setShowSuccessMessageWithThisUpdate,
  ] = useState(true);

  const [snnRenewal, setSnnRenewal] = useState<boolean | undefined>(undefined);
  const [priceChangeWarning, setPriceChangeWarning] = useState("");
  const [activateDisabled, setActivateDisabled] = useState(true);
  const [disabledMessages, setDisabledMessages] = useState([] as string[]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [showAddPONumber, setShowAddPONumber] = useState(false);
  const [quickbooksData, setQuickbooksData] = useState<{
    CustomerMemo?: {
      value?: string;
    };
    TotalAmt?: number;
    Line?: any[];
    DocNumber?: string;
    Id?: string;
    CustomerRef?: {
      value: string;
    };
    message?: string;
  }>({});
  const [quickbooksInvoiceData, setQuickbooksInvoiceData] = useState<{
    CustomerMemo?: {
      value?: string;
    };
    TotalAmt?: number;
    Line?: any[];
    DocNumber?: string;
    Id?: string;
    CustomerRef?: {
      value: string;
    };
  }>({});

  const defaultResetPayload = {
    id: "", //ncesID -- could be renamed in the Invoice model.
    type: "",
    name: "",
    status: null,
    poNumber: "",
    invoiceId: "",
    invoiceNumber: "",
    contactName: "",
    contactEmail: "",
    adminFirst: "",
    adminLast: "",
    adminEmail: "",
    notes: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    priceInfo: {
      price: 0,
      numberOfStudents: 0,
      numberOfTeachers: 0,
      discount: 0,
      priceWithDiscount: 0,
      minPrice: 0,
      sendCustomerEmail: true,
    },
    hold_until: undefined,
    amount: 1,
    licenseTier: "",
    lms: "",
    rosteringSystem: "",
    integrationStatus: "",
    site_timezone: "",
    cleverDistrictId: "",
    classlinkTenantId: "",
    rosteringData: {},
    snnLicenseType: null,
    expiration: defaultDate,
  };

  const [selectedPDF, setSelectedPDF] = useState<File>();

  const [showAddress, setShowAddress] = useState(true);
  const [showRosteringForm, setshowRosteringForm] = useState(false);

  const [activationSummary, setActivationSummary] =
    useState<ActivationSummary>();
  const [showActivationSummary, setShowActivationSummary] = useState(false);
  const [payload, setPayload] = useState<{
    hold_until?: number;
    hiddenFromView?: boolean;
    quoteNumber?: string;
    schoolYear?: string;
    id?: string;
    invoiceId?: string;
    invoiceNumber?: string;
    type?: string;
    name?: string;
    status?: string | null;
    poNumber?: string;
    poUploadedTime?: number;
    contactName?: string;
    contactEmail?: string;
    adminFirst?: string;
    adminLast?: string;
    adminEmail?: string;
    notes?: string;
    address?: string;
    city?: string;
    state?: string;
    zip?: string;
    priceInfo: {
      price?: number;
      numberOfStudents: number;
      numberOfTeachers: number;
      discount?: number;
      priceWithDiscount?: number;
      minPrice?: number;
      sendCustomerEmail?: boolean;
      // licenseType?: string;
    };
    amount?: number;
    licenseTier: string;
    lms: string;
    rosteringSystem: string;
    integrationStatus?: string;
    cleverDistrictId?: string;
    classlinkTenantId?: string;
    site_timezone?: string;
    expiration?: number;
    time?: number;
    quoteId?: string;
    customerId?: string;
    rosteringData?: {
      [key: string]: any[];
    };
    snnLicenseType?: string | null;
  }>(defaultResetPayload);
  const [holdUntilDate, setHoldUntilDate] = useState<Date | undefined>(
    undefined
  );
  const [rosteringData, setRosteringData] = useState<{ [key: string]: any[] }>(
    defaultResetPayload["rosteringData"]
  );

  const { data, refetch, isLoading, error } = useDMQuery({
    path: `/manager_new/invoices/${params.quoteNumber}`,
    customerServiceHeader: true,
    queryOptions: {
      enabled: false,
      onError: (error: {
        code: number;
        statusText: string;
        message: string;
      }) => {
        toastContext.addToast({
          status: "Error",
          message: error.message,
        });
      },
      onSuccess: (data: any) => {
        if (!data) {
          toastContext.addToast({
            status: "Error",
            message: "No DeltaMath Record was found.",
          });
        }
      },
    },
  });

  const { data: schoolDiggerData, refetch: schoolDiggerRefetch } = useDMQuery({
    path: `/manager_new/license/schooldigger-lookup?nces_id=${payload.id}`,
    customerServiceHeader: true,
    queryOptions: {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 5,
    },
  });

  const { data: lmsIntegrationData, refetch: lmsRefetch } = useDMQuery({
    path: `/manager_new/license/lms-data`,
    customerServiceHeader: true,
    params: {
      integration: payload.classlinkTenantId ? "class-link" : "clever",
      resource_id: payload.classlinkTenantId || payload.cleverDistrictId,
      nces_id: payload.id,
    },
    queryOptions: {
      enabled: false,
    },
  });

  useEffect(() => {
    refetch();
  }, [params]);

  useEffect(() => {
    setPriceChangeWarning("");
  }, []);

  // We have new Schooldigger data, update the payload and the form
  useEffect(() => {
    if (schoolDiggerData) {
      if (schoolDiggerData?.code >= 400) {
        toastContext.addToast({
          status: "Error",
          message:
            "School not found in School Digger" + schoolDiggerData.message,
        });
      } else {
        setPayload({
          ...payload,
          name:
            schoolDiggerData.type === "school"
              ? schoolDiggerData.schooldigger.schoolName
              : schoolDiggerData.schooldigger.districtName,
          address: schoolDiggerData.schooldigger.address.street,
          city: schoolDiggerData.schooldigger.address.city,
          state: schoolDiggerData.schooldigger.address.state,
          zip: schoolDiggerData.schooldigger.address.zip,
          site_timezone: schoolDiggerData.inferred_timezone,
          id: payload.id,
        });
        if (!schoolDiggerData.inferred_timezone) {
          toastContext.addToast({
            status: "Error",
            message:
              "Timezone was not able to be inferred, please make sure to select the correct timezone.",
          });
        }
        setShowAddress(true); // for now, continue to show the form, we can kill it later
      }
    }
  }, [schoolDiggerData]);

  useEffect(() => {
    if (lmsIntegrationData) {
      if (lmsIntegrationData?.code >= 400) {
        setshowRosteringForm(false);
        toastContext.addToast({
          status: "Error",
          message: lmsIntegrationData.message,
        });
      } else {
        // Remove elements no longer in scope
        for (const key in rosteringData) {
          for (const val of rosteringData[key]) {
            if (typeof val === "string") {
              if (
                !lmsIntegrationData.integrationData.find(
                  (x: any) => x.id === val
                )
              ) {
                rosteringData[key] = rosteringData[key].filter(
                  (x) => x !== val
                );
              }
            } else if (
              !lmsIntegrationData.integrationData.find(
                (x: any) => x.id === val.id
              )
            ) {
              rosteringData[key] = rosteringData[key].filter(
                (x) => x.id !== val.id
              );
            }
          }
          if (rosteringData[key].length === 0) {
            delete rosteringData[key];
          }
        }

        // Apply latest integrationData, ensuring not to override any data from the payload
        for (const x of lmsIntegrationData.integrationData) {
          if (
            !rosteringData["insert"]?.find((y) => x.id === y.id) &&
            !rosteringData["skip"]?.find((y) => x.id === y)
          ) {
            updateRosteringData(x, x.likelyMatch ? x.likelyMatch : "skip");
          }
        }
      }
    }
  }, [lmsIntegrationData]);

  const {
    data: updatedInvoiceData,
    mutate: updateInvoice,
    isLoading: updateLoading,
    error: updateError,
  } = useMutation(
    `/manager_new/invoices/${params.quoteNumber}`,
    async () => {
      const token = localStorage.getItem("customer_service_token");
      payload.hiddenFromView = false;
      const request = await axios.put(
        deltamathAPI() + `/manager_new/invoices/${params.quoteNumber}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return request.data;
    },
    {
      onSuccess: () => {
        if (showSuccessMessageWithThisUpdate) {
          toastContext.addToast({
            status: "Success",
            message: "Successfully Saved Updates.",
          });
        }
        refetch();
        setShowSuccessMessageWithThisUpdate(true);
      },
    }
  );

  useEffect(() => {
    if (updateError) {
      toastContext.addToast({
        status: "Error",
        message: updatedInvoiceData?.message,
      });
    }
  }, [updateError]);

  const getIntegrationsString = () => {
    const res: string[] = [];
    if (payload.lms) {
      res.push(payload.lms.toLowerCase());
    }
    if (payload.rosteringSystem && payload.rosteringSystem === "Clever") {
      res.push("clever");
    } else if (
      payload.rosteringSystem &&
      payload.rosteringSystem === "ClassLink/OneRoster"
    ) {
      res.push("classlink");
    }
    return res.length === 0 ? undefined : res.join(", ");
  };

  const updateRosteringData = (
    x: { id: string; name: string },
    val: string
  ) => {
    for (const key in rosteringData) {
      rosteringData[key] = rosteringData[key].filter((val) => {
        if (val.id) {
          return val.id !== x.id;
        }
        return val !== x.id;
      });
      if (rosteringData[key].length === 0) {
        delete rosteringData[key];
      }
    }

    if (val === "insert") {
      rosteringData[val] = [...(rosteringData[val] || []), x];
    } else {
      rosteringData[val] = [...(rosteringData[val] || []), x.id];
    }
    setRosteringData({ ...rosteringData });
    setPayload({
      ...payload,
      rosteringData: rosteringData,
    });
  };

  const {
    data: activateData,
    refetch: activateRefetch,
    isLoading: activateLoading,
  } = useDMQuery({
    path: `/manager_new/license/manage${editMode ? "?editMode=true" : ""}`,
    customerServiceHeader: true,
    method: "post",
    requestBody: {
      type:
        payload?.type === "individual"
          ? "individual"
          : payload?.type === "school_custom" ||
            payload?.type === "school" ||
            payload?.type === "School License"
          ? "school"
          : "district",
      nces_id: payload?.id,
      license: {
        license_type: payload?.type,
        num_students: payload?.priceInfo?.numberOfStudents,
        num_teachers: payload?.priceInfo?.numberOfTeachers,
        expiration: payload?.expiration,
        revenue: payload?.priceInfo?.price,
        note: payload?.notes,
        has_integral: payload.licenseTier === "Integral",
        integrations: getIntegrationsString(),
      },
      quote_number: data?.quoteNumber,
      site_name: payload?.name,
      site_timezone: payload?.site_timezone,
      admin_user: {
        first: payload?.adminFirst,
        last: payload?.adminLast,
        email: payload?.adminEmail,
      },
      site_address: {
        street: payload?.address,
        city: payload?.city,
        state: payload?.state,
        zip: payload?.zip,
        country: data?.country || "US",
      },
      integrations: {
        lms: payload?.lms,
        rostering:
          payload?.rosteringSystem === "Clever"
            ? "clever"
            : payload?.rosteringSystem === "ClassLink/OneRoster"
            ? "classLink"
            : undefined,
        tenantID:
          payload?.rosteringSystem === "Clever"
            ? payload?.cleverDistrictId
            : payload?.rosteringSystem === "ClassLink/OneRoster"
            ? payload?.classlinkTenantId
            : undefined,
        rosteringData: payload?.rosteringData,
      },
      hiddenFromView: false,
    },
    queryOptions: {
      enabled: false,
      retry: false,
      onSuccess: (activateData: {
        success: boolean;
        message: string;
        code: number;
        invoice: number;
      }) => {
        if (activateData.success && activateData.message) {
          refetch();
          toastContext.addToast({
            status: "Success",
            message: activateData.message,
          });
        } else if (activateData.invoice) {
          refetch();
          toastContext.addToast({
            status: "Success",
            message: `License Activated Succesfully! Created Invoice ${activateData.invoice}`,
          });
        } else if (activateData.code === 400) {
          toastContext.addToast({
            status: "Error",
            message: activateData.message,
          });
        } else if (activateData?.message?.indexOf("timezone") !== -1) {
          // toastContext.addToast({
          //   status: "Error",
          //   message: activateData?.message,
          // });
        } else if (activateData?.success === false) {
          toastContext.addToast({
            status: "Error",
            message:
              "There was an error activating your license, please check all fields and try again.",
          });
          setEditMode(false);
          // /create_site endpoint successfully returns "success: false" on failure.
        }
      },
      onError: (err: any) => {
        if (err.message) {
          toastContext.addToast({
            status: "Error",
            message: err.message,
          });
        } else {
          toastContext.addToast({
            status: "Error",
            message:
              "There was an error activating your license, please check all fields and try again.",
          });
        }
      },
    },
  });

  // If we have data from quickbooks but not from the mongo invoices collection, populate values on the form
  useEffect(() => {
    if (Object.keys(quickbooksData).length !== 0 && !quickbooksData.message) {
      if (
        typeof data?.priceInfo?.priceWithDiscount !== "undefined" &&
        data?.priceInfo?.priceWithDiscount !== 0 &&
        quickbooksData?.TotalAmt !== data?.priceInfo?.priceWithDiscount
      ) {
        setPayload({
          ...payload,
          priceInfo: {
            ...payload.priceInfo,
            price: quickbooksData.TotalAmt,
          },
        });
        setPriceChangeWarning(
          `Quickbooks Estimate Price ($${quickbooksData.TotalAmt}) was different than the initial price ($${data?.priceInfo?.priceWithDiscount}), please confirm the price and student numbers.`
        );
      }
    }

    if (
      Object.keys(quickbooksData).length !== 0 &&
      !data &&
      !quickbooksData.message
    ) {
      const newPayload = payload;

      newPayload.time = Math.floor(Date.now() / 1000);

      if (quickbooksData?.Id) {
        newPayload.quoteId = quickbooksData.Id;
      }

      if (quickbooksData?.CustomerRef?.value) {
        newPayload.customerId = quickbooksData.CustomerRef.value;
      }

      if (quickbooksData?.CustomerMemo?.value) {
        const memo = quickbooksData.CustomerMemo.value.toLowerCase();

        // set LMS
        if (memo.includes("canvas")) {
          newPayload.lms = "Canvas";
        } else if (memo.includes("schoology")) {
          newPayload.lms = "Schoology";
        }

        // Set Rostering System
        if (memo.includes("clever")) {
          newPayload.rosteringSystem = "Clever";
        } else if (memo.includes("classlink")) {
          newPayload.rosteringSystem = "ClassLink/OneRoster";
        }

        const studentsMatch = memo
          .replace(/\n|\r/g, "")
          .match(/^.*(approximately)(.*)(students)/);
        if (studentsMatch && studentsMatch[2]) {
          const numberOfStudents = studentsMatch[2].replace(/\D/g, "");
          newPayload.priceInfo.numberOfStudents = parseInt(numberOfStudents);
        }
      }

      // Set the Quote Number
      if (quickbooksData?.DocNumber) {
        newPayload.quoteNumber = quickbooksData.DocNumber;
      }

      newPayload.id = "";

      if (quickbooksData?.TotalAmt) {
        newPayload.amount = 1;
        newPayload.priceInfo.price = quickbooksData.TotalAmt;
      }

      if (quickbooksData?.Line) {
        // get the License Type and Tier from the Product
        const firstLineItem = quickbooksData.Line.filter(
          (l) => l.DetailType === "SalesItemLineDetail"
        );
        if (firstLineItem[0]) {
          const productDescription =
            firstLineItem[0]?.SalesItemLineDetail?.ItemRef?.name.toLowerCase();

          // Set License Tier
          if (productDescription.includes("integral")) {
            newPayload.licenseTier = "Integral";
          } else if (productDescription.includes("plus")) {
            newPayload.licenseTier = "Plus";
          }

          // Set License Type
          if (productDescription.includes("district license 6-12")) {
            newPayload.type = "6-12";
          } else if (productDescription.includes("district license 9-12")) {
            newPayload.type = "9-12";
          } else if (productDescription.includes("district license 6-8")) {
            newPayload.type = "6-8";
          } else if (productDescription.includes("district license 7-12")) {
            newPayload.type = "7-12";
          } else if (productDescription.includes("district license 7-8")) {
            newPayload.type = "7-8";
            // } else if (productDescription.includes("teacher")) {
            //   newPayload.type = "Individual License";
          } else if (productDescription.includes("school license")) {
            newPayload.type = "school";
          } else if (productDescription.includes("teacher")) {
            newPayload.type = "individual";
          } else {
            newPayload.type = "";
          }
        }
      }
      setPayload({ ...newPayload });
    }
  }, [quickbooksData]);

  // Once data is loaded
  useEffect(() => {
    setEdit(true);
    setPayload(defaultResetPayload);
    setshowRosteringForm(false);
    setPriceChangeWarning("");

    if (data) {
      if (data?.status === "Activated") {
        setEdit(false);
      }

      let licenseType = data.type;
      if (data?.type) {
        if (data?.type.toLowerCase().includes("grades 6-12")) {
          licenseType = "6-12";
        } else if (data?.type.toLowerCase().includes("grades 9-12")) {
          licenseType = "9-12";
        } else if (data?.type.toLowerCase().includes("grades 6-8")) {
          licenseType = "6-8";
        } else if (data?.type.toLowerCase().includes("grades 7-12")) {
          licenseType = "7-12";
        } else if (data?.type.toLowerCase().includes("grades 7-8")) {
          licenseType = "7-8";
        } else if (data?.type.toLowerCase().includes("school license")) {
          licenseType = "school";
        }
      }
      // Convert epoch date into date object in state for the hold until date selector
      if (Number.isInteger(data.hold_until)) {
        const theHoldUntilDate = new Date(data.hold_until * 1000);
        setHoldUntilDate(theHoldUntilDate);
      }
      setPayload({
        id: data?.id,
        type: licenseType || "school",
        invoiceId: quickbooksInvoiceData?.Id || data.invoiceId || "",
        invoiceNumber:
          quickbooksInvoiceData?.DocNumber || data.invoiceNumber || "",
        name: data?.name || data?.school,
        contactName: data?.contactName || "",
        contactEmail: data?.contactEmail || "",
        adminFirst: data?.adminFirst || "",
        adminLast: data?.adminLast || "",
        adminEmail: data?.adminEmail || "",
        address: data?.address || "",
        city: data?.city || "",
        state: data?.state || "",
        zip: data?.zip || "",
        status: data?.status || null,
        poNumber: data?.poNumber || "",
        notes: data?.notes || "",
        hold_until: data?.hold_until || "",
        priceInfo: {
          price: data?.priceInfo?.price || 0,
          numberOfStudents: data?.priceInfo?.numberOfStudents || 0,
          numberOfTeachers: data?.priceInfo?.numberOfTeachers || 0,
          discount: data?.priceInfo?.discount || 0,
          priceWithDiscount: data?.priceInfo?.priceWithDiscount || 0,
          minPrice: data?.priceInfo?.minPrice || 0,
          sendCustomerEmail: data?.priceInfo?.sendCustomerEmail || true,
        },
        amount: data?.amount || 1,
        licenseTier: data?.licenseTier || "",
        lms: data?.lms || "",
        rosteringSystem: data?.rosteringSystem || "",
        integrationStatus: data?.integrationStatus || "",
        cleverDistrictId: data?.cleverDistrictId || "",
        classlinkTenantId: data?.classlinkTenantId || "",
        site_timezone: data?.site_timezone || "",
        expiration: data?.expiration || defaultDate,
        rosteringData: data?.rosteringData,
        snnLicenseType: data?.snnLicenseType || null,
      });
      if (
        data?.status === "PO Received (Needs Updating)" ||
        data?.status === "PO Received (Good)"
      ) {
        setShowAddPONumber(true);
      }
      if (data?.rosteringData) {
        setRosteringData(data.rosteringData);
      }
      if (data?.snnLicenseType && data.snnLicenseType === "renewal") {
        setSnnRenewal(true);
      }
    }
  }, [data, quickbooksInvoiceData]);

  useEffect(() => {
    if (
      rosteringData &&
      Object.keys(rosteringData).length !== 0 &&
      (payload.cleverDistrictId || payload.classlinkTenantId)
    ) {
      lmsRefetch();
      setshowRosteringForm(true);
    }
  }, [rosteringData]);

  useEffect(() => {
    setActivationSummary({
      numStudents: payload.priceInfo.numberOfStudents,
      numTeachers: payload.priceInfo.numberOfTeachers,
      type: payload.type,
      adminEmail: payload.adminEmail,
      tier: payload.licenseTier,
      rostering: payload.rosteringSystem,
      lms: payload.lms,
    });
    // enabled by default
    let disableActivationButton = false;
    const disableMessages = [];

    // Check for Admin Name, Email
    if (payload.type === "individual") {
      if (
        payload?.adminEmail === "" ||
        typeof payload?.adminEmail === "undefined"
      ) {
        disableMessages.push(
          "For Individual Licenses, you must specify an admin email."
        );
        disableActivationButton = true || disableActivationButton;
      }
    } else {
      if (
        payload?.adminFirst === "" ||
        typeof payload?.adminFirst === "undefined" ||
        payload?.adminLast === "" ||
        typeof payload?.adminLast === "undefined" ||
        payload?.adminEmail === "" ||
        typeof payload?.adminEmail === "undefined"
      ) {
        disableMessages.push(
          "For School Licenses, you must specify an admin name, email, and address."
        );
        disableActivationButton = true || disableActivationButton;
      }
    }

    // Check that there's an expiration
    if (payload.expiration === 0) {
      disableMessages.push("You must specify an expiration date");
      disableActivationButton = true || disableActivationButton;
    }

    if (
      payload?.status != "CC/Check Payment Received" &&
      payload?.status !== "PO Received (Good)" &&
      payload?.status !== "Activated"
    ) {
      disableMessages.push(
        "You can only activate a license that has CC/Check Payment Received or PO Received (Good)"
      );
      disableActivationButton = true || disableActivationButton;
    }

    // It's it's PO Received check that it was uploaded
    if (
      payload?.status === "PO Received (Good)" &&
      (typeof data?.poUploadedTime === "undefined" ||
        data?.poUploadedTime === null)
    ) {
      disableMessages.push(
        "You marked that a PO was received, please upload it."
      );
      disableActivationButton = true || disableActivationButton;
    }

    if (!payload?.name) {
      disableMessages.push("Please enter a School/District name.");
      disableActivationButton = true || disableActivationButton;
    }

    if (payload?.type === "school_custom") {
      if (typeof snnRenewal === "undefined") {
        disableMessages.push(
          "For a School (No NCES ID), you must specify a SNN License Type"
        );
        disableActivationButton = true || disableActivationButton;
      }
      if (snnRenewal && !payload?.id && payload?.id === "") {
        disableMessages.push(
          "For a Renewal of a School (No NCES ID), please enter the custom NCES of the previous license."
        );
        disableActivationButton = true || disableActivationButton;
      }
      if (payload?.site_timezone === "") {
        disableMessages.push(
          "For a School (No NCES ID), you must set a timezone."
        );
        disableActivationButton = true || disableActivationButton;
      }
    }

    if (["ClassLink/OneRoster", "Clever"].includes(payload?.rosteringSystem)) {
      disableActivationButton =
        payload?.integrationStatus !== "verified" || !payload?.rosteringData
          ? true
          : false;
    }

    if (payload.hold_until && Number.isInteger(payload.hold_until)) {
      const theHoldUntilDate = new Date(payload.hold_until * 1000);
      if (isFuture(theHoldUntilDate)) {
        disableActivationButton = true || disableActivationButton;
        disableMessages.push(
          "You cannot enable this activation until after the Hold Until date has passed."
        );
      }
    }

    setDisabledMessages(disableMessages);
    setActivateDisabled(disableActivationButton);
  }, [data, payload, snnRenewal]);

  const uploadPDF = () => {
    if (selectedPDF && quickbooksData?.DocNumber && quickbooksData?.Id) {
      const formData = new FormData();
      formData.append("file", selectedPDF);
      formData.append(
        "customer_service_token",
        localStorage.getItem("customer_service_token") || ""
      );
      formData.append("quoteNumber", quickbooksData.DocNumber);
      formData.append("quoteId", quickbooksData.Id);
      axios({
        method: "post",
        url: deltamathAPI() + `/add_quickbooks_attachment`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          if (response.status === 200) {
            toastContext.addToast({
              status: "Success",
              message: "Upload complete! Thanks!",
            });
            if (fileInputRef && fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            refetch();
          }
        })
        .catch(function (response) {
          console.error(response);
        });
    }
  };

  const searchRosteringData = (value: string) => {
    if (!rosteringData) return undefined;
    for (const key in rosteringData) {
      const match = rosteringData[key].find((x) => {
        if (typeof x === "string") {
          return x === value;
        } else {
          return x.id === value;
        }
      });
      if (match) {
        return key;
      }
    }
  };
  return (
    <>
      <div className="inline-flex w-full justify-between">
        <InvoiceSearch quoteNumber={params.quoteNumber} />
      </div>

      <DmModal
        showModal={showActivationSummary && !updateLoading}
        setShowModal={setShowActivationSummary}
        cancelMessage={"Cancel"}
        confirmMessage={"Activate License"}
        modalTitle={"License Summary"}
        modalBodyText={
          <ul>
            {activationSummary?.type === "individual" && (
              <>
                <li>
                  <strong>Type:</strong> Teachers License Codes
                </li>
                {activationSummary?.adminEmail && (
                  <li>
                    <strong>Email Sent To:</strong>{" "}
                    {activationSummary?.adminEmail}
                  </li>
                )}
              </>
            )}
            {activationSummary?.numStudents &&
            activationSummary?.numStudents > 0 ? (
              <li>
                <b># Students:</b> {activationSummary?.numStudents}
              </li>
            ) : null}
            {activationSummary?.numTeachers &&
            activationSummary?.numTeachers > 0 ? (
              <li>
                <b>
                  {activationSummary?.type === "individual"
                    ? "# Codes:"
                    : "# Teachers:"}
                </b>{" "}
                {activationSummary?.numTeachers}
              </li>
            ) : null}
            <li>
              <b>License Tier:</b> {activationSummary?.tier}
            </li>
            {activationSummary?.tier === "Integral" && (
              <>
                <li>
                  <b>LMS:</b> {activationSummary?.lms}{" "}
                </li>
                <li>
                  <b>Rostering System:</b> {activationSummary?.rostering}
                </li>
              </>
            )}
          </ul>
        }
        modalAction={activateRefetch}
      />

      {(showSuccessMessageWithThisUpdate && isLoading) ||
        (showSuccessMessageWithThisUpdate && updateLoading && (
          <DmLoadingSpinner message="Loading..." />
        ))}
      <>
        <>
          <div className="mt-6">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Quickbooks Estimate
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    {quickbooksData?.Id && (
                      <a
                        className="text-indigo-500 hover:underline"
                        href={`https://app.qbo.intuit.com/app/estimate?txnId=${quickbooksData.Id}`}
                        target="_blank"
                      >
                        <svg
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-external-link mr-2 inline-block"
                        >
                          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14 21 3" />
                        </svg>
                        Open Quickbooks Estimate
                      </a>
                    )}
                  </p>
                  <p className="mt-2 text-sm text-gray-600">
                    {quickbooksInvoiceData?.Id && (
                      <a
                        className="text-indigo-500 hover:underline"
                        href={`https://app.qbo.intuit.com/app/invoice?txnId=${quickbooksInvoiceData.Id}`}
                        target="_blank"
                      >
                        <svg
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-external-link mr-2 inline-block"
                        >
                          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14 21 3" />
                        </svg>
                        Open Quickbooks Invoice
                      </a>
                    )}
                  </p>
                </div>
              </div>
              <div className="mt-5 rounded border-2 shadow-xl md:col-span-3 md:mt-0">
                <div className="overflow-hidden">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <EstimateInfo
                      setQuickbooksData={setQuickbooksData}
                      setQuickbooksInvoiceData={setQuickbooksInvoiceData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Quote Status
                  </h3>
                  <p className="mt-1 text-sm text-gray-600"></p>
                </div>
              </div>
              <div className="mt-5 rounded border-2 shadow-xl md:col-span-3 md:mt-0">
                <div className="overflow-hidden">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Status
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit ? (
                              <select
                                className="w-full border-gray-300 md:rounded-md"
                                value={payload.status || ""}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    status: e.target.value || null,
                                  });
                                  setSaveDisabled(false);
                                  if (
                                    e.target.value === "PO Received (Good)" ||
                                    e.target.value ===
                                      "PO Received (Needs Updating)"
                                  ) {
                                    setShowAddPONumber(true);
                                  } else setShowAddPONumber(false);
                                }}
                              >
                                <option value="">--</option>
                                <option value="PO Received (Needs Updating)">
                                  PO Received -- needs updating
                                </option>
                                <option value="PO Received (Good)">
                                  {"PO Received (Good)"}
                                </option>
                                <option value="CC/Check Payment Requested">
                                  CC/Check Payment Requested
                                </option>
                                <option value="CC/Check Payment Received">
                                  CC/Check Payment Received
                                </option>
                                <option value="Activated">Activated</option>
                              </select>
                            ) : (
                              <div>{data?.status || "--"}</div>
                            )}
                          </dd>
                        </div>
                      </div>

                      <div className="ml-2">
                        {showAddPONumber && (
                          <>
                            <dt className="text-sm font-medium text-gray-500">
                              PO Number
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              <input
                                className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                type="text"
                                value={payload.poNumber}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    poNumber: e.target.value.trim(),
                                  });
                                  setSaveDisabled(false);
                                }}
                              ></input>
                            </dd>
                          </>
                        )}
                      </div>

                      <div className="">
                        {payload?.status !== "Activated" &&
                        quickbooksData?.Id &&
                        !data?.poUploadedTime ? (
                          <>
                            <dt className="text-sm font-medium text-gray-500">
                              Attach P.O. PDF
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              <input
                                className="form-control
                        m-0
                        block
                        w-full rounded border
                        border-solid
                        border-gray-300
                        bg-white bg-clip-padding
                        px-3 py-1.5 text-base
                        font-normal
                        text-gray-700
                        transition
                        ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                ref={fileInputRef}
                                accept="application/pdf"
                                onChange={(event) => {
                                  event.target.files &&
                                    setSelectedPDF(event.target.files[0]);
                                }}
                              />
                              <button
                                className="mt-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                type="button"
                                onClick={() => {
                                  uploadPDF();
                                  updateInvoice();
                                }}
                              >
                                Upload File
                              </button>
                            </dd>
                          </>
                        ) : (
                          <>
                            <dt className="text-sm font-medium text-gray-500">
                              PO Uploaded
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {timeConverter(data?.poUploadedTime)}
                            </dd>
                          </>
                        )}
                      </div>

                      <div>
                        <dt className="text-sm font-medium text-gray-500">
                          Internal Notes
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <textarea
                            className="w-full"
                            rows={3}
                            value={payload.notes}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                notes: e.target.value,
                              });
                              setSaveDisabled(false);
                            }}
                          ></textarea>
                        </dd>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className={
                            "justify-self-end rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-500 disabled:opacity-50"
                          }
                          disabled={
                            saveDisabled ||
                            updateLoading ||
                            isLoading ||
                            payload?.status === ""
                          }
                          onClick={() => {
                            updateInvoice();
                            setSaveDisabled(true);
                          }}
                        >
                          Save Updates
                        </button>
                        {payload?.status === "Activated" && !edit && (
                          <button
                            type="submit"
                            className={
                              "ml-16 mt-8 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            }
                            onClick={() => {
                              setEditMode(true);
                              setEdit(true);
                            }}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </dl>

                    {payload?.status === "" && (
                      <p className="text-md my-6 font-bold text-orange-500">
                        Save Notice: You must set a status to save a quote.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(payload.id || (payload.name && payload.zip)) && (
            <CurrentLicense
              nces_id={payload.id}
              name={payload.name}
              zip={payload.zip}
            />
          )}

          <div className="mt-6">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Create New License
                  </h3>
                  <p className="mt-1 text-sm text-gray-600"></p>
                </div>
              </div>
              <div className="mt-5 rounded border-2 shadow-xl md:col-span-3 md:mt-0">
                <div className="bg-white px-4 py-5 sm:p-6 sm:pb-24">
                  <div className="">
                    <div className="mb-4 overflow-hidden bg-white sm:rounded-lg">
                      {data && (
                        <div className="grid grid-cols-2">
                          <div className="col-span-1">
                            {payload?.contactName && (
                              <div className="item mb-6 mr-6 inline-flex">
                                <dl className="grid grid-cols-1">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Contact Name
                                  </dt>
                                  <dd className="mt-1 inline-flex text-sm text-gray-900">
                                    {payload.contactName}
                                  </dd>
                                </dl>
                              </div>
                            )}
                            {payload?.contactEmail && (
                              <div className="item inline-flex justify-end sm:col-span-1">
                                <dl className="mb-6 grid grid-cols-1">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Contact Email
                                  </dt>
                                  <dd className="mt-1 inline-flex text-sm text-gray-900">
                                    {payload.contactEmail}
                                  </dd>
                                </dl>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="inline-flex sm:col-span-1">
                          <div className="">
                            <dt className="text-sm font-medium text-gray-500">
                              Admin First Name
                            </dt>
                            <dd className="mt-1 inline-flex text-sm text-gray-900">
                              {edit ? (
                                <input
                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="text"
                                  value={payload.adminFirst}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      adminFirst: e.target.value,
                                    });
                                    setSaveDisabled(false);
                                  }}
                                ></input>
                              ) : (
                                <div>{data?.adminFirst || "--"}</div>
                              )}
                            </dd>
                          </div>
                          <div className="ml-2">
                            <dt className="text-sm font-medium text-gray-500">
                              Admin Last Name
                            </dt>
                            <dd className="mt-1 inline-flex text-sm text-gray-900">
                              {edit ? (
                                <input
                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="text"
                                  value={payload.adminLast}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      adminLast: e.target.value,
                                    });
                                    setSaveDisabled(false);
                                  }}
                                ></input>
                              ) : (
                                <div>{data?.adminLast || "--"}</div>
                              )}
                            </dd>
                          </div>
                        </div>
                        <div className="inline-flex sm:col-span-1">
                          <div>
                            <dt className="text-sm font-medium text-gray-500">
                              Admin Email
                            </dt>
                            <dd className="mt-1 inline-flex text-sm text-gray-900">
                              {edit ? (
                                <input
                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="email"
                                  value={payload.adminEmail}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      adminEmail: e.target.value,
                                    });
                                    setSaveDisabled(false);
                                  }}
                                ></input>
                              ) : (
                                <div>{data?.adminEmail || "--"}</div>
                              )}
                            </dd>
                          </div>
                        </div>
                      </dl>
                    </div>

                    <div className="relative flex items-center py-5">
                      <div className="flex-grow border-t border-gray-400"></div>
                      <span className="mx-4 flex-shrink text-gray-400">
                        School / District Information
                      </span>
                      <div className="flex-grow border-t border-gray-400"></div>
                    </div>

                    <div className="">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                        <div className="">
                          <dt className="text-sm font-medium text-gray-500">
                            School/District Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit ? (
                              <input
                                className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                type="text"
                                value={payload.name || ""}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    name: e.target.value,
                                  });
                                  setSaveDisabled(false);
                                }}
                              ></input>
                            ) : (
                              <div>{data?.name || "--"}</div>
                            )}
                          </dd>
                        </div>
                        <div className="">
                          <dt className="text-sm font-medium text-gray-500">
                            State
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit ? (
                              <select
                                className="border-gray-300 md:rounded-md"
                                value={payload.state}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    state: e.target.value,
                                  });
                                  setSaveDisabled(false);
                                }}
                              >
                                <option value=""> - </option>
                                {Object.keys(US_STATES).map((state) => {
                                  return (
                                    <option key={state} value={state}>
                                      {state}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <div>{data?.state || "--"}</div>
                            )}

                            {edit &&
                              !payload.id &&
                              payload.state &&
                              payload.name && (
                                <>
                                  <Link
                                    className="ml-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    to={`/manager/?state=${payload.state}&school=${payload.name}`}
                                    target="_blank"
                                  >
                                    Search for NCES
                                  </Link>
                                </>
                              )}
                          </dd>
                        </div>

                        {payload.type === "school_custom" && edit && (
                          <div className="">
                            <dt className="text-sm font-medium text-gray-500">
                              SNN License Type
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              <select
                                name="school_custom_renewal"
                                id="school_custom_renewal"
                                className="mr-2"
                                defaultValue={payload?.snnLicenseType || ""}
                                onChange={(e) => {
                                  if (e.target.value === "") {
                                    setSnnRenewal(undefined);
                                    setPayload({
                                      ...payload,
                                      snnLicenseType: null,
                                      id: "",
                                    });
                                  } else if (e.target.value === "renewal") {
                                    setSnnRenewal(true);
                                    setPayload({
                                      ...payload,
                                      id: payload.id || data?.id,
                                      snnLicenseType: "renewal",
                                    });
                                  } else {
                                    setSnnRenewal(false);
                                    setPayload({
                                      ...payload,
                                      snnLicenseType: "new",
                                      id: "",
                                    });
                                  }
                                  setSaveDisabled(false);
                                }}
                              >
                                <option value=""> - </option>
                                <option value="renewal"> Renewal </option>
                                <option value="new"> New License </option>
                              </select>
                            </dd>
                          </div>
                        )}

                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            NCES ID
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {(payload.type === "school_custom" && snnRenewal) ||
                            (payload.type !== "school_custom" && edit) ? (
                              <>
                                <input
                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="text"
                                  value={payload.id}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      id: e.target.value.trim(),
                                    });
                                    setSaveDisabled(false);
                                  }}
                                />
                                {payload.id && payload.id.length >= 7 && (
                                  <button
                                    className="mt-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => {
                                      if (payload.id) {
                                        schoolDiggerRefetch();
                                      }
                                    }}
                                  >
                                    Search Schooldigger
                                  </button>
                                )}
                              </>
                            ) : (
                              <div>{data?.id || "--"}</div>
                            )}
                          </dd>
                        </div>

                        {payload.type !== "school_custom" && <div></div>}
                        {showAddress && (
                          <>
                            {edit ? (
                              <>
                                <div className="">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Address
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    <input
                                      className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                      type="text"
                                      value={payload.address}
                                      onChange={(e) => {
                                        setPayload({
                                          ...payload,
                                          address: e.target.value,
                                        });
                                        setSaveDisabled(false);
                                      }}
                                    ></input>
                                  </dd>
                                </div>
                                <div className="">
                                  <dt className="text-sm font-medium text-gray-500">
                                    City
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    <input
                                      className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                      type="text"
                                      value={payload.city}
                                      onChange={(e) => {
                                        setPayload({
                                          ...payload,
                                          city: e.target.value,
                                        });
                                        setSaveDisabled(false);
                                      }}
                                    ></input>
                                  </dd>
                                </div>
                                <div className="">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Zip
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    <input
                                      className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                      type="text"
                                      value={payload.zip}
                                      onChange={(e) => {
                                        setPayload({
                                          ...payload,
                                          zip: e.target.value.trim(),
                                        });
                                        setSaveDisabled(false);
                                      }}
                                    ></input>
                                  </dd>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="mr-8">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Address
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {data?.address}
                                  </dd>
                                </div>
                                <div className="mr-8">
                                  <dt className="text-sm font-medium text-gray-500">
                                    City
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {data?.city}
                                  </dd>
                                </div>
                                <div className="mr-8">
                                  <dt className="text-sm font-medium text-gray-500">
                                    State
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {data?.state}
                                  </dd>
                                </div>
                                <div className="mr-8">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Zip
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {data?.zip}
                                  </dd>
                                </div>
                              </>
                            )}
                            <div className="sm:col-span-3">
                              <dt className="text-sm font-medium text-gray-500">
                                Timezone
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {edit ? (
                                  <select
                                    className="border-gray-300 md:rounded-md"
                                    value={payload.site_timezone}
                                    onChange={(e) => {
                                      setPayload({
                                        ...payload,
                                        site_timezone: e.target.value,
                                      });
                                      setSaveDisabled(false);
                                    }}
                                  >
                                    {Object.entries(TIMEZONES_FOR_PICKER).map(
                                      ([key, zone]) => {
                                        return (
                                          <option key={zone} value={zone}>
                                            {key}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                ) : (
                                  <>{data?.site_timezone}</>
                                )}
                              </dd>
                            </div>
                          </>
                        )}
                      </dl>
                      <p className="mt-8 text-sm text-gray-600">
                        Note: Creating a license will either match NCES ID, or
                        try to match "School / District Name" and zip.
                      </p>
                    </div>

                    <div className="relative flex items-center py-5">
                      <div className="flex-grow border-t border-gray-400"></div>
                      <span className="mx-4 flex-shrink text-gray-400">
                        License Details
                      </span>
                      <div className="flex-grow border-t border-gray-400"></div>
                    </div>

                    {edit && priceChangeWarning && (
                      <div className="mb-5 rounded-md bg-yellow-50 p-4 text-yellow-700">
                        {priceChangeWarning}
                      </div>
                    )}

                    <dl className="grid grid-cols-1 gap-x-2 gap-y-8 sm:grid-cols-2">
                      <div className="inline-flex sm:col-span-1">
                        <div className="">
                          <dt className="text-sm font-medium text-gray-500">
                            License Type
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit ? (
                              <select
                                className="border-gray-300 md:rounded-md"
                                id="license_type"
                                name="license_type"
                                value={payload.type}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    type: e.target.value,
                                  });
                                  setSaveDisabled(false);
                                }}
                              >
                                <option value=""> - </option>
                                <option value="school">School License</option>
                                <option value="6-12">District (6-12)</option>
                                <option value="9-12">District (9-12)</option>
                                <option value="6-8">District (6-8)</option>
                                <option value="7-12">District (7-12)</option>
                                <option value="7-8">District (7-8)</option>
                                <option value="school_custom">
                                  School (No NCES ID)
                                </option>
                                <option value="individual">
                                  Individual Teacher License
                                </option>
                              </select>
                            ) : (
                              <div>{data?.type || "--"}</div>
                            )}
                          </dd>
                        </div>
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        {payload.type !== "school_custom" &&
                          payload.type !== "individual" && (
                            <div className="">
                              <dt className="text-sm font-medium text-gray-500">
                                Quote # Students
                              </dt>
                              <dd className="mt-1 inline-flex text-sm text-gray-900">
                                {edit && payload ? (
                                  <input
                                    className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                    type="number"
                                    min="0"
                                    value={payload.priceInfo.numberOfStudents}
                                    onChange={(e) => {
                                      setPayload({
                                        ...payload,
                                        priceInfo: {
                                          ...payload.priceInfo,
                                          numberOfStudents: parseInt(
                                            e.target.value
                                          ),
                                          numberOfTeachers: 0,
                                        },
                                      });
                                      setSaveDisabled(false);
                                    }}
                                  ></input>
                                ) : (
                                  <div>
                                    {data?.priceInfo?.numberOfStudents || "--"}
                                  </div>
                                )}
                              </dd>
                            </div>
                          )}
                        {(payload.type === "school_custom" ||
                          payload.type === "individual") && (
                          <div>
                            <dt className="text-sm font-medium text-gray-500">
                              {payload.type === "school_custom" && (
                                <>Quote # Teachers</>
                              )}
                              {payload.type === "individual" && (
                                <>Number of License Codes</>
                              )}
                            </dt>
                            <dd className="mt-1 inline-flex text-sm text-gray-900">
                              {edit && payload ? (
                                <input
                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="number"
                                  min="0"
                                  value={payload.priceInfo.numberOfTeachers}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      priceInfo: {
                                        ...payload.priceInfo,
                                        numberOfTeachers: parseInt(
                                          e.target.value
                                        ),
                                        numberOfStudents: 0,
                                      },
                                    });
                                    setSaveDisabled(false);
                                  }}
                                ></input>
                              ) : (
                                <div>
                                  {data?.priceInfo?.numberOfTeachers || "--"}
                                </div>
                              )}
                            </dd>{" "}
                          </div>
                        )}
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        <div className="">
                          <dt className="text-sm font-medium text-gray-500">
                            Price
                          </dt>
                          <dd className="relative mt-1 inline-flex text-sm text-gray-900">
                            {edit && payload ? (
                              <>
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <span className="text-gray-500 sm:text-sm">
                                    $
                                  </span>
                                </div>
                                <input
                                  className="col-span-1 block border-gray-300 pl-7 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="number"
                                  min="0"
                                  value={payload.priceInfo.price}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      priceInfo: {
                                        ...payload.priceInfo,
                                        price: parseInt(e.target.value),
                                      },
                                    });
                                    setSaveDisabled(false);
                                  }}
                                ></input>
                              </>
                            ) : (
                              <div>$ {data?.priceInfo?.price || "--"}</div>
                            )}
                          </dd>
                        </div>
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Expiration
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit && payload ? (
                              <select
                                id="expiration"
                                className="border-gray-300 md:rounded-md"
                                name="expiration"
                                value={payload.expiration || ""}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    expiration: parseInt(e.target.value),
                                  });
                                  setSaveDisabled(false);
                                }}
                              >
                                {nextDates.map((date, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={Math.floor(date.getTime() / 1000)}
                                    >
                                      {`October ${date.getDate()}, ${date.getFullYear()}`}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <div>
                                {payload?.expiration &&
                                  timeConverter(payload?.expiration)}
                              </div>
                            )}
                          </dd>
                        </div>
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            License Tier
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit && payload ? (
                              <select
                                id="license-tier"
                                className="border-gray-300 md:rounded-md"
                                name="license-tier"
                                value={payload.licenseTier || ""}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    licenseTier: e.target.value,
                                    lms:
                                      e.target.value === "Plus"
                                        ? ""
                                        : payload.lms,
                                    rosteringSystem:
                                      e.target.value === "Plus"
                                        ? ""
                                        : payload.rosteringSystem,
                                    integrationStatus:
                                      e.target.value === "Plus"
                                        ? ""
                                        : payload.integrationStatus,
                                    classlinkTenantId:
                                      e.target.value === "Plus"
                                        ? ""
                                        : payload.classlinkTenantId,
                                    cleverDistrictId:
                                      e.target.value === "Plus"
                                        ? ""
                                        : payload.cleverDistrictId,
                                  });
                                  setSaveDisabled(false);
                                }}
                              >
                                <option value="">--</option>
                                <option value="Integral">Integral</option>
                                <option value="Plus">Plus</option>
                              </select>
                            ) : (
                              <div>{data?.licenseTier || "--"}</div>
                            )}
                          </dd>
                        </div>
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        {payload.licenseTier === "Integral" && (
                          <div>
                            <dt className="text-sm font-medium text-gray-500">
                              Rostering System
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {edit && payload ? (
                                <select
                                  id="rostering-system"
                                  className="border-gray-300 md:rounded-md"
                                  name="rostering-system"
                                  value={payload.rosteringSystem || ""}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      rosteringSystem: e.target.value,
                                      classlinkTenantId:
                                        e.target.value === "Clever"
                                          ? ""
                                          : payload.classlinkTenantId,
                                      cleverDistrictId:
                                        e.target.value === "ClassLink/OneRoster"
                                          ? ""
                                          : payload.cleverDistrictId,
                                      rosteringData: {},
                                    });
                                    if (e.target.value === "") {
                                      setshowRosteringForm(false);
                                    }
                                    setSaveDisabled(false);
                                  }}
                                >
                                  <option value="">--</option>
                                  <option value="ClassLink/OneRoster">
                                    ClassLink/OneRoster
                                  </option>
                                  <option value="Clever">Clever</option>
                                </select>
                              ) : (
                                <div>{data?.rosteringSystem || "--"}</div>
                              )}
                            </dd>
                          </div>
                        )}
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        {payload.licenseTier === "Integral" &&
                          payload.type !== "individual" && (
                            <div>
                              <dt className="text-sm font-medium text-gray-500">
                                L.M.S.
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {edit && payload ? (
                                  <select
                                    id="lms"
                                    className="border-gray-300 md:rounded-md"
                                    name="lms"
                                    value={payload.lms || ""}
                                    onChange={(e) => {
                                      setPayload({
                                        ...payload,
                                        lms: e.target.value,
                                      });
                                      setSaveDisabled(false);
                                    }}
                                  >
                                    <option value="">--</option>
                                    <option value="Schoology">Schoology</option>
                                    <option value="Canvas">Canvas</option>
                                  </select>
                                ) : (
                                  <div>{data?.lms || "--"}</div>
                                )}
                              </dd>
                            </div>
                          )}
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        {payload &&
                          payload.licenseTier === "Integral" &&
                          payload.rosteringSystem != "" && (
                            <div className="inline-flex sm:col-span-1">
                              {payload &&
                                payload.licenseTier === "Integral" &&
                                payload.rosteringSystem != "" && (
                                  <>
                                    <div className="mr-4">
                                      {payload.rosteringSystem === "Clever" && (
                                        <>
                                          <dt className="text-sm font-medium text-gray-500">
                                            Clever District ID
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900">
                                            {edit ? (
                                              <>
                                                <input
                                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                                  type="text"
                                                  value={
                                                    payload.cleverDistrictId
                                                  }
                                                  onChange={(e) => {
                                                    setPayload({
                                                      ...payload,
                                                      cleverDistrictId:
                                                        e.target.value.trim(),
                                                    });
                                                    setSaveDisabled(false);
                                                  }}
                                                />
                                                {payload.cleverDistrictId &&
                                                  payload.cleverDistrictId
                                                    .length === 24 && (
                                                    <button
                                                      className="mt-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                      onClick={() => {
                                                        setshowRosteringForm(
                                                          false
                                                        );
                                                        lmsRefetch();
                                                        setSaveDisabled(false);
                                                      }}
                                                    >
                                                      Search Clever
                                                    </button>
                                                  )}
                                              </>
                                            ) : (
                                              <>{payload.cleverDistrictId}</>
                                            )}
                                          </dd>
                                        </>
                                      )}
                                      {payload.rosteringSystem ===
                                        "ClassLink/OneRoster" && (
                                        <>
                                          <dt className="text-sm font-medium text-gray-500">
                                            Classlink Tenant ID
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900">
                                            {edit ? (
                                              <>
                                                <input
                                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                                  type="text"
                                                  value={
                                                    payload.classlinkTenantId
                                                  }
                                                  onChange={(e) => {
                                                    setPayload({
                                                      ...payload,
                                                      classlinkTenantId:
                                                        e.target.value.trim(),
                                                    });
                                                    setSaveDisabled(false);
                                                  }}
                                                />
                                                {payload.classlinkTenantId &&
                                                  !isNaN(
                                                    +payload.classlinkTenantId
                                                  ) && (
                                                    <button
                                                      className="mt-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                      onClick={() => {
                                                        setshowRosteringForm(
                                                          false
                                                        );
                                                        lmsRefetch();
                                                        setSaveDisabled(false);
                                                      }}
                                                    >
                                                      Search ClassLink
                                                    </button>
                                                  )}
                                              </>
                                            ) : (
                                              <>{payload.classlinkTenantId}</>
                                            )}
                                          </dd>
                                        </>
                                      )}
                                    </div>
                                    <div>
                                      <dt className="text-sm font-medium text-gray-500">
                                        Integration Status
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {edit &&
                                        payload &&
                                        payload.rosteringSystem != "" ? (
                                          <select
                                            id="integration-status"
                                            className="border-gray-300 md:rounded-md"
                                            name="integration-status"
                                            value={
                                              payload.integrationStatus || ""
                                            }
                                            onChange={(e) => {
                                              setPayload({
                                                ...payload,
                                                integrationStatus:
                                                  e.target.value,
                                              });
                                              setSaveDisabled(false);
                                            }}
                                          >
                                            <option value="">--</option>
                                            <option value="requested">
                                              Requested Data Share
                                            </option>
                                            <option value="verified">
                                              Verified Data Share
                                            </option>
                                            Indivdual License Form
                                          </select>
                                        ) : (
                                          <div>
                                            {data?.integrationStatus || "--"}
                                          </div>
                                        )}
                                      </dd>
                                    </div>
                                  </>
                                )}
                            </div>
                          )}
                      </div>
                      <div className="relative inline-flex sm:col-span-1">
                        <div className="">
                          <dt className="text-sm font-medium text-gray-500">
                            Hold Until
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <DatePicker
                              minDate={new Date()}
                              selected={holdUntilDate}
                              onChange={(newHoldUntilDate) => {
                                if (newHoldUntilDate instanceof Date) {
                                  setHoldUntilDate(newHoldUntilDate);
                                  setPayload({
                                    ...payload,
                                    hold_until:
                                      newHoldUntilDate.getTime() / 1000,
                                  });
                                  setSaveDisabled(false);
                                }
                              }}
                              popperPlacement="bottom"
                              popperModifiers={[
                                {
                                  name: "flip",
                                  options: {
                                    flipVariations: false,
                                    allowedAutoPlacements: [
                                      "bottom",
                                      "bottom-start",
                                    ],
                                    fallbackPlacements: [
                                      "bottom",
                                      "bottom-start",
                                    ],
                                  },
                                },
                                {
                                  name: "preventOverflow",
                                  enabled: false,
                                },
                                {
                                  name: "hide",
                                  enabled: false,
                                },
                              ]}
                              selectsStart
                              nextMonthButtonLabel=">"
                              previousMonthButtonLabel="<"
                              popperClassName="react-datepicker-left"
                              dateFormat="MM/dd/yyyy"
                            />
                          </dd>
                        </div>
                      </div>
                    </dl>
                    {showRosteringForm && (
                      <>
                        <div className="relative flex items-center py-5">
                          <div className="flex-grow border-t border-gray-400"></div>
                          <span className="mx-4 flex-shrink text-gray-400">
                            Rostering Activation
                          </span>
                          <div className="flex-grow border-t border-gray-400"></div>
                        </div>

                        {lmsIntegrationData &&
                          lmsIntegrationData.integrationData && (
                            <dl className="grid grid-cols-1 items-center gap-x-2 gap-y-2 sm:grid-cols-2">
                              {lmsIntegrationData.integrationData.map(
                                (x: any) => {
                                  return (
                                    <>
                                      <div className="inline-flex sm:col-span-1">
                                        <div className="">
                                          <dt className="text-sm font-medium text-gray-500">
                                            {x.name}
                                          </dt>
                                        </div>
                                      </div>
                                      <div className="inline-flex sm:col-span-1">
                                        <div className="">
                                          <dd className="mt-1 text-sm text-gray-900">
                                            {edit ? (
                                              <select
                                                className="border-gray-300 md:rounded-md"
                                                defaultValue={
                                                  searchRosteringData(x.id) ||
                                                  x.likelyMatch ||
                                                  "skip"
                                                }
                                                onChange={(e) => {
                                                  updateRosteringData(
                                                    x,
                                                    e.target.value
                                                  );
                                                  setSaveDisabled(false);
                                                }}
                                              >
                                                <option value="skip">
                                                  Do not activate
                                                </option>
                                                {payload.type ===
                                                  "school_custom" && (
                                                  <option value="generated">
                                                    Use{" "}
                                                    {payload.id
                                                      ? payload.id
                                                      : "generated"}
                                                  </option>
                                                )}
                                                {payload.type &&
                                                  ![
                                                    "school_custom",
                                                    "school",
                                                  ].includes(payload.type) && (
                                                    <option value="insert">
                                                      Insert new school
                                                    </option>
                                                  )}
                                                {lmsIntegrationData.diggerSchools.map(
                                                  (sch: any) => {
                                                    return (
                                                      <option
                                                        value={sch.nces_id}
                                                      >
                                                        {sch.name}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                            ) : (
                                              <></>
                                            )}
                                          </dd>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </dl>
                          )}
                      </>
                    )}
                    <button
                      type="submit"
                      className={
                        "mt-4 justify-self-end rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-500 disabled:opacity-50"
                      }
                      disabled={
                        saveDisabled ||
                        updateLoading ||
                        isLoading ||
                        payload?.status === ""
                      }
                      onClick={() => {
                        updateInvoice();
                        setSaveDisabled(true);
                      }}
                    >
                      Save Updates
                    </button>

                    {(payload?.status !== "Activated" || editMode) && data && (
                      <>
                        <button
                          type="submit"
                          className={
                            activateDisabled || activateLoading
                              ? "ml-16 mt-8 cursor-not-allowed rounded-md bg-indigo-500 px-4 py-2 text-base font-medium text-white opacity-50"
                              : "ml-16 mt-8 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          }
                          disabled={activateDisabled || activateLoading}
                          onClick={() => {
                            setShowSuccessMessageWithThisUpdate(false);
                            updateInvoice();
                            setShowActivationSummary(true);
                          }}
                        >
                          Activate
                        </button>
                        {activateLoading && (
                          <span className="ml-6">Activating...</span>
                        )}
                      </>
                    )}

                    {payload?.status === "" && (
                      <p className="text-md my-6 font-bold text-orange-500">
                        Save Notice: You must set a status to save a quote.
                      </p>
                    )}

                    {disabledMessages && disabledMessages.length > 0 && (
                      <>
                        <p className="text-md mt-6 font-bold text-red-500">
                          Activation Notice:
                        </p>
                        <ul className="ml-8 list-disc">
                          {disabledMessages.map((m) => (
                            <li
                              key={m}
                              className="text-md mt-2 font-bold text-red-500"
                            >
                              {m}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* <div className="md:col-span-1"></div>
              <div className="mt-5 rounded border-2 shadow-xl md:col-span-3 md:mt-0">
                {addAdditionalLicense && (
                  <div className="mt-2 mb-4 overflow-hidden bg-white">
                    <div className="px-4 py-5 sm:px-6">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Additional License
                      </h3>
                      <p className="mt-1 max-w-2xl text-sm text-gray-500">
                        Add another License to this Quote.
                      </p>
                      <SiteLicense />
                    </div>
                  </div>
                )}
                <div className="ml-5 w-full grid-cols-2 space-x-4">
                  <button
                    className={
                      addAdditionalLicense
                        ? "my-5 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                        : "text-shadow-sm my-5 rounded-md border border-gray-300 bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    }
                    onClick={() =>
                      setAddAdditionalLicense(!addAdditionalLicense)
                    }
                  >
                    {addAdditionalLicense
                      ? "Remove Additional License Form"
                      : "+ Add Additional License"}
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </>
      </>
    </>
  );
};

export default Invoice;
