import { forwardRef, useContext, useEffect, useRef } from "react";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import { MQReact } from "../MQReact";

type CalculatorRowProps = {
  handleChange: (mq: any) => void;
  answer: any;
};

const CalculatorRow = forwardRef((props: CalculatorRowProps, mathref: any) => {
  const { handleChange, answer } = props;
  const { handleGlobalFocus } = useContext(StudentSectionsContext);

  useEffect(() => {
    handleGlobalFocus("math-input-0");
  }, []);

  return (
    <div className="z-500000000 border border-red-500 bg-white">
      <MQReact
        id="math-input-0"
        handleFocus={handleGlobalFocus}
        ariaLabel={"Calculator input area"}
        onChange={handleChange}
        ref={mathref}
        cssText={`width: 100% `}
        isCalc={true}
      />
      <span className="">
        <span className="m-2">=</span>
        {answer}
      </span>
    </div>
  );
});

export default CalculatorRow;
