/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
// The above is terrible and needs to be removed before going live.
import React from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { useDMQuery, timeConverter } from "../../utils";
import { deltamathAPI } from "../utils";
import {
  SelectColumnFilter,
  NoFilter,
  TextFilter,
} from "../../shared/table/filters";

function Purgatory() {
  const queryClient = useQueryClient();

  const { data } = useDMQuery({
    path: "/manager_new/invoices",
    customerServiceHeader: true,
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 5,
    },
  });

  const updateQuoteMutation = useMutation(
    ({ quoteNumber, field, value }) => {
      const payload: {
        [key: string]: number | null;
      } = {};
      payload[field] = value;

      const apiUrl = deltamathAPI() + `/manager_new/invoices/${quoteNumber}`;
      const token = localStorage.getItem("customer_service_token");
      return axios.put(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("/manager_new/invoices");
      },
    }
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Last Updated",
        accessor: "lastUpdated",
        Filter: "",
        Cell: ({ cell }) => <>{timeConverter(cell.row.values.lastUpdated)}</>,
      },
      {
        Header: "Quote",
        minWidth: 80,
        accessor: "quoteNumber",
        Filter: NoFilter,
        Cell: ({ cell }) => (
          <div className="w-100 text-center">
            {cell.row.values.quoteNumber ? (
              <Link
                className="text-indigo-500 hover:underline"
                to={`/manager/quote-lookup/${cell.row.values.quoteNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {cell.row.values.quoteNumber}
              </Link>
            ) : (
              <>-</>
            )}
          </div>
        ),
      },
      {
        Header: "PO Number",
        accessor: "poNumber",
        width: 90,
        Filter: NoFilter,
      },
      {
        Header: "Status",
        minWidth: 240,
        accessor: "status",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "PO Uploaded",
        accessor: "poUploadedTime",
        Filter: NoFilter,
        width: 90,
        Cell: ({ cell }) => (
          <div className="w-full text-center">
            {timeConverter(cell.row.values.poUploadedTime)}
          </div>
        ),
      },
      {
        Header: "School/District Name",
        accessor: "name",
        Filter: TextFilter,
      },
      {
        Header: "Contact Name",
        accessor: "contactName",
        Filter: TextFilter,
      },
      {
        Header: "Contact Email",
        accessor: "contactEmail",
        Filter: TextFilter,
      },
      {
        Header: "Admin Name",
        accessor: "adminFirst",
        Filter: TextFilter,
        Cell: ({ cell }) => (
          <div className="w-full text-center">
            {cell.row.original.adminFirst} {cell.row.original.adminLast}
          </div>
        ),
      },
      {
        Header: "Admin Email",
        accessor: "adminEmail",
        Filter: TextFilter,
      },
      {
        Header: "Rostering",
        minWidth: 200,
        accessor: "rosteringSystem",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "LMS",
        minWidth: 200,
        accessor: "lms",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Integration Status",
        accessor: "integrationStatus",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "License Type",
        accessor: "type",
        Filter: SelectColumnFilter,
        minWidth: 140,
        filter: "equals",
        Cell: ({ cell }) => {
          const licenseType = cell.row.values.type;
          let displayValue;
          if (licenseType === "school") {
            displayValue = "School";
          } else if (licenseType === "school_custom") {
            displayValue = "School (No NCES ID)";
          } else displayValue = licenseType;
          return <div className="w-full">{displayValue}</div>;
        },
      },
      {
        Header: "Price",
        minWidth: 50,
        accessor: "priceInfo.price",
        Filter: NoFilter,
      },
      {
        Header: "Hold Until",
        minWidth: 50,
        accessor: "hold_until",
        Filter: NoFilter,
        Cell: ({ cell }) => (
          <div className="w-full text-center">
            {timeConverter(cell.row.values.hold_until)}
          </div>
        ),
      },
      {
        Header: "Invoice",
        minWidth: 50,
        accessor: "invoiceNumber",
        Filter: NoFilter,
        Cell: ({ cell }) => (
          <div className="w-full text-center">
            {cell.row.values.invoiceNumber ? "Y" : "-"}
          </div>
        ),
      },
      {
        Header: "Hide",
        minWidth: 50,
        accessor: "TxnId",
        Filter: NoFilter,
        Cell: ({ row }) => (
          <div className="w-full text-center">
            <button
              className="ml-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => {
                updateQuoteMutation.mutate({
                  quoteNumber: row.original.quoteNumber,
                  field: "hiddenFromView",
                  value: true,
                });
              }}
            >
              Hide
            </button>
          </div>
        ),
      },
    ],
    []
  );

  return (
    data && (
      <DeltaMathTable
        columns={columns}
        data={data}
        options={{ stickyHeader: true }}
      ></DeltaMathTable>
    )
  );
}

export default Purgatory;
