import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Column } from "react-table";
import DeltaMathTable, { fixedHeaderSort } from "../../shared/DeltaMathTable";
import { formatNumber, useDMQuery } from "../../utils";
import { DownloadIcon } from "@heroicons/react/solid";
import { deltamathAPI } from "../../manager/utils";
import { DmLoadingSpinner } from "../../manager/utils/functions";
import slugify from "slugify";
import clsx from "clsx";
import { getAdminParams, WEIGHTED_TEXT } from "../utils";
import { format, toDate } from "date-fns/esm";
import { DemoMode } from "../../utils/demo";
import DeltaMathToggle from "../reports/student-performance/DeltaMathToggle";
import { useUserContext } from "../../shared/contexts/UserContext";

export default function Assignment({ schools }: { schools: any }) {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const adminParams = getAdminParams();
  const [teacher, setTeacher] = React.useState<TeacherData>();
  const [hasIntegral, setHasIntegral] = React.useState(false);
  const [excelLoading, setExcelLoading] = React.useState(false);
  const [configuredTeacherCode, setConfiguredTeacherCode] =
    React.useState<string>();
  const [assignmentTableColumns, setAssignmentTableColumns] =
    React.useState<Column[]>();
  const [assignmentDetailsSummary, setAssignmentDetailsSummary] =
    React.useState<any[]>([]);
  const [assignmentTableData, setAssignmentTableData] = React.useState<any>();
  const [which, setWhich] = useState<string[]>([]);
  const [assignmentType, setAssignmentType] = useState<string>();
  const [showAll, setShowAll] = useState(false);
  const [studentsStartedCount, setStudentsStartedCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);

  const params = useParams();

  const { data } = useDMQuery<TeacherData[]>({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const {
    data: assignmentData,
    isLoading: assignmentDataLoading,
    refetch,
  } = useDMQuery<AssignmentData[]>({
    path: `/admin_new/data/assignment/${params.assignment_id}`,
    params: { filterCompletion: showAll ? false : true },
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  useEffect(() => {
    refetch();
  }, [showAll]);

  const { data: sectionData } = useDMQuery<SectionData[]>({
    path: `/admin_new/data/sections`,
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  useEffect(() => {
    if (typeof params.selected_tab === "undefined") {
      navigate(
        `${process.env.REACT_APP_ADMIN_LINK}/teacher/${params.teachercode}/assignment/${params.assignment_id}/overview`,
        {
          replace: true,
        }
      );
    }
  }, [params]);

  useEffect(() => {
    if (params.teachercode) {
      setConfiguredTeacherCode(params.teachercode);
    } else {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      if (user?.teachercode) {
        setConfiguredTeacherCode(user.teachercode);
      }
    }
  }, []);

  useEffect(() => {
    if (data && configuredTeacherCode) {
      const teacher = data.find((s) => s.teachercode == configuredTeacherCode);
      if (teacher) {
        setTeacher(teacher);
      }
    }
  }, [data, configuredTeacherCode]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (assignmentData && assignmentData.length > 0) {
      let _assignmentType = "";
      if (assignmentData[0].type === "correction") {
        _assignmentType = "Correction";
      } else if (assignmentData[0].type === "standard") {
        if (assignmentData[0].is_test) {
          _assignmentType = "Test";
        } else {
          _assignmentType = "Standard";
        }
      }
      setAssignmentType(_assignmentType);

      // Set Data for DM Table
      const dataArray: any[] = [
        ...assignmentData.map((x) => ({
          sectionName:
            sectionData?.find((sec) => String(sec.sectionId) === x.sectionId)
              ?.sectionName || "Not Found",
          dueDate: x.dueDate,
          numStarted: x.numStarted || 0,
          studentCount: x.studentCount,
          ...x.skills.reduce((acc, val) => {
            acc[val.skill] = `${Math.round((val.percent || 0) * 10) / 10}%`;
            return acc;
          }, {} as { [key: string]: number | string }),
        })),
      ];

      // calculate the total number of students who have started across all sections
      const totalStarted = dataArray.reduce(
        (acc: number, item: AssignmentData) => (acc += item.numStarted),
        0
      );
      const studentTotal = dataArray.reduce(
        (acc: number, item: AssignmentData) => (acc += item.studentCount),
        0
      );
      setStudentsStartedCount(totalStarted);
      setStudentCount(studentTotal);

      // Calculate Weighted Average

      // Only show Weighted Summary row if there are more than one
      if (assignmentData.length > 1) {
        // First build a running total of the weighted average sums
        let numberOfSkills = 0;
        const weightedAverageSums: any = [];
        for (let i = 0; i < assignmentData.length; i++) {
          if (assignmentData[i]?.skills) {
            numberOfSkills = Math.max(
              numberOfSkills,
              assignmentData[i].skills.length
            );
            const theSkills = assignmentData[i].skills.map((a: any) => {
              return {
                skill: a.skill,
                totalPercent: a.percent * assignmentData[i].studentCount,
                studentCount: assignmentData[i].studentCount,
              };
            });
            weightedAverageSums.push(theSkills);
          }
        }

        const weightedSummary: any = {
          sectionName: WEIGHTED_TEXT,
        };
        for (let i = 0; i < numberOfSkills; i++) {
          const columnsArray = weightedAverageSums.map(function (value: any[]) {
            return value[i];
          });
          let initialValue = 0;
          const result = columnsArray.reduce(
            (previousValue: number, currentValue: { totalPercent: number }) =>
              previousValue + currentValue?.totalPercent,
            initialValue
          );

          initialValue = 0;
          const studentCountResult = columnsArray.reduce(
            (previousValue: number, currentValue: { studentCount: number }) =>
              previousValue + currentValue?.studentCount,
            initialValue
          );

          if (columnsArray[0]?.skill) {
            const skillName = columnsArray[0].skill;
            weightedSummary[skillName] = `${
              Math.round((result / studentCountResult || 0) * 10) / 10
            }%`;
          }
        }

        dataArray.unshift(weightedSummary);
      }

      let differentGrades = false;
      dataArray.forEach((x) => {
        if (x.grade !== x.complete) {
          differentGrades = true;
        }
      });

      // Set Columns for DM Table.
      const columnsArray: Array<any> = [
        {
          Header: "Section",
          accessor: "sectionName",
          sortType: fixedHeaderSort,
        },
        {
          Header: "Grade",
          accessor: "complete",
          align: "center",
          sortType: fixedHeaderSort,
        },
      ];

      if (differentGrades) {
        columnsArray.push({
          Header: "Late Grade",
          accessor: "grade",
          align: "center",
          sortType: fixedHeaderSort,
        });
        setWhich(["complete", "grade"]);
      } else {
        setWhich(["complete"]);
      }

      columnsArray.push({
        Header: "Complete",
        accessor: "actuallyComplete",
        align: "center",
        sortType: fixedHeaderSort,
      });
      columnsArray.push({
        Header: "Due",
        accessor: "dueDate",
        align: "left",
        sortType: fixedHeaderSort,
        Cell: (props: any) => {
          // format date here to allow sorting on timestamp
          let date;
          if (props.value) {
            date = format(toDate(props.value * 1000), "MMM d, hh:mm a");
          }
          return <span>{`${date || "--"}`}</span>;
        },
      });
      if (assignmentData[0].is_test) {
        columnsArray.push({
          Header: "# Students Started",
          accessor: "numStarted",
          align: "center",
          sortType: fixedHeaderSort,
        });
      }

      if (assignmentData && assignmentData.length > 0) {
        assignmentData[0].skills.map((skill) => {
          if (
            skill.skill != "grade" &&
            skill.skill != "actuallyComplete" &&
            skill.skill != "sectionName" &&
            skill.skill != "complete" &&
            skill.skill != "test" &&
            skill.skill != "newtest" &&
            assignmentType !== "Correction"
          ) {
            columnsArray.push({
              Header: skill.name,
              accessor: skill.skill,
              align: "center",
              sortType: fixedHeaderSort,
            });
          }
        });
      }
      let assignmentDetails = [
        ...assignmentData[0].skills.map((val: any) => {
          return {
            name: val.name,
            required: val.required,
            weight: val.weight,
          };
        }, {} as { [key: string]: number }),
      ];

      assignmentDetails = assignmentDetails.filter((a: any) => {
        return (
          a.name !== "Grade" &&
          a.name !== "Complete" &&
          a.name !== "Actually Complete" &&
          a.name !== "Test" &&
          a.name !== "New Test"
        );
      });

      setAssignmentDetailsSummary(assignmentDetails);
      setAssignmentTableColumns(columnsArray);
      setAssignmentTableData(dataArray);
    }
  }, [assignmentData, sectionData]);

  const downloadXlsx = async () => {
    setExcelLoading(true);
    const query = which.reduce((acc, val) => {
      if (acc === "") {
        acc = `which=${val}`;
      } else {
        acc = acc.concat(`&which=${val}`);
      }
      return acc;
    }, "");
    axios
      .request({
        method: "get",
        url:
          deltamathAPI() +
          `/admin_new/data/assignment/xlsx/${params.assignment_id}?${query}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        const link = document.createElement("a");
        const fileName = response.headers["content-disposition"].slice(21); // Extract Filename from response header
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setExcelLoading(false);
      });
  };

  useEffect(() => {
    if (teacher && schools) {
      // Check their own admin account
      adminParams?.schoolIDs.forEach((school: any) => {
        const results = schools.filter(function (entry: { schoolid: string }) {
          return entry.schoolid === school;
        });
        if (results.length > 0 && results[0]?.licenseTier === "integral") {
          setHasIntegral(true);
        }
      });

      // Also check check the teacher
      teacher?.schoolPlus.forEach((school: any) => {
        const results = schools.filter(function (entry: { schoolid: string }) {
          return entry.schoolid === school;
        });
        if (results.length > 0 && results[0]?.licenseTier === "integral") {
          setHasIntegral(true);
        }
      });
    }
  }, [teacher, schools]);

  const tabs = [
    {
      name: "Overview",
      roles: ["district", "super_district", "school", "super_school"],
      hiddenForTypes: [],
    },
    {
      name: "Assignment Details",
      roles: ["district", "super_district", "school", "super_school"],
      hiddenForTypes: ["Correction"],
    },
  ];

  return (
    <>
      <div className="flex items-start justify-between">
        <h2 className="flex text-lg font-medium leading-6 text-gray-900">
          <span className="border-b-2 border-solid border-slate-400">
            <DemoMode
              value={`${teacher?.first} ${teacher?.last}`}
              type="person_full"
            />
            {(teacher?.first || teacher?.last) &&
              assignmentData &&
              assignmentData[0] &&
              assignmentData[0].assignmentName &&
              ` - `}
            {assignmentData &&
              assignmentData[0] &&
              assignmentData[0].assignmentName}
          </span>
          <span
            className={clsx(
              "ml-4 mt-2 inline-flex items-center rounded-md px-2.5 py-0.5 text-sm font-medium",
              assignmentType === "Standard" && "bg-blue-100 text-blue-800",
              assignmentType === "Correction" && "bg-red-100 text-red-800",
              assignmentType === "Test" && "bg-gray-100 text-gray-800"
            )}
          >
            {assignmentType}
          </span>
        </h2>

        {hasIntegral &&
          !assignmentDataLoading &&
          assignmentTableData &&
          assignmentTableData.length > 0 && (
            <button
              type="button"
              className="inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-50"
              onClick={() => downloadXlsx()}
              disabled={excelLoading}
            >
              <span className="sr-only">Close panel</span>
              Download Data to Excel
              {excelLoading ? (
                <svg
                  className="ml-2 mr-3 h-5 w-5 animate-spin text-dm-blue"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <DownloadIcon className="ml-2 h-6 w-6" aria-hidden="true" />
              )}
            </button>
          )}
      </div>

      <div className="my-6 flex flex-col border-b border-gray-200 sm:flex-row">
        <nav className="-mb-px flex grow" aria-label="Tabs">
          {tabs.map(
            (tab: {
              name: string;
              roles: string[];
              hiddenForTypes: string[];
            }) =>
              (tab.hiddenForTypes.length === 0 ||
                (tab.hiddenForTypes.length > 0 &&
                  assignmentType &&
                  !tab.hiddenForTypes.includes(assignmentType))) && (
                <Link
                  key={tab.name}
                  to={`${process.env.REACT_APP_ADMIN_LINK}/teacher/${
                    params.teachercode
                  }/assignment/${params.assignment_id}/${slugify(tab.name, {
                    lower: true,
                  })}`}
                  className={clsx(
                    slugify(tab.name, { lower: true }) === params.selected_tab
                      ? "border-dm-light-blue text-dm-light-blue"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "w-1/4 border-b-2 px-1 py-4 text-center text-sm font-medium"
                  )}
                  aria-current={
                    slugify(tab.name, { lower: true }) === params.selected_tab
                      ? "page"
                      : undefined
                  }
                >
                  {tab.name}
                </Link>
              )
          )}
        </nav>
        {assignmentType === "Test" && (
          <>
            {assignmentTableData.length > 1 && (
              <span className="mr-4 px-1 py-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 sm:text-center">{`# Students Started: ${formatNumber(
                studentsStartedCount
              )}/${formatNumber(studentCount)}`}</span>
            )}
            <DeltaMathToggle
              optionA={"Show All"}
              optionB={"In Progress Only"}
              aSelected={showAll}
              onChangeFn={setShowAll}
            />
          </>
        )}
      </div>

      {params.selected_tab === "overview" && (
        <>
          {assignmentDataLoading && (
            <div className="mt-8">
              <DmLoadingSpinner message="Loading Assignment Data..." />
            </div>
          )}

          {!assignmentDataLoading &&
            (!assignmentTableData || assignmentTableData.length === 0) && (
              <div>No Assignment Data found.</div>
            )}

          {assignmentTableData &&
            assignmentTableData.length > 0 &&
            assignmentTableColumns && (
              <div className="mt-4">
                <DeltaMathTable
                  columns={assignmentTableColumns}
                  data={assignmentTableData}
                  getRowProps={(row: any) => ({
                    style: {
                      backgroundColor:
                        row.values.sectionName === WEIGHTED_TEXT
                          ? "#cbd5e1"
                          : "white",
                    },
                  })}
                />
              </div>
            )}
        </>
      )}

      {params.selected_tab === "assignment-details" && (
        <>
          {assignmentTableData &&
            assignmentTableData.length > 0 &&
            assignmentTableColumns && (
              <div className="mt-4">
                <h3 className="my-4 border-b text-lg font-medium leading-6 text-gray-900">
                  Assignment Details
                </h3>

                {assignmentDetailsSummary &&
                assignmentDetailsSummary.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th className="text-md bg-dm-light-blue p-4 font-semibold text-white">
                          Skill
                        </th>
                        <th className="text-md bg-dm-light-blue p-4 font-semibold text-white">
                          {assignmentType === "Test"
                            ? "Points"
                            : "# Problems Required"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {assignmentDetailsSummary.map((a) => (
                        <tr key={a.name}>
                          <td className="text-md whitespace-nowrap p-4 text-left font-medium text-gray-900">
                            {a.name}
                          </td>
                          <td className="text-md whitespace-nowrap p-4 text-center font-medium text-gray-900">
                            {assignmentType === "Test" ? a.weight : a.required}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No Assignment Details Available.</p>
                )}
              </div>
            )}
        </>
      )}
    </>
  );
}

type AssignmentData = {
  type: string;
  is_test: any;
  sectionId: string;
  assignmentName: string;
  studentCount: number;
  numStarted: number;
  dueDate: number;
  skills: Array<{
    [key: string]: string | number;
    required: number;
    weight: number;
    skill: string;
    name: string;
    percent: number;
  }>;
};

type TeacherData = {
  _id: number;
  first: string;
  last: string;
  teachercode: string;
  last_login: number;
  annual_logs: number;
  all_time_logs: number;
  schoolPlus: string[];
};

type SectionData = {
  sectionId: number;
  sectionName: string;
  students: UserData[];
  teachers: UserData[];
};

type UserData = {
  _id: number;
  name: string;
};
