/**
 * Error handler function to be called on failed requests.
 * @param err An error object returned by axios
 */
export const handleErr = (err: any) => {
  let message = "An error occured with the request.";

  if (err?.response?.data) {
    if (
      err.response.data.message ===
      "The token is not authorized to access this content"
    ) {
      message = "You do not have permission to complete this operation.";
    } else if ([401, 403].includes(err.response.data.code)) {
      message = "Your token has expired.";
    } else if (err.response.data.message) {
      message = err.response.data.message;
    }
  }

  return message;
};
