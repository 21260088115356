import {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/solid";
import { debounce, cloneDeep } from "lodash";
import clsx from "clsx";
import {
  skillToSolve,
  skillDataDisplay,
  obfuscate,
  scrollToView,
  // displayProblem,
  getAssignmentDueDateType,
  processlines,
  checkForCustomFiles,
  generateProblemScripts,
  fakeLatexRender,
  resizeKatex,
  updateFullAssignmentData,
  setLSPasscodeToken,
} from "../utils";
import renderMathInElement from "../utils/auto-render";
import devtools from "../utils/devtools";
import { useDMQuery } from "../../utils";
import Document from "./icons/Document";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import SkillCompletedWarningModal from "./SkillCompletedWarningModal";
import Answer from "./answerForm/Answer";
import Problem from "./standard-problems/Problem";
import MaxProblemsMessage from "./MaxProblemsMessage";
import DmWarningModal from "./DmWarningModal";
import "katex/dist/katex.min.css";
import { useWindowContext } from "../../shared/contexts/WindowContext";

type Props = {
  currentProblem: any;
  setCurrentProblem: any;
  showSolution: boolean;
  setShowSolution: any;
  showSolutionForced: boolean;
  showExample: any;
  setShowExample: any;
  setShowVideo: any;
  scoreHeader: any;
  showNextProblem: any;
  setShowNextProblem: any;
};

const MQ = (window as any).MQ;

export default function StandardSkills({
  currentProblem,
  setCurrentProblem,
  showSolution,
  setShowSolution,
  showSolutionForced,
  setShowExample,
  showExample,
  setShowVideo,
  scoreHeader,
  showNextProblem,
  setShowNextProblem,
}: Props): JSX.Element {
  const {
    activeSection,
    setLoadingData,
    dmAssignmentData,
    setDmAssignmentData,
    setIsMfeLoaded,
    studentNextProblems,
    setStudentNextProblems,
    customExternalFiles,
    setGlobalInputsMap,
    globalInputsMap,
    handleGlobalFocus,
    currentProblemData,
    setCurrentProblemData,
  } = useContext(StudentSectionsContext);

  const { skillCode, teacherId } = useParams();
  const solveSkill: any = skillToSolve();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [answer, setAnswer] = useState<Array<string> | object>([""]);
  const [formattedAnswer, setFormattedAnswer] = useState<string>("");
  const [customAnswerData, setCustomAnswerData] = useState<
    Record<string, unknown>
  >({});
  const [customMqFocus, setCustomMqFocus] = useState<string | null>(null);
  const [prevMqFocus, setPrevMqFocus] = useState<string | null>(null);

  const [justCompleted, setJustCompleted] = useState<boolean>(false);
  const [justCompletedWarning, setJustCompletedWarning] =
    useState<boolean>(false);
  const [showJustCompletedWarning, setShowJustCompletedWarning] =
    useState<boolean>(false);
  const [showStuckWarning, setShowStuckWarning] = useState<boolean>(false);
  const [studentIsStuck, setStudentIsStuck] = useState(false);

  const mathBlockContent = useRef<null | HTMLDivElement>(null);

  // stores each MathQuill node and it's current latex
  const mqLatexMap = useRef<Map<any, string>>(new Map());
  // stores the focus event handlers added to MathQuill nodes
  const focusEvents = useRef<Map<Element, () => void>>(new Map());

  const [katexResizingData, setKatexResizingData] = useState<any>({});

  const maxProblemsOneDone =
    solveSkill?.sa?.currentSkill?.maxProblemsOneDone || false;

  // WIP:
  // const problemDisplay = useRef<any>(<></>);

  // useEffect(() => {
  //   console.log("maxProblemsOneDone:", maxProblemsOneDone);
  // }, [maxProblemsOneDone]);

  // useEffect(() => {
  //   console.log("studentNextProblems:", studentNextProblems);
  // }, [studentNextProblems]);

  // useEffect(() => {
  //   console.log("currentProblem:", currentProblem);
  // }, [currentProblem]);

  // useEffect(() => {
  //   console.log("currentProblemData:", currentProblemData);
  // }, [currentProblemData]);

  // useEffect(() => {
  //   console.log("customMqFocus:", customMqFocus);
  // }, [customMqFocus]);

  // useEffect(() => {
  //   console.log("solveSkill:", solveSkill);
  // }, [solveSkill]);

  // useEffect(() => {
  //   console.log("studentIsStuck:", studentIsStuck);
  // }, [studentIsStuck]);

  // useEffect(() => {
  //   console.log("answer:", answer);
  // }, [answer]);

  const problemRef = useRef<Record<string, any>>({
    questionCode: false,
    questionScript: false,
    solutionCode: false,
    solutionScript: false,
  });

  // useEffect(() => {
  //   console.log("problemRef?.current", problemRef?.current);
  // }, [problemRef?.current]);

  const resetCurrentProblem = () => {
    setStudentIsStuck(false);
    setShowSolution(false);
    setShowExample(false);
    setShowVideo(false);
    setAnswer([""]);
    setFormattedAnswer("");
    setIsMfeLoaded(false);
  };

  const navigate = useNavigate();
  const backButtonUrl = () =>
    `${
      process.env.REACT_APP_STUDENT_LINK
    }/${activeSection}/${getAssignmentDueDateType(solveSkill?.sa?.status)}`;

  /* SET and DEFINE GLOBALS */
  window.renderMathInElement = renderMathInElement;

  const skillData = skillDataDisplay(solveSkill.ta.skillName, solveSkill);

  const inlineQuestionCode = () => {
    return `
      window.deltaGraphs2 = [];
      ${currentProblemData?.inlineQuestionCode};`;
  };

  const inlineSolutionCode = () => {
    return `
      window.deltaGraphs = [];
      ${currentProblemData?.inlineSolutionCode};`;
  };

  //TODO: temporary while in development
  const isForcedSkill =
    urlParams.get("skillcode") || urlParams.get("problemid");
  // isForcedSkill uses prod version of problemByAssignment,
  // which has obfuscation always on
  const [isToggleObfuscation, setIsToggleObfuscation] = useState<boolean>(
    isForcedSkill ? false : true
  );

  fakeLatexRender();

  /* ******************* */
  /* problemByAssignment */
  /* ******************* */

  const { data: problemByAssignmentData, refetch: problemRefetch } = useDMQuery(
    {
      path: `/student/${isForcedSkill ? "dev/" : ""}problemByAssignment/${
        solveSkill?.ta?._id
      }`,
      cacheKey: [
        "problemByAssignment",
        solveSkill?.ta?._id,
        solveSkill?.ta?.skillName,
        solveSkill.ta.last_edit,
      ],
      params: {
        sk: solveSkill.ta.skillName,
        last_edit: solveSkill.ta.last_edit,
        toggleObfuscation: isToggleObfuscation,
        force: devtools().getForceNew(),
        force_sk: urlParams.get("skillcode"),
        force_pid: urlParams.get("problemid"),
        ...setLSPasscodeToken(solveSkill?.ta?._id),
      },
      queryOptions: {
        staleTime: 0, //10 * (60 * 1000), // 10 mins
        cacheTime: 0, //15 * (60 * 1000), // 15 mins
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        retry: false,
        enabled: false,
        onSuccess: async (data: any) => {
          if (data?.assignment) {
            updateFullAssignmentData(
              data.assignment,
              data.assignment.ta._id,
              activeSection,
              dmAssignmentData,
              setDmAssignmentData
            );
          }
          // console.group("problemByAssignment success");
          // console.log("data", data);
          // console.log("data.data", data.data);
          // console.log("variables", variables);
          // console.log("context", context);
          // console.groupEnd();
        },
        onError: (error: any) => {
          setLoadingData((prevState: any) => ({
            ...prevState,
            isShowing: true,
            error: true,
            title: "Error",
            message: `${
              error?.response?.data?.message ||
              error?.message ||
              error?.error ||
              ""
            }`,
          }));

          // if passcode_token is incorrect, remove from localStorage
          if (error?.code === 423) {
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            const pcKeyName = "pc_" + solveSkill?.ta?._id + "_" + user?._id;
            localStorage.removeItem(pcKeyName);
            navigate(backButtonUrl());
          }
        },
      },
    }
  );

  useLayoutEffect(() => {
    if (problemByAssignmentData === undefined) return;

    // TEMPORARY if(), data will always be obfuscated on prod
    if (
      isToggleObfuscation == true &&
      problemByAssignmentData?.problem?.data &&
      typeof problemByAssignmentData.problem.data === "string"
    ) {
      problemByAssignmentData.problem.data = obfuscate(
        problemByAssignmentData.problem._id
      ).reveal(problemByAssignmentData.problem.data);
    }

    if (solveSkill?.sa?._id && solveSkill?.ta?.skillName) {
      setStudentNextProblems((prevState: any) => ({
        ...prevState,
        [solveSkill.sa._id]: {
          ...prevState[solveSkill.sa._id],
          [solveSkill.ta.skillName]:
            problemByAssignmentData?.problem || undefined,
        },
      }));

      updateProblem(cloneDeep(problemByAssignmentData?.problem) || undefined);
    }
  }, [problemByAssignmentData]);

  const updateProblem = async (problem: any) => {
    if (problem === undefined) {
      setCurrentProblem({
        correct: undefined,
        solution: undefined,
        complete: false,
        nextProblem: undefined,
        maxProblemsOneDone: undefined,
      });
      setCurrentProblemData(undefined);
      return;
    }

    mathBlockContent?.current?.classList.add("invisible");
    setKatexResizingData({});

    const hasExternalFiles =
      problem.ansType === "custom" ||
      problem.data?.externalUrlExists ||
      problem.data?.sharedExternalFile;

    // fetch the files and set the ref, if the correct files are not already there
    if (
      hasExternalFiles &&
      !customExternalFiles.current.has(problem.skillcode)
    ) {
      const files = await checkForCustomFiles(problem);
      customExternalFiles.current.set(problem.skillcode, files);
    }

    const problemData = hasExternalFiles
      ? generateProblemScripts(
          customExternalFiles.current.get(problem.skillcode),
          problem,
          problem?.log_data?.data
        )
      : problem;

    // obscureMode will be used in the module code to indicate to the module coder not to generate a graph with any extra data (besides the student answer)
    if (
      hasExternalFiles &&
      skillData.isTest &&
      skillData.obscureResults &&
      !skillData.solutionsAvailable
    ) {
      problemData.data.data.obscureMode = true;
    }
    // set teacher data for special custom modules
    if (hasExternalFiles) {
      problemData.data.data.assignmentTeacherID = solveSkill.ta._id;
      problemData.data.data.assignmentTeachercode = solveSkill.ta.teachercode;
    }

    setCurrentProblemData(problemData);
    //TODO: update w/ setStudentNextProblems() here too? Seems to be set correctly, but why
    resetProblemViewed();
    setCurrentProblem({
      ...currentProblem,
      correct: problemData?.log_data?.correct,
      solution: problemData?.lines,
      nextProblem: undefined,
      complete: skillData?.isCompleted, //TODO: Check if this is being updated immediately when skill completes, no refresh
      maxProblemsOneDone: problemData?.log_data?.maxProblemsOneDone,
    });

    // WIP:
    // problemDisplay.current = displayProblem(
    //   processlines(currentProblemData?.qlines),
    //   currentProblemData,
    //   katexResizingData["question"],
    //   "question"
    // );

    const timer = setTimeout(() => {
      mathBlockContent?.current?.classList.remove("invisible");
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: false,
      }));
      clearTimeout(timer);
    }, 100);
  };

  const windowContext = useWindowContext();
  // const timeTrackingForProblem = windowContext.

  /* ************ */
  /* check_answer */
  /* ************ */

  // TODO: refactor this! It should be a mutation, not a query!
  const { refetch: checkAnswerRefresh, isLoading: isCheckAnswerLoading } =
    useDMQuery({
      path: `/student/${isForcedSkill ? "dev/" : ""}check_answer`,
      method: "post",
      cacheKey: ["check_answer", solveSkill?.ta?.skillName], // TODO: Whats the proper key here?
      requestBody: {
        encryptedProblemId: currentProblemData?._id,
        taId: solveSkill.ta._id,
        sk: solveSkill.ta.skillName,
        last_edit: solveSkill.ta.last_edit,
        time_tracked: windowContext.getTimeInfo(
          skillCode ?? "",
          currentProblemData ? currentProblemData.unique_string ?? "" : "",
          solveSkill.sa._id
        ).duration,
        start_time: windowContext.getTimeInfo(
          skillCode ?? "",
          currentProblemData ? currentProblemData.unique_string ?? "" : "",
          solveSkill.sa._id
        ).start,
        submitted: {
          stuck: studentIsStuck,
          solution: formattedAnswer,
          actual_sk: solveSkill.ta.skillName, // TODO: add in mixed problem sk
          answers: answer,
          ...(currentProblemData?.ansType === "custom"
            ? {
                answerData: currentProblemData?.data?.data?.answerData,
                ...(customAnswerData.correct !== undefined
                  ? {
                      metaData: obfuscate(currentProblemData?._id).hide(
                        customAnswerData
                      ),
                    }
                  : {}),
              }
            : {}),
        },
      },
      queryOptions: {
        // staleTime: 1000 * 60 * 5,
        retry: false,
        refetchOnWindowFocus: false,
        enabled: false,
        onSuccess: async (data: any, variables: any, context: any) => {
          console.group("check_answer success");
          console.log("data", data);
          console.log("data.data", data.data);
          console.log("variables", variables);
          console.log("context", context);
          console.groupEnd();

          // wrong answer, but attempts are left
          if (data?.attempts) {
            setCurrentProblemData((prevState: any) => ({
              ...prevState,
              attempts: data?.attempts,
            }));

            if (solveSkill?.sa?._id && solveSkill?.ta?.skillName) {
              setStudentNextProblems((prevState: any) => ({
                ...prevState,
                [solveSkill.sa._id]: {
                  ...prevState[solveSkill.sa._id],
                  [solveSkill.ta.skillName]: {
                    ...prevState[solveSkill.sa._id]?.[solveSkill.ta.skillName],
                    attempts: data?.attempts,
                  },
                },
              }));
            }

            const remainingAttempts =
              data?.attempts?.max - data?.attempts?.used;

            setLoadingData((prevState: any) => ({
              ...prevState,
              isShowing: true,
              error: true,
              title: "Notice",
              message: `Your answer is not correct. Try to find your mistake. You have ${remainingAttempts} attempt${
                remainingAttempts > 1 ? "s" : ""
              } remaining.`,
            }));
          }
          // all attempts exhausted, answer is final
          else {
            // TODO: clear the time tracking here
            windowContext.trackEvent(
              "finish_problem",
              new Date(),
              skillCode ?? "",
              currentProblemData.unique_string,
              solveSkill.sa._id
            );

            // reveal data.problem.data before setting the current problem
            if (data?.problem?.data && typeof data.problem.data === "string") {
              data.problem.data = obfuscate(data?.problem._id).reveal(
                data?.problem?.data
              );
            }

            const nextProblemData = data?.problem;

            setCurrentProblem({
              ...currentProblem,
              correct: data?.solutionData?.log_data?.correct,
              solution: data?.solutionData?.lines,
              nextProblem: nextProblemData,
              complete: true,
              maxProblemsOneDone:
                data?.solutionData?.log_data?.maxProblemsOneDone,
            });

            setCurrentProblemData((prevState: any) => ({
              ...prevState,
              ...(data?.solutionData?.lines
                ? { lines: cloneDeep(data?.solutionData?.lines) }
                : {}),
              ...(data?.solutionData?.log_data
                ? {
                    log_data: {
                      ...cloneDeep(data?.solutionData?.log_data),
                    },
                  }
                : {}),
            }));

            if (nextProblemData !== undefined) {
              setStudentNextProblems((prevState: any) => ({
                ...prevState,
                [solveSkill.sa._id]: {
                  ...prevState[solveSkill.sa._id],
                  [solveSkill.ta.skillName]: nextProblemData,
                },
              }));
              // data.skillComplete?
            } else if (
              data?.solutionData?.log_data?.maxProblemsOneDone !== true &&
              nextProblemData === undefined
            ) {
              const tempClone = { ...studentNextProblems };
              delete tempClone[solveSkill.sa._id]?.[solveSkill.ta.skillName];
              setStudentNextProblems(tempClone);
            }

            if (data?.solutionData?.log_data?.maxProblemsOneDone === true) {
              setStudentNextProblems((prevState: any) => ({
                ...prevState,
                [solveSkill.sa._id]: {
                  ...prevState[solveSkill.sa._id],
                  [solveSkill.ta.skillName]: {
                    ...prevState[solveSkill.sa._id][solveSkill.ta.skillName],
                    ...(data?.solutionData?.lines
                      ? { lines: cloneDeep(data?.solutionData?.lines) }
                      : {}),
                    ...(data?.solutionData?.log_data
                      ? {
                          log_data: {
                            ...cloneDeep(data?.solutionData?.log_data),
                          },
                        }
                      : {}),
                  },
                },
              }));
            }
            if (data?.assignment) {
              updateFullAssignmentData(
                data.assignment,
                data.assignment.ta._id,
                activeSection,
                dmAssignmentData,
                setDmAssignmentData
              );
            } else {
              updateAssignmentData(data?.submissionResponse, data?.ta);
            }
            scrollToView(scoreHeader);
            setShowSolution(true);

            setLoadingData((prevState: any) => ({
              ...prevState,
              isShowing: false,
            }));
          }
        },
        onError: (error: any, variables: any, context: any) => {
          console.group("check_answer onError");
          console.log("error", error?.response?.data?.message || error?.error);
          console.log("variables", variables);
          console.log("context", context);
          console.groupEnd();
          setLoadingData((prevState: any) => ({
            ...prevState,
            isShowing: true,
            error: true,
            title: "Error",
            message: `${
              error?.response?.data?.message ||
              error?.message ||
              error?.error ||
              ""
            }`,
          }));
        },
        // onSettled: () => {
        //   setStudentIsStuck(false);
        // },
      },
    });

  /* update sa assignmentData from check_answer for this assignment */
  const updateAssignmentData = (dataObj: any, taObj?: any) => {
    if (!dataObj) return;
    // TODO: Why does invalidating stop score from updating?
    // const queryClient = useQueryClient();
    // queryClient.invalidateQueries("/student/data/assignments");
    dmAssignmentData[activeSection].filter((assignment: any, index: number) => {
      if (String(assignment.ta._id) === teacherId) {
        const assignmentObj = { ...dmAssignmentData };

        // update solvedProblems, if necessary, in the ta object
        if (dataObj.solvedProblems !== undefined) {
          assignmentObj[activeSection][index].ta.skills[
            solveSkill?.ta?.skillName
          ].solvedProblems = dataObj.solvedProblems;
          delete dataObj.solvedProblems; // delete prop from dataObj (not included in sa obj update)
        }

        // 1. spread the dataObj data into the current assignment's object
        // 2. dataObj.data only returns current skill, so spread that data
        //    after applying the current sa.data
        assignmentObj[activeSection][index].sa = {
          ...assignmentObj[activeSection][index].sa,
          ...dataObj,
          data: {
            ...assignmentObj[activeSection][index].sa.data,
            ...dataObj.data,
          },
        };

        if (taObj !== undefined) {
          assignmentObj[activeSection][index].ta = {
            ...assignmentObj[activeSection][index].ta,
            ...taObj,
          };
        }

        setDmAssignmentData({ ...assignmentObj });
        return;
      }
    });
  };

  useEffect(() => {
    if (!showNextProblem) return;
    setShowNextProblem(false);

    if (justCompletedWarning && solveSkill?.sa?.actuallyComplete < 100) {
      setJustCompletedWarning(false);
      setShowJustCompletedWarning(true);
    } else {
      setNextProblem();
    }
  }, [showNextProblem]);

  const setNextProblem = () => {
    setShowJustCompletedWarning(false);
    resetProblemViewed();
    resetCurrentProblem();
    if (
      !skillData.isCompleted &&
      studentNextProblems[solveSkill?.sa?._id]?.[solveSkill?.ta?.skillName]
    ) {
      updateProblem(
        cloneDeep(
          studentNextProblems[solveSkill?.sa?._id]?.[solveSkill?.ta?.skillName]
        )
      );
    } else if (skillData.isStandardSkill) {
      // WIP: problemDisplay.current = <></>;
      updateProblem(undefined);
      problemRefetch();
    }
  };

  useEffect(() => {
    if (!skillData.isCompleted) return;
    if (!justCompleted) {
      setJustCompleted(true);
      setJustCompletedWarning(true);
    }
  }, [skillData.isCompleted]);

  useEffect(() => {
    setLoadingData((prevState: any) => ({
      ...prevState,
      error: false,
      isShowing: true,
    }));
    setShowExample(false);
    resetProblemViewed();
    resetCurrentProblem();
    if (
      skillData.isStandardSkill &&
      !studentNextProblems[solveSkill?.sa?._id]?.[solveSkill?.ta?.skillName]
    ) {
      // WIP: problemDisplay.current = <></>;
      updateProblem(undefined);
      problemRefetch();
    } else if (
      studentNextProblems[solveSkill?.sa?._id]?.[solveSkill?.ta?.skillName]
    ) {
      updateProblem(
        cloneDeep(
          studentNextProblems[solveSkill.sa._id][solveSkill.ta.skillName]
        )
      );
    }
    setShowSolution(maxProblemsOneDone);
    setJustCompleted(false);
    setJustCompletedWarning(false);
  }, [skillCode]);

  /* Create event listeners for common btns, based on the currently focused MathQuill field */
  useEffect(() => {
    const commonBtns = document.querySelectorAll("button.common-button");
    const commonBtnClick = (btnLatex: string, customMqFocus: string | null) => {
      const node =
        customMqFocus !== null
          ? MQ(document.getElementById(customMqFocus))
          : undefined;
      if (node) {
        // replace all parentheses and square brackets with correct latex
        btnLatex = btnLatex
          .replaceAll("(", "\\left(")
          .replaceAll(")", "\\right)")
          .replaceAll("[", "\\left[")
          .replaceAll("]", "\\right]");
        // account for special buttons
        if (btnLatex.includes(",")) {
          // such as [,] for interval notation
          node.write(btnLatex).keystroke("Left Left");
        } else if (btnLatex.includes("cuberoot")) {
          node
            .cmd("nthroot")
            .typedText("3")
            .keystroke("Tab")
            .keystroke("Left Right");
        } else if (btnLatex.includes("nthroot")) {
          node.cmd("nthroot").keystroke("Left Right");
        } else if (btnLatex.includes("sqrt")) {
          if (btnLatex === "\\sqrt{}") btnLatex = "sqrt";
          node.cmd(btnLatex);
        } else {
          // all other latex
          node.write(btnLatex);
        }
        node.focus();
      }
    };
    if (customMqFocus) {
      const elm = MQ(document.getElementById(customMqFocus));

      if (globalInputsMap) {
        setGlobalInputsMap(globalInputsMap.set(String(customMqFocus), elm));
        handleGlobalFocus(customMqFocus);
      } else {
        const tempMap = new Map();
        tempMap.set(String(customMqFocus), elm);
        setGlobalInputsMap(tempMap);
        handleGlobalFocus(customMqFocus);
      }
    }
    const eventListenerMap = new Map();
    if (commonBtns.length) {
      commonBtns.forEach((btn: Element) => {
        const quill = btn.attributes.getNamedItem("quill")?.value;
        const btnLatex = quill ? quill : (btn as HTMLElement).innerText;
        const btnEventListener = () => commonBtnClick(btnLatex, customMqFocus);
        eventListenerMap.set(btn, btnEventListener);
        btn.addEventListener("click", btnEventListener);
      });
    }
    return () => {
      eventListenerMap.forEach(
        (evListenerFunc: () => void, element: Element) => {
          element.removeEventListener("click", evListenerFunc);
        }
      );
    };
  }, [customMqFocus, currentProblemData]);

  /* Function to create the MathQuill fields in custom problems */
  const createMQ = useCallback(() => {
    const mqWrappers = document.querySelectorAll(".mathquill-editable");
    if (mqWrappers.length) {
      /* Set global config defaults */
      MQ.config({
        restrictMismatchedBrackets: true,
        spaceBehavesLikeTab: true,
        sumStartsWithNEquals: false,
        supSubsRequireOperand: true,
        charsThatBreakOutOfSupSub: currentProblemData?.data?.binomialExponent
          ? ""
          : "+-=<>",
        autoCommands: "pi theta sqrt sum ge le approx pm nthroot infty cup",
        autoOperatorNames:
          "sin cos tan arccos arcsin arctan lim csc sec cot log ln Ans ans or undefined DNE",
      });

      mqWrappers.forEach((el: Element) => {
        /* Create MQ Element */
        const mq = MQ.MathField(el);

        /* Configure MQ handler to prevent long decimals */
        mq.config({
          handlers: {
            edit: (mq: any) => {
              const latex = mq.latex();
              if (/\.\d{8,}/.test(latex))
                mq.latex(mqLatexMap.current.get(mq.id));
              else mqLatexMap.current.set(mq.id, mq.latex());
            },
          },
        });

        /* Add focus in event listener */
        const textareaEl = el.querySelectorAll("textarea")[0];
        const focusInFunc = () => {
          setCustomMqFocus(el.id);
          setGlobalInputsMap(globalInputsMap.set(el.id, mq));
          handleGlobalFocus(el.id);
        };
        textareaEl.addEventListener("focusin", focusInFunc);
        /* Update the event listeners in focusEvents ref map */
        focusEvents.current.set(textareaEl, focusInFunc);
      });
    }
    setIsMfeLoaded(true); // change state for loading mq
  }, [currentProblemData]);

  const resetProblemViewed = () => {
    problemRef.current = {
      questionCode: false,
      questionScript: false,
      solutionCode: false,
      solutionScript: false,
    };
  };

  // Set a flag when a problem and it's solution has been viewed
  const checkProblemViewed = (options: any = {}) => {
    const questionCode = options.questionCode
      ? options.questionCode
      : undefined;
    const questionScript = options.questionScript
      ? options.questionScript
      : undefined;
    const solutionCode = options.solutionCode
      ? options.solutionCode
      : undefined;
    const solutionScript = options.solutionScript
      ? options.solutionScript
      : undefined;

    problemRef.current = {
      ...problemRef.current,
      ...(questionCode !== undefined
        ? { questionCode: questionCode }
        : {
            questionCode: problemRef.current.questionCode || false,
          }),
      ...(questionScript !== undefined
        ? { questionScript: questionScript }
        : {
            questionScript: problemRef.current.questionScript || false,
          }),
      ...(solutionCode !== undefined
        ? { solutionCode: solutionCode }
        : {
            solutionCode: problemRef.current.solutionCode || false,
          }),
      ...(solutionScript !== undefined
        ? { solutionScript: solutionScript }
        : {
            solutionScript: problemRef.current.solutionScript || false,
          }),
    };
  };

  const showTestSolution =
    skillData.isTest &&
    skillData.isCompleted &&
    !skillData.obscureResults &&
    skillData.solutionsAvailable;
  const showSolutionPage =
    (!skillData.isTest && showSolution) ||
    (skillData.isTest && showTestSolution && showSolution);
  const showQuestionPage =
    (!skillData.isTest && !showSolution) ||
    (skillData.isTest && !showTestSolution);

  // useEffect(() => {
  //   console.log("showSolutionPage:", showSolutionPage);
  // }, [showSolutionPage]);

  // useEffect(() => {
  //   console.log("showQuestionPage:", showQuestionPage);
  // }, [showQuestionPage]);

  // useEffect(() => {
  //   console.log("showSolution:", showSolution);
  // }, [showSolution]);

  const displayQuestion = () => {
    // hide .answerData on custom assignments when the data doesn't exist
    if (
      currentProblemData?.log_data?.data ||
      (currentProblemData?.data?.data?.answerData &&
        currentProblemData?.data?.data?.answerData?.show !== false)
    ) {
      document
        .querySelector("#problem_page .answerData")
        ?.classList.remove("hidden");
    } else {
      document
        .querySelector("#problem_page .answerData")
        ?.classList.add("hidden");
    }

    // These are possibly used by inlineSolutionCode or inlineQuestionCode when eval()
    // Guarantees data & page are in scope.
    // !!DO NOT DELETE!!

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const data = currentProblemData?.data?.data || {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const page = showSolution
      ? document.getElementById("question_page")
      : document.getElementById("problem_page");

    if (currentProblemData?.data?.inlineFirst === undefined) {
      if (!problemRef?.current?.questionCode) {
        eval(inlineQuestionCode());
        checkProblemViewed({ questionCode: true });
        createMQ(); // after the question code is evaluated, create MathQuill boxes
      }

      if (
        currentProblemData?.problemScripts &&
        !problemRef?.current?.questionScript &&
        problemRef?.current?.questionCode
      ) {
        currentProblemData?.problemScripts?.questionScripts(
          document.getElementById("question_page")
        );
        checkProblemViewed({ questionScript: true });
      }

      if (showSolution && !problemRef?.current?.solutionCode) {
        eval(inlineSolutionCode());
        checkProblemViewed({ solutionCode: true });
      }

      if (
        currentProblemData?.problemScripts &&
        showSolution &&
        !problemRef?.current?.solutionScript &&
        currentProblemData?.lines
      ) {
        currentProblemData?.problemScripts?.solutionScripts(
          document.getElementById("problem_page")
        );
        checkProblemViewed({ solutionScript: true });
      }
    } else if (currentProblemData?.data?.inlineFirst === false) {
      if (
        currentProblemData?.problemScripts &&
        !problemRef?.current?.questionScript
      ) {
        currentProblemData?.problemScripts?.questionScripts(
          document.getElementById("question_page")
        );
        checkProblemViewed({ questionScript: true });
      }

      if (
        !problemRef?.current?.questionCode &&
        problemRef?.current?.questionScript
      ) {
        eval(inlineQuestionCode());
        checkProblemViewed({ questionCode: true });
        createMQ(); // after the question code is evaluated, create MathQuill boxes
      }

      if (
        currentProblemData?.problemScripts &&
        showSolution &&
        !problemRef?.current?.solutionScript &&
        currentProblemData?.lines
      ) {
        currentProblemData?.problemScripts?.solutionScripts(
          document.getElementById("problem_page")
        );
        checkProblemViewed({ solutionScript: true });
      }

      if (
        showSolution &&
        !problemRef?.current?.solutionCode &&
        problemRef?.current?.solutionScript
      ) {
        eval(inlineSolutionCode());
        checkProblemViewed({ solutionCode: true });
      }
    }

    // TODO: HM - Keep an eye on this conditional!
    if (
      skillData.isTest &&
      skillData.isCompleted &&
      currentProblemData?.log_data?.data &&
      currentProblemData?.answerLines?.length &&
      !problemRef?.current?.solutionScript
    ) {
      eval(inlineSolutionCode());
      currentProblemData?.problemScripts?.solutionScripts(
        document.querySelector(".answerData")
      );
    }

    // increase input width as more data is typed in
    eval(`$('.display-problem input:not(.no-auto-grow)').autoGrowInput();`);
  };

  useLayoutEffect(() => {
    if (
      (currentProblemData?.problemScripts !== undefined &&
        currentProblemData?.ansType === "custom") ||
      (currentProblemData?.ansType !== "custom" &&
        currentProblemData?.ansType !== undefined)
    ) {
      displayQuestion();
    }
    if (!skillData.isVideo)
      renderMathInElement(document.getElementById("mathBlock"));

    setKatexResizingData((prevState: any) => ({
      ...prevState,
      [showSolution ? "solution" : "question"]: resizeKatex(
        showSolution,
        prevState
      ),
    }));
    return () => {
      /* Remove all event listeners from MathQuill boxes */
      if (focusEvents.current.size) {
        focusEvents.current.forEach((listener, element) => {
          element.removeEventListener("focusin", listener);
        });
      }
    };
  }, [currentProblemData, showSolutionForced]); //, showSolution]);

  /* On browser resize, call resizeKatex() to readjust the KaTeX width */
  const handleResize = () => {
    setKatexResizingData((prevState: any) => ({
      ...prevState,
      [showSolution ? "solution" : "question"]: resizeKatex(
        showSolution,
        prevState
      ),
    }));
  };

  const debounce_resize = debounce(handleResize, 150);

  useEffect(() => {
    window.addEventListener("resize", debounce_resize);
    return () => {
      window.removeEventListener("resize", debounce_resize);
    };
  });

  /* Logic to show max problems messaging */
  const maxProblems = solveSkill.ta?.currentSkill?.maxProblems;
  const solvedProblems = solveSkill.ta?.currentSkill?.solvedProblems;
  const requiredProblems = solveSkill.ta?.currentSkill?.required;
  // undefined: no message to show, true: message shows at top, false: message shows at bottom
  let showMaxMessageTop: undefined | boolean;
  if (
    !skillData.isTest &&
    maxProblems !== undefined &&
    solvedProblems !== undefined
  ) {
    if (
      showSolution &&
      (maxProblems === 1 || maxProblems - solvedProblems === 0)
    ) {
      showMaxMessageTop = true;
    } else if (!showSolution) {
      showMaxMessageTop =
        maxProblems - solvedProblems <= requiredProblems ? true : false;
    }
  }

  /* Logic for still stuck / stuck button */
  const hasMoreProblems =
    maxProblems !== undefined &&
    solvedProblems !== undefined &&
    maxProblems - solvedProblems <= 1
      ? false
      : true;
  const showStillStuck =
    currentProblemData?.attempts?.used > 0 || currentProblemData?.stillStuck;
  const showStuckButton =
    hasMoreProblems &&
    !showSolution &&
    (showStillStuck || currentProblemData?.data?.guided);

  const handleStuck = () => {
    setShowStuckWarning(true);
    setStudentIsStuck(true);
    setAnswer(["\\text{Problem skipped}"]);
    setFormattedAnswer("\\text{Problem skipped}");
    if (currentProblemData?.ansType === "custom") {
      setCustomAnswerData({
        correct: 0,
      });
    }
  };

  return (
    <>
      {showMaxMessageTop === true && (
        <div className="mt-10">
          <MaxProblemsMessage
            showSolution={showSolution}
            problemsLeft={maxProblems - solvedProblems}
            maxProblems={maxProblems}
            requiredProblems={requiredProblems}
          />
        </div>
      )}
      <div
        id="mathBlock"
        className={clsx(
          "relative border border-[#E4E6EA] bg-white px-2 py-9 sm:px-5 lg:px-9",
          !skillData.isVideo && !skillData.isTest && !showMaxMessageTop
            ? "mt-10"
            : null,
          !showMaxMessageTop && "rounded-t-lg",
          showMaxMessageTop !== false && "rounded-b-lg"
        )}
        aria-live="polite"
      >
        <div ref={mathBlockContent}>
          <div className="flex content-center justify-between gap-x-2">
            <h2
              id="problemPrompt"
              dangerouslySetInnerHTML={{
                __html: currentProblemData?.prompt
                  ? currentProblemData.prompt
                  : null,
              }}
            ></h2>
            <div className="icon">
              {/* {currentProblem.correct === undefined && (
                <>
                  <QuestionMarkCircleIcon className="w-14 text-gray-500" />
                  <span className="sr-only">unanswered</span>
                </>
              )} */}
              {currentProblem.correct === 1 && (
                <div className="inline-flex items-center rounded-full border border-dm-success-500 bg-dm-success-100 py-0.5 pl-0.5 pr-4 font-sans sm:py-1 sm:pl-2 sm:pr-6">
                  <CheckCircleIcon className="w-10 pr-1 text-dm-success-500 sm:w-14" />
                  <span>Correct</span>
                </div>
              )}
              {currentProblem.correct === 0 && (
                <div className="inline-flex items-center rounded-full border border-dm-error-500 bg-dm-error-100 py-0.5 pl-0.5 pr-4 font-sans sm:py-1 sm:pl-2 sm:pr-6">
                  <XCircleIcon className="w-10 pr-1 text-dm-error-500 sm:w-14" />
                  <span>Incorrect</span>
                </div>
              )}
              {currentProblem.correct === -1 && (
                <div className="inline-flex items-center rounded-full border border-dm-charcoal-500 bg-dm-charcoal-100 py-0.5 pl-0.5 pr-4 font-sans sm:py-1 sm:pl-2 sm:pr-6">
                  <div className="my-1 ml-1 mr-2 h-8 w-8 rounded-full border border-white bg-dm-charcoal-500 p-2 sm:h-10 sm:w-10">
                    <Document
                      classes="block m-auto w-4 sm:w-6"
                      strokeColor="#FFFFFF"
                    />
                  </div>
                  <span>Teacher Graded</span>
                </div>
              )}
            </div>
          </div>
          <div
            key={`questionpage:${currentProblemData?._id}`}
            id="question_page"
            className={clsx(
              "display-problem question-page",
              showSolutionPage ? "sr-only" : null
            )}
            aria-hidden={showSolutionPage}
          >
            {/* {problemDisplay.current} */}
            {/* {displayProblem(
              processlines(currentProblemData?.qlines),
              currentProblemData,
              katexResizingData["question"],
              "question"
            )} */}
            <Problem
              displayData={processlines(currentProblemData?.qlines)}
              problemData={currentProblemData}
              resizingData={katexResizingData["question"]}
              locString="question"
            />
          </div>
          <div
            key={`problempage:${currentProblemData?._id}`}
            id="problem_page"
            className={clsx(
              "display-problem problem-page", // TODO: double check these
              showQuestionPage ? "sr-only" : null
            )}
            aria-hidden={showQuestionPage}
          >
            {/* {displayProblem(
              processlines(currentProblemData?.lines),
              currentProblemData,
              katexResizingData["solution"],
              "solution"
            )} */}
            <Problem
              displayData={processlines(currentProblemData?.lines)}
              problemData={currentProblemData}
              resizingData={katexResizingData["solution"]}
              locString="solution"
            />
          </div>
        </div>
        {/* Answer Area */}
        <div className="relative mt-8 rounded bg-gray-100 p-4 sm:p-6">
          <>
            <Answer
              key={"answer:" + currentProblemData?._id}
              correct={currentProblem?.correct}
              complete={currentProblem?.complete}
              showSolution={showSolution}
              setShowSolution={setShowSolution}
              isCheckAnswerLoading={isCheckAnswerLoading}
              answer={answer}
              setAnswer={setAnswer}
              formattedAnswer={formattedAnswer}
              setFormattedAnswer={setFormattedAnswer}
              setCustomAnswerData={setCustomAnswerData}
              customMqFocus={customMqFocus}
              setCustomMqFocus={setCustomMqFocus}
              callback={checkAnswerRefresh}
              problem={currentProblemData}
              katexResizingData={katexResizingData}
            />
          </>
        </div>
      </div>
      {showMaxMessageTop === false && (
        <div>
          <MaxProblemsMessage
            showSolution={showSolution}
            problemsLeft={maxProblems - solvedProblems}
            maxProblems={maxProblems}
            requiredProblems={requiredProblems}
          />
        </div>
      )}
      {showStuckButton && (
        <div className="mt-4 flex justify-end">
          <button className="text-dm-brand-blue-500" onClick={handleStuck}>
            {showStillStuck ? "Still Stuck?" : "Stuck?"}
          </button>
        </div>
      )}
      {currentProblemData ? (
        <div className="mx-1 mt-14 h-[400px] overflow-scroll border-2 border-dashed border-slate-400 bg-slate-100 p-2 md:mx-24">
          <h3 className="mb-5 font-bold">/problemByAssignment JSON:</h3>
          <pre className="overflow-auto break-words font-mono text-xs">
            {JSON.stringify(currentProblemData, null, 2)}
          </pre>
        </div>
      ) : null}
      <SkillCompletedWarningModal
        isShowing={showJustCompletedWarning}
        setIsShowing={setShowJustCompletedWarning}
        setNextProblem={setNextProblem}
      />
      <DmWarningModal
        showModal={showStuckWarning}
        setShowModal={setShowStuckWarning}
        isConfirmDisabled={isCheckAnswerLoading}
        headline="Warning"
        message="If you proceed, you will be shown the solution to this problem and marked incorrect. You will then get a new question. Are you sure you would like to see the solution to this question?"
        confirmMessage="Yes, show the solution"
        modalConfirmAction={() => {
          setLoadingData((prevState: any) => ({
            ...prevState,
            isShowing: true,
          }));
          checkAnswerRefresh();
        }}
        modalCancelAction={() => {
          setStudentIsStuck(false);
        }}
      />
    </>
  );
}
