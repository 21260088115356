import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { parse, isValid } from "date-fns";
import DatePicker from "react-datepicker";
import { useDMQuery } from "../../../utils";
import { getStartEndDate } from "../StudentUsageUtils";
import clsx from "clsx";
import { ChartSquareBarIcon } from "@heroicons/react/outline";

export const StudentUsageNav = ({
  selectedDataScope,
  selectedSchools,
  selectedTeachers,
  selectedSections,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: {
  selectedDataScope: any;
  selectedSchools: any;
  selectedTeachers: any;
  selectedSections: any;
  startDate: Date | null;
  setStartDate: any;
  endDate: Date | null;
  setEndDate: any;
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const { yesterday, lastWeek, thirtyDays, yearToDate } = getStartEndDate();

  const [dateRange, setDateRange] = useState<string>("year-to-date");
  const [canViewData, setCanViewData] = useState<boolean>(true);

  const { data: schoolData } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      enabled: true,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: teacherData } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });

  const { data: sectionData } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  const ScopeBlock = () => {
    const numberOfSchoolsSelected = Object.keys(selectedSchools).length;
    const numberOfTeachersSelected = Object.keys(selectedTeachers).length;
    const numberOfSectionsSelected = Object.keys(selectedSections).length;

    if (selectedDataScope === "Schools" && numberOfSchoolsSelected) {
      return (
        <>
          {numberOfSchoolsSelected > 0 &&
            Array.isArray(schoolData) &&
            schoolData.length > 0 && (
              <span
                key="scope-schools"
                className={
                  "ml-4 inline-flex cursor-pointer items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 md:mt-2 lg:mt-0"
                }
              >
                <span className="text-left font-semibold">
                  {schoolData.length === numberOfSchoolsSelected ? (
                    <>All Schools Selected</>
                  ) : (
                    <>
                      {numberOfSchoolsSelected} School
                      {numberOfSchoolsSelected > 1 ? "s" : ""} Selected
                    </>
                  )}
                </span>
              </span>
            )}
        </>
      );
    } else if (selectedDataScope === "Teachers" && numberOfTeachersSelected) {
      return (
        <>
          {numberOfTeachersSelected > 0 &&
            Array.isArray(teacherData) &&
            teacherData.length > 0 && (
              <span
                key="scope-teachers"
                className={
                  "ml-4 inline-flex cursor-pointer items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 md:mt-2 lg:mt-0"
                }
              >
                <span className="text-left font-semibold">
                  {teacherData.length === numberOfTeachersSelected ? (
                    <>All Teachers Selected</>
                  ) : (
                    <>
                      {numberOfTeachersSelected} Teacher
                      {numberOfTeachersSelected > 1 ? "s" : ""} Selected
                    </>
                  )}
                </span>
              </span>
            )}
        </>
      );
    } else if (selectedDataScope === "Sections" && numberOfSectionsSelected) {
      return (
        <>
          {numberOfSectionsSelected > 0 &&
            Array.isArray(sectionData) &&
            sectionData.length > 0 && (
              <span
                key="scope-sections"
                className={
                  "ml-4 inline-flex cursor-pointer items-baseline rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800 md:mt-2 lg:mt-0"
                }
              >
                <span className="text-left font-semibold">
                  {sectionData.length === numberOfSectionsSelected ? (
                    <>All Sections Selected</>
                  ) : (
                    <>
                      {numberOfSectionsSelected} Section
                      {numberOfSectionsSelected > 1 ? "s" : ""} Selected
                    </>
                  )}
                </span>
              </span>
            )}
        </>
      );
    }
    return <></>;
  };

  // Sets Start and End Date when a date range selection is made
  useEffect(() => {
    if (dateRange === "last-week") {
      setStartDate(lastWeek);
      setEndDate(yesterday);
    } else if (dateRange === "thirty-days") {
      setStartDate(thirtyDays);
      setEndDate(yesterday);
    } else if (dateRange === "year-to-date") {
      setStartDate(yearToDate);
      setEndDate(yesterday);
    }
  }, [dateRange]);

  useEffect(() => {
    if (
      (selectedDataScope === "Schools" &&
        Object.keys(selectedSchools).length === 0) ||
      (selectedDataScope === "Teachers" &&
        Object.keys(selectedTeachers).length === 0) ||
      (selectedDataScope === "Sections" &&
        Object.keys(selectedSections).length === 0)
    ) {
      setCanViewData(false);
    } else setCanViewData(true);
  }, [selectedDataScope, selectedSchools, selectedSections, selectedTeachers]);

  return (
    <nav className="my-2 grid space-x-4 pb-2 sm:grid-cols-1 lg:grid-cols-3">
      <button
        onClick={() =>
          navigate(
            `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/data-scope`
          )
        }
        className={clsx(
          params.data === "data-scope"
            ? "text-gray-60 border-indigo-500"
            : "border-gray-300 text-gray-500 hover:text-gray-700",
          "col-span-1 border-b-2 px-1 py-4 text-center text-sm font-medium text-gray-500 hover:border-gray-700 hover:bg-slate-100 hover:text-dm-blue"
        )}
      >
        Data Scope
        {selectedDataScope && <ScopeBlock />}
      </button>
      <button
        className={clsx(
          params.data === "view-data"
            ? "text-gray-60 border-indigo-500"
            : "border-gray-300 text-gray-500 hover:text-gray-700",
          "group relative col-span-1 border-b-2 px-1 py-4 text-center text-sm font-medium text-gray-500 hover:border-gray-700 hover:bg-slate-100 hover:text-dm-blue disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-white"
        )}
        disabled={!canViewData}
        onClick={() =>
          navigate(
            `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/view-data`
          )
        }
      >
        <ChartSquareBarIcon
          className="-ml-1 mr-2 inline h-8 w-8"
          aria-hidden="true"
        />
        View Data
        {!canViewData && (
          <div className="invisible absolute -top-6 ml-8 items-center text-left group-hover:visible group-hover:delay-300">
            <span className="whitespace-no-wrap relative z-10 bg-black p-2 text-xs text-white shadow-lg">
              Please select a Data Scope to view results.
            </span>
            <div className="align-center mx-auto my-0 h-3 w-3 rotate-45 bg-black"></div>
          </div>
        )}
      </button>
      <div className="col-span-1 py-4 text-center text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-dm-blue">
        <div className="inline-flex">
          <DatePicker
            id="report-range"
            selected={startDate}
            onChange={(dates) => {
              if (dates) {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
                setDateRange("custom");
              }
            }}
            onChangeRaw={(event) => {
              const userEnteredDate = event.target.value;
              if (typeof userEnteredDate !== "undefined") {
                const datesArray = userEnteredDate.split(" - ");
                if (datesArray.length === 2) {
                  const startDate = parse(
                    datesArray[0],
                    "MM/dd/yyyy",
                    new Date()
                  );
                  const endDate = parse(
                    datesArray[1],
                    "MM/dd/yyyy",
                    new Date()
                  );
                  if (isValid(startDate)) {
                    setStartDate(startDate);
                    setDateRange("custom");
                  }
                  if (isValid(endDate)) {
                    setEndDate(endDate);
                    setDateRange("custom");
                  }
                }
              }
            }}
            startDate={startDate}
            endDate={endDate}
            maxDate={yesterday}
            selectsRange
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            className="!w-48"
            popperPlacement="bottom"
            popperModifiers={[
              {
                name: "flip",
                options: {
                  flipVariations: false,
                  allowedAutoPlacements: ["bottom", "bottom-start"],
                  fallbackPlacements: ["bottom", "bottom-start"],
                },
              },
              {
                name: "preventOverflow",
                enabled: false,
              },
              {
                name: "hide",
                enabled: false,
              },
            ]}
          />
          <div className="ml-4 !w-36">
            <select
              onChange={(event) => {
                setDateRange(event.target.value);
              }}
              value={dateRange}
              className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-dm-light-blue focus:outline-none focus:ring-1 focus:ring-dm-light-blue sm:text-sm"
            >
              <option value={"year-to-date"}>School YTD</option>
              <option value={"last-week"}>Last 7 days</option>
              <option value={"thirty-days"}>Last 30 days</option>
              <option value={"custom"}>Custom range</option>
            </select>
          </div>
        </div>
      </div>
    </nav>
  );
};
