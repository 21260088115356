import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import ReactTooltip from "react-tooltip";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import { SkillDataType } from "../_constants";
import { skillDataDisplay, skillToSolve, scrollToView } from "../utils";
import ProgressBar from "./ProgressBar";
import CalculatorButton from "./CalculatorButton";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import UploadWork from "./UploadWork";
import IconProgress from "./IconProgress";

type Props = {
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

export default function SideBarSolveMenu({
  setSidebarOpen,
}: Props): JSX.Element {
  const solveSkill: any = skillToSolve();
  const order = solveSkill?.ta?.order;
  const isTestType = solveSkill?.ta?.is_test;
  const obscureResults = isTestType && !!solveSkill?.ta?.obscureResults;

  const { calcAvailable } = useContext(StudentSectionsContext);

  const [uploadAvailable, setUploadAvailable] = useState(false);
  const [tooltipText, setTooltipText] = useState<string | null>(null);

  useEffect(() => {
    if (solveSkill && solveSkill.ta?.notes === 2) {
      setUploadAvailable(true);
    }
  }, []);

  ReactTooltip.rebuild();

  return (
    <>
      <div className="space-y-2">
        <div className="border-b-2 border-b-gray-100 pb-8 pt-4">
          <h3 className="mb-4 pr-5 font-bold leading-5">
            {solveSkill?.ta?.name}
          </h3>
          {!obscureResults ? (
            <h4 className="pr-5 text-sm leading-5">
              Grade: <strong>{solveSkill?.sa?.grade || 0}%</strong>
            </h4>
          ) : (
            <h4 className="pr-5 text-sm leading-5">
              Complete:{" "}
              <strong>{solveSkill?.sa?.actuallyComplete || 0}%</strong>
            </h4>
          )}
          <div className="pr-10">
            <ProgressBar
              totalSegments={100}
              currentScore={solveSkill?.sa?.grade}
              currentRecord={solveSkill?.sa?.actuallyComplete}
              showTickMarks={false}
              solvePage={true}
              percentCompleted={solveSkill?.sa?.actuallyComplete}
              tooltipEnabled={false}
            />
          </div>
        </div>
        <div className="px-5">
          {order.map((skill: string) => {
            const skillData: SkillDataType = skillDataDisplay(
              skill,
              solveSkill
            );
            const weight = skillData.progress?.weight || 1;
            const score = (skillData.progress?.score || 0) * weight;
            return (
              <NavLink
                key={`skill:${skillData.uid}`}
                to={`${skillData.url}`}
                onClick={() => {
                  scrollToView();
                  setSidebarOpen(false);
                }}
                className={({ isActive }) =>
                  clsx(
                    isActive
                      ? "group bg-dm-brand-blue-100 font-bold text-dm-gray-800"
                      : "text-dm-gray-600",
                    "relative mb-1 flex items-center gap-x-2 rounded-md p-2 pr-4 text-sm leading-5 last:mb-0 hover:font-bold hover:text-dm-gray-800"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <IconProgress
                      skillData={skillData}
                      setTooltipText={setTooltipText}
                      isActive={isActive}
                      key={`icon-solve:${skillData.uid}`}
                    />
                    <h3 className="flex flex-grow items-center text-ellipsis line-clamp-2">
                      {skillData.name}
                      {isTestType ? (
                        <>
                          {" "}
                          ({!skillData.obscureResults ? `${score}/` : null}
                          {weight} pts)
                        </>
                      ) : null}
                    </h3>
                  </>
                )}
              </NavLink>
            );
          })}
        </div>
        {uploadAvailable && <UploadWork />}
        {calcAvailable && <CalculatorButton />}
      </div>
      <ReactTooltip
        id="piechart"
        effect="solid"
        delayShow={50}
        delayHide={50}
        multiline={true}
        html={true}
        place="right"
        getContent={() => tooltipText}
      />
    </>
  );
}
