/* TODO: Fix overflow of long titles, esp on mobile. Fix mobile layout of assignment titles, badges, and due date */

import { Disclosure, Transition } from "@headlessui/react";
import { useContext, useState, useEffect, Fragment } from "react";
import axios from "axios";
import { ClockIcon } from "@heroicons/react/outline";
import { NavLink, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import ReactTooltip from "react-tooltip";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import {
  SkillDataType,
  AssignmentDueDateType,
  AssignmentType,
} from "../../_constants";
import {
  skillDataDisplay,
  timeLimitText,
  scrollToView,
  timedAssg,
  processIndividualAssignment,
  updateFullAssignmentData,
} from "../../utils";
import {
  unlockAssignment,
  getTimedAssignment,
  useShowResults,
} from "../../utils/api";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import Countdown from "../Countdown";
import SeeSolutionsModal from "../SeeSolutionsModal";
import clsx from "clsx";
import { useUserContext } from "../../../shared/contexts/UserContext";
import AssignmentLineItem from "./AssignmentLineItem";
import IconProgress from "../IconProgress";
import UploadWork from "../UploadWork";

const NOTES_INTERVAL = 30000; //30 seconds

type Props = {
  section: any;
};

export default function Assignments({ section }: Props): JSX.Element {
  const {
    setLoadingData,
    openAssignment,
    setOpenAssignment,
    dmAssignmentData,
    setDmAssignmentData,
    activeSection,
    setTimedModalShowing,
    userValues,
    setTooltipText,
  } = useContext(StudentSectionsContext);
  const userContext = useUserContext();

  const token = userContext.getJWT();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  const assignmentType: AssignmentDueDateType | undefined =
    section?.sa?.status || "";
  const sectionType: AssignmentType | string = section?.ta?.type;
  const teacherId = section?.ta?._id;
  const isCorrectionType = sectionType == AssignmentType.correction;
  const isTestType = section?.ta?.is_test;
  const timedAssignment = timedAssg(section, true);

  const showSolutionsBtn =
    isTestType &&
    !!section?.ta?.obscureResults &&
    (!!section?.ta?.solutionsAvailable ||
      // show if a timed test runs out of time and was set to show results 'At test completion'
      // b/c solutionsAvailable will still be false until refetch or refresh of browser
      (timedAssignment?.isTimed &&
        section?.ta?.showSolutionsOnTimeOver === true &&
        timedAssignment?.isOver));

  // TEMPORARY FOR TESTING
  // useEffect(() => {
  //   console.log(
  //     `timedAssignment(${section?.ta?.name}) [assgn]:`,
  //     timedAssignment
  //   );
  // }, [timedAssignment]);

  const isStandardType = sectionType === AssignmentType.standard && !isTestType;
  const defaultOpen = teacherId === openAssignment;
  const [uploadNotesIntervalRef, setUploadNotesIntervalRef] = useState<any>();
  const [showUploadWorkOptions, setShowUploadWorkOptions] = useState(false);
  const [uploadNotesAssignment, setUploadNotesAssignment] = useState({
    ...section.sa,
  });
  const [showQRCode, setShowQRCode] = useState(false);
  const [uploadNotesSessionURL, setUploadNotesSessionURL] = useState<string>();
  const [existingNotesUrlSigned, setExistingNotesUrlSigned] =
    useState<string>();
  const [showConfirmDeleteUploadedWork, setShowConfirmDeleteUploadedWork] =
    useState(false);
  const [noteSessionTimeLeft, setNoteSessionTimeLeft] = useState<number>(
    15 * 60000
  );

  const [isSeeSolutionsModalShowing, setSeeSolutionsModalShowing] =
    useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (teacherId == openAssignment) {
      setOpenAssignment(null);

      const assignment = document.getElementById(`assg-ta-${openAssignment}`);
      if (assignment)
        assignment.scrollIntoView({
          behavior: "auto",
          block: "start",
        });
    }
  }, []);

  const [isResetCorrectionActive, setIsResetCorrectionActive] =
    useState<boolean>(false);

  const [correctionPoints, setCorrectionPoints] = useState<any>({});
  const defaultPayload = {
    points: {},
    extraPoints: 0,
    test: 0,
    lost_or_earned: section?.ta?.correction_points_lost_or_earned || "lost",
  };

  const [payload, setPayload] = useState(defaultPayload);
  const pointValues = (key: any, pointsValue: number) => {
    setPayload({
      ...payload,
      points: { ...payload.points, [key]: pointsValue || 0 },
    });
    setCorrectionPoints({ ...correctionPoints, [key]: pointsValue });
  };

  const correctionValue = (key: string, totalValue: number) => {
    setPayload({
      ...payload,
      [key]: totalValue || 0,
    });
  };

  // useEffect(() => {
  //   console.log("correctionPoints", correctionPoints);
  // }, [correctionPoints]);

  // useEffect(() => {
  //   console.log("payload", payload);
  // }, [payload]);

  const initializeCorrections = () => {
    if (sectionType == AssignmentType.correction) {
      const questions: any = section?.ta?.questions;
      const questionPoints: any = {};
      for (const key in questions) {
        questionPoints[key] = parseFloat(section?.sa?.testdata?.[key]) || 0;
      }

      return setPayload({
        ...defaultPayload,
        points: { ...questionPoints },
        extraPoints: section?.sa?.extra || 0,
        test: section?.sa?.testgrade || 0,
        lost_or_earned: section?.ta?.correction_points_lost_or_earned || "lost",
      });
    }
  };

  const resetCorrections = (e: any) => {
    e.preventDefault();
    const correctionActiveSwitch = !isResetCorrectionActive;
    setIsResetCorrectionActive(correctionActiveSwitch);

    if (!correctionActiveSwitch) {
      initializeCorrections();
    }
  };

  const showResultsMutation = useShowResults(section?.ta?._id);

  const handleShowSolutions = () => {
    const taId = section?.ta?._id;
    if (taId === undefined) return;
    setSeeSolutionsModalShowing(true);
  };

  const handleShowSolutionsData = (taId: any, skillName?: string) => {
    const body = skillName ? JSON.stringify({ sk: skillName }) : "{}";
    showResultsMutation.mutate(body, {
      onSuccess: (data) => {
        if (data?.data?.assignment) {
          updateFullAssignmentData(
            data.data.assignment,
            taId,
            activeSection,
            dmAssignmentData,
            setDmAssignmentData
          );
        }
      },
    });
  };

  /* ******************** */
  /* startTimedAssignment */
  /* ******************** */

  const startTimed = getTimedAssignment(
    teacherId,
    activeSection,
    setLoadingData,
    dmAssignmentData,
    setDmAssignmentData
  );
  const { refetch: timedRefetch } = startTimed;

  useEffect(() => initializeCorrections(), []);

  const skillOnClick = (e: React.MouseEvent<HTMLElement>) => {
    if (
      !timedAssignment?.isOver ||
      (timedAssignment?.isOver &&
        isTestType &&
        section?.ta?.obscureResults !== true)
    ) {
      scrollToView();
      return;
    }

    e.preventDefault();
    setTimedModalShowing(true);
  };

  // ---------------
  // PASSCODES

  const [passcode, setPasscode] = useState<string>("");
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const pcKeyName = "pc_" + section?.ta?._id + "_" + user?._id;

  /* **************** */
  /* unlockAssignment */
  /* **************** */

  const unlockWithPasscode = unlockAssignment(
    teacherId,
    passcode,
    setLoadingData
  );

  // unlockAssignment useEffect:
  // Timed tests with a passcode need to run timedRefetch() after unlockWithPasscode()
  useEffect(() => {
    if (
      unlockWithPasscode.isSuccess &&
      timedAssignment?.isTimed &&
      timedAssignment.timeLimit &&
      !timedAssignment.endTime
    ) {
      timedRefetch();
    } else {
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: false,
      }));
    }
  }, [unlockWithPasscode.isSuccess]);

  const mapAssignmentSkills = (): JSX.Element => {
    if (!sectionType) return <></>;

    if (
      isTestType &&
      section?.ta?.passcode_required === "yes" &&
      localStorage.getItem(pcKeyName) === null
    ) {
      return (
        <div className="px-6 py-3.5">
          <p className="pb-3">
            {timedAssignment?.isTimed
              ? `Once you start this test, you will have ${
                  timedAssignment.isUnlimited
                    ? "unlimited time"
                    : timeLimitText(timedAssignment.timeLimit)
                } to complete it.`
              : "You must enter the correct passcode to begin the test on this device."}
          </p>
          <div className="my-2 gap-x-1 sm:flex sm:items-center">
            <label htmlFor="passcode" className="font-bold">
              Passcode:
            </label>
            <input
              type="text"
              name="passcode"
              id="passcode"
              className="block w-[90px] rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              maxLength={5}
              value={passcode}
              onChange={(e) => setPasscode(e.target.value)}
            />
            <button
              type="button"
              className="ml-2 inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={() => unlockWithPasscode.mutate({})}
            >
              Begin Test NOW
            </button>
          </div>
        </div>
      );
    }
    if (
      timedAssignment?.isTimed &&
      timedAssignment.timeLimit &&
      !timedAssignment.endTime
    ) {
      return (
        <div className="px-6 py-3.5">
          <p className="pb-3">
            {timedAssignment.additionalTime
              ? `You have been given additional time to finish this ${
                  isTestType ? "test" : "assignment"
                }. Once you resume, you will have ${
                  timedAssignment.isUnlimited
                    ? "unlimited time"
                    : timeLimitText(timedAssignment.timeLimit)
                } to complete it.`
              : `Once you start this ${
                  isTestType ? "test" : "assignment"
                }, you will have ${
                  timedAssignment.isUnlimited
                    ? "unlimited time"
                    : timeLimitText(timedAssignment.timeLimit)
                } to complete it.`}
          </p>
          <button
            className={clsx(
              "inline-block cursor-pointer rounded-md border-2 border-gray-300 bg-slate-50/50 px-2 py-1.5 text-xs font-normal text-gray-600 hover:border-gray-500 hover:bg-slate-50 hover:text-gray-900"
            )}
            role="button"
            onClick={() => {
              timedRefetch();
            }}
          >
            {timedAssignment.additionalTime ? "Resume " : "Begin "}
            {isTestType ? "Test " : "Assignment "}NOW
          </button>
        </div>
      );
    }
    if (
      isStandardType ||
      (isCorrectionType &&
        section?.sa?.correctionInitialized &&
        !isResetCorrectionActive) ||
      isTestType
    ) {
      const order = section?.ta?.order;

      if (!order.length)
        return (
          <div className="px-6 py-3.5">
            There are no questions for this assignment
          </div>
        );

      return order.map((skill: string) => {
        const skillData: SkillDataType = skillDataDisplay(
          skill,
          section,
          activeSection,
          userValues
        );
        const weight = section?.ta?.skills[skill]?.weight;
        const score = (skillData.progress?.score || 0) * weight;

        return (
          <NavLink
            key={`skill:${skillData.uid}`}
            to={`${skillData.url}`}
            className="group mx-2 my-1.5 flex items-center gap-2 rounded px-2 py-3 text-dm-gray-600 hover:bg-dm-brand-blue-100 hover:font-bold hover:text-dm-gray-800"
            onClick={skillOnClick}
          >
            <IconProgress
              skillData={skillData}
              setTooltipText={setTooltipText}
              tooltipId="piechart-app"
              extraClasses="group-hover:brightness-200"
              key={`icon:${skillData.uid}`}
            />
            <h3
              className={clsx(
                "flex flex-grow items-center gap-x-1",
                skillData.isCompleted && skillData.isMaxProblemType
                  ? "text-dm-gray-500"
                  : "text-dm-gray-800"
              )}
            >
              {skillData.name}
            </h3>
            {!isTestType ? (
              <>
                <span className="w-[40px] text-center">
                  {skillData.completion}
                </span>
              </>
            ) : (
              <span className="w-auto text-right">
                {!skillData.obscureResults ? `${score}/` : null}
                {weight} points
              </span>
            )}
          </NavLink>
        );
      });
    } else if (sectionType == AssignmentType.correction) {
      const questions: any = section?.ta?.questions;

      const isPointsTypeEarned =
        section?.ta?.correction_points_lost_or_earned == "earned";

      const pointsTypeText = isPointsTypeEarned
        ? "(points earned)"
        : "(points lost)";

      const questionList: any = [];

      questionList.push(
        <Fragment key={`key-${teacherId}`}>
          <label
            id={`correctionLabel-${teacherId}`}
            htmlFor={`correction-${teacherId}`}
            className="text-right text-sm font-bold text-gray-700"
          >
            Test Grade
          </label>
          <input
            type="text"
            defaultValue={section?.sa?.testgrade || ""}
            name={`correction-${teacherId}`}
            id={`correction-${teacherId}`}
            aria-labelledby={`correctionLabel-${teacherId} correctionPostLabel-${teacherId}`}
            onChange={(e) => correctionValue("test", parseInt(e.target.value))}
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          <div
            id={`correctionPostLabel-${teacherId}`}
            className="col-span-2 flex flex-col justify-between text-sm font-bold text-gray-700 sm:col-auto sm:flex-row sm:items-center"
          >
            /{section?.ta?.totalPoints}
          </div>
          <button
            type="button"
            onClick={() => handleInitializeCorrection.mutate()}
            className="order-last col-span-4 rounded-md border border-gray-300 px-3 py-1.5 text-xs font-normal text-gray-600 hover:border-gray-500 hover:bg-slate-50 hover:text-gray-900 sm:order-none sm:col-auto"
          >
            Initialize Test Corrections
          </button>
          <span className="col-span-4 h-px bg-black"></span>
        </Fragment>
      );

      const questionPoints: any = {};

      for (const key in questions) {
        const question = questions[key];

        questionPoints[key] = question.points || 0;

        questionList.push(
          <Fragment key={`${encodeURI(question.name)}-key`}>
            <label
              id={`${question.name}-inputLabel`}
              htmlFor={`${question.name}-input`}
              className="text-right text-sm text-gray-700"
            >
              Question {question?.name}
            </label>
            <input
              type="text"
              defaultValue={
                section?.sa?.testdata ? section?.sa?.testdata[key] || "" : ""
              }
              name={`${question.name}-input`}
              id={`${question.name}-input`}
              aria-labelledby={`${question.name}-inputLabel ${question.name}-inputPostLabel`}
              onChange={(e) => pointValues(key, parseInt(e.target.value))}
              className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            <div
              id={`${question.name}-inputPostLabel`}
              className="col-span-2 items-center text-sm text-gray-500"
            >
              /{question.points} {pointsTypeText}
            </div>
          </Fragment>
        );
      }
      return (
        <div className="grid grid-cols-[auto_60px_minmax(0,_1fr)_auto] items-center gap-3 px-6 py-3.5">
          {questionList}
          {section?.ta?.extraPoints ? (
            <Fragment>
              <label
                id={`extraLabel-${teacherId}`}
                htmlFor={`extra-${teacherId}`}
                className="text-right text-sm text-gray-700"
              >
                Extra Credit
              </label>
              <input
                type="text"
                defaultValue={section?.sa?.extra || ""}
                name={`extra-${teacherId}`}
                id={`extra-${teacherId}`}
                aria-labelledby={`extraLabel-${teacherId} extraPostLabel-${teacherId}`}
                onChange={(e) =>
                  correctionValue("extraPoints", parseInt(e.target.value))
                }
                className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
              <div
                id={`extraPostLabel-${teacherId}`}
                className="col-span-2 items-center text-sm text-gray-500"
              >
                /{section?.ta?.extraPoints} (points earned)
              </div>
            </Fragment>
          ) : null}
        </div>
      );
    }
    return <></>;
  };

  /* ************************ */
  /* initializeTestCorrection */
  /* ************************ */

  const handleInitializeCorrection = useMutation(
    () => {
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: true,
        error: false,
      }));
      return axios.post(
        deltamathAPI() + `/student/initializeTestCorrection/${teacherId}`,
        JSON.stringify(payload),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: async (data, variables, context) => {
        console.log("onSuccess data", data);
        console.log("onSuccess data.data", data.data);
        console.log("onSuccess variables", variables);
        console.log("onSuccess context", context);
        toastContext.addToast({
          status: "Success",
          message: "Correction successfully initialized",
        });
        // setIsResetCorrectionActive(false);
        queryClient.invalidateQueries("/student/data/assignments");
        queryClient.invalidateQueries("/student/data/sections");
        // await assignmentsRefresh();
        dmAssignmentData[activeSection].filter(
          (assignment: any, index: number) => {
            if (assignment.ta._id === teacherId) {
              const dataObj = { ...dmAssignmentData };
              dataObj[activeSection][index] = processIndividualAssignment(
                data.data.assignment
              );
              setDmAssignmentData({ ...dataObj });
              return;
            }
          }
        );
        initializeCorrections();
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: false,
        }));
      },
      onError: (error: any, variables, context) => {
        console.log("onError err", error?.response?.data?.message);
        console.log("onError variables", variables);
        console.log("onError context", context);
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Error",
          message: error?.response?.data?.message,
        }));
      },
      onSettled: (data, variables, context) => {
        console.log("onSettled data", data);
        console.log("onSettled data?.data", data?.data);
        console.log("onSettled variables", variables);
        console.log("onSettled context", context);
        if (data?.statusText === "OK") {
          setIsResetCorrectionActive(false);
        }
      },
    }
  );

  // useEffect(() => {
  //   console.log("isResetCorrectionActive", isResetCorrectionActive);
  // }, [isResetCorrectionActive]);

  // const className = clsx(
  //   "flex w-full content-start items-center justify-between gap-x-1 p-4 text-left text-sm text-gray-900 shadow-md focus:outline-none focus-visible:ring focus-visible:ring-opacity-75",
  //   "sm:flex-row sm:px-6 sm:py-5 md:gap-x-3",
  //   // "truncate",
  //   !isCorrectionType && !isTestType && !open
  //     ? "bg-white hover:bg-gray-200"
  //     : null,
  //   !isCorrectionType && !isTestType && open
  //     ? "border-b-2 bg-white"
  //     : null,
  //   isCorrectionType && open ? "bg-orange-200" : null,
  //   isCorrectionType && !open
  //     ? "bg-orange-100 hover:bg-orange-200"
  //     : null,
  //   isTestType && open ? "bg-blue-300" : null,
  //   isTestType && !open ? "bg-blue-200 hover:bg-blue-300" : null
  // )

  return (
    <>
      <Disclosure
        as="div"
        defaultOpen={defaultOpen}
        className="mb-4"
        data-sa={section?.sa?._id}
        id={`assg-ta-${section?.ta?._id}`}
      >
        {({ open }) => (
          /* Use the `open` state to conditionally change the direction of an icon. */
          <>
            {" "}
            {timedAssignment.isTimed && !timedAssignment.endTime ? (
              <div>
                <AssignmentLineItem
                  section={section}
                  open={open}
                  type={
                    isCorrectionType
                      ? "correction"
                      : isTestType
                      ? "test"
                      : "standard"
                  }
                  timedAssignment={timedAssignment}
                  resetCorrections={resetCorrections}
                  isResetCorrectionActive={isResetCorrectionActive}
                  assignmentType={assignmentType}
                  uploadNotesAssignment={uploadNotesAssignment}
                />
              </div>
            ) : (
              <Disclosure.Button className="w-full">
                <AssignmentLineItem
                  section={section}
                  open={open}
                  type={
                    isCorrectionType
                      ? "correction"
                      : isTestType
                      ? "test"
                      : "standard"
                  }
                  timedAssignment={timedAssignment}
                  resetCorrections={resetCorrections}
                  isResetCorrectionActive={isResetCorrectionActive}
                  assignmentType={assignmentType}
                  uploadNotesAssignment={uploadNotesAssignment}
                />
              </Disclosure.Button>
            )}
            <Transition
              enter="transition-opacity duration-75"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              beforeEnter={initializeCorrections}
            >
              <Disclosure.Panel
                unmount={true}
                className="overflow-auto rounded-b-lg border-2 border-t-0 border-dm-charcoal-100 bg-white text-sm text-gray-600"
              >
                {mapAssignmentSkills()}
                {section?.ta?.notes === 2 && (
                  <div>
                    <div className="ml-8 font-sans font-bold text-black">
                      Your Work
                    </div>
                    <div className="ml-4">
                      <UploadWork section={section} />
                    </div>
                  </div>
                )}
                {showSolutionsBtn ? (
                  <>
                    <div className="flex justify-end border-t px-6 py-3.5">
                      <button
                        className={clsx(
                          "inline-flex min-w-0 flex-nowrap items-center justify-between rounded-md border border-gray-300 px-1 py-1 text-xs text-gray-600 hover:bg-gray-200"
                        )}
                        onClick={() => handleShowSolutions()}
                      >
                        {section?.ta?.show_solutions &&
                        section.ta.show_solutions === "never"
                          ? "show results"
                          : "show solutions"}
                      </button>
                    </div>
                    <SeeSolutionsModal
                      isShowing={isSeeSolutionsModalShowing}
                      setIsShowing={setSeeSolutionsModalShowing}
                      handleShowSolutionsData={handleShowSolutionsData}
                      taId={section?.ta?._id}
                    />
                  </>
                ) : null}
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      {isCorrectionType && section?.sa?.correctionInitialized ? (
        <ReactTooltip
          id={`assignments-tooltip-${teacherId}`}
          className="max-w-[16rem]"
        />
      ) : null}
    </>
  );
}
