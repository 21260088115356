import clsx from "clsx";
import { useState, useEffect } from "react";
import {
  SubStandard,
  StandardRule,
  SkillCodes,
} from "../../../manager/components/standards/common/types";

export const SelectStandard = (props: {
  standard: SubStandard;
  depth: number;
  path: string;
  activatedPath: string;
  rules: StandardRule[];
  stateCode: string;
  onClickFn: (e: React.MouseEvent<HTMLDivElement>, standard: any) => void;
  selectFn: any;
  closeFn: () => void;
}) => {
  const {
    standard,
    depth,
    stateCode,
    activatedPath,
    onClickFn,
    selectFn,
    closeFn,
  } = props;
  const [isActive, setActive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  let { path, rules } = props;
  path = path.concat(standard.description);
  const ruleAtPath: StandardRule = rules.find((r) => r.path === path) || {
    path: path,
  };
  rules = rules.filter((r) => r.path.includes(path) && r.path !== path);

  const printLabel = (label: string | undefined, depth: number) => {
    if (label && [".", ":"].includes(label[label.length - 1])) {
      label = label.slice(0, -1);
    }
    if (depth === 2) return label ? " (" + label + ")" : "";
    else if ([3, 4, 5, 6].includes(depth)) {
      if (label && label.trim() === label) return label + ":";
      if (label && label.trim() !== label) return "\u2022"; // Bullet Point
      return "";
    }
  };

  const printDescription = (description: string | undefined) => {
    if (!description) return "";
    return description.replace(/\(([^)]*)\)$[^(]*$/, "");
  };

  // Because tailwind doesnt seem to like string manipulation (aka: `pl-${n}` does not work).
  const getPadding = (depth: number) => {
    if (depth === 2) return "pl-4";
    if (depth === 3) return "pl-8";
    if (depth === 4) return "pl-16";
    else if (depth === 5) return "pl-24";
    else if (depth === 6) return "pl-32";
    else return "";
  };

  // Controls which standard has been selected
  useEffect(() => {
    if (activatedPath === path) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [activatedPath]);

  // Auto-expand SubStandards down the "path" to selected module.
  useEffect(() => {
    if (activatedPath.indexOf(path) !== -1) {
      setExpanded(true);
    }
  }, []);

  const ExpandButton = ({
    isExpanded,
    setExpanded,
  }: {
    isExpanded: boolean;
    setExpanded: any;
  }) => {
    const [rotate, setRotate] = useState(isExpanded);
    function toggleExpanded() {
      setExpanded(!expanded);
      setRotate((previous) => !previous);
    }
    return (
      <button
        className="mr-2 box-border inline-flex cursor-pointer appearance-none py-4 focus:outline-none"
        onClick={toggleExpanded}
      >
        {rotate ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        )}
      </button>
    );
  };

  // const SelectStandardButton = ({
  //   selectFn,
  //   skillcodes,
  // }: {
  //   selectFn: any;
  //   skillcodes: any;
  // }) => {
  //   return (
  //     <button
  //       type="button"
  //       onClick={selectFn(skillcodes)}
  //       className="m-2 inline-flex items-center rounded-full bg-dm-blue px-2 py-1 text-white shadow-sm hover:bg-dm-alt-blue focus:outline-none focus:ring-2 focus:ring-offset-2"
  //     >
  //       <svg
  //         xmlns="http://www.w3.org/2000/svg"
  //         fill="none"
  //         viewBox="0 0 24 24"
  //         strokeWidth={1.5}
  //         stroke="currentColor"
  //         className="mr-1 h-4 w-4"
  //       >
  //         <path
  //           strokeLinecap="round"
  //           strokeLinejoin="round"
  //           d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
  //         />
  //       </svg>
  //       Select
  //     </button>
  //   );
  // };

  return (
    <>
      <div className={ruleAtPath.hide === true ? "line-through" : ""}>
        <div className="bg-white">
          <div className="flow-root px-4">
            {depth === 1 && (
              <h4
                onClick={(e) => {
                  setExpanded(!expanded);
                  if (standard.module_data) {
                    onClickFn(e, standard);
                  }
                }}
                id={path}
                className={`dm-dark-blue mb-2 mt-0 rounded-md text-xl font-normal leading-relaxed hover:cursor-pointer hover:bg-gray-200 ${
                  isActive ? "border-1 border bg-gray-300" : ""
                }`}
              >
                {standard.module_data ? (
                  <input
                    type={"radio"}
                    name="select-standard"
                    value={path}
                    className="absolute left-0 m-2 outline-1 disabled:bg-gray-200"
                    disabled={standard.module_data.length === 0}
                  ></input>
                ) : (
                  <ExpandButton
                    isExpanded={expanded}
                    setExpanded={setExpanded}
                  />
                )}
                {printDescription(
                  ruleAtPath.newDescription
                    ? ruleAtPath.newDescription
                    : standard.description
                )}
                {Array.isArray(standard.module_data) &&
                  standard?.module_data.length === 0 && (
                    <div className="invisible absolute -top-10 ml-8 items-center text-left group-hover:visible">
                      <span className="whitespace-no-wrap relative z-10 block bg-black p-2 text-xs text-white shadow-lg">
                        No skills on DeltaMath yet align to this standard.{" "}
                      </span>
                      <div className="align-center -my-2 mx-auto h-3 w-3 rotate-45 bg-black"></div>
                    </div>
                  )}
              </h4>
            )}
            {depth === 2 && (
              <div className={getPadding(depth)}>
                <h5
                  onClick={(e) => {
                    setExpanded(!expanded);
                    if (standard.module_data?.length) {
                      onClickFn(e, standard);
                      selectFn(standard.module_data);
                    }
                  }}
                  id={path}
                  className={clsx(
                    "text-1xl dm-dark-blue group relative mb-2 mt-0 rounded-md font-semibold leading-relaxed hover:cursor-pointer hover:bg-gray-200",
                    isActive &&
                      "border-1 rounded-md border border-dm-dark-blue bg-gray-300",
                    Array.isArray(standard?.module_data) &&
                      standard?.module_data &&
                      "pl-8",
                    Array.isArray(standard?.module_data) &&
                      standard?.module_data.length === 0 &&
                      "text-gray-400"
                  )}
                >
                  {standard.module_data ? (
                    <input
                      type={"radio"}
                      name="select-standard"
                      value={path}
                      checked={isActive}
                      disabled={standard.module_data.length === 0}
                      className="absolute left-0 m-2 outline-1 hover:cursor-pointer disabled:bg-gray-200"
                      onChange={() => {
                        if (Array.isArray(standard.module_data)) {
                          return selectFn(standard.module_data);
                        }
                      }}
                    ></input>
                  ) : (
                    <ExpandButton
                      isExpanded={expanded}
                      setExpanded={setExpanded}
                    />
                  )}
                  {printDescription(
                    ruleAtPath.newDescription
                      ? ruleAtPath.newDescription
                      : standard.description
                  )}{" "}
                  {printLabel(
                    ruleAtPath.label === undefined
                      ? standard.label
                      : ruleAtPath.label,
                    depth
                  )}
                  {Array.isArray(standard.module_data) &&
                    standard?.module_data.length === 0 && (
                      <div className="invisible absolute -top-10 ml-8 items-center text-left group-hover:visible">
                        <span className="whitespace-no-wrap relative z-10 block bg-black p-2 text-xs text-white shadow-lg">
                          No skills on DeltaMath yet align to this standard.{" "}
                        </span>
                        <div className="align-center -my-2 mx-auto h-3 w-3 rotate-45 bg-black"></div>
                      </div>
                    )}
                </h5>
                {/* {
                  <ModuleList
                    standard={standard}
                    ruleAtPath={ruleAtPath}
                    skillCodes={skillCodes}
                  />
                } */}
              </div>
            )}
            {depth > 2 && (
              <div className={getPadding(depth)}>
                <h5
                  onClick={(e) => {
                    setExpanded(!expanded);
                    if (standard.module_data?.length) {
                      onClickFn(e, standard);
                      selectFn(standard.module_data);
                    }
                  }}
                  id={path}
                  className={clsx(
                    "group relative rounded-md hover:cursor-pointer hover:bg-gray-200",
                    isActive &&
                      "border-1 rounded-md border border-dm-dark-blue bg-gray-300",
                    Array.isArray(standard?.module_data) &&
                      standard?.module_data &&
                      "pl-8",
                    Array.isArray(standard?.module_data) &&
                      standard?.module_data.length === 0 &&
                      "text-gray-400"
                  )}
                >
                  {Array.isArray(standard?.module_data) ? (
                    <input
                      type="radio"
                      name="select-standard"
                      value={path}
                      className="absolute left-0 m-1 outline-1 disabled:bg-gray-200"
                      checked={isActive}
                      disabled={standard.module_data.length === 0}
                      onChange={() => {
                        if (Array.isArray(standard.module_data)) {
                          return selectFn(standard.module_data);
                        }
                      }}
                    ></input>
                  ) : (
                    <ExpandButton
                      isExpanded={expanded}
                      setExpanded={setExpanded}
                    />
                  )}
                  <span className="text-1xl dm-dark-blue mb-0 mt-0 inline-block font-semibold leading-relaxed">
                    {printLabel(
                      ruleAtPath.label === undefined
                        ? standard.label
                        : ruleAtPath.label,
                      depth
                    )}
                  </span>
                  <span className="leading-relaxed">
                    {" "}
                    {printDescription(
                      ruleAtPath.newDescription
                        ? ruleAtPath.newDescription
                        : standard.description
                    )}
                  </span>
                  {Array.isArray(standard.module_data) &&
                    standard?.module_data.length === 0 && (
                      <div className="invisible absolute -top-10 ml-8 items-center text-left group-hover:visible">
                        <span className="whitespace-no-wrap relative z-10 block bg-black p-2 text-xs text-white shadow-lg">
                          No skills on DeltaMath yet align to this standard.{" "}
                        </span>
                        <div className="align-center -my-2 mx-auto h-3 w-3 rotate-45 bg-black"></div>
                      </div>
                    )}
                </h5>
                {/* {expanded && (
                  <ModuleList
                    standard={standard}
                    ruleAtPath={ruleAtPath}
                    skillCodes={skillCodes}
                  />
                )} */}
              </div>
            )}
          </div>
        </div>
      </div>

      {expanded &&
        standard.data &&
        ruleAtPath.hide !== true &&
        Object.entries(standard.data).map(([k, v]) => (
          <SelectStandard
            key={k}
            standard={v}
            depth={depth + 1}
            path={path.concat(".data.")}
            activatedPath={activatedPath}
            rules={rules}
            stateCode={stateCode}
            onClickFn={onClickFn}
            selectFn={selectFn}
            closeFn={closeFn}
          />
        ))}
    </>
  );
};

export default SelectStandard;
