import {
  useContext,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  Fragment,
} from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { deltamathAPI } from "../../manager/utils";
import { unlockAssignment, getTimedAssignment } from "../utils/api";
import { useMutation, useQueryClient } from "react-query";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import clsx from "clsx";
import { SectionDataStatus, AssignmentDueDateType } from "../_constants";
import { forEach } from "lodash";
import {
  timeLimitText,
  timedAssg,
  processIndividualAssignment,
  updateFullAssignmentData,
  findNearestUpcoming,
} from "../utils";
import { useUserContext } from "../../shared/contexts/UserContext";

type Props = {
  assignments: any;
};

enum AssignmentType {
  standard = "standard",
  correction = "correction",
}

export default function Link({ assignments }: Props): JSX.Element {
  if (assignments === undefined) return <></>;

  const userContext = useUserContext();
  const navigate = useNavigate();
  const { teacherId } = useParams();
  const { dmAssignmentData, setDmAssignmentData, setLoadingData } = useContext(
    StudentSectionsContext
  );

  const token = userContext.getJWT();
  const assignment = useRef<any>(undefined);
  const section = useRef<any>(undefined);

  useEffect(() => {
    forEach(assignments, (item, key: any) =>
      item.find((el: any) => {
        if (el?.ta?._id == teacherId) {
          assignment.current = el;
          section.current = key;
        }
      })
    );
  }, []);

  const assignmentType: AssignmentDueDateType | undefined =
    assignment?.current?.sa?.status || "";
  const sectionType: AssignmentType | string = assignment?.current?.ta?.type;

  const isCorrectionType = sectionType == AssignmentType.correction;
  const isTestType = assignment?.current?.ta?.is_test;
  const timedAssignment = timedAssg(assignment?.current, true);

  const [passcode, setPasscode] = useState<string>("");

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const pcKeyName = "pc_" + assignment?.current?.ta?._id + "_" + user?._id;
  const isPasscode =
    !!isTestType &&
    assignment?.current?.ta?.passcode_required === "yes" &&
    localStorage.getItem(pcKeyName) === null;

  const isTimedPending =
    timedAssignment?.isTimed &&
    timedAssignment.timeLimit &&
    !timedAssignment.endTime;

  const dateObject =
    assignmentType == SectionDataStatus.late_credit_available
      ? assignment?.current?.sa?.lateDate?.date
      : assignment?.current?.sa?.dueDate;

  const dueDate =
    dateObject?.date && dateObject?.tz
      ? new Date(dateObject?.date).toLocaleString("en-US", {
          timeZone: dateObject?.tz,
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
      : "";

  const firstSkill: any = assignment?.current?.ta?.order?.[0]
    ? assignment?.current?.ta?.skills[assignment.current.ta.order[0]]
    : undefined;

  const firstSkillUrl = `${process.env.REACT_APP_STUDENT_LINK}/${section?.current}/${teacherId}/${firstSkill?.uid}`;

  // TODO: temporary until better solution-
  // using `reloadDocument` in the <NavLink> b/c
  // when hitting the back button after navigating away from /link,
  // it wasn't loading properly
  const assignmentsUrl = `${process.env.REACT_APP_STUDENT_LINK}/${section?.current}/upcoming`;

  const nearestUpcomingUrl = `${process.env.REACT_APP_STUDENT_LINK}/${
    findNearestUpcoming(assignments) ?? Object.keys(assignments)[0]
  }/upcoming`;

  /* **************** */
  /* unlockAssignment */
  /* **************** */

  const unlockWithPasscode = unlockAssignment(
    teacherId,
    passcode,
    setLoadingData
  );

  const startTimed = getTimedAssignment(
    teacherId,
    section.current,
    setLoadingData,
    dmAssignmentData,
    setDmAssignmentData
  );
  const { refetch: timedRefetch } = startTimed;
  // unlockAssignment useEffect:
  // Timed tests with a passcode need to run timedRefetch() after unlockAssignment()
  useEffect(() => {
    if (
      unlockWithPasscode.isSuccess &&
      timedAssignment?.isTimed &&
      timedAssignment.timeLimit &&
      !timedAssignment.endTime
    ) {
      timedRefetch();
    } else if (unlockWithPasscode.isSuccess && firstSkill !== undefined) {
      navigate(firstSkillUrl, { replace: true });
    }
  }, [unlockWithPasscode.isSuccess]);

  /* Corrections */
  const [isResetCorrectionActive, setIsResetCorrectionActive] =
    useState<boolean>(false);

  const isCorrectionPending =
    isCorrectionType &&
    (assignment?.current?.sa?.correctionInitialized === false ||
      (assignment?.current?.sa?.correctionInitialized === true &&
        isResetCorrectionActive === true));

  const isCorrectionEmpty =
    isCorrectionType &&
    assignment?.current?.sa?.correctionInitialized === true &&
    assignment?.current?.ta?.order?.length === 0;

  console.log(
    "IS CORRECTION EMPTY",
    assignment?.current?.sa,
    isCorrectionEmpty,
    isCorrectionPending,
    isCorrectionType
  );

  const noPassThru: boolean =
    !!isPasscode ||
    !!isTimedPending ||
    !!isCorrectionPending ||
    !!isCorrectionEmpty;

  const [correctionPoints, setCorrectionPoints] = useState<any>({});
  const defaultPayload = {
    points: {},
    extraPoints: 0,
    test: 0,
    lost_or_earned:
      assignment?.current?.ta?.correction_points_lost_or_earned || "lost",
  };

  const [payload, setPayload] = useState(defaultPayload);

  // When the assignment data is loaded and ta._id exists, set the 'lost_or_earned' value
  useEffect(() => {
    setPayload({
      ...payload,
      lost_or_earned:
        assignment?.current?.ta?.correction_points_lost_or_earned || "lost",
    });
  }, [assignment?.current?.ta?._id]);

  const pointValues = (key: any, pointsValue: number) => {
    setPayload({
      ...payload,
      points: { ...payload.points, [key]: pointsValue || 0 },
      lost_or_earned:
        assignment?.current?.ta?.correction_points_lost_or_earned || "lost",
    });
    setCorrectionPoints({ ...correctionPoints, [key]: pointsValue });
  };

  const correctionValue = (key: string, totalValue: number) => {
    setPayload({
      ...payload,
      [key]: totalValue || 0,
    });
  };

  const initializeCorrections = () => {
    if (sectionType == AssignmentType.correction) {
      const questions: any = assignment?.current?.ta?.questions;
      const questionPoints: any = {};
      for (const key in questions) {
        questionPoints[key] =
          parseFloat(assignment?.current?.sa?.testdata?.[key]) || 0;
      }

      return setPayload({
        ...defaultPayload,
        points: { ...questionPoints },
        extraPoints: assignment?.current?.sa?.extra || 0,
        test: assignment?.current?.sa?.testgrade || 0,
        lost_or_earned:
          assignment?.current?.ta?.correction_points_lost_or_earned || "lost",
      });
    }
  };

  const resetCorrections = (e: any) => {
    e.preventDefault();
    const correctionActiveSwitch = !isResetCorrectionActive;
    setIsResetCorrectionActive(correctionActiveSwitch);
    initializeCorrections();
  };

  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  /* ************************ */
  /* initializeTestCorrection */
  /* ************************ */

  const handleInitializeCorrection = useMutation(
    () => {
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: true,
        error: false,
      }));
      return axios.post(
        deltamathAPI() + `/student/initializeTestCorrection/${teacherId}`,
        JSON.stringify(payload),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: async (data: any) => {
        toastContext.addToast({
          status: "Success",
          message: "Correction successfully initialized",
        });
        queryClient.invalidateQueries("/student/data/assignments");
        queryClient.invalidateQueries("/student/data/sections");

        if (data?.data?.assignment) {
          updateFullAssignmentData(
            data.data.assignment,
            data.data.assignment.ta._id,
            section?.current,
            dmAssignmentData,
            setDmAssignmentData
          );

          assignment.current = processIndividualAssignment(
            data.data.assignment
          );
          initializeCorrections();
          if (firstSkill !== undefined)
            navigate(firstSkillUrl, { replace: true });
        }
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: false,
        }));
      },
      onError: (error: any) => {
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Error",
          message: error?.response?.data?.message,
        }));
      },
      onSettled: (data: any) => {
        if (data?.statusText === "OK") {
          setIsResetCorrectionActive(false);
        }
      },
    }
  );

  const displayInfo = (): JSX.Element => {
    if (isPasscode)
      return (
        <>
          <p>
            {timedAssignment?.isTimed ? (
              <>
                Once you start this test, you will have{" "}
                <strong>{timeLimitText(timedAssignment.timeLimit)}</strong> to
                complete it.
              </>
            ) : (
              "You must enter the correct passcode to begin the test on this device."
            )}
          </p>
          <div className="mt-6 flex flex-wrap items-end gap-x-6 gap-y-3 sm:flex-nowrap">
            <div className="flex grow basis-full flex-col sm:basis-auto">
              <label
                htmlFor="passcode"
                className="text-sm leading-6 text-dm-charcoal-500"
              >
                Passcode
              </label>
              <input
                type="text"
                name="passcode"
                id="passcode"
                className="block grow rounded border border-[#979797] px-2 py-2.5 text-sm text-gray-900 focus:ring-dm-brand-blue-500"
                maxLength={5}
                value={passcode}
                onChange={(e) => setPasscode(e.target.value)}
              />
            </div>
            <button
              type="button"
              className={clsx(
                "basis-full rounded border px-8 py-2.5 text-sm text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto",
                !passcode
                  ? "border-transparent bg-dm-brand-blue-500/50"
                  : "border-dm-brand-blue-500 bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
              )}
              disabled={!passcode}
              onClick={() => unlockWithPasscode.mutate({})}
            >
              Start Test Now
            </button>
          </div>
        </>
      );
    if (isTimedPending)
      return (
        <>
          <p>
            {timedAssignment.additionalTime
              ? `You have been given additional time to finish this ${
                  isTestType ? "test" : "assignment"
                }. Once you resume, you will have ${timeLimitText(
                  timedAssignment.timeLimit
                )} to complete it.`
              : `Once you start this ${
                  isTestType ? "test" : "assignment"
                }, you will have ${timeLimitText(
                  timedAssignment.timeLimit
                )} to complete it.`}
          </p>
          <div className="mt-8 flex flex-wrap items-center justify-between gap-x-6 gap-y-3 border-t border-t-[#CCCED0] pt-8 sm:flex-nowrap">
            <NavLink
              to={assignmentsUrl}
              reloadDocument
              className="basis-full rounded border border-dm-charcoal-500 bg-white px-5 py-2.5 text-center text-sm text-dm-charcoal-500 hover:bg-dm-charcoal-500 hover:text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto sm:px-8"
            >
              Back To Assignments
            </NavLink>
            <button
              className={clsx(
                "basis-full rounded border px-5 py-2.5 text-sm text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto sm:px-8",
                "border-dm-brand-blue-500 bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
              )}
              role="button"
              onClick={() => {
                timedRefetch();
              }}
            >
              {timedAssignment.additionalTime ? "Resume " : "Start "}
              {isTestType ? "Test " : "Assignment "}Now
            </button>
          </div>
        </>
      );

    // if (isPasscode && !timedAssignment?.isTimed) return <h3>Passcode Only</h3>;
    // if (isPasscode && timedAssignment?.isTimed) return <h3>Timed Passcode</h3>;
    // if (isTimedPending) return <h3>Timed Assignment Pending</h3>;
    // if (isTimedStarted) return <h3>Timed Assignment Started</h3>;
    if (isCorrectionPending) {
      const questions: any = assignment?.current?.ta?.questions;

      const isPointsTypeEarned =
        assignment?.current?.ta?.correction_points_lost_or_earned == "earned";

      const pointsTypeText = isPointsTypeEarned
        ? "(points earned)"
        : "(points lost)";

      const questionList: any = [];

      questionList.push(
        <Fragment key={`key-${teacherId}`}>
          <label
            id={`correctionLabel-${teacherId}`}
            htmlFor={`correction-${teacherId}`}
            className="text-right text-sm font-bold text-dm-charcoal-500"
          >
            Test Grade
          </label>
          <input
            type="text"
            defaultValue={assignment?.current?.sa?.testgrade || ""}
            name={`correction-${teacherId}`}
            id={`correction-${teacherId}`}
            aria-labelledby={`correctionLabel-${teacherId} correctionPostLabel-${teacherId}`}
            onChange={(e) => correctionValue("test", parseInt(e.target.value))}
            className="block rounded-md border-[#979797] px-2.5 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          <div
            id={`correctionPostLabel-${teacherId}`}
            className="col-span-2 items-center text-sm text-dm-charcoal-500"
          >
            <strong>/{assignment?.current?.ta?.totalPoints}</strong>
          </div>
        </Fragment>
      );

      const questionPoints: any = {};

      for (const key in questions) {
        const question = questions[key];

        questionPoints[key] = question.points || 0;

        questionList.push(
          <Fragment key={`${encodeURI(question.name)}-key`}>
            <label
              id={`${question.name}-inputLabel`}
              htmlFor={`${question.name}-input`}
              className="text-right text-sm text-dm-charcoal-500"
            >
              Question {question?.name}
            </label>
            <input
              type="text"
              defaultValue={
                assignment?.current?.sa?.testdata
                  ? assignment?.current?.sa?.testdata[key] || ""
                  : ""
              }
              name={`${question.name}-input`}
              id={`${question.name}-input`}
              aria-labelledby={`${question.name}-inputLabel ${question.name}-inputPostLabel`}
              onChange={(e) => pointValues(key, parseInt(e.target.value))}
              className="block rounded-md border-[#979797] px-2.5 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
            <div
              id={`${question.name}-inputPostLabel`}
              className="col-span-2 items-center text-sm text-dm-charcoal-500"
            >
              <strong>/{question.points}</strong> {pointsTypeText}
            </div>
          </Fragment>
        );
      }
      return (
        <div className="grid grid-cols-[auto_48px_minmax(0,_1fr)_auto] items-center gap-3">
          {questionList}
          {assignment?.current?.ta?.extraPoints ? (
            <>
              <label
                id={`extraLabel-${teacherId}`}
                htmlFor={`extra-${teacherId}`}
                className="text-right text-sm text-gray-700"
              >
                Extra Credit
              </label>
              <input
                type="text"
                defaultValue={assignment?.current?.sa?.extra || ""}
                name={`extra-${teacherId}`}
                id={`extra-${teacherId}`}
                aria-labelledby={`extraLabel-${teacherId} extraPostLabel-${teacherId}`}
                onChange={(e) =>
                  correctionValue("extraPoints", parseInt(e.target.value))
                }
                className="block rounded-md border-[#979797] px-2.5 py-2 text-sm focus:border-indigo-500 focus:ring-indigo-500"
              />
              <div
                id={`extraPostLabel-${teacherId}`}
                className="col-span-2 items-center text-sm text-dm-charcoal-500"
              >
                <strong>/{assignment?.current?.ta?.extraPoints}</strong> (points
                earned)
              </div>
            </>
          ) : null}
          <div className="col-span-4 text-right">
            <button
              type="button"
              onClick={() => handleInitializeCorrection.mutate()}
              className={clsx(
                "w-full rounded border px-5 py-2.5 text-center text-sm text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:w-auto sm:px-8",
                "border-dm-brand-blue-500 bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
              )}
            >
              Initialize Test Corrections
            </button>
          </div>
        </div>
      );
    }
    if (isCorrectionEmpty) {
      return (
        <>
          <p>
            There are no problems for you to solve in this test correction. Go
            back to the assignments list to work on another assignment or reset
            test correction.
          </p>
          <div className="mt-8 flex flex-wrap items-center justify-between gap-x-6 gap-y-3 border-t border-t-[#CCCED0] pt-8 sm:flex-nowrap">
            <button
              role="button"
              className="basis-full rounded border border-dm-charcoal-500 bg-white px-5 py-2.5 text-center text-sm text-dm-charcoal-500 hover:bg-dm-charcoal-500 hover:text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto sm:px-8"
              onClick={resetCorrections}
            >
              Reset Test Correction
            </button>
            <NavLink
              to={assignmentsUrl}
              reloadDocument
              className={clsx(
                "basis-full rounded border px-5 py-2.5 text-center text-sm text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto sm:px-8",
                "border-dm-brand-blue-500 bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
              )}
            >
              Back To Assignment List
            </NavLink>
          </div>
        </>
      );
    }

    return <></>;
  };

  /* TESTING */
  // useEffect(() => {
  //   console.log("firstSkill:", firstSkill);
  // }, [firstSkill]);

  // useEffect(() => {
  //   console.log("assignment?.current:", assignment?.current);
  // }, [assignment?.current]);

  // useEffect(() => {
  //   console.log("payload:", payload);
  // }, [payload]);

  // useEffect(() => {
  //   console.log("isCorrectionEmpty:", isCorrectionEmpty);
  //   console.log("isCorrectionType:", isCorrectionType);
  //   console.log(
  //     "assignment?.current?.sa?.correctionInitialized",
  //     assignment?.current?.sa?.correctionInitialized === true
  //   );
  //   console.log(
  //     "assignment?.current?.ta?.order?.length",
  //     assignment?.current?.ta?.order?.length > 0
  //   );
  // }, [isCorrectionEmpty]);

  // useEffect(() => {
  //   console.log("noPassThru:", noPassThru);
  // }, [noPassThru]);

  // useEffect(() => {
  //   console.log("assignment?.current:", assignment?.current);
  // }, [assignment?.current]);

  // useEffect(() => {
  //   console.log("section?.current:", section?.current);
  // }, [section?.current]);

  // useEffect(() => {
  //   console.log("isResetCorrectionActive:", isResetCorrectionActive);
  // }, [isResetCorrectionActive]);

  /* END TESTING */

  useLayoutEffect(() => {
    if (assignment?.current === undefined) return;

    if (noPassThru) {
      return;
    } else if (firstSkill !== undefined) {
      navigate(firstSkillUrl, { replace: true });
    }
  }, [assignment.current]);

  if (assignment?.current === undefined)
    return (
      <div className="max-h-screen w-full max-w-2xl overflow-y-auto rounded-xl bg-white p-4 text-left sm:px-8 sm:py-12">
        <p className="text-sm tracking-tight">
          The link is not valid or links to an assignment which is not currently
          assigned to your class.
        </p>
        <div className="mt-8 flex flex-wrap items-center justify-end gap-x-6 gap-y-3 border-t border-t-[#CCCED0] pt-8 sm:flex-nowrap">
          <NavLink
            to={nearestUpcomingUrl}
            reloadDocument
            className={clsx(
              "basis-full rounded border px-5 py-2.5 text-center text-sm text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto sm:px-8",
              "border-dm-brand-blue-500 bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
            )}
          >
            Back To Assignments
          </NavLink>
        </div>
      </div>
    );

  if (!noPassThru) return <></>;

  return (
    <div className="max-h-screen w-full max-w-2xl overflow-y-auto rounded-xl bg-white p-4 text-left sm:px-8 sm:py-12">
      <h1 className="font-serif text-2xl font-semibold tracking-tight">
        {assignment?.current?.ta?.name}
      </h1>
      {dueDate && <h2 className="mt-4 font-bold">Due: {dueDate}</h2>}

      <div className="mt-6">{displayInfo()}</div>
    </div>
  );
}
