import { useState, useEffect, Dispatch, SetStateAction } from "react";
import clsx from "clsx";
import { PieChart } from "react-minimal-pie-chart";
import { SkillDataType } from "../_constants";
import Document from "./icons/Document";
import DonutChart from "./DonutChart";

type Props = {
  skillData: SkillDataType;
  setTooltipText: Dispatch<SetStateAction<string | null>>;
  tooltipId?: string;
  isActive?: boolean;
  extraClasses?: string;
};

export default function IconProgress({
  skillData,
  setTooltipText,
  tooltipId = "piechart",
  isActive = false,
  extraClasses = "",
}: Props): JSX.Element {
  const isTestType = skillData.isTest;
  const isVideoType = skillData.isVideo;
  const isTimedType = skillData.isTimedModule;
  const isSpecial = skillData.type === "special";

  const [textforTooltip, setTextForTooltip] = useState<string>("");

  useEffect(() => {
    if (skillData.isTeacherType) {
      setTextForTooltip(
        skillData.isCompleted ? "Teacher Graded - Pending" : "Teacher Graded"
      );
    } else if (
      !isTestType &&
      skillData?.progress?.record !== undefined &&
      skillData?.progress?.score !== undefined &&
      skillData?.progress?.total !== undefined
    ) {
      if (skillData?.progress?.record > skillData?.progress?.score) {
        setTextForTooltip(
          `Record: ${skillData?.progress?.record}/${skillData?.progress?.total}<br>Score: ${skillData?.progress?.score}/${skillData?.progress?.total}`
        );
      } else {
        setTextForTooltip(
          `Score: ${skillData?.progress?.record}/${skillData?.progress?.total}`
        );
      }
    }
  }, [skillData]);

  const icon = () => {
    if (isSpecial) {
      return (
        <i
          className="far fa-circle text-xl leading-none text-gray-400"
          aria-hidden="true"
        ></i>
      );
    } else if (isTestType) {
      if (skillData.isCompleted) {
        if (skillData.obscureResults) {
          return (
            <i
              className="far fa-check-circle text-xl leading-none text-dm-brand-blue-500"
              aria-hidden="true"
            ></i>
          );
        } else if (
          skillData.maxProblemsOneDoneCorrect &&
          !skillData.obscureResults
        ) {
          return (
            <i
              className="far fa-check text-xl leading-none text-dm-success-500"
              aria-hidden="true"
            ></i>
          );
        } else if (
          !skillData.maxProblemsOneDoneCorrect &&
          !skillData.obscureResults
        ) {
          return (
            <i
              className="far fa-times text-xl leading-none text-dm-error-500"
              aria-hidden="true"
            ></i>
          );
        }
      } else {
        return (
          <i
            className="far fa-circle text-xl leading-none text-gray-400"
            aria-hidden="true"
          ></i>
        );
      }
    } else if (skillData.isTeacherType) {
      return (
        <Document strokeColor={skillData.isCompleted ? "#585858" : "#9CA3AF"} />
      );
    } else if (isVideoType) {
      const completed = skillData.percentCompleted || 0;
      if (completed === 100)
        return (
          <i
            className="far fa-check text-xl leading-none text-dm-success-500"
            aria-hidden="true"
          ></i>
        );
      else
        return (
          <div className="relative flex h-5 w-5 flex-shrink-0 items-center justify-center text-xs leading-none">
            <PieChart
              data={[
                { value: completed, color: "#2F52E9" },
                { value: 100 - completed, color: "#E3E8EF" },
              ]}
              lineWidth={25}
              paddingAngle={0}
              totalValue={100}
              startAngle={270}
              className="pointer-events-none absolute"
            />
            <i
              className={clsx(
                "fas fa-play fa-xs pl-0.5",
                completed < 100
                  ? "text-dm-charcoal-100"
                  : "text-dm-brand-blue-500"
              )}
              aria-hidden="true"
            ></i>
          </div>
        );
    } else if (isTimedType) {
      const completed = skillData.percentCompleted || 0;
      if (completed === 100)
        return (
          <i
            className="far fa-check text-xl leading-none text-dm-success-500"
            aria-hidden="true"
          ></i>
        );
      else
        return (
          <div className="relative flex h-5 w-5 items-center justify-center text-xs leading-none">
            <PieChart
              data={[
                { value: completed, color: "#2F52E9" },
                { value: 100 - completed, color: "#E3E8EF" },
              ]}
              lineWidth={25}
              paddingAngle={0}
              totalValue={100}
              startAngle={270}
              className="pointer-events-none absolute"
            />
            <svg
              viewBox="0 0 500 500"
              xmlns="http://www.w3.org/2000/svg"
              fill="#E3E8EF"
              className="pl-0.5"
              aria-hidden="true"
            >
              <path d="M 275.938 372.652 L 191.038 310.952 C 187.938 308.652 186.138 305.052 186.138 301.252 L 186.138 137.052 C 186.138 130.452 191.538 125.052 198.138 125.052 L 230.138 125.052 C 236.738 125.052 242.138 130.452 242.138 137.052 L 242.138 278.752 L 308.938 327.352 C 314.338 331.252 315.438 338.752 311.538 344.152 L 292.738 370.052 C 288.838 375.352 281.338 376.552 275.938 372.652 Z"></path>
            </svg>
          </div>
        );
    } else if (!isTestType) {
      if (skillData.isMaxProblemType && skillData.isCompleted) {
        if (skillData?.progress?.record === 0) {
          return (
            <i
              className="far fa-times text-xl leading-none text-dm-error-500"
              aria-hidden="true"
            ></i>
          );
        } else if (skillData.maxProblemsOneDoneCorrect) {
          return (
            <i
              className="far fa-check text-xl leading-none text-dm-success-500"
              aria-hidden="true"
            ></i>
          );
        } else {
          return (
            <DonutChart
              isMaxOne={!!skillData.maxProblems}
              progress={skillData.progress}
            />
          );
        }
      } else {
        return (
          <DonutChart
            isMaxOne={!!skillData.maxProblems}
            progress={skillData.progress}
          />
        );
      }
    }
    return <></>;
  };

  return (
    <>
      <div
        className={clsx(
          "relative block h-5 w-5 text-center leading-none",
          extraClasses,
          isActive ? "brightness-200" : null
        )}
        data-tip
        data-for={tooltipId}
        onPointerOver={() => {
          setTooltipText(textforTooltip);
        }}
        onPointerOut={() => {
          setTooltipText("");
        }}
      >
        {icon()}
      </div>
      {textforTooltip !== null ? (
        <p
          className="sr-only order-last"
          dangerouslySetInnerHTML={{ __html: textforTooltip }}
        ></p>
      ) : null}
    </>
  );
}
