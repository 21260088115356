import { useState, useEffect, useRef, useMemo, useContext } from "react";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import CommonButton from "./CommonButton";
import { InputField } from "./InputField";
import SubmitButton from "./SubmitButton";
import { displayInlineMath } from "../../utils";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
const dmKAS = (window as any).dmKAS; // TODO: find better way to deal with globals

type SimpleAnswerProps = {
  className: string;
  commonButtons?: Array<Array<string | boolean>>;
  leftLatex?: string;
  rightLatex?: string;
  answerWidth?: number;
  multipleSolutionsPossible?: boolean;
  multipleSolutionsBtns?: Array<string>;
  allowComma?: boolean;
  allowCommasForNumbers?: boolean;
  binomialExponent?: boolean;
  warnings?: {
    giveEquationWarning?: boolean;
    giveCoordinatePointWarning?: boolean;
    giveNoEquationWarning?: boolean;
    skipFrontendSimplify?: boolean;
    simplifyRadicalWarning?: boolean;
    studentWarningFunction?: string;
  };
  setAnswer: (answer: Array<string>) => void;
  setAlertMsg: (msg: string) => void;
  setShowAnswerPreview: (bool: boolean) => void;
  setShowAlert: (bool: boolean) => void;
  problemData?: any;
};

export default function SimpleAnswer({
  className,
  commonButtons,
  leftLatex,
  rightLatex,
  answerWidth,
  multipleSolutionsPossible,
  multipleSolutionsBtns,
  allowComma,
  allowCommasForNumbers,
  binomialExponent,
  warnings,
  setAnswer,
  setAlertMsg,
  setShowAnswerPreview,
  setShowAlert,
  problemData,
}: SimpleAnswerProps): JSX.Element {
  const inputsRef = useRef<Map<string, any> | null>(null);
  const initialRender = useRef(true);
  const latexForInput = useRef("");
  const [numInputFields, { addField, removeField }] = useInputFields(1);
  // const [showKeyboard, setShowKeyboard] = useState<boolean>(false);
  const { setGlobalInputsMap, globalFocusedInput, handleGlobalFocus } =
    useContext(StudentSectionsContext);

  useManageNumInputFields({
    initialRender,
    inputsRef,
    latexForInput,
    numInputFields,
  });

  useEffect(() => {
    if (inputsRef.current) {
      setGlobalInputsMap(inputsRef.current);
    }
  }, [globalFocusedInput]);

  /* Submit Button Event Handler */
  const handleSubmit = () => {
    if (inputsRef.current) {
      /* prevent calculator input from being included in answer array */
      if (inputsRef.current.has("math-input-0")) {
        inputsRef.current.delete("math-input-0");
      }
    }
    const answerArray = computeAnswerArray(inputsRef);
    const alertStr = computeAlertMessage(answerArray, warnings, problemData);
    setAlertMsg(alertStr);
    setAnswer(answerArray);
    if (alertStr !== "") {
      setShowAlert(true);
    } else {
      setShowAnswerPreview(true);
    }
  };

  /* Math Input Field Event Handler */
  const handleChange = (mq: any): void => {
    // commas should trigger adding a field if multiple solutions are possible
    const inputStr = mq.latex();
    const allowCommas = allowComma || allowCommasForNumbers;
    const commaIndex = inputStr.indexOf(",");
    if (multipleSolutionsPossible && !allowCommas && commaIndex !== -1) {
      mq.latex(inputStr.slice(0, commaIndex));
      addField();
      latexForInput.current = inputStr.slice(commaIndex + 1);
    }
  };

  /* Create Math Input Fields */
  const inputFieldsArr: Array<JSX.Element> = [];
  let keyNum = 1;
  for (let i = 1; i <= numInputFields; i++) {
    inputFieldsArr.push(
      <InputField
        currentInputField={i}
        numInputFields={numInputFields}
        leftLatex={leftLatex}
        rightLatex={rightLatex}
        answerWidth={answerWidth}
        binomialExponent={binomialExponent}
        handleChange={handleChange}
        handleFocus={handleGlobalFocus}
        ref={(node: any) => {
          if (!inputsRef.current) inputsRef.current = new Map();
          const map = inputsRef.current;
          if (node) {
            map.set(`math-input-${i}`, node);
          } else {
            map.delete(`math-input-${i}`);
          }
        }}
        key={"inputs" + keyNum}
        handleSubmit={handleSubmit}
      />
    );
    keyNum += 1;
  }

  return (
    <>
      <div className={className}>
        {multipleSolutionsPossible ? (
          <div className="mb-4 flex flex-row flex-wrap justify-start gap-x-4 gap-y-2 text-sm font-medium text-dm-charcoal-500">
            <button
              type="button"
              className="inline-flex items-center hover:text-dm-charcoal-600"
              // className="inline-flex items-center rounded-md border border-transparent bg-dm-alt-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#2397C4] focus:outline-none focus:ring-2 focus:ring-[#9DD3E7] focus:ring-offset-2"
              onClick={() => addField()}
            >
              <PlusCircleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              {multipleSolutionsBtns
                ? multipleSolutionsBtns[1]
                : "Additional Solution"}
            </button>
            {numInputFields > 0 ? (
              <button
                type="button"
                className="inline-flex items-center hover:text-dm-charcoal-600"
                // className="inline-flex items-center rounded-md border border-transparent bg-dm-alt-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-[#2397C4] focus:outline-none focus:ring-2 focus:ring-[#9DD3E7] focus:ring-offset-2"
                onClick={() => removeField()}
              >
                <MinusCircleIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                {numInputFields === 1
                  ? multipleSolutionsBtns
                    ? multipleSolutionsBtns[0]
                    : "No Solution"
                  : multipleSolutionsBtns
                  ? multipleSolutionsBtns[2]
                  : "Remove Solution"}
              </button>
            ) : null}
          </div>
        ) : null}
        <div
          id="answer-block"
          className="flex flex-row flex-wrap items-center gap-x-4 gap-y-2"
          // className="flex flex-col justify-stretch sm:flex-row sm:flex-wrap sm:items-center gap-x-4 gap-y-2"
        >
          {/* <div>Answer:</div> */}
          {problemData.setNotation ? (
            <div
              className={clsx(
                "text-[x-large]",
                numInputFields > 0 ? "-mr-2" : "-mr-1"
              )}
            >
              {displayInlineMath("\\{")}
            </div>
          ) : null}
          {numInputFields > 0 ? inputFieldsArr : null}
          {numInputFields === 0 && problemData.setNotation !== true
            ? multipleSolutionsBtns
              ? displayInlineMath("\\text{" + multipleSolutionsBtns[0] + "}")
              : displayInlineMath("\\text{No solution}")
            : null}
          {problemData.setNotation ? (
            <div
              className={clsx(
                "text-[x-large]",
                numInputFields > 0 ? "-ml-2" : "-ml-1"
              )}
            >
              {displayInlineMath("\\}")}
            </div>
          ) : null}
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
            {/* {numInputFields > 0 ? (
              <VirtualKeyboard handleKeyboardToggle={handleKeyboardToggle} />
            ) : null} */}
            <SubmitButton handleSubmit={handleSubmit} />
          </div>
        </div>
        {commonButtons !== undefined ? (
          <div className="mx-4 my-2 flex flex-row flex-wrap items-center gap-x-4 gap-y-2">
            {commonButtons.map((btn) => (
              <CommonButton
                btnText={btn[0] as string}
                btnCmd={btn[1] ? (btn[1] as boolean) : undefined}
                btnOutput={btn[2] ? (btn[2] as string) : undefined}
                inputsRef={inputsRef}
                focusedInput={globalFocusedInput}
                key={"" + btn[0]}
              />
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
}

/* ************ */
/* Custom Hooks */
/* ************ */

/* Custom Hook for Managing Input Fields */
function useInputFields(initialState = 1) {
  const [numInputFields, setNumInputFields] = useState(initialState);

  const handlers = useMemo(
    () => ({
      addField: () => {
        if (numInputFields < 8)
          setNumInputFields((numInputFields) => numInputFields + 1);
      },
      removeField: () => {
        if (numInputFields > 0)
          setNumInputFields((numInputFields) => numInputFields - 1);
      },
    }),
    [initialState, numInputFields]
  );

  return [numInputFields, handlers] as const;
}

/* Custom Hook for Managing Input Field Focus */
function useFieldFocus(initialState = "") {
  const [focusedInput, setFocusedInput] = useState(initialState);

  const handlers = useMemo(
    () => ({
      handleFocus: (mqID: any): void => {
        setFocusedInput(mqID.slice(-1));
      },
    }),
    []
  );

  return [focusedInput, handlers] as const;
}

/* Custom Hook for Managing Focus and Initial Values of New Input Fields */
function useManageNumInputFields({
  initialRender,
  inputsRef,
  latexForInput,
  numInputFields,
}: {
  initialRender: React.MutableRefObject<boolean>;
  inputsRef: React.MutableRefObject<any>;
  latexForInput: React.MutableRefObject<string>;
  numInputFields: number;
}) {
  useEffect(() => {
    // should only change focus after initial render
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    const map = getMap(inputsRef);
    const node = map.get(String(numInputFields));
    // if the user entered a comma, set the value of last node to be the value after the comma
    if (latexForInput.current !== "") {
      node?.latex(latexForInput.current);
      latexForInput.current = ""; // clear latex for input
    }
    node?.focus();
  }, [numInputFields]);
}

/* **************** */
/* Helper Functions */
/* **************** */

/* Function to get the Map from inputsRef */
const getMap = (refs: any) => {
  if (!refs.current) refs.current = new Map();
  return refs.current;
};

/* Function to compute the answer array from the map of refs */
const computeAnswerArray = (inputsRef: any) => {
  const map = getMap(inputsRef);
  const answerArray: string[] = [];
  map.forEach((element: any) => {
    if (element) {
      const ansValue = element.latex();
      if (ansValue.length) {
        answerArray.push(ansValue);
      }
    }
  });
  if (!answerArray.length) answerArray.push("");
  return answerArray;
};

/* Function to check if a student typed a character that cannot be processed by the answer service */
const checkForWarningCharacters = (str: string): string => {
  if (str.search(/\d\\frac\{\d/) !== -1) {
    return "Do not write your answer as a mixed number. Instead write your answer as an improper fraction. For example, write \\(\\frac{11}{3}\\) instead of \\(3\\tfrac{2}{3}\\).";
  } else if (str.substring(0, 1) === "^") {
    return "Your answer is as an exponent right now, making everything very small. Try clearing the box and typing your answer again.";
  } else if (str.search(/[⁰¹²⁴⁵⁶⁷⁸⁹]/) !== -1) {
    return "Do not use superscript characters in your answer like ², ³, or ⁴. Instead use the ^ key for exponents (SHIFT 6 on the keyboard) or use the DeltaMath keypad \\(a^b\\).";
  } else if (str.search(/⋅/) !== -1) {
    return "Do not copy the ⋅ symbol into the answer box. Instead use the * symbol on your keyboard for multiplication.";
  } else if (str.search(/√/) !== -1) {
    return "Do not copy the √ symbol into the answer box. Instead click the \\(\\sqrt{\\phantom{x}}\\) button (either below the answer form or on the keypad found after clicking the keyboard icon.";
  } else if (str.search(/ln\^/) != -1) {
    return "Exponents directly on natural log functions, such as \\(\\ln^2(x),\\) are not supported. Please write in one of the following forms: \\((\\ln x)^2\\) or \\((\\ln(x))^2\\).";
  } else if (str.indexOf("\\approx") !== -1 || str.search(/≈/) !== -1) {
    return "Do not use the \\(\\approx\\) symbol. If the question is asking for a rounded value, just write that value.";
  } else if (str.search(/[𝑎𝑏𝑐𝑑𝑒𝑓𝑔ℎ𝑖𝑗𝑘𝑙𝑚𝑛𝑜𝑝𝑞𝑟𝑠𝑡𝑢𝑣𝑤𝑥𝑦𝑧]/u) !== -1) {
    return "You have pasted italic type letters like 𝑥, 𝑦, 𝑧, that will not be marked correct by DeltaMath. You must type the letters or use the DeltaMath keyboard.";
  }
  return "";
};

/* Function to compute an alert message, based on the student answer inputs and the warnings on the problem object */
function computeAlertMessage(
  answer: string[],
  warnings: SimpleAnswerProps["warnings"],
  problemData: any
): string {
  // check for equation warning
  // TODO: do I need to check for data.allowEqualsMistake?
  if (warnings?.giveEquationWarning) {
    for (const ans of answer) {
      if (ans.includes("=")) {
        return "Please do not write an \\(=\\) sign in your answer. For example, if you are solving for \\(x,\\) instead of typing \\(x=7,\\) just type \\(7.\\)";
      }
    }
  }
  // check for simplify radical warning
  if (warnings?.simplifyRadicalWarning) {
    for (const ans of answer) {
      if (ans && !dmKAS.checkRadical(ans, { simplifyRadical: true })) {
        return "Be sure to simplify your radical before submitting.";
      }
    }
  }
  // evaluate the student warning function
  if (warnings?.studentWarningFunction !== undefined) {
    const warningFunc = eval(warnings?.studentWarningFunction);
    const msg = warningFunc(answer);
    if (msg) {
      return msg;
    }
  }
  // check for warning characters
  for (const ans of answer) {
    const msg = checkForWarningCharacters(ans);
    if (msg !== "") {
      return msg;
    }
  }
  // prevent dmKAS infinite loop on the backend by checking each answer on the user's local device with dmKAS.isSimplified (unless skipFrontendSimplify is true, meaning isSimplified won't be invoked on the backend)
  if (warnings?.skipFrontendSimplify !== true) {
    for (const ans of answer) {
      dmKAS.isSimplified(ans);
    }
  }
  // check if answer can be parsed
  for (const ans of answer) {
    const arbitraryAns = problemData.point
      ? "(-1234.12121212,-5678.34343434)"
      : "-1234.12121212";
    // dmKAS.compareMany returns {equal: boolean, messages: [], parseError?: boolean}
    // if parseError is true, then the student's answer cannot be parsed
    let compareResult: any;
    try {
      compareResult = dmKAS.compareMany(ans, arbitraryAns, problemData);
      displayInlineMath(ans); // check if ans can be parsed by katex renderer
    } catch {
      return errorMessage(ans);
    }
    if (compareResult?.parseError === true) {
      return errorMessage(ans);
    }
  }

  return "";
}

function errorMessage(ans: string): string {
  // TODO: log the student's answer to a file, in order to investigate any common errors that are occurring with MQ and KAS
  console.log("student answer:", ans);
  return "Please check that your answer is typed correctly before submitting. Try clearing the box and typing your answer again.";
}
