import { Dispatch, Fragment, SetStateAction } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import SideBarMenu from "../components/SideBarMenu";
import SideBarSolveMenu from "../components/SideBarSolveMenu";

type Props = {
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  isSolve?: boolean;
};

export default function Sidebar({
  sidebarOpen,
  setSidebarOpen,
  isSolve = false,
}: Props): JSX.Element {
  const SideBarBlock = () =>
    isSolve ? (
      <SideBarSolveMenu setSidebarOpen={setSidebarOpen} />
    ) : (
      <SideBarMenu setSidebarOpen={setSidebarOpen} />
    );

  return (
    <div className="bg-white shadow-xl sm:max-w-xs md:max-w-md lg:basis-1/4">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[1001] lg:hidden"
          onClose={() => setSidebarOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex max-w-full flex-1 flex-col bg-white pb-4 pt-5 sm:max-w-sm">
                <div
                  className={`mt-2 h-0 flex-1 overflow-y-auto ${
                    !isSolve ? "px-5" : ""
                  }`}
                >
                  <nav aria-label="Sidebar" className="h-full">
                    <SideBarBlock />
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute right-0 top-0 mr-4 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </Transition.Child>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      {/* <div className="hidden lg:inset-y-0 lg:flex lg:w-full lg:flex-col lg:pb-4 lg:pt-6"> */}
      <div className="hidden h-screen px-6 pb-4 pt-8 lg:sticky lg:inset-y-0 lg:top-0 lg:flex lg:w-full lg:flex-col">
        <nav
          className={`flex h-0 flex-1 flex-col overflow-y-auto`}
          aria-label="Sidebar"
        >
          <SideBarBlock />
        </nav>
      </div>
    </div>
  );
}
