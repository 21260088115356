import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { formatNumber, useDMQuery } from "../../../utils";
import { getAdminParams } from "../../utils";
import { useUserContext } from "../../../shared/contexts/UserContext";

export default function InviteAdmins({ school_id }: { school_id: any }) {
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  const adminParams = getAdminParams();

  const [createAdminDisabled, setCreateAdminDisabled] = useState(true);
  const [adminToCreate, setAdminToCreate] = useState({
    account_type: "school",
    districtID: adminParams.districtID,
    first: "",
    last: "",
    email: "",
    schoolIDs: [school_id], // [school.schoolid],
    schoolid: school_id, // school.schoolid
  });

  const createAdmin = useMutation(
    /** body = { params: {
     * account_type: string,
     * districtID: string,
     * email: string,
     * first: string,
     * last: string
     * schoolIDs: string[] **not sure what this is for?
     * schoolid: string
     * }}
     */
    (body: string) => {
      return axios.post(deltamathAPI() + "/admin/create_admin", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: (data: any) => {
        if (data.data.error && data.data.message) {
          toastContext.addToast({
            status: "Error",
            message: data.data.message,
          });
        } else {
          queryClient.invalidateQueries("/admin_new/data/admins");
          toastContext.addToast({
            status: "Success",
            message: "Admin created successfully",
          });
          setAdminToCreate({
            account_type: "school",
            districtID: adminParams.districtID,
            first: "",
            last: "",
            email: "",
            schoolIDs: [school_id], // [school.schoolid],
            schoolid: school_id, // school.schoolid
          });
        }
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "There was an error inviting your admin",
        });
      },
    }
  );

  useEffect(() => {
    setCreateAdminDisabled(true);
    if (
      adminToCreate.account_type &&
      adminToCreate.email &&
      adminToCreate.first &&
      adminToCreate.last &&
      adminToCreate.schoolIDs &&
      adminToCreate.schoolid
    ) {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regex.test(adminToCreate.email)) {
        setCreateAdminDisabled(false);
      }
    }
  }, [adminToCreate]);

  return (
    <>
      <div className="mt-8 shadow sm:overflow-hidden sm:rounded-md">
        <div className="space-y-6 bg-white px-4 py-6 sm:p-6">
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Invite School Administrator
            </h3>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="off"
                value={adminToCreate.first}
                onChange={(e) => {
                  e.preventDefault();
                  setAdminToCreate({
                    ...adminToCreate,
                    first: e.target.value,
                  });
                }}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <label
                htmlFor="region"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                autoComplete="off"
                value={adminToCreate.last}
                onChange={(e) => {
                  e.preventDefault();
                  setAdminToCreate({
                    ...adminToCreate,
                    last: e.target.value,
                  });
                }}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                type="text"
                name="email"
                id="email"
                autoComplete="off"
                value={adminToCreate.email}
                onChange={(e) => {
                  e.preventDefault();
                  setAdminToCreate({
                    ...adminToCreate,
                    email: e.target.value,
                  });
                }}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6">
          <button
            type="submit"
            disabled={createAdminDisabled}
            onClick={() => {
              adminToCreate.first = adminToCreate.first.trim();
              adminToCreate.last = adminToCreate.last.trim();
              createAdmin.mutate(
                JSON.stringify({
                  params: {
                    ...adminToCreate,
                  },
                })
              );
            }}
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-400"
          >
            Send Invite
          </button>
        </div>
      </div>
    </>
  );
}
