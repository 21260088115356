import { useContext, useState, useRef, useEffect } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import { merge, cloneDeep } from "lodash";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import DmConfirmModal from "./DmConfirmModal";
import FormattedAnswer from "./FormattedAnswer";
import YourSolutionGraph from "./YourSolutionGraph";
import { deltamathAPI } from "../../../manager/utils";
import { skillToSolve, timedAssg } from "../../utils";
import renderMathInElement from "../../utils/auto-render";
import clsx from "clsx";
import { useUserContext } from "../../../shared/contexts/UserContext";

type YourSolutionProps = {
  correct: number | undefined;
  complete: boolean;
  ansType: string | number;
  leftLatex?: string;
  rightLatex?: string;
  noSolutionText: string;
  choices?: Array<string>;
  setNotation?: boolean;
  problem?: any;
  katexResizingData?: any;
  setShowSolution?: any;
};

export default function YourSolution({
  correct,
  complete,
  ansType,
  leftLatex,
  rightLatex,
  noSolutionText,
  choices,
  setNotation,
  problem,
  katexResizingData,
  setShowSolution,
}: YourSolutionProps): JSX.Element {
  // TODO - styling
  const {
    setLoadingData,
    dmAssignmentData,
    setDmAssignmentData,
    activeSection,
  } = useContext(StudentSectionsContext);
  const userContext = useUserContext();
  const [showModal, setShowModal] = useState(false);
  const messagesRef = useRef<Map<string, HTMLLIElement> | null>(null);
  const token = userContext.getJWT(); // for authorization
  const solveSkill: any = skillToSolve();

  /* Calculated variables to determine display */
  // destructuring problem
  const { log_data: logData } = problem || {};
  const showCorrectness = correct === 0 || correct === 1;
  const showStudentGraph =
    problem?.answerLines?.length > 0 && logData?.data !== undefined;
  // filter out any attempts that result from unsubmitting/resubmitting answers
  const attemptsData = logData?.attempts_data?.filter(
    (attempt: any) => !Object.hasOwn(attempt, "unsubmitted")
  );
  const [attemptNumber, setAttemptNumber] = useState<number>(
    attemptsData !== undefined ? attemptsData.length : 0
  ); // state for showing answer graph "carousel"
  const showAttempts = attemptsData?.length > 0 && showCorrectness;
  // TODO: update noStudentAnswerToShow - modules should eventually always show the student's submitted graph / work on a separate graph. Currently, noStudentAnswerToShow means that there is no student sol string and no graph to show on a test.
  const noStudentAnswerToShow =
    !showAttempts &&
    !showStudentGraph &&
    !showCorrectness &&
    (logData?.sol === undefined || logData?.sol === "");
  // TODO: how to determine if the student did not submit an answer? I think logData is undefined...
  const noAnswerSubmitted = problem !== undefined && logData === undefined;

  /* Logic to unsubmit a problem */
  const timedAssignment = timedAssg(solveSkill);
  const hasTimeLeft = timedAssignment.isTimed
    ? timedAssignment.endTime && !timedAssignment.isOver
    : true;
  const showUnsubmit =
    solveSkill?.ta?.allow_resubmit === 1 && !!hasTimeLeft && !showCorrectness;
  const unsubmit = useUnsubmit(token);
  const handleUnsubmit = () => {
    unsubmit.mutate(
      {
        body: JSON.stringify({
          sk: solveSkill.ta.skillName,
        }),
        id: solveSkill?.ta?._id,
      },
      {
        onSuccess: (response) => {
          const assignmentIndex = dmAssignmentData[activeSection].findIndex(
            (assignment: any) => assignment.ta._id === solveSkill?.ta?._id
          );
          if (assignmentIndex > -1) {
            const newAssignmentObj = { ...dmAssignmentData };
            // merge the new assignment data with the existing assignment data, since the incoming data is missing some properties, so can't overwrite everything
            newAssignmentObj[activeSection][assignmentIndex] = merge(
              newAssignmentObj[activeSection][assignmentIndex],
              response.data
            );
            // overwrite the data for this specific assignment on the student assignment to be the data from the response (rather than a merge)
            newAssignmentObj[activeSection][assignmentIndex].sa.data[
              solveSkill.ta.skillName
            ] = response.data.sa.data[solveSkill.ta.skillName];
            // reset the dmAssignmentData
            setDmAssignmentData(newAssignmentObj);
          }
          setShowSolution(false); // show the question page
        },
        onError: (error: any) => {
          console.log("Error message in unsubmit.mutate", error);
          setLoadingData((prevState: any) => ({
            ...prevState,
            isShowing: true,
            error: true,
            title: "Error",
            message: `${
              error?.response?.data?.message ||
              error?.message ||
              error?.error ||
              ""
            }`,
          }));
        },
      }
    );
  };

  /* Returns map of references to each answer message node */
  const getMap = () => {
    if (!messagesRef.current) {
      // Initialize the Map on first usage.
      messagesRef.current = new Map();
    }
    return messagesRef.current;
  };

  /* Renders the math in each answer message */
  useEffect(() => {
    if (messagesRef.current) {
      messagesRef.current.forEach((node: HTMLLIElement) => {
        renderMathInElement(node);
      });
    }
  }, [attemptNumber]);

  const generateEachSolution = (
    answer: Array<string> | object,
    solutionNum: number,
    correct: number | undefined,
    answerMessages?: string[],
    customMsg?: string
  ): JSX.Element => {
    return (
      <>
        {answerMessages && showCorrectness ? (
          <div className={clsx(showStudentGraph && "row")}>
            <ul
              role="list"
              className={clsx(showStudentGraph && "col-sm-9 text-center")}
            >
              {answerMessages.map((msg, index) => (
                <li
                  key={"message" + index}
                  ref={(node) => {
                    const map = getMap();
                    if (node) map.set("message" + solutionNum + index, node);
                    else map.delete("message" + solutionNum + index);
                  }}
                  className={clsx(
                    "px-4 py-2 sm:px-6",
                    correct === 1 ? "text-[#078445]" : "text-[#D21527]"
                  )}
                >
                  {msg}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div className="row">
          <div
            className={clsx(
              typeof customMsg === "string" &&
                customMsg.indexOf("col-sm-12") !== -1
                ? ""
                : "col-sm-9"
            )}
          >
            <FormattedAnswer
              ansType={ansType}
              answer={answer}
              leftLatex={leftLatex}
              rightLatex={rightLatex}
              noSolutionText={noSolutionText}
              choices={choices}
              setNotation={setNotation}
              customMsg={customMsg}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {
        <DmConfirmModal
          showModal={showModal}
          setShowModal={setShowModal}
          isSubmitDisabled={unsubmit.isLoading}
          headline="Warning"
          subHeadline="Are you sure you want to unsubmit this answer?"
          message={
            <div className="p-4 text-center text-lg">
              Make sure you have enough time to resubmit a new answer, or else
              you will not get credit on this problem.
            </div>
          }
          cancelMessage="Cancel"
          confirmMessage="Yes, Unsubmit"
          modalAction={handleUnsubmit}
        />
      }
      {problem !== undefined && (
        <div className="yoursolution">
          <h2
            className={clsx(
              "text-[1.3125em] font-semibold",
              (!showStudentGraph || showAttempts) && "mb-6"
            )}
          >
            Your Answer
            {showAttempts ? "s" : ""}
          </h2>
          {complete === true && !noStudentAnswerToShow ? (
            <>
              {showStudentGraph ? (
                <>
                  <YourSolutionGraph
                    problem={problem}
                    attemptsData={attemptsData}
                    katexResizingData={katexResizingData}
                    showAttempts={showAttempts}
                    attemptNumber={attemptNumber}
                    setAttemptNumber={setAttemptNumber}
                  />
                  {showAttempts && attemptNumber < attemptsData.length
                    ? generateEachSolution(
                        attemptsData[attemptNumber].ans,
                        attemptNumber,
                        0,
                        attemptsData[attemptNumber].messages,
                        attemptsData[attemptNumber].sol
                      )
                    : generateEachSolution(
                        logData.ans,
                        attemptsData?.length || 0,
                        correct,
                        logData.messages,
                        logData.sol
                      )}
                </>
              ) : (
                <>
                  {showAttempts && (
                    <>
                      {attemptsData.map((attempt: any, index: number) => {
                        return (
                          <div className="mb-6">
                            <h3 className="pb-2 text-[#4B5563]">
                              Attempt {index + 1} out of{" "}
                              {attemptsData.length + 1 + " "}
                              <span className="font-medium">(incorrect)</span>
                              <XIcon
                                className="ml-2 w-4 text-[#D21527]"
                                aria-label="X icon"
                              />
                            </h3>
                            {generateEachSolution(
                              attempt.ans,
                              index,
                              0,
                              attempt.messages,
                              attempt.sol
                            )}
                          </div>
                        );
                      })}
                      <h3 className="pb-2 text-[#4B5563]">
                        Attempt {attemptsData.length + 1} out of{" "}
                        {attemptsData.length + 1 + " "}
                        <span className="font-medium">
                          ({correct === 1 ? "correct" : "incorrect"})
                        </span>
                        {correct === 1 ? (
                          <CheckIcon
                            className="ml-2 w-4 text-[#078445]"
                            aria-label="Check icon"
                          />
                        ) : (
                          <XIcon
                            className="ml-2 w-4 text-[#D21527]"
                            aria-label="X icon"
                          />
                        )}
                      </h3>
                    </>
                  )}
                  {generateEachSolution(
                    logData.ans,
                    attemptsData?.length || 0,
                    correct,
                    logData.messages,
                    logData.sol
                  )}
                </>
              )}
            </>
          ) : null}
          {complete === true && noStudentAnswerToShow ? (
            <div className="my-6 text-[1.3125em] sm:w-3/4 sm:text-center">
              Answer submitted.
            </div>
          ) : null}
          {complete === false && noAnswerSubmitted ? (
            <div className="my-6 text-[1.3125em] sm:w-3/4 sm:text-center">
              No answer was submitted.
            </div>
          ) : null}
          {showUnsubmit && (
            <button
              type="button"
              id="unsubmit"
              className="m-1 inline-flex items-center rounded-md border border-transparent bg-dm-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-dm-dark-blue focus:outline-none focus:ring-2 focus:ring-dm-lightest-blue focus:ring-offset-2"
              onClick={() => setShowModal(true)}
              disabled={unsubmit.isLoading}
            >
              Unsubmit Answer
            </button>
          )}
        </div>
      )}
    </>
  );
}

/* Custom Hook for un-submitting a student's answer */
// TODO: return type for endpoint from backend!
const useUnsubmit = (token: string | null) => {
  return useMutation(
    ({ body, id }: { body: string; id: number | undefined }) => {
      return axios.post(deltamathAPI() + `/student/unsubmit/${id}`, body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onError: (error) => {
        console.log("Error in unsubmit:", error);
      },
    }
  );
};
