import { useContext, Dispatch, SetStateAction, useEffect } from "react";
import { UserCircleIcon } from "@heroicons/react/outline";
import AddNewClass from "./AddNewClass";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { SectionDataStatus } from "../_constants";
import { getSectionData } from "../utils";
import CalculatorButton from "./CalculatorButton";
import { useQueryClient } from "react-query";
import { useUserContext } from "../../shared/contexts/UserContext";
import ArchivedCourses from "./ArchivedCourses";
import { getFilePath } from "../../utils";

type Props = {
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

export default function SideBarMenu({ setSidebarOpen }: Props): JSX.Element {
  const queryClient = useQueryClient();
  const userContext = useUserContext();
  const { dmSectionsData, dmAssignmentData, calcAvailable } = useContext(
    StudentSectionsContext
  );

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const userName = user ? `${user.first} ${user.last}` : "Your Profile";

  const logout = () => {
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    userContext.clearJWT();
    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    window.location.href = `${process.env.REACT_APP_HOMEPAGE_LINK}`;
  };

  return (
    <div className="relative flex h-full flex-col">
      <NavLink
        key="topnavlogo"
        to={`${process.env.REACT_APP_STUDENT_LINK}`}
        className="sticky top-0 bg-white pb-14"
      >
        <img
          className="lg-w-screen z-2 block max-h-6 w-auto px-3"
          src={getFilePath("/images/DeltaMath-Logo_Blue.png")}
          alt="DeltaMath Home"
        />
      </NavLink>
      <div className="flex flex-grow flex-col justify-between">
        <div className="space-y-2 overflow-auto">
          <h4 className="pl-3 text-xs uppercase text-dm-gray-200">Classes</h4>
          {dmSectionsData
            ?.filter((item: any) => item.term === "current")
            .map((item: any) => {
              const assignmentList = getSectionData(
                SectionDataStatus.upcoming,
                item._id,
                dmAssignmentData
              );
              return (
                <NavLink
                  key={item._id}
                  to={`${item._id}`}
                  onClick={() => setSidebarOpen(false)}
                  className={({ isActive }) =>
                    clsx(
                      isActive
                        ? "bg-dm-brand-blue-100 font-bold text-dm-gray-800"
                        : "text-dm-gray-200 hover:text-dm-gray-600",
                      "group mb-1 flex items-center rounded-md p-2 text-sm last:mb-0 hover:font-bold hover:text-dm-gray-800"
                    )
                  }
                  aria-current={item.current ? "page" : undefined}
                >
                  {({ isActive }) => (
                    <>
                      <i className="far fa-chalkboard mr-2 w-6 flex-shrink-0 text-center"></i>
                      <span
                        className={`truncate ${
                          isActive ? "text-dm-gray-800" : "text-dm-gray-600"
                        }`}
                      >
                        {item.name}
                      </span>
                      {assignmentList &&
                        Object.keys(assignmentList)?.length > 0 && (
                          <span
                            className={clsx(
                              "bg-dm-error-500 text-white",
                              "ml-auto inline-block rounded-full px-3 py-0.5 text-xs"
                            )}
                          >
                            {Object.keys(assignmentList).length}
                          </span>
                        )}
                    </>
                  )}
                </NavLink>
              );
            })}
          <AddNewClass />
          <ArchivedCourses />
        </div>

        <div className="sticky bottom-0 mt-8 flex flex-col space-y-2 border-t bg-white">
          {calcAvailable && <CalculatorButton />}

          <div
            id="user-buttons"
            className="flex items-center justify-between p-2"
          >
            <NavLink
              to={`${process.env.REACT_APP_STUDENT_LINK}/profile`}
              className="text-dm-gray-200 hover:text-dm-gray-600"
              aria-label="Profile Settings"
            >
              <div className="flex items-center text-sm">
                <i className="far fa-user mr-2 h-6 w-6 pl-1 text-xl leading-none"></i>
                <span className="text-dm-gray-600">{userName}</span>
              </div>
            </NavLink>
            <a
              className="cursor-pointer align-middle text-sm text-dm-brand-blue-500"
              onClick={logout}
              aria-label="log out"
            >
              <span>Log Out</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
