import { useContext, useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import {
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@heroicons/react/solid";
import { PlayIcon } from "@heroicons/react/outline";
import {
  skillToSolve,
  skillDataDisplay,
  getAssignmentDueDateType,
  getPenalty,
  timedAssg,
  updateFullAssignmentData,
} from "../utils";
import { useShowResults, useEndEarly } from "../utils/api";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import ProgressBar from "../components/ProgressBar";
import StandardSkills from "../components/StandardSkills";
import TimedModule from "../components/TimedModule";
import Countdown from "../components/Countdown";
import SeeSolutionsModal from "../components/SeeSolutionsModal";
import ExamplesModal from "../components/ExamplesModal";
import "katex/dist/katex.min.css";
import clsx from "clsx";
import { DevOptions } from "../utils/devtools";
import HelpVideo from "./HelpVideo";
import BrailleConversionWrapper from "../utils/BrailleConversionWrapper";
import { useUserContext } from "../../shared/contexts/UserContext";
import { cloneDeep } from "lodash";
import ReactTooltip from "react-tooltip";

export default function Solve(): JSX.Element {
  const {
    activeSection,
    setOpenAssignment,
    studentNextProblems,
    setTimedModalShowing,
    dmAssignmentData,
    setDmAssignmentData,
    setStudentNextProblems,
    currentProblemData,
    setCurrentProblemData,
  } = useContext(StudentSectionsContext);

  const solveSkill: any = skillToSolve();

  if (!Object.keys(solveSkill).length) return <></>;

  const [showSolution, setShowSolution] = useState<boolean>(false);
  const [showSolutionForced, setShowSolutionForced] = useState<boolean>(false);
  const [showExample, setShowExample] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [showNextProblem, setShowNextProblem] = useState<boolean>(false);

  const [currentProblem, setCurrentProblem] = useState<any>({
    correct: undefined,
    solution: undefined,
    complete: false, // is the user done with this problem, no matter right or wrong?
    nextProblem: undefined,
    maxProblemsOneDone: undefined,
  });

  const [isSeeSolutionsModalShowing, setSeeSolutionsModalShowing] =
    useState(false);

  const userContext = useUserContext();
  const isLocked = userContext.getIsLocked();

  const navigate = useNavigate();

  const scoreHeader = useRef<null | HTMLDivElement>(null);
  const nextProblemBtnRef = useRef<HTMLButtonElement>(null);
  const nextSectionBtnRef = useRef<HTMLButtonElement>(null);

  /* START | CONSOLE LOG TESTING */
  // useEffect(() => {
  //   console.log("solveSkill:", cloneDeep(solveSkill));
  // }, [solveSkill]);

  // useEffect(() => {
  //   console.log("currentProblemData:", currentProblemData);
  // }, [currentProblemData]);

  // useEffect(() => {
  //   console.log("currentProblem:", currentProblem);
  // }, [currentProblem]);

  // useEffect(() => {
  //   console.log("showSolution:", showSolution);
  // }, [showSolution]);

  // useEffect(() => {
  //   console.log("katexResizingData:", katexResizingData);
  // }, [katexResizingData]);

  /* END | CONSOLE LOG TESTING */

  //TODO: temporary while in development
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isForcedSkill =
    urlParams.get("skillcode") || urlParams.get("problemid");

  const skillData = skillDataDisplay(solveSkill.ta.skillName, solveSkill);

  // Timed Assignment Info
  const timedAssignment = timedAssg(solveSkill);

  // TEMPORARY FOR TESTING
  // useEffect(() => {
  //   console.log(
  //     `timedAssignment(${solveSkill.ta.skillName}):`,
  //     timedAssignment
  //   );
  // }, []);

  // If student gets to a skill in a timed assignment problem that has
  // already ended, it will kick them back to the assignments list page
  useEffect(() => {
    if (!timedAssignment?.isTimed) return;

    // if there's no endTime, student hasn't started the assignment yet and
    // shouldn't be able to see the problems. Same if the assignment is over.
    if (
      !timedAssignment.endTime ||
      (timedAssignment.isOver &&
        (!skillData.isTest ||
          (skillData.isTest && skillData.obscureResults === true)))
    ) {
      if (solveSkill?.sa?._id) clearAssignmentProblems(solveSkill.sa._id);
      navigate(backButtonUrl());
      // show modal

      // TODO: if unlimited test and endEarly was hit, change modal messaging
      setTimedModalShowing(true);
    }
  }, [timedAssignment]);

  // when a timed assignment ends, clear out the saved problems so it will
  // refetch with the solutions if student revisits
  const clearAssignmentProblems = (saId: number) => {
    const tempNextProblems = cloneDeep(studentNextProblems);
    delete tempNextProblems[saId];
    setStudentNextProblems(tempNextProblems);
  };

  const uniqueId =
    studentNextProblems[solveSkill?.sa?._id]?.[solveSkill?.ta?.skillName]
      ?.uniqueID || undefined;

  const backButtonUrl = () =>
    `${
      process.env.REACT_APP_STUDENT_LINK
    }/${activeSection}/${getAssignmentDueDateType(solveSkill?.sa?.status)}`;

  const problemSkillData = solveSkill?.ta?.skills[solveSkill?.ta?.skillName];

  const nextSectionClick = () => {
    const next = nextSection();
    if (next) {
      navigate(next);
    }
  };

  const nextSection = () => {
    const order = solveSkill?.ta?.order;
    const currentSkillName = solveSkill?.ta?.skillName;
    const index = order.indexOf(currentSkillName);
    if (index + 1 >= order.length) {
      return null;
    } else {
      const nextSkillName = order[index + 1];
      const uid = solveSkill?.ta?.skills[nextSkillName]?.uid;
      return `${process.env.REACT_APP_STUDENT_LINK}/${activeSection}/${solveSkill?.ta?._id}/${uid}`;
    }
  };

  const previousSectionClick = () => {
    const previous = previousSection();
    if (previous) {
      navigate(previous);
    }
  };

  const previousSection = () => {
    const order = solveSkill?.ta?.order;
    const currentSkillName = solveSkill?.ta?.skillName;
    const index = order.indexOf(currentSkillName);
    if (index - 1 < 0) {
      return null;
    } else {
      const previousSkillName = order[index - 1];
      const uid = solveSkill?.ta?.skills[previousSkillName]?.uid;
      return `${process.env.REACT_APP_STUDENT_LINK}/${activeSection}/${solveSkill?.ta?._id}/${uid}`;
    }
  };

  // give 'Next Problem' button focus when student answers correctly
  useEffect(() => {
    const interval = setTimeout(() => {
      if (currentProblem?.complete && skillData?.isCompleted) {
        nextSectionBtnRef?.current?.focus();
      } else if (currentProblem?.complete && currentProblem?.correct) {
        nextProblemBtnRef?.current?.focus();
      }
    }, 100);

    return () => clearTimeout(interval);
  }, [
    currentProblem.complete,
    currentProblem.correct,
    nextProblemBtnRef.current,
    nextSectionBtnRef.current,
  ]);

  const showResultsMutation = useShowResults(solveSkill?.ta?._id);

  const endEarlyMutation = useEndEarly(solveSkill?.ta?._id);

  const handleEndEarly = (taId: number) =>
    endEarlyMutation.mutate("", {
      onSuccess: (data) => {
        if (data?.data?.assignment) {
          updateFullAssignmentData(
            data.data.assignment,
            taId,
            activeSection,
            dmAssignmentData,
            setDmAssignmentData
          );
        }
      },
    });

  const handleShowSolutions = () => {
    if (solveSkill?.ta?._id === undefined) return;
    setSeeSolutionsModalShowing(true);
  };

  const handleShowSolutionsData = (taId: any, skillName?: string) => {
    const body = skillName ? JSON.stringify({ sk: skillName }) : "{}";
    showResultsMutation.mutate(body, {
      onSuccess: (data) => {
        if (data?.data?.assignment) {
          updateFullAssignmentData(
            data.data.assignment,
            taId,
            activeSection,
            dmAssignmentData,
            setDmAssignmentData
          );
        }
        // only the current skill's problem should be added to the assignment
        // the other skills should have their problems erased so they can be
        // refetched with all their results and/or solutions data
        if (data?.data?.problem) {
          setStudentNextProblems((prevState: any) => ({
            ...prevState,
            [solveSkill.sa._id]: {
              //  ...prevState[solveSkill.sa._id],
              [solveSkill.ta.skillName]: data.data.problem,
            },
          }));
        }
      },
    });
  };

  /* Update the state of still stuck button on example/video views */
  const updateStillStuck = () => {
    setCurrentProblemData((prevState: any) => ({
      ...prevState,
      stillStuck: true,
    }));
    setStudentNextProblems((prevState: any) => ({
      ...prevState,
      [solveSkill.sa._id]: {
        ...prevState[solveSkill.sa._id],
        [solveSkill.ta.skillName]: {
          ...prevState[solveSkill.sa._id]?.[solveSkill.ta.skillName],
          stillStuck: true,
        },
      },
    }));
  };

  ReactTooltip.rebuild();

  /* Button Visibility Switches */

  const showNextProblemButton = () =>
    !currentProblem.maxProblemsOneDone &&
    currentProblem.complete &&
    !skillData.isVideo;
  const showHelpVideoButton = () =>
    skillData?.helpVideoAvailable && !skillData.isVideo;
  const showSeeSolutionButton = () =>
    isForcedSkill ||
    (skillData.isStandardSkill &&
      !skillData.maxProblemsOneDone &&
      currentProblemData?.lines &&
      !currentProblem?.complete);
  const showSolutionsBtn =
    skillData.isTest &&
    !!skillData.obscureResults &&
    !isLocked &&
    (!!skillData.solutionsAvailable ||
      // show if a timed test runs out of time and was set to show results 'At test completion'
      // b/c solutionsAvailable will still be false until refetch or refresh of browser
      (timedAssignment?.isTimed &&
        solveSkill?.ta?.showSolutionsOnTimeOver === true &&
        timedAssignment?.isOver));
  const showEndEarlyButton =
    timedAssignment?.isTimed &&
    isLocked &&
    ((!timedAssignment?.isUnlimited &&
      solveSkill?.sa?.actuallyComplete === 100) ||
      (timedAssignment?.isUnlimited &&
        (timedAssignment?.isOverUnlimitedTime ||
          solveSkill?.sa?.actuallyComplete === 100)));
  const showExampleButton = () =>
    skillData.isStandardSkill &&
    skillData.type !== "teacher_created" &&
    problemSkillData?.show_example &&
    currentProblemData?.examples &&
    currentProblemData?.skillcode;
  const showPreviousSection = () =>
    (skillData?.isCompleted || currentProblem.maxProblemsOneDone) &&
    previousSection();
  const showNextSection = () =>
    (skillData?.isCompleted || currentProblem.maxProblemsOneDone) &&
    nextSection();

  return (
    <BrailleConversionWrapper>
      <div className="flex-grow overflow-auto sm:p-8 lg:basis-3/4">
        <div
          ref={scoreHeader}
          className="flex flex-col gap-y-3 px-3 pb-4 pt-3 sm:px-0 sm:pb-8 sm:pt-0 md:gap-y-6"
        >
          {/* Assignment Title Row */}
          <div className="order-1 flex min-w-0 flex-1 basis-full flex-nowrap items-center gap-x-1 md:gap-x-3">
            <h1 className="truncate text-2xl font-bold leading-normal text-gray-900">
              {problemSkillData?.name}
            </h1>
            {uniqueId ? (
              <span className="ml-1.5 inline-flex min-w-[71px] flex-col items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                <span className="font-bold text-green-800">Unique ID</span>
                {uniqueId}
              </span>
            ) : null}
            {timedAssignment?.isTimed && timedAssignment?.endTime ? (
              <>
                <div
                  className="shrink-0 grow text-right"
                  aria-live="assertive"
                  aria-relevant="text"
                >
                  {timedAssignment?.isUnlimited ? (
                    <span
                      className={clsx(
                        "inline-block border-2 border-dotted border-rose-400 p-2 text-xs"
                      )}
                    >
                      Unlimited Time
                    </span>
                  ) : (
                    <>
                      <span
                        role="timer"
                        className={clsx(
                          "inline-block border-2 border-dotted border-rose-400 p-2 text-xs",
                          timedAssignment?.isOver ? "text-rose-800" : null
                        )}
                      >
                        <Countdown targetTime={timedAssignment.endTime} />
                      </span>
                      <div role="alert" className="sr-only">
                        {timedAssignment?.timerText}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : null}
          </div>
          {/* Button Row */}
          <div className="flex items-center gap-1 sm:gap-2">
            <div className="grow">
              {!isLocked && (
                <NavLink
                  to={backButtonUrl()}
                  onClick={() => setOpenAssignment(solveSkill?.ta?._id)}
                  className="inline-flex min-w-0 flex-nowrap items-center justify-between gap-x-1 rounded-md border-2 border-gray-300 px-1 py-1 text-sm text-gray-600 hover:bg-gray-200 md:px-3 md:py-2 md:text-base"
                >
                  <ArrowLeftIcon className="h-4 w-4" />
                  Back
                </NavLink>
              )}
            </div>

            {/* Standard Buttons */}

            {showHelpVideoButton() ? (
              <button
                className={clsx(
                  "inline-flex items-center gap-x-1 rounded-md border-2 border-gray-300 px-1 py-1 text-sm md:px-3 md:py-2 md:text-base",
                  showVideo ? "bg-gray-600 text-white" : "text-gray-600"
                )}
                onClick={() => {
                  setShowVideo(!showVideo);
                  setShowSolution(false);
                  // if a student watches a video, the Still Stuck? button shows for that problem
                  updateStillStuck();
                }}
              >
                <PlayIcon
                  className={clsx(
                    "h-4 w-4 shrink-0",
                    showVideo ? "text-white" : "text-red-500"
                  )}
                />
                {showVideo ? "Hide Help Video" : "Watch Help Video"}
              </button>
            ) : null}
            {showSeeSolutionButton() ? (
              <button
                className={clsx(
                  "rounded-md border-2 border-gray-300 px-1 py-1 text-sm md:px-3 md:py-2 md:text-base",
                  showSolution ? "bg-gray-600 text-white" : "text-gray-600"
                )}
                onClick={() => {
                  setShowSolution(!showSolution);
                  setShowSolutionForced(!showSolutionForced);
                  setShowExample(false);
                }}
              >
                See Solution
              </button>
            ) : null}

            {/* Has Example */}
            {showExampleButton() ? (
              <button
                className={clsx(
                  "rounded-md border-2 border-gray-300 px-1 py-1 text-sm md:px-3 md:py-2 md:text-base",
                  showExample ? "bg-gray-600 text-white" : "text-gray-600"
                )}
                onClick={() => {
                  setShowExample(!showExample);
                  setShowSolution(false);
                  // if a student views an example, the Still Stuck? button shows for that problem
                  updateStillStuck();
                }}
              >
                Show Example
              </button>
            ) : null}
            {showEndEarlyButton ? (
              <button
                className={clsx(
                  "rounded-md border-2 border-gray-300 px-1 py-1 text-sm text-gray-600 md:px-3 md:py-2 md:text-base"
                )}
                onClick={() => handleEndEarly(solveSkill?.ta?._id)}
              >
                End Test Early
              </button>
            ) : null}
            {showSolutionsBtn ? (
              <>
                <button
                  className={clsx(
                    "inline-flex min-w-0 flex-nowrap items-center justify-between rounded-md border-2 border-violet-700 px-1 py-1 text-xs text-gray-600 hover:bg-gray-200 md:px-3 md:py-2 md:text-sm"
                  )}
                  onClick={() => handleShowSolutions()}
                >
                  {skillData.showSolutionsSetting &&
                  skillData.showSolutionsSetting === "never"
                    ? "show results"
                    : "show solutions"}
                </button>
                <SeeSolutionsModal
                  isShowing={isSeeSolutionsModalShowing}
                  setIsShowing={setSeeSolutionsModalShowing}
                  handleShowSolutionsData={handleShowSolutionsData}
                  taId={solveSkill?.ta?._id}
                  skill={solveSkill?.ta?.skillName}
                />
              </>
            ) : null}

            {/* Problem Answered & Solution Showing */}
            {showNextProblemButton() ? (
              <>
                <button
                  ref={nextProblemBtnRef}
                  className={clsx(
                    "rounded-md border-2 border-gray-300 bg-gray-600 px-1 py-1 text-sm text-white hover:bg-gray-800 focus:border-blue-800 md:px-3 md:py-2 md:text-base"
                  )}
                  onClick={() => setShowNextProblem(true)}
                >
                  {skillData?.isCompleted
                    ? "Additional Practice"
                    : "Next Problem"}
                </button>
              </>
            ) : null}
            {showPreviousSection() ? (
              <>
                <button
                  className={clsx(
                    !previousSection()
                      ? "cursor-not-allowed bg-slate-500 hover:bg-slate-500"
                      : "hover:bg-gray-800",
                    "inline-flex items-center rounded-md border-2 border-gray-300 bg-gray-600 px-1 py-1 text-sm text-white focus:border-blue-800 md:px-3 md:py-2 md:text-base"
                  )}
                  disabled={!previousSection()}
                  onClick={() => previousSectionClick()}
                >
                  <ChevronLeftIcon className="h-5 w-5 shrink-0" /> Previous
                  Section
                </button>
              </>
            ) : null}
            {showNextSection() ? (
              <>
                <button
                  ref={nextSectionBtnRef}
                  className={clsx(
                    !nextSection()
                      ? "cursor-not-allowed bg-slate-500 hover:bg-slate-500"
                      : "hover:bg-gray-800",
                    "inline-flex items-center rounded-md border-2 border-gray-300 bg-gray-600 px-1 py-1 text-sm text-white focus:border-blue-800 md:px-3 md:py-2 md:text-base"
                  )}
                  disabled={!nextSection()}
                  onClick={() => nextSectionClick()}
                >
                  Next Section <ChevronRightIcon className="h-5 w-5 shrink-0" />
                </button>
              </>
            ) : null}
          </div>
        </div>
        <main id="main-content" role="main">
          {!skillData.isVideo && !skillData.isTest && (
            <div className="flex items-center gap-x-6 bg-white px-5 py-2.5 shadow-md">
              <div className="flex-row">
                <strong>
                  Score: {skillData?.scoreOnSolve || skillData?.completion}
                </strong>
                {solveSkill?.ta?.currentSkill?.penalty !== undefined ? (
                  <>
                    <br />
                    Penalty: {getPenalty(solveSkill?.ta?.currentSkill?.penalty)}
                  </>
                ) : null}
              </div>
              <div className="grow">
                <ProgressBar
                  totalSegments={skillData?.progress?.total}
                  currentScore={skillData?.progress?.score}
                  currentRecord={skillData?.progress?.record}
                  showTickMarks={skillData?.progress?.showSegments}
                  percentCompleted={skillData?.percentCompleted}
                  solvePage={true}
                />
              </div>
            </div>
          )}
          {showVideo && solveSkill && <HelpVideo solveSkill={solveSkill} />}
          {skillData.isTimedModule && (
            <TimedModule
              key={solveSkill.ta.skillName}
              solveSkill={solveSkill}
            />
          )}
          {skillData.isVideo && <HelpVideo solveSkill={solveSkill} />}
          {skillData.isStandardSkill && (
            <StandardSkills
              currentProblem={currentProblem}
              setCurrentProblem={setCurrentProblem}
              showSolution={showSolution}
              setShowSolution={setShowSolution}
              showSolutionForced={showSolutionForced}
              showExample={showExample}
              setShowExample={setShowExample}
              setShowVideo={setShowVideo}
              showNextProblem={showNextProblem}
              setShowNextProblem={setShowNextProblem}
              scoreHeader={scoreHeader}
            />
          )}
        </main>
        <DevOptions />
        <ExamplesModal
          skillName={currentProblemData?.skillcode}
          isShowing={showExample}
          setIsShowing={setShowExample}
          typesSelected={solveSkill?.ta?.currentSkill?.typesSelected}
        />
      </div>
    </BrailleConversionWrapper>
  );
}
