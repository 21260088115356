import React, { useContext, useEffect, useRef, useState } from "react";
import { useDMQuery } from "../../utils";
import axios, { AxiosRequestConfig } from "axios";
import jwt_decode from "jwt-decode";

interface UserApi {
  setJWT(jwt: string): void;
  getJWT(): string;
  clearJWT(): void;
  getIsLocked(): boolean;
  getTAid(): number | undefined;
}

type UserState = {
  jwt?: string;
  lastUpdated?: Date;
  decodedJwt?: any;
};

export class UserHolder implements UserApi {
  private readonly state: UserState;
  private setState: (state: Partial<UserState>) => void;

  setJWT(jwt: string): void {
    this.setState({ jwt, decodedJwt: jwt_decode(jwt) });
  }
  getJWT(): string {
    return this.state.jwt ?? "";
  }
  clearJWT() {
    this.setState({ jwt: undefined, decodedJwt: undefined });
  }

  getIsLocked() {
    // lock object isn't in JWT
    if (typeof this.state?.decodedJwt?.data?.lock !== "object") return false;

    // if exp time has passed, override lock
    if (this.state.decodedJwt?.data?.lock?.exp * 1000 < new Date().getTime())
      return false;

    return true;
  }

  getTAid() {
    return this.state.decodedJwt?.data?.lock?.taId;
  }

  constructor(state: UserState, setState: (state: Partial<UserState>) => void) {
    this.state = state;
    this.setState = setState;
  }
}

const UserContext = React.createContext<UserHolder>(null!);

export default UserContext;

export function UserContextProvider({ children }: { children: any }) {
  const [state, setState] = useState<UserState>({});

  const saveState = (partialState: Partial<UserState>) => {
    setState({ ...state, ...partialState });
  };

  const jwtRef = useRef(state.jwt);
  jwtRef.current = state.jwt ?? "";

  axios.defaults.headers.common["Authorization"] = `Bearer ${jwtRef.current}`;
  axios.defaults.withCredentials = true;
  // axios.defaults.headers.post["Content-Type"] = "application/json";

  axios.interceptors.request.use(
    (request) => {
      // Edit request config
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      // console.log(response);
      // Edit response config
      if (response.headers["x-deltamath-new-token"]) {
        saveState({
          jwt: response.headers["x-deltamath-new-token"],
          decodedJwt: jwt_decode(response.headers["x-deltamath-new-token"]),
        });
      }
      if (
        response.data.error ===
        "You must sign into deltamath.com to renew your authentication token."
      ) {
        window.location.reload();
      }

      return response;
    },
    (error) => {
      if (
        error.response &&
        error.response.status &&
        error.response.status === 500
      ) {
        saveState({ jwt: undefined, decodedJwt: undefined });
        localStorage.removeItem("admin");
        localStorage.removeItem("user");
      }
      return Promise.reject(error);
    }
  );

  return (
    <UserContext.Provider value={new UserHolder(state, saveState)}>
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
