import { render } from "@headlessui/react/dist/utils/render";
import { useEffect, useRef, useState } from "react";

interface AccordionProps {
  title: React.ReactNode;
  content: React.ReactNode;
  showExpand: boolean;
  renderExpanded?: boolean;
}

const DeltaMathAccordion: React.FC<AccordionProps> = ({
  title,
  content,
  showExpand,
  renderExpanded,
}) => {
  const [active, setActive] = useState(false);
  const [height, setHeight] = useState("0px");
  const [rotate, setRotate] = useState(
    renderExpanded
      ? "transform duration-700 ease"
      : "transform duration-700 ease rotate-90"
  );

  const contentSpace = useRef<any>(null);

  function toggleAccordion() {
    setActive((prevState) => !prevState);
    setHeight(active ? "0px" : `${contentSpace.current.scrollHeight}px`);
    setRotate(
      active
        ? "transform duration-700 ease"
        : "transform duration-700 ease rotate-90"
    );
  }

  useEffect(() => {
    renderExpanded && toggleAccordion();
  }, []);

  return (
    <div className="flex flex-col">
      {showExpand && (
        <button
          className="box-border flex cursor-pointer appearance-none py-4 focus:outline-none"
          onClick={toggleAccordion}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${rotate} mr-2 inline-block h-6 w-6`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4v16m8-8H4"
            />
          </svg>
          {title}
        </button>
      )}
      {!showExpand && (
        <div className="box-border flex appearance-none py-4 focus:outline-none">
          {title}
        </div>
      )}
      <div
        ref={contentSpace}
        style={{ height: `${height}` }}
        className="transition-height overflow-auto duration-700 ease-in-out"
      >
        <div className="pb-6">{content}</div>
      </div>
    </div>
  );
};

export default DeltaMathAccordion;
