import clsx from "clsx";
import DMKey from "./DMKey";

const FunctionsKeyboard = ({
  shift,
  showRad,
  input,
  focusedInput,
  showFuncs,
  showFrac,
  setShowFuncs,
  setShift,
  setShowNumeric,
  setShowRad,
  setShowFrac,
}: {
  shift: boolean;
  showRad: boolean;
  input: any;
  focusedInput: any;
  showFuncs: boolean;
  showFrac: boolean;
  setShowFuncs: (state: boolean) => void;
  setShift: (state: boolean) => void;
  setShowNumeric: (state: boolean) => void;
  setShowRad: (state: boolean) => void;
  setShowFrac: (state: boolean) => void;
}) => {
  return (
    <>
      <div className="col col-span-1 space-y-1">
        <div className="grid grid-cols-4 space-x-1">
          <DMKey
            btnText={"\\log"}
            btnOutput="log"
            addParens={true}
            ariaLabel={"log"}
            focusedInput={focusedInput}
            inputsRef={input}
            className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
          />
          <DMKey
            btnText={"\\ln"}
            addParens={true}
            ariaLabel={"natural log"}
            focusedInput={focusedInput}
            inputsRef={input}
            className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
          />
        </div>
        <div className="grid grid-cols-4 space-x-1">
          <DMKey
            btnText={"nCr"}
            ansType={"icon"}
            btnOutput={"\\text{nCr}\\left({},{}\\right)"}
            ariaLabel={"combination"}
            focusedInput={focusedInput}
            inputsRef={input}
            className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
          />
          <DMKey
            btnText={"nPr"}
            ansType={"icon"}
            btnOutput={"\\text{nPr}\\left({},{}\\right)"}
            ariaLabel={"permutation"}
            focusedInput={focusedInput}
            inputsRef={input}
            className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
          />
        </div>
        <div className="grid grid-cols-4 space-x-1">
          <button
            onClick={() => setShowFuncs(false)}
            aria-label="back"
            className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
          >
            Back
          </button>
          <button
            aria-label="fraction"
            className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            onClick={() => setShowFrac(!showFrac)}
          >
            Frac
          </button>
        </div>
        <div className="col col-span-2 space-y-1">
          <div className="grid grid-cols-4 space-x-1">
            <button
              onClick={() => {
                setShift(!shift);
                setShowFuncs(!showFuncs);
              }}
              aria-label="shift"
              className="border-1 col col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            >
              SHIFT
            </button>
            <button
              onClick={() => {
                setShowFuncs(false);
                setShowNumeric(false);
              }}
              aria-label="show-alphabetical-keyboard"
              className="border-1 col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            >
              ABC
            </button>
          </div>
        </div>
      </div>
      <div className="col col-span-1 space-y-1">
        <div className="col col-span-2 space-y-1">
          <div className="grid grid-cols-4 space-x-1">
            <DMKey
              btnText={"\\sin"}
              addParens={true}
              ariaLabel="sine"
              focusedInput={focusedInput}
              inputsRef={input}
              className="border-1 col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            />
            <DMKey
              btnText={"\\cos"}
              addParens={true}
              ariaLabel="cosine"
              focusedInput={focusedInput}
              inputsRef={input}
              className="border-1 col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            />
          </div>
          <div className="grid grid-cols-4 space-x-1">
            <DMKey
              btnText={"\\sin^{-1}"}
              addParens={true}
              ariaLabel={"inverse sine"}
              focusedInput={focusedInput}
              inputsRef={input}
              className="border-1 col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            />
            <DMKey
              btnText={"\\cos^{-1}"}
              addParens={true}
              ariaLabel={"inverse cosine"}
              focusedInput={focusedInput}
              inputsRef={input}
              className="border-1 col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            />
          </div>
          <div className="grid grid-cols-4 space-x-1">
            <DMKey
              btnText={"\\tan"}
              addParens={true}
              ariaLabel={"tangent"}
              focusedInput={focusedInput}
              inputsRef={input}
              className="border-1 col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            />
            <button
              className={clsx(
                showRad ? "bg-green-300" : "bg-gray-300",
                "border-1 col-span-2 rounded-md border-black p-2 hover:bg-gray-400"
              )}
              aria-label="radians"
              onClick={() => setShowRad(true)}
            >
              Radian
            </button>
          </div>
          <div className="grid grid-cols-4 space-x-1">
            <DMKey
              btnText={"\\tan^{-1}"}
              addParens={true}
              focusedInput={focusedInput}
              inputsRef={input}
              ariaLabel={"inverse tangent"}
              className="border-1 col-span-2 rounded-md border-black bg-gray-300 p-2 hover:bg-gray-400"
            />
            <button
              className={clsx(
                showRad ? "bg-gray-300" : "bg-green-300",
                "border-1 col-span-2 rounded-md border-black p-2 hover:bg-gray-400"
              )}
              aria-label="degrees"
              onClick={() => setShowRad(false)}
            >
              Degree
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FunctionsKeyboard;
