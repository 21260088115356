import { Dispatch, SetStateAction, memo } from "react";
import ProfileDropdown from "../components/ProfileDropdown";
import { MenuIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import { getFilePath } from "../../utils";

type Props = {
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

const NavBar = ({ setSidebarOpen }: Props): JSX.Element => {
  return (
    <nav className="z-[39]">
      <div className="mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-14 items-center justify-between lg:hidden">
          <button
            type="button"
            className="mr-2 px-4 text-white focus:outline-none lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" color="#9CA3AF" />
          </button>
          <div className="flex flex-1">
            <NavLink
              key="topnavlogo"
              to={`${process.env.REACT_APP_STUDENT_LINK}`}
            >
              <img
                className="block max-h-6 w-auto"
                src={getFilePath("/images/DeltaMath-Logo_Blue.png")}
                alt="DeltaMath Home"
              />
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default memo(NavBar);
