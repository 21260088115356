import { useEffect, useState, useRef, useMemo } from "react";
import NavBar from "./layout/NavBar";
import Sidebar from "./layout/Sidebar";
import Main from "./layout/Main";
import Solve from "./layout/Solve";
import Link from "./layout/Link";
import Cookies from "js-cookie";
import { isRequestFromReviewapp, isTokenExpired, useDMQuery } from "../utils";
import { useQueryClient } from "react-query";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import StudentSectionsContext from "./_context/StudentSectionsContext";
import Loading from "./components/Loading";
import {
  getActiveSectionData,
  processAssignmentData,
  findNearestUpcoming,
} from "./utils";
import Profile from "./components/Profile";
import Login from "../manager/components/Login";
import "./index.css";
import { renderA11yString } from "./utils/render-a11y-string";
import CodeModules from "./components/code-modules/code-modules";
import TimedModal from "./components/TimedModal";
import { WindowContextProvider } from "../shared/contexts/WindowContext";
import DMKeyboard from "./components/calculator/DMKeyboard";
import AddStudentId from "./components/AddStudentId";
import { useUserContext } from "../shared/contexts/UserContext";
import ReactTooltip from "react-tooltip";

type Props = {
  section?: string;
};

/* Custom Hook for Managing Input Field Focus */
function useFieldFocus(initialState = "") {
  const [globalFocusedInput, setGlobalFocusedInput] = useState(initialState);

  const handlers = useMemo(
    () => ({
      handleGlobalFocus: (mqID: any): void => {
        setGlobalFocusedInput(mqID);
      },
    }),
    []
  );

  return [globalFocusedInput, handlers] as const;
}

export default function Student({ section = "main" }: Props): JSX.Element {
  const queryClient = useQueryClient();
  const userContext = useUserContext();

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [dmSectionsData, setDmSectionsData] = useState<any>([]);
  const [dmAssignmentData, setDmAssignmentData] = useState<any>({});
  const customExternalFiles = useRef<Map<string, any>>(new Map());
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [showCalculator, setShowCalculator] = useState(false); // determines whether to render calculator or not
  const [calcAvailable, setCalcAvailable] = useState(true);
  const [globalInputsMap, setGlobalInputsMap] = useState<Map<string, any>>(
    new Map()
  );
  const [globalFocusedInput, { handleGlobalFocus }] = useFieldFocus("");

  const [exampleData, setExampleData] = useState<object>({}); // skillname: {numExamples, currentExampleIndex, problems[], range:{min, max}}
  const [loadingData, setLoadingData] = useState<any>({
    error: false,
    isShowing: true,
    title: "Error",
    message: "You encountered an error",
  });

  const [userValues, setUserValues] = useState<any>({
    hasPlus: false,
  });

  const [openAssignment, setOpenAssignment] = useState<any>(null);

  const [isMfeLoaded, setIsMfeLoaded] = useState<boolean>(false);

  const { sectionId: activeSection, teacherId } = useParams();

  const [studentNextProblems, setStudentNextProblems] = useState<
    Record<number, any>
  >({});

  const [currentProblemData, setCurrentProblemData] = useState<any>(undefined);

  const [isTimedModalShowing, setTimedModalShowing] = useState<boolean>(false);

  const [showPastDue, setShowPastDue] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string | null>(null);

  useEffect(() => {
    document.title = "DeltaMath Student Application";
    document.body.classList.add("h-full");
    const html = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
    html?.setAttribute("class", "h-full bg-gray-100");
    const root = document.getElementById("root");
    root?.classList.add("h-full");
  }, []);

  useEffect(() => {
    if (activeSection) {
      const section = dmSectionsData.find(
        (section: any) => parseInt(activeSection) === section._id
      );
      if (section && section.calculator === false) {
        setCalcAvailable(false);
      } else {
        setCalcAvailable(true);
      }
    } else {
      setCalcAvailable(false);
    }
  }, [activeSection]);

  const logout = () => {
    queryClient.invalidateQueries();
    userContext.clearJWT();
    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    setLoggedIn(false);
  };

  const { refetch: sectionsRefresh } = useDMQuery({
    path: "/student/data/sections",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDmSectionsData(data);
        // setLoadingData({ ...loadingData, isShowing: false });
      },
      onError: (error: any) => {
        if (error.code === 403) {
          logout();
        }
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Error",
          message: `${error?.response?.data?.message || error?.error || ""} ${
            prevState.message || ""
          }`,
        }));
      },
    },
  });

  const { refetch: assignmentsRefresh } = useDMQuery({
    path: "/student/data/assignments",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDmAssignmentData({
          ...dmAssignmentData,
          ...processAssignmentData(data),
        });
        setLoadingData({ ...loadingData, isShowing: false });
      },
      onError: (error: any) => {
        if (error.code === 403) {
          logout();
        }
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Error",
          message: `${
            error?.response?.data?.message ||
            error?.error ||
            error?.message ||
            ""
          } ${prevState.message || ""}`,
        }));
      },
    },
  });

  const { refetch: archivedSectionRefetch } = useDMQuery({
    path: `/student/data/assignments/?secId=${activeSection}`,
    cacheKey: ["/student/data/assignments", `${activeSection}`],
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false,
      onSuccess: (data: any) => {
        setDmAssignmentData({
          ...dmAssignmentData,
          ...processAssignmentData(data),
        });
        setLoadingData({ ...loadingData, isShowing: false });
      },
      onError: (error: any) => {
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Error",
          message: `${error?.response?.data?.message || error?.error || ""} ${
            prevState.message || ""
          }`,
        }));
      },
    },
  });

  // useEffect(() => {
  //   console.log("dmAssignmentData", dmAssignmentData);
  // }, [dmAssignmentData]);

  // useEffect(() => {
  //   console.log("activeSection", activeSection);
  // }, [activeSection]);

  useEffect(() => {
    const jwt = userContext.getJWT();
    const refresh_token_cookie = Cookies.get("refresh_token_javascript");
    // User has non-expired JWT or refresh token cookie
    if (
      (jwt && !isTokenExpired(jwt)) ||
      refresh_token_cookie ||
      isRequestFromReviewapp(window.location.origin)
    ) {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      // Set them as logged in
      if (user?.login) {
        setLoadingData({ ...loadingData, isShowing: true });
        sectionsRefresh();
        assignmentsRefresh();
        setLoggedIn(true);
      } else {
        // Log them out
        queryClient.invalidateQueries();
        userContext.clearJWT();
        localStorage.removeItem("admin");
        localStorage.removeItem("user");

        if (!isRequestFromReviewapp(window.location.origin)) {
          window.location.href = `${process.env.REACT_APP_HOMEPAGE_LINK}`;
        }
      }
    }
  }, [loggedIn]);

  const isLocked = userContext.getIsLocked();

  // if assignment is locked/restricted, force student into that assignment
  useEffect(() => {
    const jwtTeacherId = userContext.getTAid()?.toString();
    if (isLocked && teacherId !== jwtTeacherId) {
      navigate(`${process.env.REACT_APP_STUDENT_LINK}/link/${jwtTeacherId}`);
    }
  }, [isLocked, teacherId]);

  // if user is at default /student page, redirect them to
  // the section with their first upcoming assignment
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      typeof activeSection === "undefined" &&
      Array.isArray(dmSectionsData) &&
      dmSectionsData.length > 0 &&
      Object.keys(dmAssignmentData).length > 0 &&
      !location.pathname.includes("profile") &&
      !location.pathname.includes("code-modules") &&
      section !== "link"
    ) {
      navigate(
        `${process.env.REACT_APP_STUDENT_LINK}/${
          findNearestUpcoming(dmAssignmentData) ?? dmSectionsData[0]?._id
        }/upcoming`,
        { replace: true }
      );
    }
  }, [activeSection, dmSectionsData, dmAssignmentData]);

  // wait for the initial assignment data to be sorted before checking if the activeSection
  // exists in the list, and if not, call the archived endpoint
  useEffect(() => {
    if (!Object.keys(dmAssignmentData).length || section === "link") {
      return;
    } else if (
      activeSection &&
      dmAssignmentData &&
      !(activeSection in dmAssignmentData) &&
      Object.keys(dmSectionsData).length &&
      getActiveSectionData(activeSection, dmSectionsData)?.term !== "current" &&
      !location.pathname.includes("profile") &&
      !location.pathname.includes("code-modules")
    ) {
      // if activeSection exists, activeSection is not in dmAssignmentData yet, and
      // activeSession is not 'current' in dmSectionsData, it must be an archived assignment
      // we have not fetched yet
      setLoadingData({ ...loadingData, isShowing: true });
      archivedSectionRefetch();
    }
  }, [dmAssignmentData, activeSection, dmSectionsData]);

  // Check if user has Plus account
  useEffect(() => {
    if (activeSection && Object.keys(dmSectionsData).length) {
      setUserValues({
        ...userValues,
        hasPlus:
          getActiveSectionData(activeSection, dmSectionsData)?.has_plus ||
          false,
      });
    }
  }, [activeSection, dmSectionsData]);

  window.renderA11yString = renderA11yString;

  ReactTooltip.rebuild();

  return (
    <>
      {!loggedIn && <Login setLoggedIn={setLoggedIn} />}
      {loggedIn && dmSectionsData && Object.keys(dmAssignmentData).length ? (
        <StudentSectionsContext.Provider
          value={{
            dmSectionsData,
            activeSection,
            setLoadingData,
            assignmentsRefresh,
            sectionsRefresh,
            dmAssignmentData,
            setDmAssignmentData,
            openAssignment,
            setOpenAssignment,
            userValues,
            isMfeLoaded,
            setIsMfeLoaded,
            studentNextProblems,
            setStudentNextProblems,
            setTimedModalShowing,
            customExternalFiles,
            showKeyboard,
            setShowKeyboard,
            showCalculator,
            setShowCalculator,
            globalInputsMap,
            setGlobalInputsMap,
            globalFocusedInput,
            handleGlobalFocus,
            calcAvailable,
            setCalcAvailable,
            currentProblemData,
            setCurrentProblemData,
            exampleData,
            setExampleData,
            showPastDue,
            setShowPastDue,
            setTooltipText,
            tooltipText,
          }}
        >
          <WindowContextProvider>
            {loggedIn && section !== "link" && (
              <div className="flex h-full flex-col">
                <>
                  <a
                    className="skipToMainContent bg-white text-dm-blue hover:underline"
                    href="#main-content"
                  >
                    Skip to main content
                  </a>
                  <NavBar setSidebarOpen={setSidebarOpen} />
                  <div className="flex flex-grow flex-row bg-gray-100">
                    <Sidebar
                      sidebarOpen={sidebarOpen}
                      setSidebarOpen={setSidebarOpen}
                      isSolve={section === "solve"}
                    />
                    {window.location.href.includes("profile") ? (
                      <Profile />
                    ) : window.location.href.includes("code-modules") ? (
                      <CodeModules />
                    ) : section !== "solve" ? (
                      <Main />
                    ) : (
                      <Solve />
                    )}
                    {showKeyboard && (
                      <DMKeyboard
                        close={() => setShowKeyboard(false)}
                        focusedInput={globalFocusedInput}
                        input={globalInputsMap}
                        showCalculator={showCalculator}
                      />
                    )}
                  </div>
                </>
              </div>
            )}
            {loggedIn && section === "link" && (
              <div className="sm:px-auto fixed inset-0 flex flex-col items-center justify-center bg-gray-600 px-2">
                <Link assignments={dmAssignmentData} />
              </div>
            )}
            <AddStudentId />
            <ReactTooltip
              id="piechart-app"
              effect="solid"
              delayShow={100}
              delayHide={50}
              multiline={true}
              html={true}
              place="right"
              getContent={() => tooltipText}
            />
            <ReactTooltip
              id="asstProgress"
              className="max-w-[80%] text-center"
              effect="solid"
              delayShow={100}
              delayHide={50}
              multiline={true}
              html={true}
              place="top"
              getContent={(dataTip) => dataTip}
            />
          </WindowContextProvider>
        </StudentSectionsContext.Provider>
      ) : null}
      {loggedIn && <Loading {...loadingData} setLoadingData={setLoadingData} />}
      <TimedModal
        isShowing={isTimedModalShowing}
        setTimedModalShowing={setTimedModalShowing}
        setLoadingData={setLoadingData}
      />
    </>
  );
}
