import { elementScroll } from "@tanstack/react-virtual";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { useDMQuery } from "../../utils";
import { demoString } from "../../utils/demo";

export default function View() {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [tableData, setTableData] = React.useState<any>([]);

  const { data: pushedAssignmentData } = useDMQuery({
    path: "/admin_new/data/pushed-assignments",
  });

  const { data: sectionData } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });

  useEffect(() => {
    if (sectionData && pushedAssignmentData) {
      const finalData: any[] = [];
      pushedAssignmentData.forEach((assignment: any) => {
        const flatTeachers = assignment.sectionList
          .map((section: any) => {
            const id = section;
            const match = sectionData.find(
              (section: any) => section.sectionId === id
            );
            if (match?.teachers) {
              return match.teachers;
            }
          })
          .filter((e: any) => !!e)
          .flat();

        if (flatTeachers) {
          const uniqueTeachers = flatTeachers
            .filter(
              (teacher: any, index: number, self: any) =>
                index ===
                self.findIndex(
                  (t: any) => t._id === teacher._id && t.name === teacher.name
                )
            )
            .map((t: any) => t.name); // we just need the name

          finalData.push({
            ...assignment,
            teachers: uniqueTeachers,
          });
        }
      });
      setTableData(finalData);
    }
  }, [pushedAssignmentData, sectionData]);

  const teachersTableColumns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: (props: any) => {
        return (
          user.teachercode &&
          props.row.original._id && (
            <Link
              className="underline hover:no-underline"
              to={`${process.env.REACT_APP_ADMIN_LINK}/teacher/${user.teachercode}/assignment/${props.row.original._id}`}
            >
              {props.row.original.name}
            </Link>
          )
        );
      },
    },
    {
      Header: "Teachers",
      accessor: "teachers",
      Cell: (props: any) =>
        props.value.length > 0
          ? props.value
              .map((teacher: string) =>
                demoString({ value: teacher, type: "person_full" })
              )
              .join(", ")
          : "-",
      align: "left",
    },
    {
      Header: "Edit Sections/Dates",
      accessor: "",
      Cell: (props: any) => {
        return (
          user.teachercode &&
          props.row.original._id && (
            <Link
              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              to={`${process.env.REACT_APP_ADMIN_LINK}/push-assignment/${props.row.original._id}`}
            >
              Edit Sections/Dates
            </Link>
          )
        );
      },
      align: "center",
    },
    {
      Header: "Edit Content",
      accessor: "_id",
      Cell: (props: { value: any }) => {
        return (
          user.teachercode &&
          props.value && (
            <a
              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              target="_blank"
              href={`${process.env.REACT_APP_TEACHER_LINK}?edit=${props.value}`}
            >
              Edit Content
            </a>
          )
        );
      },
      align: "center",
    },
    {
      Header: "Last Edit",
      accessor: "last_edit",
      Cell: (props: any) => {
        const { last_edit } = props.row.original;
        return last_edit
          ? new Date(last_edit * 1000).toLocaleDateString()
          : "-";
      },
      align: "right",
    },
  ];

  return (
    <>
      <div className="mt-5 md:col-span-6 md:mt-0">
        <h3 className="my-4 border-b text-lg font-medium leading-6 text-gray-900">
          View Pushed Assignments
        </h3>
      </div>

      {tableData && tableData.length > 0 ? (
        <div className="flow-root">
          <DeltaMathTable columns={teachersTableColumns} data={tableData} />
        </div>
      ) : (
        <p>
          You haven't pushed any assignments yet. You can{" "}
          <Link
            className="underline hover:no-underline"
            to={`${process.env.REACT_APP_ADMIN_LINK}/push-assignment`}
          >
            push an assignment here.
          </Link>
        </p>
      )}
    </>
  );
}
