import { printClockSentence } from "../utils";
import { useState, useRef } from "react";

type Props = {
  targetTime: number;
};
export default function Countdown({ targetTime }: Props): JSX.Element {
  const countDownDate = new Date(targetTime * 1000).getTime();

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const [timerText, setTimerText] = useState(
    printClockSentence(countDownDate - new Date().getTime())
  );

  if (intervalRef.current) clearInterval(intervalRef.current);

  intervalRef.current = setInterval(() => {
    const nowTime = new Date().getTime();
    setTimerText(printClockSentence(countDownDate - nowTime));

    if (countDownDate - nowTime <= 0 && intervalRef?.current)
      clearInterval(intervalRef.current);
  }, 1000);

  const nothingLeft = timerText.toLowerCase() === "no time remaining";

  if (nothingLeft) {
    return (
      <div className="whitespace-nowrap text-dm-error-500">
        <span className="font-bold">No Time Remaining</span>
        <span className="hidden text-dm-charcoal-500 sm:inline"> |</span>
      </div>
    );
  }

  return (
    <div className="whitespace-nowrap text-dm-error-500">
      <span className="font-bold">Time Remaining: </span>
      {timerText}
      <span className="hidden text-dm-charcoal-500 sm:inline"> |</span>
    </div>
  );
}
