// import { useRef, useEffect, Fragment } from "react";
import clsx from "clsx";
import ReactTooltip from "react-tooltip";

type Props = {
  totalSegments?: number;
  currentScore?: number;
  currentRecord?: number;
  showTickMarks?: boolean;
  percentCompleted?: number;
  solvePage?: boolean;
  tooltipEnabled?: boolean;
};

export default function ProgressBar({
  totalSegments = 5,
  currentScore,
  currentRecord,
  showTickMarks,
  percentCompleted,
  solvePage = false,
  tooltipEnabled = true,
}: Props): JSX.Element {
  // const recordSegment = useRef(null);

  const renderSegments = () => {
    const segmentsCombined = [];

    for (let i = 0; i < totalSegments; i++) {
      // const showSolveTooltip = (): boolean =>
      //   !!tooltipEnabled &&
      //   !!solvePage &&
      //   !!currentRecord &&
      //   currentScore !== undefined &&
      //   currentRecord > currentScore &&
      //   currentRecord == i + 1;

      // const segmentOptions = () => {
      //   return {
      //     "data-tip": solveTooltipCopy(),
      //     "data-for": "progressbar-segment",
      //     "data-effect": "solid",
      //     "data-place": "top",
      //     "data-event": "",
      //     "data-event-off": "",
      //     "data-delay-hide": 300,
      //     "data-delay-show": 300,
      //     "data-iscapture": true,
      //     ref: recordSegment,
      //   };
      // };

      segmentsCombined.push(
        <div
          key={`segment-${i}`}
          className={clsx(
            "block flex-grow first:rounded-l-full last:rounded-r-full",
            "pointer-events-none",
            showTickMarks
              ? `${
                  solvePage ? "border-r-4" : "border-r-2"
                } border-white last:border-none`
              : "",
            currentScore && currentScore >= i + 1
              ? "bg-blue-800"
              : currentRecord &&
                currentScore !== undefined &&
                currentRecord > currentScore &&
                currentRecord >= i + 1
              ? "bg-blue-800/40"
              : "bg-slate-200"
            // !showSolveTooltip() ? "pointer-events-none" : ""
          )}
          // {...(showSolveTooltip() ? segmentOptions() : {})}
        ></div>
      );
    }
    return segmentsCombined;
  };

  const renderFullBar = () => {
    return (
      <div className="relative block flex-grow overflow-hidden rounded-full bg-slate-200">
        {/* <div
          className={"absolute h-full bg-blue-400 rounded-full"}
          style={{ width: `${40}%` }}
        ></div> */}
        <div
          className={"absolute h-full rounded-full bg-blue-800"}
          style={{ width: `${percentCompleted}%` }}
        ></div>
      </div>
    );
  };

  const tooltipCopy = () => {
    return `
      Current Score: ${currentScore}<br>
      Record Score: ${currentRecord}
      `;
  };

  const tooltipFlatCopy = () => {
    return `Current Score: ${currentScore}, Record Score: ${currentRecord}`;
  };

  const solveTooltipCopy = () => {
    return `Best Record: ${currentRecord}`;
  };

  // useEffect(() => {
  //   if (recordSegment.current) ReactTooltip.show(recordSegment.current);
  // }, [recordSegment.current]);

  // useEffect(() => {
  //   function handleResize() {
  //     if (recordSegment.current) ReactTooltip.show(recordSegment.current);
  //     ReactTooltip.rebuild();
  //   }

  //   window.addEventListener("resize", handleResize);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  const showTooltip = (): boolean => {
    return Boolean(
      tooltipEnabled &&
        !solvePage &&
        currentRecord &&
        currentScore !== undefined &&
        currentRecord < totalSegments &&
        currentScore < totalSegments &&
        currentRecord > currentScore
    );
  };

  const tooltipOptions = () => {
    return {
      "data-tip": tooltipCopy(),
      "data-for": "progressbar-solve",
      "data-effect": "solid",
      "data-multiline": true,
      "data-place": "left,top,bottom",
      "data-delay-hide": 300,
      "data-delay-show": 300,
    };
  };

  const showSolveTooltip = (): boolean =>
    !!tooltipEnabled &&
    !!solvePage &&
    !!currentRecord &&
    currentScore !== undefined &&
    currentRecord > currentScore;

  const solveTooltipOptions = () => {
    return {
      "data-tip": solveTooltipCopy(),
      "data-for": "progressbar-solve",
      "data-effect": "solid",
      "data-place": "top",
      // "data-event": "mouseenter click",
      "data-event": "",
      "data-event-off": "",
      "data-delay-hide": 300,
      "data-delay-show": 300,
      "data-iscapture": true,
      // ref: recordSegment,
    };
  };

  return (
    <>
      {showTooltip() || showSolveTooltip() ? (
        <>
          <ReactTooltip
            id="progressbar-solve"
            delayHide={300}
            // clickable={true}
          />
          <p className="sr-only">
            {showTooltip() ? tooltipFlatCopy() : null}
            {showSolveTooltip() ? solveTooltipCopy() : null}
          </p>
        </>
      ) : null}
      <div
        className={clsx(
          "relative flex py-1",
          solvePage ? "h-4 w-full" : "h-3.5 w-24",
          showTooltip() || showSolveTooltip() ? "cursor-pointer" : null
        )}
        //showTooltip() || showSolveTooltip()
        role="progressbar"
        aria-hidden={true}
        {...(showTooltip() ? tooltipOptions() : {})}
        {...(showSolveTooltip() ? solveTooltipOptions() : {})}
      >
        {showTickMarks ? <>{renderSegments()}</> : renderFullBar()}
      </div>
    </>
  );
}
