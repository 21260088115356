import { TrashIcon } from "@heroicons/react/outline";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import DeltaMathConfirmation from "../../../shared/DeltaMathConfirmation";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import { DemoMode } from "../../../utils/demo";
import { useUserContext } from "../../../shared/contexts/UserContext";

export default function CurrentAdmins({
  admins,
  school_id,
}: {
  admins: any;
  school_id: any;
}) {
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  const [showDeleteAdminConfirmation, setShowDeleteAdminConfirmation] =
    useState(false);
  const [userToDelete, setUserToDelete] = useState({
    id: "",
    first: "",
    last: "",
  });

  const removeAdmin = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/admin/remove_admin", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("/admin_new/data/admins");
        toastContext.addToast({
          status: "Success",
          message: "Admin Removed Succesfully",
        });
      },
    }
  );

  useEffect(() => {
    setShowDeleteAdminConfirmation(false);
  }, [removeAdmin.isSuccess]);

  const adminColumns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "first",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_first" />;
        },
      },
      {
        Header: "Last Name",
        accessor: "last",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_last" />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_email" />;
        },
      },
      {
        Header: "Delete",
        accessor: "showDeleteButton",
        disableSortBy: true,
        Cell: (props: { row: { original: any } }) => (
          <>
            {props.row.original.showDeleteButton && (
              <span
                onClick={() => {
                  setShowDeleteAdminConfirmation(true);
                  setUserToDelete({
                    id: props.row.original._id,
                    first: props.row.original.first,
                    last: props.row.original.last,
                  });
                }}
              >
                {props.row.original.showDeleteButton && (
                  <TrashIcon className="h-6 w-full cursor-pointer text-gray-600" />
                )}
              </span>
            )}
          </>
        ),
      },
    ],
    []
  );

  return (
    <>
      <h3 className="mt-8 text-lg font-medium leading-6 text-gray-900">
        School Administrators
      </h3>

      {admins && admins.length > 0 && (
        <DeltaMathTable
          columns={adminColumns}
          data={admins}
          options={{
            initialState: {
              sortBy: [
                {
                  id: "last",
                  desc: false,
                },
              ],
            },
          }}
        />
      )}
      {admins && admins.length === 0 && (
        <>
          <p className="text-md my-1 mb-6 text-gray-500">No admins found.</p>

          <p>
            <Link
              className="underline hover:no-underline"
              to={`${process.env.REACT_APP_ADMIN_LINK}/schools/${school_id}/invite-admins`}
            >
              Invite an Admin
            </Link>{" "}
            to get started.
          </p>
        </>
      )}

      {showDeleteAdminConfirmation && userToDelete && (
        <DeltaMathConfirmation
          title={`Remove Admin`}
          message={
            <>
              Are you sure you want to remove{" "}
              <DemoMode value={userToDelete.first} type="person_first" />{" "}
              <DemoMode value={userToDelete.last} type="person_last" />
              's account? This admin will be removed from the school and will no
              longer have access to the school's data.
            </>
          }
          confirm="Delete"
          confirmAction={() => {
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            const isTeacherAdmin = !!user.admin;
            const currentUserId = isTeacherAdmin ? user.admin : user._id;
            const body = JSON.stringify({
              params: {
                _id: userToDelete.id,
                userId: currentUserId,
              },
            });
            removeAdmin.mutate(body);
          }}
          cancel="Cancel"
          cancelAction={() => {
            setShowDeleteAdminConfirmation(false);
            setUserToDelete({
              id: "",
              first: "",
              last: "",
            });
          }}
        />
      )}
    </>
  );
}
