import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

import clsx from "clsx";
import { DmLoadingSpinner } from "../../manager/utils/functions";
import {
  accumulateUsageData,
  correctTime,
  DAY,
  formatBasicData,
  formattedLabelValue,
  GraphDataInterface,
  SeriesDataInterface,
  UsageDataInterface,
} from "./StudentUsageUtils";
import { useDMQuery } from "../../utils";
import { demoString } from "../../utils/demo";
import { useNavigate } from "react-router-dom";
import { getHours } from "date-fns";
import { QueryClient } from "react-query";
import { useUserContext } from "../../shared/contexts/UserContext";

const COLOR_ARRAY = ["#5cb4d6", "#408996", "#d65caa", "#964077", "#d6c15c"];

const NoDataMessage = () => (
  <p className="py-8 text-neutral-700">
    No data found for your selected scope and data range, please try another
    selection.
  </p>
);

export const DisplayGraphs = ({
  reportType,
  selectedDataScope,
  requestParams,
  startDate,
  endDate,
}: {
  reportType: string;
  selectedDataScope: any;
  requestParams: any;
  startDate: any;
  endDate: any;
}) => {
  const navigate = useNavigate();
  const queryClient = new QueryClient();
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const reportEndpoint =
    window.location.href.indexOf("student-performance") > 0
      ? "progress"
      : "usage";

  const [haveResults, setHaveResults] = useState(false);

  // cleaned response value of /usage API call
  const [usageData, setUsageData] = useState<UsageDataInterface[]>();
  // cleaned response value of /usage API call, separated by the ID (schoolId, teacherId, etc.)
  const [seriesData, setSeriesData] = useState<SeriesDataInterface[]>();
  const [problemsByHourData, setProblemsByHourData] =
    useState<GraphDataInterface>();
  const [timeSpentData, setTimeSpentData] = useState<GraphDataInterface>();
  const [problemsSolvedData, setProblemsSolvedData] =
    useState<GraphDataInterface>();

  const [timeSpentSeriesData, setTimeSpentSeriesData] = useState<{
    labels: any[];
    datasets: any[];
  }>();
  const [problemsSolvedSeriesData, setProblemsSolvedSeriesData] = useState<{
    labels: any[];
    datasets: any[];
  }>();
  const [isParsing, setIsParsing] = useState(false);

  const { data: schoolData } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      enabled: true,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: teacherData } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });

  const { data: sectionData } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  const { data, refetch, isLoading } = useDMQuery({
    cacheKey: [
      `/admin_new/data/${reportEndpoint}`,
      JSON.stringify(requestParams),
    ],
    path: `/admin_new/data/${reportEndpoint}`,
    params: requestParams,
    method: "get",
    additionalHeaders: {
      Authorization: `Bearer ${token}`,
    },
    queryOptions: {
      enabled: false,
    },
  });

  // API call to /usage was successful
  useEffect(() => {
    if (data && Object.keys(data)) {
      let formattedUsageData: any[] = [];
      let formattedSeriesData: SeriesDataInterface[] = [];
      if (
        selectedDataScope === "Teachers" ||
        selectedDataScope === "Schools" ||
        selectedDataScope === "Sections"
      ) {
        // format API response for "total" and "comparison" graphs
        formattedUsageData = formatBasicData({
          data: { data: data.data },
        });
        formattedSeriesData = Object.entries(data)
          .filter((entry: any) => entry[0] !== "data")
          .map((entry: any) => ({
            id: entry[0],
            data: formatBasicData({ data: { data: entry[1] } }),
          }));
      }
      setUsageData(formattedUsageData);
      setSeriesData(formattedSeriesData);
    }
  }, [data]);

  // Switching to view-data and we have request params, actually make the API request
  useEffect(() => {
    queryClient.removeQueries({ queryKey: ["usage"] });

    if (
      (typeof requestParams.teacherIds !== "undefined" &&
        requestParams.teacherIds.length > 0) ||
      (typeof requestParams.sectionIds !== "undefined" &&
        requestParams.sectionIds.length > 0) ||
      (typeof requestParams.schoolIds !== "undefined" &&
        requestParams.schoolIds.length > 0 &&
        typeof requestParams.start !== "undefined" &&
        typeof requestParams.end !== "undefined")
    ) {
      refetch();
    } else if (
      typeof requestParams.start !== "undefined" &&
      typeof requestParams.end !== "undefined"
    ) {
      navigate(
        `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/data-scope`,
        {
          replace: true,
        }
      );
    }
  }, [requestParams]);

  useEffect(() => {
    setIsParsing(true);
    if (usageData && usageData?.length > 1 && startDate && endDate) {
      /** construct data (non-series) for graphs */
      const {
        acc: range,
        aggregateDateLabels: dateLabels,
        aggregateTimeAccrued: time,
        aggregateProblemsSolved: problems,
      } = accumulateUsageData({
        data: usageData,
        dateRange: {
          start: Math.floor(correctTime(startDate).getTime() / 1000),
          end: Math.floor(correctTime(endDate).getTime() / 1000),
        },
      });

      /** set graph variables */
      setProblemsSolvedData({
        labels: dateLabels,
        datasets: [
          {
            label: "# of Problems Solved",
            data: problems,
            backgroundColor: "#5CB4D6",
          },
        ],
      });
      setTimeSpentData({
        labels: dateLabels,
        datasets: [
          {
            label: "Time Spent (minutes)",
            data: time,
            backgroundColor: "#5CB4D6",
          },
        ],
      });

      if (seriesData && seriesData.length > 1) {
        const seriesDataArray: {
          id: any;
          range: any;
          dateLabels: any;
          aggregateTimeAccrued: any;
          aggregateProblemsSolved: any;
        }[] = [];

        seriesData.forEach((element: any) => {
          /** construct graph data for each item requested (Schools, Teachers, or Sections) */
          if (element.data.length) {
            const {
              acc: range,
              aggregateDateLabels: dateLabels,
              aggregateTimeAccrued: time,
              aggregateProblemsSolved: problems,
            } = accumulateUsageData({
              data: element.data,
              dateRange: {
                start: Math.floor(
                  Math.floor(correctTime(startDate).getTime()) / 1000
                ),
                end: Math.floor(
                  Math.floor(correctTime(endDate).getTime()) / 1000
                ),
              },
            });
            seriesDataArray.push({
              id: element.id,
              range: range,
              dateLabels: dateLabels,
              aggregateTimeAccrued: time,
              aggregateProblemsSolved: problems,
            });
          } else {
            /** add an object with empty values if no data is returned for a given id */
            seriesDataArray.push({
              id: element.id,
              range: "",
              dateLabels: [],
              aggregateTimeAccrued: [],
              aggregateProblemsSolved: [],
            });
          }
        });

        /** Go back over seriesDataArray and use each item's id to find the
         * School, Teacher, or Section name to display
         */
        const seriesDataSetProblemsSolved: any[] = [];
        const seriesDataSetTimeSpent: any[] = [];
        if (selectedDataScope === "Schools") {
          // construct Schools data
          seriesDataArray.forEach((entry: any, index: number) => {
            const thisSchool = schoolData.find(
              (school: any) => school.schoolid === entry.id
            );
            if (thisSchool) {
              seriesDataSetProblemsSolved.push({
                label: demoString({
                  value: thisSchool.schoolName,
                  type: "school_name",
                }),
                data: entry.aggregateProblemsSolved,
                backgroundColor: COLOR_ARRAY[index],
              });
              seriesDataSetTimeSpent.push({
                label: demoString({
                  value: thisSchool.schoolName,
                  type: "school_name",
                }),
                data: entry.aggregateTimeAccrued,
                backgroundColor: COLOR_ARRAY[index],
              });
            } else {
              // addToast({
              //   status: "Error",
              //   message: `No data found for School with NCES ID: ${entry.id}.`,
              // });
            }
          });
        } else if (selectedDataScope === "Teachers") {
          // construct Teachers data
          seriesDataArray.forEach((entry: any, index: number) => {
            const thisTeacher = teacherData.find(
              (teacher: any) => teacher._id === parseInt(entry.id)
            );
            if (thisTeacher) {
              seriesDataSetProblemsSolved.push({
                label:
                  demoString({
                    value: thisTeacher.first,
                    type: "person_first",
                  }) +
                  " " +
                  demoString({ value: thisTeacher.last, type: "person_last" }),
                data: entry.aggregateProblemsSolved,
                backgroundColor: COLOR_ARRAY[index],
              });
              seriesDataSetTimeSpent.push({
                label:
                  demoString({
                    value: thisTeacher.first,
                    type: "person_first",
                  }) +
                  " " +
                  demoString({ value: thisTeacher.last, type: "person_last" }),
                data: entry.aggregateTimeAccrued,
                backgroundColor: COLOR_ARRAY[index],
              });
            } else {
              // addToast({
              //   status: "Error",
              //   message: `No data found for Teacher with code: ${entry.id}.`,
              // });
            }
          });
        } else if (selectedDataScope === "Sections") {
          // construct Sections data
          seriesDataArray.forEach((entry: any, index: number) => {
            const thisSection = sectionData.find(
              (section: any) => section.sectionId === parseInt(entry.id)
            );
            if (thisSection) {
              seriesDataSetProblemsSolved.push({
                label: thisSection.sectionName,
                data: entry.aggregateProblemsSolved,
                backgroundColor: COLOR_ARRAY[index],
              });
              seriesDataSetTimeSpent.push({
                label: thisSection.sectionName,
                data: entry.aggregateTimeAccrued,
                backgroundColor: COLOR_ARRAY[index],
              });
            } else {
              // addToast({
              //   status: "Error",
              //   message: `No data found for Section with ID: ${entry.id}.`,
              // });
            }
          });
        }

        /** set graph variables for series data */
        if (
          seriesDataSetProblemsSolved.length &&
          seriesDataSetTimeSpent.length
        ) {
          setTimeSpentSeriesData({
            labels: dateLabels,
            datasets: seriesDataSetTimeSpent,
          });

          setProblemsSolvedSeriesData({
            labels: dateLabels,
            datasets: seriesDataSetProblemsSolved,
          });
        }
      }
    }
    setIsParsing(false);
  }, [usageData, seriesData, startDate, endDate]);

  const problemsSolvedByHour = (data: any) => {
    const dayArray = Array.from(Array(24).keys());
    const hourLabels = dayArray.map(
      (hour: number) => `${hour % 12 || 12}:00${hour >= 12 ? "pm" : "am"}`
    );
    const initialVal: { [key: number]: any } = { 0: 0 };
    dayArray.map((hour: number) => {
      initialVal[hour] = 0;
    });
    data.map((entry: any) => {
      const hour = getHours(correctTime(new Date(entry.time * 1000)));
      initialVal[hour] += entry.stats.n;
    });
    return { hourLabels, initialVal };
  };

  useEffect(() => {
    if (startDate && endDate) {
      const start = Math.floor(
        Math.floor(correctTime(startDate).getTime()) / 1000
      );
      const end = Math.floor(Math.floor(correctTime(endDate).getTime()) / 1000);
      if (usageData && Array.isArray(usageData)) {
        if (seriesData && seriesData.length && startDate && endDate) {
          if (seriesData && Array.isArray(seriesData)) {
            const dataArr: any[] = [];
            const dayArray = Array.from(Array(24).keys());
            const labelsArray: string[] = [];
            const hourLabels = dayArray.map(
              (hour: number) =>
                `${hour % 12 || 12}:00${hour >= 12 ? "pm" : "am"}`
            );

            /**filter data according to selected date range */
            const selectedRange = seriesData.map(
              (item: { id: string; data: UsageDataInterface[] }) => {
                return {
                  id: item.id,
                  data: item.data.filter(
                    (entry: UsageDataInterface) =>
                      start <= entry.time && entry.time <= end + DAY
                  ),
                };
              }
            );

            seriesData.map((item) => {
              dataArr.push(problemsSolvedByHour(item.data).initialVal);
              if (selectedDataScope === "Schools") {
                // find school
                const thisSchool = schoolData.find(
                  (school: any) => school.schoolid === item.id
                );
                labelsArray.push(
                  demoString({
                    value: thisSchool.schoolName,
                    type: "school_name",
                  })
                );
              } else if (selectedDataScope === "Teachers") {
                // find teacher
                const thisTeacher = teacherData.find(
                  (teacher: any) => teacher._id === parseInt(item.id)
                );
                labelsArray.push(
                  demoString({
                    value: thisTeacher.first,
                    type: "person_first",
                  }) +
                    " " +
                    demoString({ value: thisTeacher.last, type: "person_last" })
                );
              } else if (selectedDataScope === "Sections") {
                // find section
                const thisSection = sectionData.find(
                  (section: any) => section.sectionId === parseInt(item.id)
                );
                labelsArray.push(thisSection.sectionName);
              }
            });
            const newDatasets: any = []; //{labels: [], datasets: {label: string, data: unknown[], backgroundColor: string}[]}
            dataArr.map((item: any, i: number) => {
              newDatasets.push({
                label: labelsArray[i],
                data: Object.values(item),
                backgroundColor: COLOR_ARRAY[i],
              });
            });
            newDatasets.length > 1 &&
              setProblemsByHourData({
                labels: hourLabels,
                datasets: newDatasets,
              });
          }
        } else {
          /**filter data according to selected date range */
          // const selectedRange = usageData.filter((entry) => {
          //   return start <= entry.time && entry.time <= end + DAY;
          // });
          const { hourLabels, initialVal } = problemsSolvedByHour(usageData);
          setProblemsByHourData({
            labels: hourLabels,
            datasets: [
              {
                label: "# of Problems Solved",
                data: Object.values(initialVal),
                backgroundColor: "#5CB4D6",
              },
            ],
          });
        }
      }
    }
  }, [usageData, seriesData, startDate, endDate]);

  const graphOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: formattedLabelValue,
        },
      },
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text:
          selectedDataScope +
          ": " +
          clsx(
            reportType === "time-spent"
              ? "Time Spent" // (minutes) is displayed in the graph legend
              : reportType === "problems-solved"
              ? "Problems Solved"
              : reportType === "compare-time-spent"
              ? "Compare Time Spent (minutes)"
              : "Compare Problems Solved"
          ),
      },
    },
  };

  const stackedGraphOptions = {
    plugins: {
      title: {
        display: true,
        text:
          selectedDataScope +
          ": " +
          clsx(
            reportType === "time-spent"
              ? "Cumulative Time Spent (minutes)"
              : "Cumulative Problems Solved"
          ),
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };

  useEffect(() => {
    /** determine if graph data was successfully parsed and ready to display */
    let data = undefined;
    switch (reportType) {
      case "problems-solved":
        data = problemsSolvedData;
        break;
      case "time-spent":
        data = timeSpentData;
        break;
      case "compare-problems-solved":
        data = problemsSolvedSeriesData;
        break;
      case "compare-time-spent":
        data = timeSpentSeriesData;
        break;
      case "problems-by-hour":
        data = problemsByHourData;
        break;
    }

    if (data?.datasets) {
      setHaveResults(
        data?.datasets.length > 0 &&
          data.datasets.filter((d) => d.data.length > 0).length > 0
          ? true
          : false
      );
    }
  }, [problemsSolvedData]);

  return (
    <>
      {(isLoading || isParsing) && <DmLoadingSpinner message="Loading..." />}

      {!isLoading && !isParsing && !haveResults && <NoDataMessage />}

      {!isLoading && !isParsing && haveResults && (
        <>
          {reportType === "problems-solved" && problemsSolvedData && (
            <Bar
              key="time-spent"
              options={graphOptions}
              data={problemsSolvedData}
            />
          )}
          {reportType === "time-spent" && timeSpentData && (
            <Bar key="time-spent" options={graphOptions} data={timeSpentData} />
          )}
          {reportType === "compare-problems-solved" &&
            problemsSolvedSeriesData && (
              <>
                {" "}
                <Bar
                  key="problems-solved-series"
                  options={graphOptions}
                  data={problemsSolvedSeriesData}
                />{" "}
                <Bar
                  key="problems-solved-series-stacked"
                  options={stackedGraphOptions}
                  data={problemsSolvedSeriesData}
                />
              </>
            )}
          {reportType === "compare-time-spent" && timeSpentSeriesData && (
            <>
              <Bar
                key="time-spent-series"
                options={graphOptions}
                data={timeSpentSeriesData}
              />
              <Bar
                key="time-spent-series-stacked"
                options={stackedGraphOptions}
                data={timeSpentSeriesData}
              />
            </>
          )}
          {reportType === "problems-by-hour" && problemsByHourData && (
            <>
              <Bar
                key="time-spent-series-stacked"
                options={stackedGraphOptions}
                data={problemsByHourData}
              />
              <Bar
                key="time-spent-series"
                options={graphOptions}
                data={problemsByHourData}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
