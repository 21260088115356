import { PieChart } from "react-minimal-pie-chart";

type Props = {
  isMaxOne?: boolean;
  lineWidth?: number;
  progress?: {
    showSegments?: boolean;
    total?: number;
    score?: number;
    record?: number;
  };
};

export default function DonutChart({
  isMaxOne = false,
  lineWidth = 40,
  progress,
}: Props): JSX.Element {
  if (!progress) return <></>;

  const { total, score, record = score, showSegments = true } = progress;

  if (!total) return <></>;

  const segments: any[] = [];
  for (let i = 1; i <= total; i++) {
    if (score && i <= score) {
      segments.push({ value: 1, color: isMaxOne ? "#585858" : "#1e40af" });
    } else if (record && i <= record) {
      segments.push({ value: 1, color: isMaxOne ? "#585858" : "#a9ccfa" });
    } else {
      segments.push({ value: 1, color: "#E3E8EF" });
    }
  }

  if (segments.length == 0) return <></>;

  const segmentSpacing = total < 15 ? 10 : 4;

  return (
    <>
      <div className="pointer-events-auto absolute h-5 w-5" aria-hidden={true}>
        <PieChart
          data={segments}
          lineWidth={lineWidth}
          paddingAngle={showSegments && total > 1 ? segmentSpacing : 0}
          totalValue={total}
          startAngle={270}
          className="pointer-events-none"
        />
      </div>
    </>
  );
}
