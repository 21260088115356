import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useDMQuery } from "../../../utils";
import { DisplayGraphs } from "../DisplayGraphs";
import { useNavigate, useParams } from "react-router-dom";

export const ViewDataTabs = ({
  selectedDataScope,
  selectedSchools,
  startDate,
  endDate,
  requestParams,
}: {
  selectedDataScope: any;
  selectedSchools: any;
  startDate: any;
  endDate: any;
  requestParams: any;
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const [compareDisabled, setCompareDisabled] = useState<boolean>(false);
  const [reportType, setReportType] = useState<string>("problems-solved");

  const { data: schoolData } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      enabled: true,
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  useEffect(() => {
    if (params.report_type) {
      setReportType(params.report_type);
    } else {
      setReportType("problems-solved");
      navigate(
        `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/view-data/problems-solved`
      );
    }
  }, [params.report_type]);

  // Check if we need to disable Compare or default to another report type
  useEffect(() => {
    if (
      (typeof requestParams.schoolIds !== "undefined" &&
        Array.isArray(requestParams.schoolIds) &&
        requestParams?.schoolIds?.length === 1) ||
      requestParams?.schoolIds?.length > 5 ||
      (typeof requestParams.teacherIds !== "undefined" &&
        Array.isArray(requestParams.teacherIds) &&
        requestParams?.teacherIds?.length === 1) ||
      requestParams?.teacherIds?.length > 5 ||
      (typeof requestParams.sectionIds !== "undefined" &&
        Array.isArray(requestParams.sectionIds) &&
        requestParams?.sectionIds?.length === 1) ||
      requestParams?.sectionIds?.length > 5
    ) {
      setCompareDisabled(true);

      // Switch the Report Type if compare is now disabled
      if (reportType === "compare-time-spent") {
        navigate(
          `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/view-data/time-spent`
        );

        // setReportType("time-spent");
      } else if (reportType === "compare-problems-solved") {
        navigate(
          `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/view-data/problems-solved`
        );
      }
    } else {
      setCompareDisabled(false);
    }
  }, [requestParams]);

  return (
    <>
      <div className="relative mt-2 flex space-x-4 border-b-2">
        <button
          onClick={() => {
            navigate(
              `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/view-data/problems-solved`
            );
          }}
          className={clsx(
            reportType === "problems-solved"
              ? "border-gray-500"
              : "border-transparent hover:border-gray-300",
            "text-gray-60 mb-2 w-1/4 rounded-lg border-2 px-1 py-6 text-center text-sm font-medium text-gray-600 disabled:border-transparent disabled:text-gray-300"
          )}
        >
          Problems Solved
        </button>
        <button
          onClick={() => {
            navigate(
              `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/view-data/time-spent`
            );
          }}
          className={clsx(
            reportType === "time-spent"
              ? "border-gray-500"
              : "border-transparent hover:border-gray-300",
            "text-gray-60 mb-2 w-1/4 rounded-lg border-2 px-1 py-6 text-center text-sm font-medium text-gray-600 disabled:border-transparent disabled:text-gray-300"
          )}
        >
          Time Spent
        </button>

        {(selectedDataScope !== "Schools" ||
          (selectedDataScope === "Schools" &&
            Object.keys(selectedSchools).length > 1 &&
            schoolData &&
            schoolData.length > 1)) && (
          <>
            <div className="relative mb-2 w-1/4">
              <button
                onClick={() => {
                  navigate(
                    `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/view-data/compare-problems-solved`
                  );
                }}
                disabled={compareDisabled}
                className={clsx(
                  reportType === "compare-problems-solved"
                    ? "border-gray-500"
                    : "border-transparent hover:border-gray-300",
                  "text-gray-60 group w-full rounded-lg border-2 px-1 py-6 text-center text-sm font-medium text-gray-600 disabled:border-transparent disabled:text-gray-300"
                )}
              >
                Compare Problems Solved
                {compareDisabled && (
                  <div className="absolute -top-6 ml-8 hidden flex-col items-center group-hover:flex">
                    <span className="whitespace-no-wrap relative z-10 bg-black p-2 text-xs leading-none text-white shadow-lg">
                      Compare View only available
                      <br />
                      for up to 5 {selectedDataScope.toLowerCase()} at a time.
                    </span>
                    <div className="-mt-2 h-3 w-3 rotate-45 bg-black"></div>
                  </div>
                )}
              </button>
            </div>
            <div className="relative mb-2 w-1/4">
              <button
                onClick={() => {
                  navigate(
                    `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/view-data/compare-time-spent`
                  );
                }}
                disabled={compareDisabled}
                className={clsx(
                  reportType === "compare-time-spent"
                    ? "border-gray-500"
                    : "border-transparent hover:border-gray-300",
                  "text-gray-60 group w-full rounded-lg border-2 px-1 py-6 text-center text-sm font-medium text-gray-600 disabled:border-transparent disabled:text-gray-300"
                )}
              >
                Compare Time Spent
                {compareDisabled && (
                  <div className="absolute -top-6 ml-8 hidden flex-col items-center group-hover:flex">
                    <span className="whitespace-no-wrap relative z-10 bg-black p-2 text-xs leading-none text-white shadow-lg">
                      Compare View only available
                      <br />
                      for up to 5 {selectedDataScope.toLowerCase()} at a time.
                    </span>
                    <div className="-mt-2 h-3 w-3 rotate-45 bg-black"></div>
                  </div>
                )}
              </button>
            </div>
          </>
        )}
        {
          <button
            onClick={() => {
              navigate(
                `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/view-data/problems-by-hour`
              );
            }}
            className={clsx(
              reportType === "problems-by-hour"
                ? "border-gray-500"
                : "hover:border-gray-300",
              "text-gray-60 group mb-2 w-1/4 rounded-lg border-2 border-transparent px-1 py-6 text-center text-sm font-medium text-gray-600 disabled:border-transparent disabled:text-gray-300"
            )}
          >
            Problems Solved by Hour
          </button>
        }
      </div>

      {!reportType && <div>Please select a display type above.</div>}

      {reportType && selectedDataScope && (
        <DisplayGraphs
          reportType={reportType}
          selectedDataScope={selectedDataScope}
          startDate={startDate}
          endDate={endDate}
          requestParams={requestParams}
        />
      )}
    </>
  );
};
