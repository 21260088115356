import { useContext, useState, useCallback } from "react";
import CelebrateIcon from "../icons/CelebrateIcon";
import { SectionDataStatus } from "../../_constants";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import Assignments from "../../components/assignments";
import SectionEmptyState from "./SectionEmptyState";
import SectionWelcomeState from "./SectionWelcomeState";
import { getSectionData } from "../../utils";
import clsx from "clsx";

export default function Upcoming(): JSX.Element {
  const { activeSection, dmAssignmentData, showPastDue } = useContext(
    StudentSectionsContext
  );

  const [assignmentType, setAssignmentType] = useState<string>("all");

  if (typeof dmAssignmentData[activeSection] === "undefined") {
    return <SectionWelcomeState />;
  }

  const assignmentList = getSectionData(
    SectionDataStatus.upcoming,
    activeSection,
    dmAssignmentData
  );
  const assignmentCount = assignmentList?.length;

  const assignmentByType = useCallback(
    (type = "") =>
      assignmentList.filter((assignment: any) => {
        const aType = assignment.ta.type;
        const isTest = assignment.ta.is_test || false;

        switch (type) {
          case "standard": {
            if (aType === "standard" && isTest === false) {
              return assignment;
            }
            break;
          }
          case "test": {
            if (aType === "standard" && isTest === true) {
              return assignment;
            }
            break;
          }
          case "correction": {
            if (aType === "correction") {
              return assignment;
            }
            break;
          }
          case "all":
          default:
            return assignment;
        }
      }),
    [assignmentList]
  );

  // show assignment sorting buttons if there are more than 5 total
  // and they are not all the same type
  const showAssignmentTypeButtons = (): boolean =>
    assignmentCount > 5 &&
    assignmentByType("standard").length !== assignmentCount &&
    assignmentByType("test").length !== assignmentCount &&
    assignmentByType("correction").length !== assignmentCount;

  return (
    <>
      {assignmentCount ? (
        <div className="px-1 py-5">
          {showAssignmentTypeButtons() ? (
            <div className="flex flex-wrap gap-3 pb-7 text-slate-600">
              <button
                className={clsx(
                  "group rounded-full border px-4 py-1.5 text-sm hover:border-slate-700 hover:bg-slate-200",
                  assignmentType === "all"
                    ? "border-slate-700 bg-slate-200"
                    : "border-slate-300 bg-transparent"
                )}
                onClick={() => setAssignmentType("all")}
              >
                All
                <span
                  className={clsx(
                    "ml-3 rounded-full px-2.5 py-0.5 text-xs group-hover:bg-slate-600 group-hover:text-slate-200",
                    assignmentType === "all"
                      ? "bg-slate-600 text-slate-200"
                      : "bg-slate-300 text-slate-700"
                  )}
                >
                  {assignmentByType("all").length}
                </span>
              </button>
              {assignmentByType("standard").length > 0 ? (
                <button
                  className={clsx(
                    "group rounded-full border px-4 py-1.5 text-sm hover:border-slate-700 hover:bg-slate-200",
                    assignmentType === "standard"
                      ? "border-slate-700 bg-slate-200"
                      : "border-slate-300 bg-transparent"
                  )}
                  onClick={() => setAssignmentType("standard")}
                >
                  Assignments
                  <span
                    className={clsx(
                      "ml-3 rounded-full px-2.5 py-0.5 text-xs group-hover:bg-slate-600 group-hover:text-slate-200",
                      assignmentType === "standard"
                        ? "bg-slate-600 text-slate-200"
                        : "bg-slate-300 text-slate-700"
                    )}
                  >
                    {assignmentByType("standard").length}
                  </span>
                </button>
              ) : null}
              {assignmentByType("test").length > 0 ? (
                <button
                  className={clsx(
                    "group rounded-full border px-4 py-1.5 text-sm hover:border-slate-700 hover:bg-slate-200",
                    assignmentType === "test"
                      ? "border-slate-700 bg-slate-200"
                      : "border-slate-300 bg-transparent"
                  )}
                  onClick={() => setAssignmentType("test")}
                >
                  Tests
                  <span
                    className={clsx(
                      "ml-3 rounded-full px-2.5 py-0.5 text-xs group-hover:bg-slate-600 group-hover:text-slate-200",
                      assignmentType === "test"
                        ? "bg-slate-600 text-slate-200"
                        : "bg-slate-300 text-slate-700"
                    )}
                  >
                    {assignmentByType("test").length}
                  </span>
                </button>
              ) : null}

              {assignmentByType("correction").length > 0 ? (
                <button
                  className={clsx(
                    "group rounded-full border px-4 py-1.5 text-sm hover:border-slate-700 hover:bg-slate-200",
                    assignmentType === "correction"
                      ? "border-slate-700 bg-slate-200"
                      : "border-slate-300 bg-transparent"
                  )}
                  onClick={() => setAssignmentType("correction")}
                >
                  Corrections
                  <span
                    className={clsx(
                      "ml-3 rounded-full px-2.5 py-0.5 text-xs group-hover:bg-slate-600 group-hover:text-slate-200",
                      assignmentType === "correction"
                        ? "bg-slate-600 text-slate-200"
                        : "bg-slate-300 text-slate-700"
                    )}
                  >
                    {assignmentByType("correction").length}
                  </span>
                </button>
              ) : null}
            </div>
          ) : null}
          {assignmentByType(assignmentType)?.map((section: any) => (
            <Assignments
              section={section}
              key={`section:${encodeURI(section?.ta?.name)}`}
            />
          ))}
        </div>
      ) : (
        <SectionEmptyState
          icon={<CelebrateIcon />}
          header="You’re all caught up!"
          message={`Go to the ${
            showPastDue ? "Past Due or " : ""
          }Completed tab to keep practicing skills.`}
          btnText={
            showPastDue
              ? "Work On Past Due Assignments"
              : "Practice Skills From Completed Assignments"
          }
          btnLink={
            showPastDue
              ? `${process.env.REACT_APP_STUDENT_LINK}/${activeSection}/${SectionDataStatus["past-due"]}`
              : `${process.env.REACT_APP_STUDENT_LINK}/${activeSection}/${SectionDataStatus.completed}`
          }
        />
      )}
    </>
  );
}
