import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDMQuery } from "../../utils";
import { SelectDataScope, getStartEndDate } from "./StudentUsageUtils";
import { StudentUsageNav } from "./student-usage/StudentUsageNav";
import { ViewDataTabs } from "./student-usage/ViewDataTabs";

export const StudentUsage = ({
  schoolsWithIntegral,
}: {
  schoolsWithIntegral: any;
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const { yesterday, yearToDate } = getStartEndDate();
  const storageObject = JSON.parse(
    localStorage.getItem("usagePreferences") || "{}"
  );
  useEffect(() => {
    /** default to data-scope page if the url gets changed */
    if (!params.data) {
      navigate(
        `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/data-scope`,
        {
          replace: true,
        }
      );
    } else if (params.data === "view-data") {
      if (
        typeof requestParams === "undefined" ||
        (typeof requestParams.teacherIds === "undefined" &&
          typeof requestParams.schoolIds === "undefined" &&
          typeof requestParams.sectionIds === "undefined")
      ) {
        navigate(
          `${process.env.REACT_APP_ADMIN_LINK}/reports/student-usage/data-scope`,
          {
            replace: true,
          }
        );
      }
    }
  }, []);

  const getDefaultDataScope = (obj: any) => {
    /** we need to parse this object on page load, but it requires these check in case anything is not defined. */
    if (obj && Object.keys(obj)) {
      if (obj.usagePreferences) {
        if (obj.usagePreferences.usageReportDataScope) {
          return Object.keys(
            storageObject?.usagePreferences?.usageReportDataScope.Schools
          ).length > 0
            ? "Schools"
            : Object.keys(
                storageObject?.usagePreferences?.usageReportDataScope.Teachers
              ).length > 0
            ? "Teachers"
            : Object.keys(
                storageObject?.usagePreferences?.usageReportDataScope.Sections
              ).length > 0
            ? "Sections "
            : "Schools";
        } else return "Schools";
      } else return "Schools";
    } else return "Schools";
  };

  /**
   * this just reduces the number of times we have to write out all of the properties on "storageObj"
   * to check if anything is saved in localStorage.
   * @param storageObj object parsed from localStorage
   * @param field the field we are checking -- "School", "Teacher", or "Section"
   * @returns an object of selected options e.g. {0: true, 1: false, 2: false, 3: true... }
   * or an empy object.
   */
  const parseLocalStorage = (storageObj: any, field: string) => {
    if (storageObj && Object.keys(storageObj).length) {
      if (Object.keys(storageObj.usagePreferences)) {
        if (Object.keys(storageObj.usagePreferences.usageReportDataScope)) {
          if (
            Object.keys(storageObj.usagePreferences.usageReportDataScope[field])
          ) {
            return storageObj.usagePreferences.usageReportDataScope[field];
          } else return {};
        } else return {};
      } else return {};
    } else return {};
  };

  const [startDate, setStartDate] = useState<Date | null>(yearToDate);
  const [endDate, setEndDate] = useState<Date | null>(yesterday);
  const [selectedDataScope, setSelectedDataScope] = useState<string>(
    getDefaultDataScope(storageObject) || "Schools"
  );
  const [selectedSchools, setSelectedSchools] = useState<any>(
    parseLocalStorage(storageObject, "Schools")
  );
  const [selectedTeachers, setSelectedTeachers] = useState<any>(
    parseLocalStorage(storageObject, "Teachers")
  );
  const [selectedSections, setSelectedSections] = useState<any>(
    parseLocalStorage(storageObject, "Sections")
  );

  // query params for /useage API call
  const [requestParams, setRequestParams] = useState<{
    district?: boolean;
    teacherIds?: any;
    schoolIds?: any;
    sectionIds?: any;
    series?: any;
    start?: any;
    end?: any;
  }>({
    start: startDate,
    end: endDate,
  });

  const { data: teacherData } = useDMQuery({
    path: "/admin_new/data/teacher",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
    },
  });

  const { data: sectionData } = useDMQuery({
    path: "/admin_new/data/sections",
    queryOptions: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 15,
    },
  });

  useEffect(() => {
    if (
      parseLocalStorage(storageObject, "Schools") &&
      Object.keys(parseLocalStorage(storageObject, "Schools")).length
    ) {
      setSelectedSchools(parseLocalStorage(storageObject, "Schools"));
    }
    if (
      parseLocalStorage(storageObject, "Teachers") &&
      Object.keys(parseLocalStorage(storageObject, "Teachers")).length
    ) {
      setSelectedTeachers(parseLocalStorage(storageObject, "Teachers"));
    }
    if (
      parseLocalStorage(storageObject, "Sections") &&
      Object.keys(parseLocalStorage(storageObject, "Sections")).length
    ) {
      setSelectedSections(parseLocalStorage(storageObject, "Sections"));
    }
    if (
      Object.keys(parseLocalStorage(storageObject, "Schools")).length === 0 &&
      Object.keys(parseLocalStorage(storageObject, "Teachers")).length === 0 &&
      Object.keys(parseLocalStorage(storageObject, "Sections")).length === 0
    ) {
      if (!schoolsWithIntegral || schoolsWithIntegral?.length === 0) return;
      if (selectedSchools.length === 0 && schoolsWithIntegral?.length > 0) {
        const allSchools = Object.create({});
        for (let i = 0; i < schoolsWithIntegral.length; i++) {
          allSchools[i] = true;
        }
        setSelectedSchools(allSchools);
      }
    }
  }, []);

  useEffect(() => {
    if (
      selectedDataScope === "Schools" &&
      selectedSchools &&
      schoolsWithIntegral.length > 0
    ) {
      const selectedSchoolIds = setIdsArray(
        selectedSchools,
        schoolsWithIntegral,
        ["schoolid"]
      ).map((school) => school.schoolid);
      setRequestParams({
        start:
          startDate?.getTime() &&
          Math.floor(startDate?.getTime() / 1000).toString(),
        end:
          endDate?.getTime() &&
          Math.floor(endDate?.getTime() / 1000).toString(),
        schoolIds: selectedSchoolIds,
        series:
          selectedSchoolIds.length > 1 && selectedSchoolIds.length <= 5
            ? selectedSchoolIds
            : null,
      });
    } else if (
      selectedDataScope === "Teachers" &&
      selectedTeachers &&
      teacherData
    ) {
      const selectedTeacherCodes = setIdsArray(selectedTeachers, teacherData, [
        "_id",
      ]).map((teacher) => teacher._id);
      setRequestParams({
        start:
          startDate?.getTime() &&
          Math.floor(startDate?.getTime() / 1000).toString(),
        end:
          endDate?.getTime() &&
          Math.floor(endDate?.getTime() / 1000).toString(),
        teacherIds: selectedTeacherCodes,
        series:
          selectedTeacherCodes.length > 1 && selectedTeacherCodes.length <= 5
            ? selectedTeacherCodes
            : null,
      });
    } else if (
      selectedDataScope === "Sections" &&
      selectedSections &&
      sectionData
    ) {
      const selectedSectionIds = setIdsArray(selectedSections, sectionData, [
        "sectionId",
      ]).map((section) => section.sectionId);
      setRequestParams({
        start:
          startDate?.getTime() &&
          Math.floor(startDate?.getTime() / 1000).toString(),
        end:
          endDate?.getTime() &&
          Math.floor(endDate?.getTime() / 1000).toString(),
        sectionIds: selectedSectionIds,
        series:
          selectedSectionIds.length > 1 && selectedSectionIds.length <= 5
            ? selectedSectionIds
            : null,
      });
    }
  }, [
    selectedDataScope,
    selectedSchools,
    selectedSections,
    selectedTeachers,
    schoolsWithIntegral,
    teacherData,
    sectionData,
    startDate,
    endDate,
  ]);

  return (
    <>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        Student Usage Reports
      </h3>

      <StudentUsageNav
        selectedDataScope={selectedDataScope}
        selectedSchools={selectedSchools}
        selectedTeachers={selectedTeachers}
        selectedSections={selectedSections}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      {params.data === "data-scope" &&
        (selectedSchools || selectedSections || selectedTeachers) && (
          <SelectDataScope
            schoolsWithIntegral={schoolsWithIntegral}
            selectedDataScope={selectedDataScope}
            setSelectedDataScope={setSelectedDataScope}
            selectedSchools={selectedSchools}
            setSelectedSchools={setSelectedSchools}
            selectedSections={selectedSections}
            setSelectedSections={setSelectedSections}
            selectedTeachers={selectedTeachers}
            setSelectedTeachers={setSelectedTeachers}
          />
        )}

      {params.data === "view-data" && (
        <ViewDataTabs
          selectedDataScope={selectedDataScope}
          selectedSchools={selectedSchools}
          startDate={startDate}
          endDate={endDate}
          requestParams={requestParams}
        />
      )}
    </>
  );
};

export default StudentUsage;

// Take Received IDs and Table Data, return an array of the object properties specified
export const setIdsArray = (
  selectedItems: any,
  tableData: any,
  objectProperties: string[]
) => {
  const selectedDataIds = Object.keys(selectedItems);
  const selectedIds = [];
  if (Array.isArray(objectProperties)) {
    for (let j = 0; j < selectedDataIds.length; j++) {
      const arrayId = parseInt(selectedDataIds[j]);
      // initialize an empty object to push into final array
      const element = JSON.parse("{}");
      for (let i = 0; i < objectProperties.length; i++) {
        element[objectProperties[i].toString()] =
          tableData[arrayId][objectProperties[i]];
      }
      selectedIds.push(element);
    }
  }

  return selectedIds;
};
