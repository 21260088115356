import { useEffect, useRef } from "react";
import clsx from "clsx";
import { displayInlineMath, resizeKatexLine } from "../../utils";
import SubmitButton from "./SubmitButton";
import renderMathInElement from "../../utils/auto-render";

type MultipleChoiceAnswerProps = {
  className: string;
  choices?: Array<string>;
  choicesPerLine?: number;
  inlineChoices?: boolean;
  setAnswer: (answer: Array<string>) => void;
  setShowAnswerPreview: (bool: boolean) => void;
};

export default function MultipleChoiceAnswer({
  className,
  choices,
  choicesPerLine,
  inlineChoices,
  setAnswer,
  setShowAnswerPreview,
}: MultipleChoiceAnswerProps): JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const katexRefs = useRef<Map<string, any> | null>(null);

  const refCallback = (node: any, i: number) => {
    if (!katexRefs.current) katexRefs.current = new Map();
    const map = katexRefs.current;
    if (node) {
      map.set(String(i), node);
    } else {
      map.delete(String(i));
    }
  };

  const handleResize = () => {
    const elementMap = katexRefs.current ? katexRefs.current : new Map();
    elementMap.forEach((value: any) => {
      resizeKatexLine(value);
    });
  };

  /* to render math in element if choices are in html format and resize, if necessary */
  useEffect(() => {
    if (containerRef.current && choices && choices[0][0] === "<") {
      renderMathInElement(containerRef.current);
    }
    const resizeNeeded = containerRef.current?.querySelector(".katex") !== null;
    if (resizeNeeded) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }
    return () => {
      if (resizeNeeded) window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = clsx(
    "basis-3/4 grow grid gap-4 grid-cols-1 pr-4",
    choicesPerLine && "sm:grid-cols-" + choicesPerLine,
    !choicesPerLine && "sm:grid-cols-2"
  );

  return (
    <>
      <div
        ref={containerRef}
        className={clsx("flex flex-row flex-wrap gap-y-2", className)}
      >
        <fieldset className={containerStyle}>
          <legend className="sr-only">Multiple Choice Answers</legend>
          {choices?.map((choice, index) => {
            const setInnerHTML = choice[0] === "<" ? true : false;
            return (
              <div
                key={choice + index}
                className="flex items-center text-lg"
                ref={(node: any) => refCallback(node, index)}
              >
                <input
                  id={choice + index}
                  name="answer-choices"
                  type="radio"
                  value={String(index)}
                  className="h-4 w-4 cursor-pointer border-gray-400 text-dm-alt-blue focus:ring-[#9DD3E7]"
                  onChange={(e) => setAnswer([e.currentTarget.value])}
                  onKeyUp={(e) => {
                    if (e.key === "Enter") setShowAnswerPreview(true);
                  }}
                />
                {setInnerHTML ? (
                  <label
                    htmlFor={choice + index}
                    dangerouslySetInnerHTML={{ __html: choice }}
                    className="ml-3 block cursor-pointer font-medium"
                  ></label>
                ) : (
                  <label
                    htmlFor={choice + index}
                    className="ml-3 block cursor-pointer font-medium"
                  >
                    {inlineChoices === false
                      ? displayInlineMath(choice, !inlineChoices)
                      : displayInlineMath(choice)}
                  </label>
                )}
              </div>
            );
          })}
        </fieldset>
        <div className="basis-1/4">
          <SubmitButton handleSubmit={() => setShowAnswerPreview(true)} />
        </div>
      </div>
    </>
  );
}
