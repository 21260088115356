import { ReactNode } from "react";

type Props = {
  message?: string;
  children: ReactNode;
  options?: {
    aligned?: "default" | "center";
  };
};

export const Tooltip = (props: Props) => {
  return (
    <div
      className={`group relative flex flex-col ${
        !props.options || props.options.aligned === "center"
          ? "items-center"
          : ""
      } overflow-visible`}
    >
      {props.children}
      {props.message && (
        <div className="absolute bottom-0 z-10 mb-6 hidden flex-col items-center whitespace-nowrap group-hover:flex">
          <span className="relative z-10 whitespace-nowrap rounded-md bg-dm-charcoal-600 p-2 text-xs leading-5 text-white opacity-90 shadow-lg">
            {props.message}
          </span>
          <div className="-mt-2 h-3 w-3 rotate-45 bg-gray-600"></div>
        </div>
      )}
    </div>
  );
};
