type SubmitButtonProps = {
  handleSubmit: () => void;
};

export default function SubmitButton({
  handleSubmit,
}: SubmitButtonProps): JSX.Element {
  return (
    <>
      <button
        type="button"
        id="submit-answer-form"
        className="submit-answer-form m-1 inline-flex items-center rounded-md border border-transparent bg-dm-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-dm-dark-blue focus:outline-none focus:ring-2 focus:ring-dm-lightest-blue focus:ring-offset-2"
        onClick={handleSubmit}
      >
        Submit Answer
      </button>
    </>
  );
}
