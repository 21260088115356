import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import slugify from "slugify";
import AddAdministrator from "./AddAdministrator";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { TrashIcon } from "@heroicons/react/solid";
import { useDMQuery } from "../../utils";
import { getAdminParams } from "../utils";
import clsx from "clsx";
import DeltaMathConfirmation from "../../shared/DeltaMathConfirmation";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { DemoMode } from "../../utils/demo";
import { useUserContext } from "../../shared/contexts/UserContext";

export default function Index() {
  const params = useParams();
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const adminParams = getAdminParams();
  const [admins, setAdmins] = React.useState<any>();

  const [userToDelete, setUserToDelete] = useState({
    _id: "",
    first: "",
    last: "",
    account_type: "",
    districtID: "",
    email: "",
    preferences: {
      showActiveSchools: "",
    },
    schoolIDs: [],
    showDeleteButton: "",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const { data: adminsData } = useDMQuery({
    path: "/admin_new/data/admins",
    params: adminParams,
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const { data: districtData, refetch: districtDataRefetch } = useDMQuery({
    path: "/admin_new/data/district",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: false, // We only want to make this request if we're a district admin, we check that in a useEffect below
    },
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const id = user.admin ? user.admin : user._id;
    if (adminsData && adminsData.length > 0) {
      const findCurrentUser = adminsData.filter(
        (user: { _id: string }) => user._id === id
      );
      if (findCurrentUser && findCurrentUser.length > 0) {
        districtDataRefetch();
      }
    }
  }, [adminsData]);

  useEffect(() => {
    if (adminsData) {
      const results = adminsData.filter(
        (a: { account_type: string }) =>
          a.account_type === "super_district" || a.account_type === "district"
      );
      results.forEach((a: { account_type: string }, index: number) => {
        if (a.account_type !== "super_district") {
          results[index]["showDeleteButton"] = true;
        }
      });
      setAdmins(results);
    }
  }, [adminsData]);

  const columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "first",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_first" />;
        },
      },
      {
        Header: "Last Name",
        accessor: "last",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_last" />;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        align: "left",
        Cell: (props: any) => {
          return <DemoMode value={props.value} type="person_email" />;
        },
      },
      {
        Header: "Delete",
        accessor: "showDeleteButton",
        disableSortBy: true,
        Cell: (props: { row: { original: any } }) => (
          <>
            {props.row.original.showDeleteButton && (
              <span
                onClick={() => {
                  setShowDeleteConfirmation(true);
                  setUserToDelete(props.row.original);
                }}
              >
                <TrashIcon className="h-6 w-full cursor-pointer text-gray-600" />
              </span>
            )}
          </>
        ),
      },
    ],
    []
  );

  const removeTeacher = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/admin/remove_admin", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/admin_new/data/admins"]);
        setShowDeleteConfirmation(false);
        toastContext.addToast({
          status: "Success",
          message: "District Admin Account deleted.",
        });
      },
      onError: () => {
        setShowDeleteConfirmation(false);
        toastContext.addToast({
          status: "Error",
          message: "There was an error with the request",
        });
        setUserToDelete({
          _id: "",
          first: "",
          last: "",
          account_type: "",
          districtID: "",
          email: "",
          preferences: {
            showActiveSchools: "",
          },
          schoolIDs: [],
          showDeleteButton: "",
        });
      },
    }
  );

  const tabs = [
    { name: "Current Admins", roles: ["district", "super_district"] },
    {
      name: "Invite Admins",
      roles: ["district", "super_district", "school", "super_school"],
    },
  ];

  return (
    <>
      {showDeleteConfirmation && userToDelete && (
        <DeltaMathConfirmation
          title={`Remove Account`}
          message={
            <>
              Are you sure you want to remove{" "}
              <DemoMode value={userToDelete.first} type="person_first" />{" "}
              <DemoMode value={userToDelete.last} type="person_last" />
              's account? This user will be removed from the district and will
              no longer have access to the districts's data.
            </>
          }
          confirm="Delete"
          confirmAction={() => {
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            const isTeacherAdmin = !!user.admin;
            const currentUserId = isTeacherAdmin ? user.admin : user._id;
            const body = JSON.stringify({
              params: {
                _id: userToDelete._id,
                userId: currentUserId,
              },
            });
            removeTeacher.mutate(body);
          }}
          cancel="Cancel"
          cancelAction={() => {
            setShowDeleteConfirmation(false);
            setUserToDelete({
              _id: "",
              first: "",
              last: "",
              account_type: "",
              districtID: "",
              email: "",
              preferences: {
                showActiveSchools: "",
              },
              schoolIDs: [],
              showDeleteButton: "",
            });
          }}
        />
      )}

      {districtData && (
        <h2 className="border-b-2 border-solid border-slate-400 text-lg font-medium leading-6 text-gray-900">
          <DemoMode value={districtData?.districtName} type="city" /> District
          Administrators
        </h2>
      )}

      <div className="my-6 border-b border-gray-200">
        <nav className="-mb-px flex" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={`${process.env.REACT_APP_ADMIN_LINK}/district/${slugify(
                tab.name,
                { lower: true }
              )}`}
              replace
              className={clsx(
                slugify(tab.name, { lower: true }) === params.selected_tab
                  ? "border-dm-light-blue text-dm-light-blue"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "w-1/4 border-b-2 px-1 py-4 text-center text-sm font-medium"
              )}
              aria-current={
                slugify(tab.name, { lower: true }) === params.selected_tab
                  ? "page"
                  : undefined
              }
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>

      {params.selected_tab === "current-admins" && (
        <>
          {admins && admins.length > 0 && (
            <DeltaMathTable
              columns={columns}
              data={admins}
              options={{
                initialState: {
                  sortBy: [
                    {
                      id: "last",
                      desc: false,
                    },
                  ],
                },
              }}
            />
          )}
        </>
      )}
      {params.selected_tab === "invite-admins" && <AddAdministrator />}
    </>
  );
}
