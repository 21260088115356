export default function CelebrateIcon({
  strokeColor = "#2F52E9", // dm-brand-blue-500
}: {
  classes?: string;
  strokeColor?: string;
}): JSX.Element {
  return (
    <svg viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1085_8806)">
        <path
          d="M7.83325 9.16663H11.4999"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.66675 7.33337V11"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.5834 7.33337L20.6667 11"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5 9.16663H37.1667"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.3333 7.33337V11"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.0001 16.5L26.1667 18.3333"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5 23.8333L37.1667 22.9166"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M33.5 34.8334H37.1667"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.3333 33V36.6667"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M26.1667 30.283L14.217 18.3334L6.16872 35.8967C6.00964 36.2377 5.95934 36.6194 6.02466 36.99C6.08997 37.3606 6.26773 37.7021 6.5338 37.9681C6.79987 38.2342 7.14137 38.412 7.51194 38.4773C7.88252 38.5426 8.26421 38.4923 8.60522 38.3332L26.1667 30.283Z"
          stroke={strokeColor}
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1085_8806">
          <rect
            width="44"
            height="44"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
