import { useEffect, useRef, forwardRef, useContext } from "react";
import StudentSectionsContext from "../_context/StudentSectionsContext";
const MQ = (window as any).MQ;

type MQProps = {
  latex?: string;
  onChange?: (mq: any) => void;
  handleSubmit?: () => void;
  handleFocus?: (mq: any) => void;
  config?: any; // TS TODO
  mathquillDidMount?: (mq: any) => void;
  id?: string;
  ariaLabel?: string;
  cssText?: string;
  isCalc?: boolean;
};

export const MQReact = forwardRef((props: MQProps, forwardMathRef: any) => {
  const wrapperElement = useRef<HTMLSpanElement>(null);
  const mqLatex = useRef<string>("");
  const { globalInputsMap } = useContext(StudentSectionsContext);
  useMQConfig(props, wrapperElement, mqLatex, forwardMathRef);

  return (
    <>
      <span
        className="bg-white"
        aria-label={props.ariaLabel}
        ref={wrapperElement}
        id={props.id}
      ></span>
    </>
  );
});

/* ************ */
/* Custom Hooks */
/* ************ */

/* Custom Hook to configure MQ */
function useMQConfig(
  {
    latex,
    onChange,
    handleSubmit,
    handleFocus,
    config,
    mathquillDidMount,
    id,
    cssText,
    isCalc = false,
  }: MQProps,
  wrapperElement: React.RefObject<HTMLSpanElement>,
  mqLatex: React.MutableRefObject<string>,
  forwardMathRef?: any
) {
  useEffect(() => {
    if (!wrapperElement.current) return;

    /* Create MQ Element */
    const mq = MQ.MathField(wrapperElement.current);

    /* Configure MQ with props */
    mq.config(
      createOptions({
        config,
        onChange,
        handleSubmit,
        mqLatex,
        isCalc,
      })
    );

    /* Set latex */
    if (latex) {
      mq.latex(latex);
      mqLatex.current = latex;
    }

    /* Define 'focusin' event listener */
    let focusInFunc: () => void;
    let textareaEl: HTMLElement;
    if (handleFocus) {
      focusInFunc = () => {
        handleFocus(id);
      };
      textareaEl = wrapperElement.current.querySelectorAll("textarea")[0];
      textareaEl.addEventListener("focusin", focusInFunc);
    }

    /* Invoke mathquillDidMount func */
    if (mathquillDidMount) mathquillDidMount(mq);

    /* Invoke ref callback passing in MQ object as the node */
    if (forwardMathRef) forwardMathRef(mq);

    /* Set styles, if necessary */
    if (cssText) wrapperElement.current.style.cssText = cssText;

    return () => {
      if (forwardMathRef) forwardMathRef(null); // invoke ref callback to nullify unmounted MQ
      if (handleFocus) {
        textareaEl.removeEventListener("focusin", focusInFunc);
      }
    };
  }, [onChange]);
}

/* **************** */
/* Helper Functions */
/* **************** */

/* Function to generate default options */
const createOptions = ({
  config,
  onChange,
  handleSubmit,
  mqLatex,
  isCalc = false,
}: {
  config: any;
  onChange?: (mq: any) => void;
  handleSubmit?: () => void;
  mqLatex: React.MutableRefObject<string>;
  isCalc: boolean;
}): Record<string, any> => {
  const combinedConfig: Record<string, any> = {
    restrictMismatchedBrackets: true,
    spaceBehavesLikeTab: true,
    sumStartsWithNEquals: false,
    supSubsRequireOperand: true,
    charsThatBreakOutOfSupSub: "+-=<>",
    autoCommands: "pi theta sqrt sum ge le approx pm nthroot infty cup",
    autoOperatorNames:
      "sin cos tan arccos arcsin arctan lim csc sec cot log ln Ans ans or undefined DNE",
    ...config,
  };

  combinedConfig.handlers = {
    edit: (mq: any) => {
      // invoke any edit handlers provided in props
      if (config?.handlers?.edit) config.handlers.edit(mq);
      if (onChange) onChange(mq);
      // restrict to 7 decimal spaces
      if (isCalc === false) {
        preventLongDecimals(mq, mqLatex);
      }
    },
    enter: (mq: any) => {
      // invoke any enter handlers provided in props
      if (config?.handlers?.enter) config.handlers.enter(mq);
      if (handleSubmit) handleSubmit();
    },
    ...(config?.handlers?.reflow ? { reflow: config.handlers.reflow } : {}),
  };

  return combinedConfig;
};

/* Function to prevent long decimals */
const preventLongDecimals = (
  mq: any,
  mqLatex: React.MutableRefObject<string>
): void => {
  const latex = mq.latex();
  if (/\.\d{8,}/.test(latex)) mq.latex(mqLatex.current);
  else mqLatex.current = latex;
};
