import React, { useState, useEffect, useContext, useRef } from "react";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInDays,
} from "date-fns";
import { v4 } from "uuid";
import ProblemEventTracker from "../../student/components/ProblemEventTracker";

// 10 min of inactivity stop clock
// no mouse move or keystroke

// TODO: track time only on current tab
interface WindowContextApi {
  trackEvent(
    trackingEvent:
      | "status_ping"
      | "unfocus_browser"
      | "start_problem"
      | "focus_browser"
      | "close_problem",
    eventTimestamp: Date,
    sc?: string,
    uniqueString?: string,
    saId?: string,
    oldProblemIds?: {
      skillCode: string;
      uniqueString: string;
      studentAssignmentId: string;
    }
  ): void;
  getTimeInfo(
    skillCode: string,
    uniqueString: string,
    studentAssignmentId: string
  ): {
    duration: number;
    start?: Date;
  };
}

export class WindowHolder implements WindowContextApi {
  private readonly eventTracker: (
    trackingEvent:
      | "status_ping"
      | "unfocus_browser"
      | "start_problem"
      | "focus_browser"
      | "close_problem"
      | "finish_problem",
    eventTimestamp: Date,
    sc?: string,
    uniqueString?: string,
    saId?: string,
    oldProblemIds?: {
      skillCode: string;
      uniqueString: string;
      studentAssignmentId: string;
    }
  ) => void;
  // private readonly timeTracking: TimeTracking[];

  constructor(
    trackEvent: (
      trackingEvent:
        | "status_ping"
        | "unfocus_browser"
        | "start_problem"
        | "focus_browser"
        | "close_problem"
        | "finish_problem",
      eventTimestamp: Date,
      sc?: string,
      uniqueString?: string,
      saId?: string,
      oldProblemIds?: {
        skillCode: string;
        uniqueString: string;
        studentAssignmentId: string;
      }
    ) => void
    // timeTracking: TimeTracking[]
  ) {
    this.eventTracker = trackEvent;
    // this.timeTracking = timeTracking;
  }

  getTimeInfo(
    skillCode: string,
    uniqueString: string,
    studentAssignmentId: string
  ): { duration: number; start?: Date | undefined } {
    const tt: TimeTracking[] = JSON.parse(
      localStorage.getItem("timeTracking") ?? "[]"
    );
    const problemTime = tt.find(
      (x) =>
        x.sc === skillCode &&
        x.problem === uniqueString &&
        x.sa === studentAssignmentId
    );

    if (problemTime) {
      return {
        start: problemTime.start,
        duration: problemTime.duration,
      };
    } else {
      return {
        duration: 0,
      };
    }
  }

  trackEvent = (
    trackingEvent:
      | "status_ping"
      | "unfocus_browser"
      | "start_problem"
      | "focus_browser"
      | "close_problem"
      | "finish_problem",
    eventTimestamp: Date,
    sc?: string,
    uniqueString?: string,
    saId?: string,
    oldProblemIds?: {
      skillCode: string;
      uniqueString: string;
      studentAssignmentId: string;
    }
  ): void => {
    return this.eventTracker(
      trackingEvent,
      eventTimestamp,
      sc,
      uniqueString,
      saId,
      oldProblemIds
    );
  };
}

export type TimeTracking = {
  sa: string;
  problem: string;
  sc: string;
  duration: number;
  start: Date;
};

const WindowContext = React.createContext<WindowHolder>(null!);

export function WindowContextProvider({ children }: { children: any }) {
  // Store a tab id in session storage
  // Session storage will keep the id if the tab is refreshed but will not share data between tabs / windows
  const tabId = sessionStorage.getItem("tabId") ?? v4();
  useEffect(() => {
    if (sessionStorage.getItem("tabId") !== tabId) {
      sessionStorage.setItem("tabId", tabId);
    }
    localStorage.setItem("activeTabId", tabId);
  }, [tabId]);

  const [lastUpdated, setLastUpdated] = useState<Date>(new Date());
  const lastUpdatedRef = useRef(lastUpdated);
  lastUpdatedRef.current = lastUpdated;
  const [browserFocus, setBrowserFocus] = useState<
    | {
        focused: true;
      }
    | {
        focused: false;
        unfocusedTime: Date;
      }
  >({
    focused: true,
  });

  // ********************** //
  // UPDATING TIME TRACKING //
  // ********************** //

  const updateCurrentTimeDuration = (
    uniqueString?: string,
    skillCode?: string,
    saId?: string,
    timeDuration?: number
  ) => {
    const tt: TimeTracking[] = JSON.parse(
      localStorage.getItem("timeTracking") ?? "[]"
    );

    const newTime = new Date();

    // take out any time tracking that's a week or more old
    const allOtherTime = tt.filter(
      (x) =>
        (x.problem !== uniqueString || x.sc !== skillCode || x.sa !== saId) &&
        (!x.start || differenceInDays(newTime, new Date(x.start)) < 7)
    );
    const currentTime = tt.find(
      (x) => x.problem === uniqueString && x.sc === skillCode && x.sa === saId
    );

    if (!currentTime) {
      return [];
    }

    if (timeDuration) {
      currentTime.duration += timeDuration;
    } else if (lastUpdatedRef.current) {
      currentTime.duration += differenceInSeconds(
        newTime,
        lastUpdatedRef.current
      );
    }

    allOtherTime.push(currentTime);
    localStorage.setItem("timeTracking", JSON.stringify(allOtherTime));

    setLastUpdated(newTime);
    return allOtherTime;
  };

  // ************** //
  // TRACKING EVENT //
  // ************** //

  const trackEvent = (
    trackingEvent:
      | "status_ping"
      | "unfocus_browser"
      | "start_problem"
      | "focus_browser"
      | "close_problem"
      | "finish_problem",
    eventTimestamp: Date,
    sc?: string,
    uniqueString?: string,
    saId?: string,
    oldProblemIds?: {
      skillCode: string;
      uniqueString: string;
      studentAssignmentId: string;
    }
  ): void => {
    const previousActiveTab = localStorage.getItem("activeTabId");
    const activeTab =
      previousActiveTab !== tabId && trackingEvent === "focus_browser"
        ? tabId
        : previousActiveTab;

    if (previousActiveTab !== tabId) {
      if (trackingEvent === "focus_browser") {
        localStorage.setItem("activeTabId", tabId);
      }
    }

    if (sc && uniqueString && saId && activeTab === tabId) {
      // some sort of event just happened which makes us want to update the time with this problem
      // if the browser is unfocused but less than 5 min has elapsed then we are good to continue tracking
      if (
        (trackingEvent === "close_problem" ||
          trackingEvent === "status_ping") &&
        (browserFocus.focused ||
          (!browserFocus.focused &&
            differenceInMinutes(new Date(), browserFocus.unfocusedTime) < 5))
      ) {
        updateCurrentTimeDuration(uniqueString, sc, saId);
      } else if (trackingEvent === "finish_problem") {
        // Finish problem has been called, make sure to clear local storage from the problem
        const tt: TimeTracking[] = JSON.parse(
          localStorage.getItem("timeTracking") ?? "[]"
        );
        localStorage.setItem(
          "timeTracking",
          JSON.stringify(
            tt.filter(
              (x) => x.sc !== sc && x.sa !== saId && x.problem !== uniqueString
            )
          )
        );
      } else if (trackingEvent === "start_problem") {
        // If we are moving from one problem to the next we were running into race conditions by calling close problem then start problem
        // We now pass in oldProblemIds so we can quick update that time then work on the new one
        const tt: TimeTracking[] = oldProblemIds
          ? updateCurrentTimeDuration(
              oldProblemIds.uniqueString,
              oldProblemIds.skillCode,
              oldProblemIds.studentAssignmentId
            )
          : JSON.parse(localStorage.getItem("timeTracking") ?? "[]");

        // Starting the problem makes us start the timer fresh
        setLastUpdated(new Date());

        // On problem starting make sure it exists in state
        if (
          tt &&
          !tt.some(
            (x) => x.sc === sc && x.sa === saId && x.problem === uniqueString
          )
        ) {
          localStorage.setItem(
            "timeTracking",
            JSON.stringify([
              ...tt,
              {
                sc: sc,
                sa: saId,
                problem: uniqueString,
                start: new Date(),
                duration: 0,
              },
            ])
          );
        }
      } else if (trackingEvent === "focus_browser" && !browserFocus.focused) {
        setBrowserFocus({
          focused: true,
        });
      } else if (trackingEvent === "unfocus_browser" && browserFocus.focused) {
        setBrowserFocus({
          focused: false,
          unfocusedTime: new Date(),
        });
      }

      // ************************************************************************* //
      // If you wanted to send events to the backend this is where you would do it //
      // ************************************************************************* //

      // console.log(
      //   "TRACKING EVENT",
      //   trackingEvent,
      //   eventTimestamp,
      //   browserFocus.focused ||
      //     (!browserFocus.focused &&
      //       differenceInMinutes(new Date(), browserFocus.unfocusedTime))
      // );
      // axios.post(
      //   deltamathAPI() + "/track_problem_analytics",
      //   JSON.stringify({
      //     trackingEvent,
      //     eventTimestamp,
      //     tabId,
      //     teacherId: tId ?? scAndTeacher.teacherId,
      //     skillCode: sc ?? scAndTeacher.skillCode,
      //   }),
      //   { headers: { "Content-Type": "application/json" } }
      // );
    } else if (activeTab !== tabId) {
      setLastUpdated(new Date());
    }
  };

  return (
    <WindowContext.Provider value={new WindowHolder(trackEvent)}>
      <ProblemEventTracker trackEvent={trackEvent}>
        {children}
      </ProblemEventTracker>
    </WindowContext.Provider>
  );
}

export function useWindowContext() {
  return useContext(WindowContext);
}
