import { useContext, useState, useEffect } from "react";
import { SectionDataStatus } from "../../_constants";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import Assignments from "../../components/assignments";
import { getSectionData } from "../../utils";
import clsx from "clsx";

export default function PastDue(): JSX.Element {
  const { activeSection, dmAssignmentData, openAssignment } = useContext(
    StudentSectionsContext
  );

  // enum SectionDataStrings {
  //   no_late_available = "past due assignments without late credit available",
  //   late_credit_available = "past due assignments with late credit available",
  // }

  const assignmentList = getSectionData(
    SectionDataStatus["past-due"],
    activeSection,
    dmAssignmentData
  );

  // if student hit back button from problem page, activate proper status section
  // so window can scroll to the assignment
  useEffect(() => {
    if (openAssignment) {
      const AssgStatus = assignmentList?.find(
        (section: any) => section.ta?._id == openAssignment
      )?.sa?.status;

      setIsCreditAvailable(
        AssgStatus === SectionDataStatus.late_credit_available ? true : false
      );
    }
  }, []);

  // const labelCount = (label: SectionDataStatus) => {
  //   return assignmentList?.filter((section: any) => section.sa?.status == label)
  //     .length;
  // };

  const filteredList = (label: SectionDataStatus) =>
    assignmentList?.filter((section: any) => section.sa?.status == label);

  const hasCreditList = filteredList(SectionDataStatus.late_credit_available);
  const noCreditList = filteredList(SectionDataStatus.no_late_available);
  const hasCreditCount = hasCreditList.length;
  const noCreditCount = noCreditList.length;
  const showFilters = hasCreditCount > 0 && noCreditCount > 0;

  // if no assignments w/ available credit, default to assignments w/o available credit
  const [isCreditAvailable, setIsCreditAvailable] = useState<boolean>(
    //labelCount(SectionDataStatus.late_credit_available)
    hasCreditCount > 0 ? true : false
  );

  // const creditAvailabilityLabel = isCreditAvailable
  //   ? SectionDataStatus.late_credit_available
  //   : SectionDataStatus.no_late_available;

  const listToShow = isCreditAvailable ? hasCreditList : noCreditList;

  const assignmentCount = assignmentList?.length;

  return (
    <div className="px-1 py-5">
      {assignmentCount ? (
        <>
          {showFilters && (
            <div className="flex gap-3 pb-7 text-slate-600">
              <button
                className={clsx(
                  "group rounded-full border px-4 py-1.5 text-sm hover:border-slate-700 hover:bg-slate-200",
                  isCreditAvailable
                    ? "border-slate-700 bg-slate-200"
                    : "border-slate-300 bg-transparent"
                )}
                onClick={() => setIsCreditAvailable(true)}
              >
                Late Credit Available
                <span
                  className={clsx(
                    "ml-3 rounded-full px-2.5 py-0.5 text-xs group-hover:bg-slate-600 group-hover:text-slate-200",
                    isCreditAvailable
                      ? "bg-slate-600 text-slate-200"
                      : "bg-slate-300 text-slate-700"
                  )}
                >
                  {hasCreditCount}
                </span>
                {/* {labelCount(SectionDataStatus.late_credit_available) ? (
                  <span
                    className={clsx(
                      "ml-3 rounded-full px-2.5 py-0.5 text-xs group-hover:bg-slate-600 group-hover:text-slate-200",
                      isCreditAvailable
                        ? "bg-slate-600 text-slate-200"
                        : "bg-slate-300 text-slate-700"
                    )}
                  >
                    {labelCount(SectionDataStatus.late_credit_available)}
                  </span>
                ) : null} */}
              </button>
              <button
                className={clsx(
                  "group rounded-full border px-4 py-1.5 text-sm hover:border-slate-700 hover:bg-slate-200",
                  !isCreditAvailable
                    ? "border-slate-700 bg-slate-200"
                    : "border-slate-300 bg-transparent"
                )}
                onClick={() => setIsCreditAvailable(false)}
              >
                No Late Credit Available
                <span
                  className={clsx(
                    "ml-3 rounded-full px-2.5 py-0.5 text-xs group-hover:bg-slate-600 group-hover:text-slate-200",
                    !isCreditAvailable
                      ? "bg-slate-600 text-slate-200"
                      : "bg-slate-300 text-slate-700"
                  )}
                >
                  {noCreditCount}
                </span>
                {/* {labelCount(SectionDataStatus.no_late_available) ? (
                  <span
                    className={clsx(
                      "ml-3 rounded-full px-2.5 py-0.5 text-xs group-hover:bg-slate-600 group-hover:text-slate-200",
                      !isCreditAvailable
                        ? "bg-slate-600 text-slate-200"
                        : "bg-slate-300 text-slate-700"
                    )}
                  >
                    {labelCount(SectionDataStatus.no_late_available)}
                  </span>
                ) : null} */}
              </button>
            </div>
          )}
          {listToShow.map((section: any) => (
            <Assignments
              section={section}
              key={`section:${encodeURI(section?.ta?.name)}`}
            />
          ))}
          {/* {assignmentList?.filter(
            (section: any) => section.sa?.status == creditAvailabilityLabel
          ).length > 0 ? (
            assignmentList
              ?.filter(
                (section: any) => section.sa?.status == creditAvailabilityLabel
              )
              .map((section: any) => (
                <Assignments
                  section={section}
                  key={`section:${encodeURI(section?.ta?.name)}`}
                />
              ))
          ) : (
            // if no past due assignments exist for whatever type is determined by `isCreditAvailable`
            <div className="pt-5 text-orange-600">
              Placeholder Text: No {SectionDataStrings[creditAvailabilityLabel]}
            </div>
          )} */}
        </>
      ) : null}
    </div>
  );
}
