import { useContext, useEffect, useRef } from "react";
import { displayInlineMath } from "../../utils";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
const MQ = (window as any).MQ;

type DMKeyProps = {
  id?: string;
  btnText: string | any; //| React.ReactElement
  btnCmd?: boolean;
  btnAction?: boolean;
  btnOutput?: string;
  addParens?: boolean;
  ansType?: string | number;
  className?: string;
  ariaLabel?: string;
  moveCursor?: { dir: string; num: number };
  inputsRef?: React.MutableRefObject<any>;
  focusedInput: string | null;
};

const DMKey = ({
  btnText,
  btnCmd,
  btnAction,
  btnOutput,
  addParens,
  ansType,
  className,
  ariaLabel,
  moveCursor,
  inputsRef,
  focusedInput,
}: DMKeyProps) => {
  const btnRef = useRef<HTMLButtonElement>(null);
  /** If we're using context here, we actually don't need inputsRef & focusedInput as props.
   * But maybe they should be passed as props?
   */
  const { globalFocusedInput, globalInputsMap } = useContext(
    StudentSectionsContext
  );

  const handleKeyClick = (btnLatex: string) => {
    let node: any;

    if (globalInputsMap) {
      const map = globalInputsMap ?? new Map();
      node = globalFocusedInput
        ? map.get(globalFocusedInput)
        : map.get("math-input-1");
    }
    if (btnAction) {
      if (btnLatex.includes("start")) {
        node.moveToLeftEnd();
        btnLatex = btnLatex.replace("start", "");
      }
      node.keystroke(btnLatex);
    } else if (btnCmd) {
      // use the command mq method
      node.cmd(btnLatex);
    } else {
      // account for special buttons
      if (btnLatex.includes(",")) {
        // such as [,] for interval notation
        node.write(btnLatex).keystroke("Left Left");
      } else if (btnLatex.includes("cuberoot")) {
        node
          .cmd("nthroot")
          .typedText("3")
          .keystroke("Tab")
          .keystroke("Left Right");
      } else if (btnLatex.includes("nthroot")) {
        node.cmd("nthroot").keystroke("Left Right");
      } else if (btnLatex.includes("\\left(")) {
        node.write(btnLatex);
        node.keystroke("Left");
      } else {
        // all other latex
        node.write(btnLatex);
      }
      if (moveCursor) {
        for (let i = 0; i < moveCursor.num; i++) {
          node.keystroke(moveCursor.dir);
        }
      }
      if (addParens) {
        node.cmd("(");
        node.keystroke("Left Right").focus();
      }
    }
    node.focus();
  };

  return (
    <button
      type={"button"}
      ref={btnRef}
      aria-label={ariaLabel || "button"}
      name={btnText}
      onClick={() => {
        const btnLaTeX = btnOutput !== undefined ? btnOutput : btnText;
        handleKeyClick(btnLaTeX);
      }}
      className={
        className ||
        `border-1 col col-span-1 rounded-md border-black bg-gray-300 hover:bg-gray-400 sm:p-2`
      }
    >
      {" "}
      {ansType === "icon" ? btnText : displayInlineMath(btnText)}
    </button>
  );
};

export default DMKey;
