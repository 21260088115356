import { useContext } from "react";
import { CheckIcon, XIcon } from "@heroicons/react/solid";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import {
  // displayProblem,
  processlines,
  generateProblemScripts,
} from "../../utils";
import Problem from "../standard-problems/Problem";

type YourSolutionGraphProps = {
  problem: any;
  attemptsData: Array<any> | undefined;
  katexResizingData: any;
  showAttempts: boolean;
  attemptNumber: number;
  setAttemptNumber: React.Dispatch<React.SetStateAction<number>>;
};

export default function YourSolutionGraph({
  problem,
  attemptsData,
  katexResizingData,
  showAttempts,
  attemptNumber,
  setAttemptNumber,
}: YourSolutionGraphProps): JSX.Element {
  const { customExternalFiles } = useContext(StudentSectionsContext);

  const maxAttempt = attemptsData ? attemptsData.length : 0;

  const correct =
    showAttempts &&
    attemptNumber === maxAttempt &&
    problem.log_data.correct === 1;

  const inlineSolutionCode = () => {
    return `
      window.deltaGraphs = [];
      ${problem?.inlineSolutionCode};`;
  };

  const handleClick = (moveForward: boolean) => {
    const currAttempt = moveForward ? attemptNumber + 1 : attemptNumber - 1;
    if (currAttempt >= 0 && currAttempt <= maxAttempt) {
      const answerData =
        currAttempt === maxAttempt || !attemptsData
          ? problem.log_data.data
          : attemptsData[currAttempt].data;
      if (answerData) {
        generateProblemScripts(
          customExternalFiles.current.get(problem.skillcode),
          problem,
          answerData
        );
        // This is possibly used by inlineSolutionCode when eval()
        // Guarantees data is in scope.
        // TODO: does inlineSolutionCode need the page variable?
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const data = { answerData };
        eval(inlineSolutionCode());
        problem?.problemScripts?.solutionScripts(
          document.querySelector(".answerData")
        );
      }
      setAttemptNumber(currAttempt);
    }
  };

  return (
    <>
      {showAttempts && (
        <div className="row -mb-4">
          <div className="col-sm-9 z-10 flex items-center justify-around text-[#4B5563]">
            <button
              disabled={attemptNumber === 0}
              className="disabled:opacity-50"
              onClick={() => handleClick(false)}
              aria-labelledby="arrow-left-circle"
            >
              {/* arrow-left-circle icon from heroicons (not available in react package) */}
              <svg
                id="arrow-left-circle"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
                role="img"
              >
                <title>See previous answer</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
            <h3>
              Attempt {attemptNumber + 1} out of {maxAttempt + 1 + " "}
              <span className="font-medium">
                ({correct ? "correct" : "incorrect"})
              </span>
              {correct ? (
                <CheckIcon
                  className="ml-2 w-4 text-[#078445]"
                  aria-label="Check icon"
                />
              ) : (
                <XIcon
                  className="ml-2 w-4 text-[#D21527]"
                  aria-label="X icon"
                />
              )}
            </h3>
            <button
              disabled={attemptNumber === maxAttempt}
              className="disabled:opacity-50"
              onClick={() => handleClick(true)}
              aria-labelledby="arrow-right-circle"
            >
              {/* arrow-right-circle icon from heroicons (not available in react package) */}
              <svg
                id="arrow-right-circle"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
                role="img"
              >
                <title>See next answer</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <div className="display-problem problem-page mb-8">
        {/* {displayProblem(
          processlines(problem.answerLines),
          problem,
          katexResizingData ? katexResizingData["solution"] : undefined,
          "solution"
        )} */}
        <Problem
          displayData={processlines(problem.answerLines)}
          problemData={problem}
          resizingData={
            katexResizingData ? katexResizingData["solution"] : undefined
          }
          locString="solution"
        />
      </div>
    </>
  );
}
