import { Disclosure } from "@headlessui/react";
import clsx from "clsx";
import { SectionDataStatus, AssignmentDueDateType } from "../../_constants";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/solid";
import { Tooltip } from "../../../shared/Tooltip";
import IconAssignmentProgress from "../IconAssignmentProgress";
import Countdown from "../Countdown";
import { timeLimitText } from "../../utils";
import SubTextBlock from "./SubTextBlock";
import AssignmentAccordionButton from "./AssignmentAccordionButton";

type Props = {
  section: any;
  open: boolean;
  type: "correction" | "test" | "standard";
  timedAssignment: {
    isTimed: boolean;
    timeLimit: any;
    endTime: number | undefined;
    serverTime: any;
    additionalTime: any;
    timerText: string;
    isUnlimited: boolean;
    isOverUnlimitedTime: boolean | 0 | undefined;
    isOver: any;
  };
  resetCorrections: (e: any) => void;
  isResetCorrectionActive: boolean;
  assignmentType: AssignmentDueDateType | undefined;
  uploadNotesAssignment: any;
};

const AssignmentLineItem = (props: Props) => {
  const teacherId = props.section?.ta?._id;

  const dateObject =
    props.assignmentType == SectionDataStatus.late_credit_available
      ? props.section?.sa?.lateDate?.date
      : props.section?.sa?.dueDate;

  const dueDate =
    dateObject?.date && dateObject?.tz
      ? new Date(dateObject?.date).toLocaleString("en-US", {
          timeZone: dateObject?.tz,
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
      : "";
  const originalDueDate =
    props.section?.sa?.dueDate?.date && props.section?.sa?.dueDate?.tz
      ? new Date(props.section?.sa?.dueDate?.date).toLocaleString("en-US", {
          timeZone: dateObject?.tz,
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        })
      : "";

  const skills = Object.keys(props.section?.ta?.skills);
  const teacherGradedSkills = skills
    .map((key) => props.section?.ta?.skills[key])
    .filter((skill: any) => skill.teacher_graded);

  const allSpecialTypes = skills
    .map((key) => props.section?.ta?.skills[key])
    .every((skill: any) => skill.type === "special");

  const showGrade =
    props.assignmentType !== SectionDataStatus.late_credit_available &&
    props.section?.sa?.grade !== undefined &&
    props.section?.sa?.grade !== null &&
    props.section?.sa?.grade !== -1 &&
    !props.section?.ta?.obscureResults &&
    !(props.timedAssignment?.isTimed && !props.timedAssignment.isOver);
  !allSpecialTypes;

  const totalPoints =
    props.section?.ta?.is_test && props.section?.ta?.type === "standard"
      ? props.section?.ta?.totalPoints
      : undefined;
  const studentPoints =
    totalPoints &&
    props.section?.sa?.grade !== undefined &&
    props.section?.sa?.grade !== null &&
    props.section?.sa?.grade !== -1 &&
    !props.section?.ta?.obscureResults
      ? (props.section?.sa?.complete / 100) * props.section?.ta?.totalPoints
      : undefined;

  if (props.section?.ta?.name === "Raleigh Testing time") {
    console.log(props.timedAssignment, props.section);
  }

  return (
    <div
      className={clsx(
        `flex w-full content-start items-center justify-between gap-x-1 border border-dm-charcoal-100 bg-white p-4 text-left text-sm text-gray-900 ${
          props.timedAssignment?.isTimed && !props.timedAssignment.endTime
            ? ""
            : "hover:bg-dm-brand-blue-100"
        } focus:outline-none focus-visible:ring focus-visible:ring-opacity-75`,
        "sm:flex-row sm:px-6 sm:py-5 md:gap-x-3"
        // "truncate",
        // props.type === "standard" && !props.open
        //   ? "bg-white hover:bg-gray-200"
        //   : null,
        // props.type === "standard" && props.open ? "border-b-2 bg-white" : null,
        // props.type === "correction" && props.open ? "bg-orange-200" : null,
        // props.type === "correction" && !props.open
        //   ? "bg-orange-100 hover:bg-orange-200"
        //   : null,
        // props.type === "test" && props.open ? "bg-blue-300" : null,
        // props.type === "test" && !props.open
        //   ? "bg-blue-200 hover:bg-blue-300"
        //   : null
      )}
      {...(props.timedAssignment?.isTimed && props.timedAssignment.endTime
        ? { "aria-describedby": `timer-${props.section?.sa?._id}` }
        : null)}
    >
      <IconAssignmentProgress
        section={props.section}
        timedAssignment={props.timedAssignment}
      />
      <div
        className={clsx(
          "flex max-w-full flex-grow flex-col gap-x-1.5 sm:flex-row"
        )}
      >
        <div
          className={clsx(
            "flex flex-grow flex-col justify-start gap-x-1.5 gap-y-1",
            "sm:flex-row sm:gap-y-0",
            "sm:items-center md:gap-x-3",
            "w-[90%] self-start sm:w-0"
          )}
        >
          <div className="truncate text-base font-bold">
            {props.section?.ta?.name}
          </div>
          {props.type === "correction" &&
          props.section?.sa?.correctionInitialized ? (
            <div
              data-for={`assignments-tooltip-${teacherId}`}
              data-tip="This will allow you to re-enter your points. You will not lose credit for problems already solved."
              className={clsx(
                "rounded-md border border-gray-300 bg-slate-50/50 px-2 py-0.5 text-xs font-normal text-gray-600 hover:border-gray-500 hover:bg-slate-50 hover:text-gray-900",
                "w-fit",
                props.open ? "block" : "hidden"
              )}
              onClick={props.resetCorrections}
            >
              {props.isResetCorrectionActive
                ? // add diff text styles for each
                  `undo`
                : `reset`}
            </div>
          ) : null}
        </div>
        <span className="flex flex-col gap-1 leading-normal text-dm-charcoal-500 sm:flex-row">
          <>
            {props.timedAssignment?.isTimed ? (
              props.timedAssignment.endTime ? (
                <div className="whitespace-nowrap text-dm-error-500">
                  <Countdown targetTime={props.timedAssignment.endTime} />
                </div>
              ) : (
                <SubTextBlock
                  label="Time"
                  value={timeLimitText(
                    props.section?.ta?.timeLimitData?.timeLimit
                  )}
                />
              )
            ) : (
              <></>
            )}
            {!!studentPoints && !!totalPoints && (
              <SubTextBlock label="Points" value={totalPoints} />
            )}
            {props.section?.ta?.type === "correction" ? (
              props.section?.sa?.newtest ? (
                <Tooltip
                  message={`Original Test Grade: ${props.section.sa.test}%`}
                >
                  <SubTextBlock
                    label="New Test Grade"
                    value={`${props.section.sa.newtest}%`}
                  />
                </Tooltip>
              ) : (
                <SubTextBlock
                  label="Test Grade"
                  value={`${props.section.sa.test}%`}
                />
              )
            ) : (
              <></>
            )}
            {showGrade ? (
              <SubTextBlock
                label="Grade"
                value={`${props.section?.sa?.grade}%`}
              />
            ) : props.section?.ta?.obscureResults &&
              !(
                props.timedAssignment?.isTimed && !props.timedAssignment.endTime
              ) ? (
              <SubTextBlock
                label="Complete"
                value={`${props.section?.sa?.actuallyComplete || 0}%`}
              />
            ) : (
              <></>
            )}
          </>
          <Tooltip
            message={
              props.assignmentType === SectionDataStatus.late_credit_available
                ? `Originally Due: ${originalDueDate}`
                : undefined
            }
            options={{
              aligned: "default",
            }}
          >
            {props.assignmentType ===
            SectionDataStatus.late_credit_available ? (
              <SubTextBlock
                label={`${props.section?.sa?.lateDate?.credit}% Credit until`}
                value={dueDate}
                noBar
              />
            ) : (
              <SubTextBlock label="Due" value={dueDate} noBar />
            )}
          </Tooltip>
        </span>
      </div>
      {props.timedAssignment?.isTimed && !props.timedAssignment.endTime ? (
        <AssignmentAccordionButton section={props.section} type={props.type} />
      ) : (
        <>
          {props.open ? (
            <ChevronUpIcon
              className="h-4 w-4 flex-shrink-0 md:h-5 md:w-5"
              aria-hidden="true"
            />
          ) : (
            <ChevronDownIcon
              className="h-4 w-4 flex-shrink-0 md:h-5 md:w-5"
              aria-hidden="true"
            />
          )}
        </>
      )}
    </div>
  );
};

export default AssignmentLineItem;
