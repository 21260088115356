import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDMQuery } from "../../utils";

export default function Index() {
  const navigate = useNavigate();

  const { data: schoolsData } = useDMQuery({
    path: "/admin_new/data/school",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  useEffect(() => {
    if (schoolsData) {
      if (typeof schoolsData !== "undefined" && schoolsData.length === 1) {
        navigate(
          `${process.env.REACT_APP_ADMIN_LINK}/schools/${schoolsData[0].schoolid}`,
          {
            replace: true,
          }
        );
      } else {
        navigate(`${process.env.REACT_APP_ADMIN_LINK}/schools`, {
          replace: true,
        });
      }
    }
  }, [schoolsData]);

  return <></>;
}
