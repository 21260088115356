import { useContext } from "react";
import BookClosedIcon from "../icons/BookClosedIcon"; // TODO: Add correct icon
import { SectionDataStatus } from "../../_constants";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import Assignments from "../../components/assignments";
import SectionWelcomeState from "./SectionWelcomeState";
import SectionEmptyState from "./SectionEmptyState";
import { getSectionData } from "../../utils";

export default function Completed(): JSX.Element {
  const { activeSection, dmAssignmentData, showPastDue } = useContext(
    StudentSectionsContext
  );

  if (typeof dmAssignmentData[activeSection] === "undefined") {
    return <SectionWelcomeState />;
  }

  const assignmentList = getSectionData(
    SectionDataStatus.completed,
    activeSection,
    dmAssignmentData
  );
  const assignmentCount = assignmentList?.length;

  const showUpcoming =
    !assignmentCount &&
    getSectionData(SectionDataStatus.upcoming, activeSection, dmAssignmentData)
      ?.length
      ? true
      : false;

  return (
    <>
      {assignmentCount ? (
        <div className="px-1 py-5">
          {assignmentList?.map((section: any) => (
            <Assignments
              section={section}
              key={`section:${encodeURI(section?.ta?.name)}`}
            />
          ))}
        </div>
      ) : (
        <SectionEmptyState
          icon={<BookClosedIcon />}
          header="You have no completed assignments or tests"
          message={`You can find assignments to work on in the Upcoming ${
            showPastDue ? "or Past Due tabs" : "tab"
          } above.`}
          btnText={
            !showUpcoming && showPastDue
              ? "Work On Past Due Assignments"
              : "Check Out Upcoming"
          }
          btnLink={
            !showUpcoming && showPastDue
              ? `${process.env.REACT_APP_STUDENT_LINK}/${activeSection}/${SectionDataStatus["past-due"]}`
              : `${process.env.REACT_APP_STUDENT_LINK}/${activeSection}/${SectionDataStatus.upcoming}`
          }
        />
      )}
    </>
  );
}
