export function getAdminParams() {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const admin = JSON.parse(localStorage.getItem("admin") || "{}");

  let account_type, districtID, schoolIDs;
  if (admin) {
    // user is a teacher with linked admin account
    account_type = admin.account_type;
    districtID = admin.districtID;
    schoolIDs = admin.schoolIDs;
  } else {
    account_type = user.account_type;
    districtID = user.districtID;
    schoolIDs = user.schoolIDs;
  }
  return {
    account_type,
    districtID,
    schoolIDs,
  };
}

export function getAdminId() {
  const adminData = JSON.parse(localStorage.getItem("admin") || "{}");
  if (adminData) {
    return adminData._id;
  } else return false;
}

export const WEIGHTED_TEXT = "Weighted Average";
