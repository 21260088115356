import { useEffect, useMemo, Fragment, useRef } from "react";
import { displayInlineMath, resizeKatexLine } from "../../utils";
import renderMathInElement from "../../utils/auto-render";
import clsx from "clsx";

type FormattedAnswerProps = {
  ansType: string | number;
  answer?: Array<string> | object;
  leftLatex?: string;
  rightLatex?: string;
  noSolutionText: string;
  choices?: Array<string>;
  setNotation?: boolean;
  customMsg?: string;
  setFormattedAnswer?: (str: string) => void;
};

export default function FormattedAnswer({
  ansType,
  answer,
  leftLatex,
  rightLatex,
  noSolutionText,
  choices,
  setNotation,
  customMsg,
  setFormattedAnswer,
}: FormattedAnswerProps): JSX.Element {
  const ref = useRef<HTMLDivElement>(null);

  // check if student was stuck for this answer
  const studentIsStuck =
    Array.isArray(answer) &&
    answer.length === 1 &&
    answer[0] === "\\text{Problem skipped}";

  const { formattedAnsArray, formattedAnsStrArray } = useMemo(
    () =>
      formatAns({
        ansType,
        answer,
        leftLatex,
        rightLatex,
        noSolutionText,
        choices,
        setNotation,
        customMsg,
        studentIsStuck,
      }),
    [answer, customMsg]
  );

  /* Sets the formatted answer string - WOULD LIKE TO REFACTOR THIS, BAD PRACTICE! */
  useEffect(() => {
    const ansStr = formattedAnsStrArray.join("");
    if (setFormattedAnswer) setFormattedAnswer(ansStr);
  }, []);

  /**
   * Renders the math in the format-ans element and resizes, if necessary
   * Note: This is only for custom problem types and multiple choice where the answer choices are in html format -> custom problems must use the \[...\] convention for latex (as opposed to displayStyle) due to how the modules have been coded, requiring renderMathInElement
   */
  useEffect(() => {
    if (
      ansType === "custom" ||
      (ansType === 2 && choices && choices[0][0] === "<")
    ) {
      renderMathInElement(ref.current);
    }
    const resizeNeeded = ref.current?.querySelector(".katex") !== null;
    const handleResize = () => resizeKatexLine(ref.current);
    if (resizeNeeded) {
      handleResize();
      window.addEventListener("resize", handleResize);
    }
    return () => {
      if (resizeNeeded) window.removeEventListener("resize", handleResize);
    };
  }, [answer, customMsg]);

  return (
    <>
      <div
        id="format-ans"
        ref={ref}
        className={clsx(
          ansType === "custom"
            ? "text-[1.3125em]"
            : "text-center text-[1.3125em]"
        )}
      >
        {studentIsStuck || ansType === 1 ? (
          <Fragment>
            {displayInlineMath(formattedAnsStrArray.join(""), true)}
          </Fragment>
        ) : (
          formattedAnsArray
        )}
      </div>
    </>
  );
}

/* **************** */
/* Helper Functions */
/* **************** */

/* Formats the answer based on the answer type, returns an object with two properties, an array of JSX elements to render and an array of strings for setFormattedAnswer */
const formatAns = ({
  ansType,
  answer,
  leftLatex,
  rightLatex,
  noSolutionText,
  choices,
  setNotation,
  customMsg,
  studentIsStuck,
}: {
  ansType: string | number;
  answer?: Array<string> | object;
  leftLatex?: string;
  rightLatex?: string;
  noSolutionText: string;
  choices?: Array<string>;
  setNotation?: boolean;
  customMsg?: string;
  studentIsStuck: boolean;
}) => {
  const formattedAnsArray: Array<string | JSX.Element> = [];
  const formattedAnsStrArray: Array<string> = [];

  if (studentIsStuck) {
    const stuckAnswer = answer as string[];
    stuckAnswer.forEach((ans) => {
      formattedAnsArray.push(ans);
      formattedAnsStrArray.push(ans);
    });
  } else if (ansType === 1) {
    const simpleAnswer = answer as string[];
    if (simpleAnswer.length && simpleAnswer[0] !== "") {
      if (setNotation) formattedAnsStrArray.push("\\{");
      simpleAnswer.forEach((e, i) => {
        if (leftLatex) {
          formattedAnsStrArray.push(leftLatex, e);
        } else {
          formattedAnsStrArray.push(e);
        }
        if (rightLatex) {
          if (rightLatex.indexOf("\\text") === 0) {
            formattedAnsStrArray.push("\\hspace{1px}");
          }
          formattedAnsStrArray.push(rightLatex);
        }
        if (i < simpleAnswer.length - 1) {
          formattedAnsStrArray.push(",", "\\hspace{4px}");
        }
      });
      if (setNotation) formattedAnsStrArray.push("\\}");
    } else {
      if (setNotation) {
        formattedAnsStrArray.push("\\{\\hspace{2px}\\}");
      } else {
        formattedAnsStrArray.push("\\text{" + noSolutionText + "}");
      }
    }
  } else if (ansType === 2) {
    const multChoiceAnswer = answer as string[];
    const studentAnsIndexStr = multChoiceAnswer[0];
    if (multChoiceAnswer.length && studentAnsIndexStr === "") {
      formattedAnsStrArray.push("\\text{No choice selected.}");
      formattedAnsArray.push(
        <Fragment key="ansBlank">
          {displayInlineMath("\\text{No choice selected.}", true)}
        </Fragment>
      );
    } else {
      const studentAnsIndex = Number(studentAnsIndexStr);
      if (choices) formattedAnsStrArray.push(choices[studentAnsIndex]);
      choices && choices[studentAnsIndex][0] === "<"
        ? formattedAnsArray.push(
            <div
              key={"mcAns" + studentAnsIndexStr}
              dangerouslySetInnerHTML={{ __html: choices[studentAnsIndex] }}
            ></div>
          )
        : formattedAnsArray.push(
            <Fragment key={"mcAns" + studentAnsIndexStr}>
              {choices ? (
                displayInlineMath(choices[studentAnsIndex], true)
              ) : (
                <></>
              )}
            </Fragment>
          );
    }
  } else if (ansType === "custom") {
    let msg = customMsg as string;
    formattedAnsStrArray.push(msg);
    // if the custom message is not HTML, add in the brackets for latex rendering
    if (msg.length && msg[0] !== "<") msg = "\\[" + msg + "\\]";
    formattedAnsArray.push(
      <div
        key={"customAns"}
        className="verify-math"
        dangerouslySetInnerHTML={{ __html: msg }}
      ></div>
    );
  }
  return { formattedAnsArray, formattedAnsStrArray };
};
