import axios from "axios";
import md5 from "md5";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import Cookies from "js-cookie";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useUserContext } from "../../shared/contexts/UserContext";

const Profile = () => {
  const refresh_token_cookie = Cookies.get("refresh_token_javascript");
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const toastContext = useDeltaToastContext();

  const [editMode, setEditMode] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [userName, setUserName] = useState("");
  const [userLogin, setUserLogin] = useState("");
  const [infoPayload, setInfoPayload] = useState({
    fields: {
      first: "",
      last: "",
      login: "",
      student_id: "",
    },
    stats: false,
    student: true,
  });

  useEffect(() => {
    if (refresh_token_cookie) {
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      if (user?.first && user?.last) {
        setUserName(`${user.first} ${user.last}`);
        setInfoPayload({
          ...infoPayload,
          fields: {
            ...infoPayload.fields,
            first: user.first,
            last: user.last,
            login: user.login,
            student_id: user.student_id || "",
          },
        });
      }
      if (user?.login) {
        setUserLogin(user.login);
      }
    }
  }, []);

  const updateStudentInfo = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/update_user_field", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess(data: any) {
        if (data?.data?.success) {
          toastContext.addToast({
            status: "Success",
            message: "Info successfully updated!",
          });
          setUserName(`${infoPayload.fields.first} ${infoPayload.fields.last}`);
          setUserLogin(`${infoPayload.fields.login}`);
          const user = JSON.parse(localStorage.getItem("user") || "{}");
          const newUser = {
            ...user,
            first: infoPayload.fields.first,
            last: infoPayload.fields.last,
            login: infoPayload.fields.login,
          };
          updateUser({ ...newUser });
          setEditMode(false);
        } else if (data?.data?.message) {
          toastContext.addToast({
            status: "Error",
            message: data?.data?.message,
          });
        }
      },
    }
  );

  return (
    <main id="main-content" className="flex-grow overflow-auto p-8">
      <div className="flex min-w-0 flex-1 flex-col gap-2 pb-2 sm:flex-row sm:items-end sm:gap-6">
        <h3 className="text-2xl font-bold leading-5 text-gray-900 sm:truncate sm:leading-7">
          Profile Information
        </h3>
      </div>
      <div className="flex flex-row border border-x-0 border-y-2 py-2">
        <button
          disabled={editMode}
          onClick={() => {
            setShowChangePassword(false);
            setEditMode(!editMode);
          }}
          className="border-1 rounded-md border bg-gray-300 p-2 hover:bg-gray-500 disabled:cursor-not-allowed disabled:opacity-50"
        >
          Edit Info
        </button>
        <button
          disabled={showChangePassword}
          onClick={() => {
            setShowChangePassword(!showChangePassword);
            setEditMode(false);
          }}
          className="border-1 ml-2 rounded-md border bg-dm-dark-blue p-2 text-white hover:bg-dm-lightest-blue disabled:cursor-not-allowed disabled:opacity-50"
        >
          Change Password
        </button>
      </div>
      <div>
        {showChangePassword ? (
          <UpdatePasswordInline setIsOpen={setShowChangePassword} />
        ) : userName.length && userLogin.length && editMode ? (
          <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                First Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  value={infoPayload.fields.first}
                  onChange={(e) => {
                    setInfoPayload({
                      ...infoPayload,
                      fields: {
                        ...infoPayload.fields,
                        first: e.target.value,
                      },
                    });
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Last Name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  value={infoPayload.fields.last}
                  onChange={(e) => {
                    setInfoPayload({
                      ...infoPayload,
                      fields: {
                        ...infoPayload.fields,
                        last: e.target.value,
                      },
                    });
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="sm:col-span-4">
              <label
                htmlFor="login"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Login: Must be a valid email address
              </label>
              <div className="mt-2">
                <input
                  id="login"
                  name="login"
                  type="email"
                  autoComplete="email"
                  value={infoPayload.fields.login}
                  onChange={(e) => {
                    setInfoPayload({
                      ...infoPayload,
                      fields: {
                        ...infoPayload.fields,
                        login: e.target.value,
                      },
                    });
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
                />
              </div>
              <div className="pt-5">
                <div className="flex justify-end sm:justify-start">
                  <button
                    type="button"
                    onClick={() => setEditMode(!editMode)}
                    className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    onClick={() =>
                      updateStudentInfo.mutate(
                        JSON.stringify({
                          ...infoPayload,
                        })
                      )
                    }
                    className="ml-3 inline-flex justify-center rounded-md bg-dm-dark-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-dm-lightest-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="">
            <div className="inline-flex">
              <span className="text-dm-dark-blue sm:text-lg">Name:</span>
              <span className="ml-2 text-dm-dark-blue sm:text-lg">
                {userName}
              </span>
            </div>
            <div>
              <span className="text-dm-dark-blue sm:text-lg">Login:</span>
              <span className="ml-2 text-dm-dark-blue sm:text-lg">
                {userLogin}
              </span>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

const UpdatePasswordInline = ({
  setIsOpen,
}: {
  setIsOpen: (value: any) => void;
}) => {
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const toastContext = useDeltaToastContext();

  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordPayload, setPasswordPayload] = useState({
    fields: {
      oldpassword: "",
      password: "",
    },
    stats: false,
    student: true,
  });
  const [newPasswordError, setNewPasswordError] = useState("");

  const updateStudentPassword = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/update_user_field", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess(data: any) {
        if (data?.data?.success) {
          toastContext.addToast({
            status: "Success",
            message: "Password successfully updated!",
          });
          setPasswordPayload({
            fields: {
              oldpassword: "",
              password: "",
            },
            stats: false,
            student: true,
          });
          setPasswordConfirmation("");
          setIsOpen(false);
        } else if (data?.data?.message) {
          toastContext.addToast({
            status: "Error",
            message: data?.data?.message,
          });
        }
      },
    }
  );

  useEffect(() => {
    setNewPasswordError("");
    if (
      passwordPayload.fields.password.length > 0 ||
      passwordConfirmation.length > 0
    ) {
      if (passwordPayload.fields.password.length < 8) {
        setNewPasswordError("Password must be at least 8 characters.");
      } else if (passwordConfirmation !== passwordPayload.fields.password) {
        setNewPasswordError("Passwords must match.");
      }
    } else {
      setNewPasswordError("");
    }
  }, [passwordConfirmation, passwordPayload]);

  return (
    <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6">
      <div className="sm:col-span-4">
        <label
          htmlFor="old-password"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Old Password
        </label>
        <div className="mt-2">
          <input
            id="old-password"
            name="old-password"
            type="password"
            onChange={(e) => {
              setPasswordPayload({
                ...passwordPayload,
                fields: {
                  ...passwordPayload.fields,
                  oldpassword: e.target.value,
                },
              });
            }}
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="sm:col-span-3">
        <label
          htmlFor="new-password"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          New Password
        </label>
        <div className="mt-2">
          <input
            type="password"
            name="new-password"
            aria-invalid={newPasswordError ? true : false}
            aria-describedby="password-error"
            id="new-password"
            onChange={(e) => {
              setPasswordPayload({
                ...passwordPayload,
                fields: {
                  ...passwordPayload.fields,
                  password: e.target.value,
                },
              });
            }}
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
          />
        </div>
      </div>

      <div className="sm:col-span-3">
        <label
          htmlFor="confirm-password"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Confirm New Password
        </label>
        <div className="mt-2">
          <input
            type="password"
            name="confirm-password"
            id="confirm-password"
            aria-invalid={newPasswordError ? true : false}
            aria-describedby="password-error"
            onChange={(e) => {
              setPasswordConfirmation(e.target.value);
            }}
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
          />
        </div>
      </div>
      <div className="">
        <div className="-mt-4 text-sm text-red-700 sm:col-span-3">
          {newPasswordError && (
            <span role="alert" id="password-error">
              {newPasswordError}
            </span>
          )}
        </div>
        <div className="mt-2 flex justify-end sm:justify-start">
          <button
            type="button"
            onClick={() => setIsOpen(false)}
            className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={
              !!newPasswordError ||
              !passwordPayload.fields.oldpassword ||
              !passwordPayload.fields.password
            }
            onClick={() => {
              const oldpass = md5(
                "deltamath" + passwordPayload.fields.oldpassword
              );
              const newpass = md5(
                "deltamath" + passwordPayload.fields.password
              );
              setPasswordPayload({
                ...passwordPayload,
                fields: {
                  oldpassword: oldpass,
                  password: newpass,
                },
              });
              updateStudentPassword.mutate(
                JSON.stringify({
                  ...passwordPayload,
                  fields: {
                    oldpassword: oldpass,
                    password: newpass,
                  },
                })
              );
            }}
            className="ml-3 inline-flex justify-center rounded-md bg-dm-dark-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-dm-lightest-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export const updateUser = (user: any) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export default Profile;
