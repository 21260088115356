import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/outline";
import { formatNumber } from "../../../utils";
import { NoFilter } from "../../../shared/table/filters";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import DeltaMathConfirmation from "../../../shared/DeltaMathConfirmation";
import { DemoMode } from "../../../utils/demo";
import { useUserContext } from "../../../shared/contexts/UserContext";

export default function CurrentTeachers({
  teacherDataisLoading,
  teachers,
  teacherDataForTable,
}: {
  teacherDataisLoading: any;
  teachers: any;
  teacherDataForTable: any;
}) {
  const params = useParams();
  const userContext = useUserContext();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();
  const token = userContext.getJWT();

  const [userToDelete, setUserToDelete] = useState({
    id: "",
    first: "",
    last: "",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const removeTeacher = useMutation(
    (body: string) => {
      return axios.post(deltamathAPI() + "/admin/remove_teacher", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["/admin_new/data/teacher"]);
        setShowDeleteConfirmation(false);
        toastContext.addToast({
          status: "Success",
          message: "Teacher Account deleted.",
        });
      },
      onError: (err) => {
        setShowDeleteConfirmation(false);
        toastContext.addToast({
          status: "Error",
          message: "There was an error with the request",
        });
        setUserToDelete({
          id: "",
          first: "",
          last: "",
        });
      },
    }
  );

  const teachersTableColumns = [
    {
      Header: "Teacher",
      accessor: "teacher.last",
      align: "left",
      Cell: (props: any) => {
        const { id: teachercode, first, last } = props.row.original.teacher;
        return (
          <p className="text-md truncate font-medium text-blue-900">
            <Link
              to={`${process.env.REACT_APP_ADMIN_LINK}/teacher/${teachercode}`}
            >
              <DemoMode value={`${first} ${last}`} type="person_full" />
            </Link>
          </p>
        );
      },
    },
    {
      Header: "Last Login",
      align: "center",
      accessor: "last_login",
      Cell: (props: any) => {
        const { last_login } = props.row.original;
        return last_login
          ? new Date(last_login * 1000).toLocaleDateString()
          : "-";
      },
    },
    {
      Header: "Students",
      align: "center",
      accessor: "num_students",
    },
    {
      Header: "Assignments",
      align: "center",
      accessor: "assignments",
    },
    {
      Header: "Problems",
      align: "center",
      accessor: "annual_logs",
      Cell: (props: { row: { original: any } }) =>
        formatNumber(props.row.original.annual_logs),
      Filter: NoFilter,
    },
    {
      Header: "Delete",
      align: "center",
      accessor: "showDeleteButton",
      disableSortBy: true,
      Cell: (props: { row: { original: any } }) => (
        <>
          <span
            onClick={() => {
              setShowDeleteConfirmation(true);
              setUserToDelete(props.row.original.teacher);
            }}
          >
            <TrashIcon className="h-6 w-full cursor-pointer text-gray-600" />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <h3 className="mt-8 text-lg font-medium leading-6 text-gray-900">
        Teachers
      </h3>

      {teacherDataisLoading && <div>Loading...</div>}

      {!teacherDataisLoading &&
        teacherDataForTable &&
        teachers &&
        teachers.length > 0 && (
          <>
            <div className="mt-6 flow-root">
              <DeltaMathTable
                columns={teachersTableColumns}
                data={teacherDataForTable}
                options={{
                  initialState: {
                    sortBy: [
                      {
                        id: "teacher.last",
                        desc: false,
                      },
                    ],
                  },
                }}
              />
            </div>
          </>
        )}
      {!teacherDataisLoading && teachers && teachers.length === 0 && (
        <>
          <p className="text-md my-1 mb-6 text-gray-500">No teachers found.</p>

          <p>
            <Link
              className="underline hover:no-underline"
              to={`${process.env.REACT_APP_ADMIN_LINK}/schools/${params.school_id}/invite-teachers`}
            >
              Invite a teacher
            </Link>{" "}
            to get started.
          </p>
        </>
      )}

      {showDeleteConfirmation && userToDelete && (
        <DeltaMathConfirmation
          title={
            <>
              Remove Teacher{" "}
              <DemoMode
                value={`${userToDelete.first} ${userToDelete.last}`}
                type="person_full"
              />
            </>
          }
          message={
            <>
              Are you sure you want to remove{" "}
              <DemoMode
                value={`${userToDelete.first} ${userToDelete.last}`}
                type="person_full"
              />{" "}
              from the site license?
            </>
          }
          confirm="Delete"
          confirmAction={() => {
            const user = JSON.parse(localStorage.getItem("user") || "{}");
            const isTeacherAdmin = !!user.admin;
            const currentUserId = isTeacherAdmin ? user.admin : user._id;
            const body = JSON.stringify({
              params: {
                teachercode: userToDelete.id,
                userId: currentUserId,
                schoolid: params.school_id,
              },
            });
            removeTeacher.mutate(body);
          }}
          cancel="Cancel"
          cancelAction={() => {
            setShowDeleteConfirmation(false);
            setUserToDelete({
              id: "",
              first: "",
              last: "",
            });
          }}
        />
      )}
    </>
  );
}
