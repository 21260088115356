import axios from "axios";
import { useQuery } from "react-query";
import { deltamathAPI } from "../utils";

interface AddPlusInterface {
  teacherCode: string;
  expiration: number;
  revenue: number;
}

export const AddPlus = (addPlusPayload: AddPlusInterface) =>
  useQuery(
    "add-plus",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: "add-plus",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          teachercode: addPlusPayload.teacherCode,
          expiration: addPlusPayload.expiration,
          revenue: addPlusPayload.revenue,
        }),
        { headers: { "Content-Type": "application/json" } }
      );
      return request.data;
    },
    {
      enabled: false,
    }
  );

interface RemovePlusInterface {
  teacherCode: string;
}

export const RemovePlus = (removePlusPayload: RemovePlusInterface) =>
  useQuery(
    "remove-plus",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: "remove-plus",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          teachercode: removePlusPayload.teacherCode,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return request.data;
    },
    {
      enabled: false,
    }
  );

interface ResetIpInterface {
  teacherCode: string;
}

export const ResetIpAddress = (resetIpPayload: ResetIpInterface) =>
  useQuery(
    "reset-ip",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: "reset-ip",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          teachercode: resetIpPayload.teacherCode,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return request.data;
    },
    {
      enabled: false,
    }
  );

interface GetDeletedAssignmentsInterface {
  teacherCode: string;
}

export const GetDeletedAssignments = (
  getDeletedAssignmentsPayload: GetDeletedAssignmentsInterface
) =>
  useQuery(
    "get-deleted-assignments",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: "get_deleted_assignments",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          teachercode: getDeletedAssignmentsPayload.teacherCode,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return request.data;
    },
    {
      enabled: false,
    }
  );

interface UndeleteAssignmentInterface {
  teacherCode: string;
  _id: number;
}

export const UndeleteAssignment = (
  undeleteAssignmentPayload: UndeleteAssignmentInterface
) =>
  useQuery(
    "undelete_deleted_assignment",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: "undelete_deleted_assignment",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          teachercode: undeleteAssignmentPayload.teacherCode,
          _id: undeleteAssignmentPayload._id,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return request.data;
    },
    {
      enabled: false,
    }
  );

interface MergeAnotherAccountInterface {
  teacherCode: string;
  newTeacherCode: string;
}

export const MergeAnotherAccount = (
  mergeAnotherAccountPayload: MergeAnotherAccountInterface
) =>
  useQuery(
    "merge-another-account",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: "merge-accounts",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          teachercode: mergeAnotherAccountPayload.teacherCode,
          newteachercode: mergeAnotherAccountPayload.newTeacherCode,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return request.data;
    },
    {
      enabled: false,
    }
  );

interface GetSchoolOrDistrictByIdInterface {
  districtID?: number;
  schoolID?: number;
}

export const GetSchoolOrDistrictById = (
  getSchoolOrDistrictByIdProps: GetSchoolOrDistrictByIdInterface
) =>
  useQuery(
    "get-school-or-district-by-id",
    async () => {
      const request = await axios.post(
        deltamathAPI() + "/manage",
        JSON.stringify({
          type: "school-or-district-by-id",
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          districtID: getSchoolOrDistrictByIdProps.districtID,
          schoolID: getSchoolOrDistrictByIdProps.schoolID,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return request.data;
    },
    {
      enabled: false,
    }
  );

interface SearchSchoolOrDistrictInterface {
  search: string;
  state: string;
}

export const SearchSchoolOrDistrict = (
  searchSchoolOrDistrictProps: SearchSchoolOrDistrictInterface
) =>
  useQuery("quote", async () => {
    const request = await axios.post(
      deltamathAPI() + "/manage",
      JSON.stringify({
        type: "search-school-or-district",
        customer_service_token: localStorage.getItem("customer_service_token"),
        search: searchSchoolOrDistrictProps.search,
        state: searchSchoolOrDistrictProps.state,
      }),
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    return request.data;
  });
