import { forwardRef } from "react";
import { displayInlineMath } from "../../utils";
import { MQReact } from "../MQReact";
import clsx from "clsx";

type InputFieldProps = {
  currentInputField: number;
  numInputFields: number;
  leftLatex?: string;
  rightLatex?: string;
  answerWidth?: number;
  binomialExponent?: boolean;
  handleChange: (mq: any) => void;
  handleFocus: (mq: any) => void;
  handleSubmit: () => void;
};

export const InputField = forwardRef((props: InputFieldProps, mathref: any) => {
  const {
    currentInputField,
    numInputFields,
    leftLatex,
    rightLatex,
    answerWidth,
    binomialExponent,
    handleChange,
    handleFocus,
    handleSubmit,
  } = props;

  const commaStyle: string = clsx(
    "text-lg",
    rightLatex === undefined ? "self-end ml-1" : "ml-0.5"
  );

  const containerStyle: string = clsx(
    "flex items-center",
    answerWidth === undefined && numInputFields <= 1 && "grow max-w-lg",
    answerWidth === undefined &&
      numInputFields >= 2 &&
      numInputFields <= 3 &&
      "flex-initial basis-40",
    answerWidth === undefined &&
      numInputFields >= 4 &&
      numInputFields <= 6 &&
      "flex-initial basis-24",
    answerWidth === undefined &&
      numInputFields >= 7 &&
      numInputFields <= 8 &&
      "flex-initial basis-16"
  );

  return (
    <>
      <div className={containerStyle}>
        {leftLatex !== undefined ? (
          <div className="mr-1 shrink-0">{displayInlineMath(leftLatex)}</div>
        ) : null}
        <div className="w-full min-w-[32px] rounded-md border border-gray-300 shadow-sm">
          <div className="mq-simple-ans">
            <MQReact
              ref={mathref}
              id={"math-input-" + currentInputField}
              ariaLabel={createAriaLabel(leftLatex, rightLatex)}
              onChange={handleChange}
              handleSubmit={handleSubmit}
              handleFocus={handleFocus}
              config={binomialExponent ? { charsThatBreakOutOfSupSub: "" } : {}}
              cssText={answerWidth ? `width: ${answerWidth}px` : undefined}
            />
          </div>
        </div>
        {rightLatex !== undefined ? (
          <div className="ml-1 shrink-0">{displayInlineMath(rightLatex)}</div>
        ) : null}
        {currentInputField < numInputFields ? (
          <div className={commaStyle}>,</div>
        ) : null}
      </div>
    </>
  );
});

/* **************** */
/* Helper Functions */
/* **************** */

/* Function to generate aria-label from left and right latex */
const createAriaLabel = (leftLatex?: string, rightLatex?: string): string => {
  const ariaLabelArr: Array<string> = ["Answer area:"];
  const leftLatexStr = leftLatex ? window.renderA11yString(leftLatex) : "";
  const rightLatexStr = rightLatex ? window.renderA11yString(rightLatex) : "";
  if (leftLatex && rightLatex) {
    ariaLabelArr.push(leftLatexStr, "blank", rightLatexStr);
  } else if (leftLatex) {
    ariaLabelArr.push(leftLatexStr, "blank");
  } else if (rightLatex) {
    ariaLabelArr.push("blank", rightLatexStr);
  }
  return ariaLabelArr.join(" ");
};
